import { makeStyles } from "@material-ui/core/styles";
import { themeType } from "../../../types/theme-types";

const useStyles = makeStyles((theme: themeType) => ({
  root: {
    width: "36%",
    backgroundColor: "#1B1D28 !important",
    color: "#606479 !important",
    borderRadius: "20px",
    padding: "12px 24px 24px 24px",
  },
  cardContents: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "0px !important",
  },
  title: {
    color: "white",
    textAlign: "center",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    justifyContent: "center",
  },

  //report successfull modal
  card: {
    position: "relative",
    fontFamily: "poppins",
  },
  cardContentsTwo: {
    position: "absolute",
    top: "60px",
    right: "80px",
    color: "black",
    width: "320px",
    textAlign: "center",
  },
  crossBtn: {
    width: "120px",
    color: "#F9F9F9 !important",
    backgroundColor: "#6C65D9 !important",
  },
  btn: {
    border: "1px solid #6C65D9 !important",
    borderRadius: "5px",
  },
}));

export default useStyles;
