import { Box, Typography } from "@mui/material";
import { ArrowLeft } from "@phosphor-icons/react";
import { useNavigate } from "react-router";
import { FilledButton } from "../../Components/ReusableComponents/Buttons/Buttons";
import { ROUTES } from "../../Routes";
import theme from "../../Theme";

const UnauthorizedAccessPage = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Box alignItems="center" height="90vh" display="flex" mx={6}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          gap="60px"
        >
          <Box display="flex" flexDirection="column" gap="30px">
            <Typography variant="h1" fontSize="48px !important">
              Unauthorized Access
            </Typography>
            <Typography fontSize="24px !important">
              Looks like the page you were trying to access <br /> requires
              special permission.
            </Typography>
            <Box>
              <FilledButton
                isLoading={false}
                disabled={false}
                startIcon={<ArrowLeft />}
                handleClick={() => navigate(ROUTES.HOME)}
                fullWidth={false}
                minWidth={300}
                text="Go Back To Home"
              />
            </Box>
          </Box>
          <Box>
            <OppsSvg />
          </Box>
        </Box>
      </Box>
      <Box
        position="fixed"
        bottom="0px"
        bgcolor={theme.palette.secondary.main}
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding="40px 0px"
        flexDirection="column"
        gap="10px"
      >
        <Box display="flex" alignItems="center" gap="8px">
          <Typography variant="h2">Need help? Contact</Typography>
          <Typography variant="h2" color={theme.palette.primary.main}>
            cloud@vigaet.com
          </Typography>
        </Box>
        <Typography fontSize="20px">{`and we’ll get back to you ASAP! `}</Typography>
      </Box>
    </Box>
  );
};

export default UnauthorizedAccessPage;

const OppsSvg = () => {
  return (
    <svg
      width="400"
      height="400"
      viewBox="0 0 293 207"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 3H284C287.314 3 290 5.68629 290 9V197C290 200.314 287.314 203 284 203H9C5.68628 203 3 200.314 3 197V9C3 5.68629 5.68628 3 9 3ZM284 0C288.971 0 293 4.02944 293 9V197C293 201.971 288.971 206 284 206H9C4.02945 206 0 201.971 0 197V9C0 4.02944 4.02945 0 9 0H284Z"
        fill="#6C64D9"
      />
      <path
        d="M268.186 179.232C263.199 140.219 217.02 127.232 195.186 126.732C116.186 120.732 120.118 170.704 119.951 206.371L268.951 206.371C268.951 196.371 269.29 187.871 268.186 179.232Z"
        fill="#6C65D9"
      />
      <path
        d="M244.451 135.371C239.251 113.371 212.618 108.871 199.951 109.371C176.752 102.171 160.618 115.371 155.451 122.871C148.785 132.037 146.751 151.171 191.951 154.371C237.151 157.571 245.785 143.037 244.451 135.371Z"
        fill="#A09AFB"
      />
      <path
        d="M221.952 118.37C213.952 113.57 202.286 113.704 197.452 114.37C186.786 115.704 167.652 120.77 176.452 130.371C185.252 139.971 202.452 136.371 209.952 133.371C217.286 130.371 229.952 123.17 221.952 118.37Z"
        fill="#252A38"
      />
      <path
        d="M212.452 132.371L212.952 105.871L184.952 114.871L183.452 134.871C191.452 138.871 206.452 135.371 212.452 132.371Z"
        fill="#E5986D"
      />
      <path
        d="M183.952 126.371C193.552 127.971 203.285 120.037 206.952 115.871L184.452 118.871L183.952 126.371Z"
        fill="#252A38"
      />
      <path
        d="M175.288 88.1949C172.77 104.292 175.101 118.595 186.452 120.37C205.952 123.42 213.876 110.721 216.393 94.6244C218.911 78.5277 211.75 64.0395 200.399 62.264C189.048 60.4886 177.806 72.0982 175.288 88.1949Z"
        fill="#E6986D"
      />
      <path
        d="M207.61 91.4609C206.706 97.2384 207.543 102.372 211.617 103.009C218.616 104.104 221.46 99.546 222.364 93.7686C223.267 87.9912 220.697 82.791 216.623 82.1538C212.549 81.5165 208.514 85.6835 207.61 91.4609Z"
        fill="#E6986D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M218.5 92C217.948 92 217.5 92.4477 217.5 93C217.5 93.6712 217.384 94.6596 217.072 95.4411C216.747 96.253 216.373 96.5 216 96.5C215.448 96.5 215 96.9477 215 97.5C215 98.0523 215.448 98.5 216 98.5C217.627 98.5 218.503 97.247 218.928 96.1839C219.366 95.0904 219.5 93.8288 219.5 93C219.5 92.4477 219.052 92 218.5 92Z"
        fill="#1B1D28"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M183.518 79.0393C184.473 79.1811 185.329 79.6794 185.801 80.0844C186.221 80.4438 186.852 80.3953 187.211 79.976C187.571 79.5566 187.522 78.9253 187.103 78.5659C186.409 77.9709 185.215 77.2693 183.811 77.061C182.358 76.8452 180.695 77.1679 179.245 78.6181C178.854 79.0086 178.854 79.6417 179.245 80.0323C179.636 80.4228 180.269 80.4228 180.659 80.0323C181.609 79.0824 182.613 78.9051 183.518 79.0393Z"
        fill="#1B1D28"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M196.552 84.8754C196.002 84.8204 195.512 85.2214 195.457 85.7709L194.957 90.7709C194.902 91.3205 195.303 91.8105 195.853 91.8655C196.402 91.9204 196.892 91.5195 196.947 90.9699L197.447 85.9699C197.502 85.4204 197.101 84.9304 196.552 84.8754Z"
        fill="#1B1D28"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M182.052 83.3754C181.502 83.3204 181.012 83.7214 180.957 84.2709L180.457 89.2709C180.402 89.8205 180.803 90.3105 181.353 90.3655C181.902 90.4204 182.392 90.0195 182.447 89.4699L182.947 84.4699C183.002 83.9204 182.601 83.4304 182.052 83.3754Z"
        fill="#1B1D28"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M187.159 89.1633C186.769 88.7727 186.136 88.7727 185.745 89.1633C184.334 90.5745 182.125 94.7295 182.961 101.003C183.034 101.55 183.537 101.935 184.084 101.862C184.632 101.789 185.016 101.286 184.943 100.738C184.18 95.0112 186.237 91.4996 187.159 90.5775C187.55 90.1869 187.55 89.5538 187.159 89.1633Z"
        fill="#1B1D28"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M194.695 81.2954C197.382 80.6236 199.123 82.1347 199.62 82.88C199.926 83.3395 200.547 83.4637 201.007 83.1573C201.466 82.851 201.59 82.2301 201.284 81.7706C200.448 80.5159 197.922 78.427 194.21 79.3551C193.674 79.4891 193.348 80.032 193.482 80.5678C193.616 81.1036 194.159 81.4294 194.695 81.2954Z"
        fill="#1B1D28"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M184.5 111C182.622 111 182 109.89 182 109.5C182 109.11 182.622 108 184.5 108C186.378 108 187 109.11 187 109.5C187 109.89 186.378 111 184.5 111ZM184.5 113C182.015 113 180 111.433 180 109.5C180 107.567 182.015 106 184.5 106C186.985 106 189 107.567 189 109.5C189 111.433 186.985 113 184.5 113Z"
        fill="#1B1D28"
      />
      <path
        d="M166.952 63.3705C172.952 74.5705 185.785 70.7038 191.452 67.3705C194.652 78.1704 205.452 78.8705 210.452 77.8706C208.104 82.5665 208.394 87.5494 209.11 90.5285C209.448 91.9363 210.936 92.5015 212.292 91.9931L217.952 89.8706C225.152 93.4706 231.785 88.3706 233.452 85.3706C238.252 72.5706 227.619 67.0373 222.952 66.3706C221.752 56.3705 217.785 51.8704 215.452 50.3705C199.852 39.9705 193.619 47.0372 192.452 51.8705C189.652 49.4705 183.619 48.5372 180.952 48.3705C173.785 48.7038 160.952 52.1705 166.952 63.3705Z"
        fill="#1B1D28"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M235.353 148.936C235.113 148.438 234.515 148.23 234.017 148.47C229.297 150.749 216.413 155.054 203.04 153.874C202.49 153.826 202.005 154.233 201.956 154.783C201.907 155.333 202.314 155.818 202.864 155.867C216.691 157.087 229.94 152.659 234.887 150.271C235.384 150.031 235.593 149.433 235.353 148.936Z"
        fill="#252A38"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M154.397 141.539C153.938 141.845 153.814 142.466 154.12 142.925C155.239 144.603 157.259 146.323 159.9 147.805C162.559 149.296 165.908 150.582 169.756 151.351C170.298 151.459 170.824 151.108 170.933 150.567C171.041 150.025 170.69 149.498 170.148 149.39C166.496 148.66 163.346 147.445 160.879 146.061C158.396 144.668 156.666 143.138 155.784 141.816C155.478 141.356 154.857 141.232 154.397 141.539Z"
        fill="#252A38"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M202.583 167.379C202.036 167.306 201.533 167.691 201.46 168.238L202.451 168.371C201.46 168.238 201.46 168.238 201.46 168.238L201.458 168.251L201.454 168.284L201.437 168.414C201.423 168.527 201.402 168.693 201.376 168.906C201.325 169.332 201.254 169.944 201.175 170.696C201.018 172.199 200.829 174.263 200.703 176.502C200.577 178.738 200.513 181.165 200.608 183.39C200.703 185.592 200.957 187.688 201.511 189.212C201.7 189.731 202.274 189.999 202.793 189.81C203.312 189.622 203.58 189.048 203.391 188.529C202.945 187.304 202.699 185.462 202.606 183.304C202.515 181.17 202.576 178.815 202.7 176.614C202.823 174.416 203.009 172.386 203.164 170.904C203.242 170.164 203.312 169.562 203.362 169.146C203.387 168.938 203.407 168.776 203.421 168.667L203.437 168.543L203.441 168.512L203.442 168.503"
        fill="#252A38"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M179.183 164.898C178.646 164.77 178.106 165.102 177.978 165.639L178.951 165.871C177.978 165.639 177.978 165.639 177.978 165.639L177.977 165.644L177.972 165.663C177.968 165.682 177.961 165.709 177.952 165.747C177.933 165.821 177.904 165.933 177.866 166.08C177.788 166.373 177.669 166.803 177.508 167.343C177.186 168.424 176.695 169.946 176.018 171.698C174.661 175.214 172.58 179.609 169.67 183.246C169.325 183.677 169.395 184.306 169.826 184.651C170.258 184.996 170.887 184.927 171.232 184.495C174.322 180.633 176.491 176.027 177.884 172.418C178.583 170.608 179.091 169.036 179.425 167.914C179.592 167.353 179.716 166.904 179.799 166.593C179.84 166.438 179.871 166.317 179.892 166.234C179.902 166.192 179.91 166.161 179.915 166.138L179.921 166.113L179.923 166.106L179.924 166.103C179.924 166.103 179.924 166.102 178.951 165.871L179.924 166.102C180.052 165.565 179.72 165.026 179.183 164.898Z"
        fill="#252A38"
      />
      <path
        d="M152 74C152 69.0294 147.971 65 143 65H68C63.0294 65 59 69.0294 59 74V91C59 95.9706 63.0294 100 68 100H143C147.971 100 152 95.9706 152 91V74Z"
        fill="#F9F9F9"
      />
      <path
        d="M162.425 83.2727C158.945 81.2542 155.414 78.7001 152.985 76.798C152.115 76.117 150.851 76.2325 150.225 77.1429C147.539 81.0538 145.737 86.0409 144.412 89.448C144.155 90.1092 154.04 89.224 161.91 88.0037C163.967 87.6847 164.225 84.3169 162.425 83.2727Z"
        fill="#F9F9F9"
      />
      <path
        d="M111.705 85.87V84.22L116.61 77.2H119.055V84.04H120.375V85.87H119.055V88H116.955V85.87H111.705ZM117.09 79.45L114.015 84.04H117.09V79.45ZM121.712 82.39C121.712 80.66 122.022 79.305 122.642 78.325C123.272 77.345 124.312 76.855 125.762 76.855C127.212 76.855 128.247 77.345 128.867 78.325C129.497 79.305 129.812 80.66 129.812 82.39C129.812 84.13 129.497 85.495 128.867 86.485C128.247 87.475 127.212 87.97 125.762 87.97C124.312 87.97 123.272 87.475 122.642 86.485C122.022 85.495 121.712 84.13 121.712 82.39ZM127.742 82.39C127.742 81.65 127.692 81.03 127.592 80.53C127.502 80.02 127.312 79.605 127.022 79.285C126.742 78.965 126.322 78.805 125.762 78.805C125.202 78.805 124.777 78.965 124.487 79.285C124.207 79.605 124.017 80.02 123.917 80.53C123.827 81.03 123.782 81.65 123.782 82.39C123.782 83.15 123.827 83.79 123.917 84.31C124.007 84.82 124.197 85.235 124.487 85.555C124.777 85.865 125.202 86.02 125.762 86.02C126.322 86.02 126.747 85.865 127.037 85.555C127.327 85.235 127.517 84.82 127.607 84.31C127.697 83.79 127.742 83.15 127.742 82.39ZM131.154 79V77.065H134.769V88H132.609V79H131.154ZM139.799 77.35L139.559 84.595H137.789L137.534 77.35H139.799ZM138.719 88.105C138.339 88.105 138.024 87.99 137.774 87.76C137.534 87.52 137.414 87.225 137.414 86.875C137.414 86.525 137.534 86.235 137.774 86.005C138.024 85.765 138.339 85.645 138.719 85.645C139.089 85.645 139.394 85.765 139.634 86.005C139.874 86.235 139.994 86.525 139.994 86.875C139.994 87.225 139.874 87.52 139.634 87.76C139.394 87.99 139.089 88.105 138.719 88.105Z"
        fill="#6C65D9"
      />
      <path
        d="M73.135 79.225V81.85H76.66V83.515H73.135V86.29H77.11V88H71.035V77.515H77.11V79.225H73.135ZM81.1184 80.98C81.3884 80.54 81.7384 80.195 82.1684 79.945C82.6084 79.695 83.1084 79.57 83.6684 79.57V81.775H83.1134C82.4534 81.775 81.9534 81.93 81.6134 82.24C81.2834 82.55 81.1184 83.09 81.1184 83.86V88H79.0184V79.69H81.1184V80.98ZM87.1829 80.98C87.4529 80.54 87.8029 80.195 88.2329 79.945C88.6729 79.695 89.1729 79.57 89.7329 79.57V81.775H89.1779C88.5179 81.775 88.0179 81.93 87.6779 82.24C87.3479 82.55 87.1829 83.09 87.1829 83.86V88H85.0829V79.69H87.1829V80.98ZM94.8373 88.135C94.0373 88.135 93.3173 87.96 92.6773 87.61C92.0373 87.25 91.5323 86.745 91.1623 86.095C90.8023 85.445 90.6223 84.695 90.6223 83.845C90.6223 82.995 90.8073 82.245 91.1773 81.595C91.5573 80.945 92.0723 80.445 92.7223 80.095C93.3723 79.735 94.0973 79.555 94.8973 79.555C95.6973 79.555 96.4223 79.735 97.0723 80.095C97.7223 80.445 98.2323 80.945 98.6023 81.595C98.9823 82.245 99.1723 82.995 99.1723 83.845C99.1723 84.695 98.9773 85.445 98.5873 86.095C98.2073 86.745 97.6873 87.25 97.0273 87.61C96.3773 87.96 95.6473 88.135 94.8373 88.135ZM94.8373 86.305C95.2173 86.305 95.5723 86.215 95.9023 86.035C96.2423 85.845 96.5123 85.565 96.7123 85.195C96.9123 84.825 97.0123 84.375 97.0123 83.845C97.0123 83.055 96.8023 82.45 96.3823 82.03C95.9723 81.6 95.4673 81.385 94.8673 81.385C94.2673 81.385 93.7623 81.6 93.3523 82.03C92.9523 82.45 92.7523 83.055 92.7523 83.845C92.7523 84.635 92.9473 85.245 93.3373 85.675C93.7373 86.095 94.2373 86.305 94.8373 86.305ZM102.813 80.98C103.083 80.54 103.433 80.195 103.863 79.945C104.303 79.695 104.803 79.57 105.363 79.57V81.775H104.808C104.148 81.775 103.648 81.93 103.308 82.24C102.978 82.55 102.813 83.09 102.813 83.86V88H100.713V79.69H102.813V80.98Z"
        fill="#1B1D28"
      />
    </svg>
  );
};
