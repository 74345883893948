import { makeStyles } from "@material-ui/core/styles";
import { themeType } from "../../../types/theme-types";

const useStyles = makeStyles((theme: themeType) => ({
  root: {
    width: "500px",
    height: "100%",
  },
  button: {
    color: "white !important",
  },
  disabledButton: {
    color: "gray !important",
    cursor: "not-allowed",
  },
  currentPage: {
    border: "1px dashed gray",
    padding: "2px 10px",
    marginRight: "5px",
    borderRadius: "5px",
  },
}));

export default useStyles;
