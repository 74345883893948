import { Box, Typography, Button } from "@mui/material";
import useStyles from "./SortModal.style";
import { ArrowUp } from "@phosphor-icons/react";
import { useState } from "react";

type Props = {
  handleCloseSortModal: () => void;
  handleSubmitSort: (sortBy: any) => void;
  sortByProjects: string;
};

const SortModal = ({
  handleCloseSortModal,
  handleSubmitSort,
  sortByProjects,
}: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <div
        className={classes.flexContainer}
        onClick={() => {
          handleSubmitSort(sortByProjects === "A-Z" ? "Z-A" : "A-Z");
        }}
      >
        <Typography
          className={classes.sortTypes}
          style={{
            color:
              sortByProjects === "Z-A" || sortByProjects === "A-Z"
                ? "#F9F9F9"
                : "#606479",
          }}
        >
          Alphabetical Order
        </Typography>
        <Button className={classes.btnContainer}>
          <ArrowUp
            size={18}
            style={{
              transform: `rotate(${
                sortByProjects === "A-Z" ? "0deg" : "180deg"
              })`,
              transition: "transform 0.6s ease-in-out",
              color: "#6C65D9",
              visibility:
                sortByProjects === "Z-A" || sortByProjects === "A-Z"
                  ? "visible"
                  : "hidden",
            }}
          />
        </Button>
      </div>
      <div
        className={classes.flexContainer}
        onClick={() => {
          handleSubmitSort(
            sortByProjects === "LatestModified"
              ? "LastModified"
              : "LatestModified"
          );
        }}
      >
        <Typography
          className={classes.sortTypes}
          style={{
            color:
              sortByProjects === "LastModified" ||
              sortByProjects === "LatestModified"
                ? "#F9F9F9"
                : "#606479",
          }}
        >
          Date Modified
        </Typography>
        <Button className={classes.btnContainer}>
          <ArrowUp
            size={18}
            style={{
              transform: `rotate(${
                sortByProjects === "LatestModified" ? "0deg" : "180deg"
              })`,
              transition: "transform 0.6s ease-in-out",
              color: "#6C65D9",
              visibility:
                sortByProjects === "LatestModified" ||
                sortByProjects === "LastModified"
                  ? "visible"
                  : "hidden",
            }}
          />
        </Button>
      </div>
      <div className={classes.flexContainer}>
        <Button
          className={classes.clearBtn}
          onClick={(e) => {
            e.stopPropagation();
            handleSubmitSort("LatestModified");
          }}
        >
          Clear
        </Button>
        <Button className={classes.closeBtn} onClick={handleCloseSortModal}>
          Close
        </Button>
      </div>
    </Box>
  );
};

export default SortModal;
