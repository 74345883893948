import { Box, TextField, Typography } from "@mui/material";

type Props = {
  // title: string;
  // isRequired: boolean;
  name: string;
  placeholder: string;
  handleChange: any;
  value: string;
  error: string;
};
const CustomInputWithFormik = ({
  // title,
  // isRequired,
  name,
  placeholder,
  handleChange,
  value,
  error,
}: Props) => {
  return (
    <Box>
      <TextField
        name={name}
        onChange={handleChange}
        value={value}
        type="text"
        variant="standard"
        placeholder={placeholder}
        fullWidth
        sx={{
          backgroundColor: "#252A38",
          color: "white",
          borderRadius: "10px",
          mt: 1,
        }}
        InputProps={{
          disableUnderline: true,
          style: {
            height: "40px",
          },
        }}
        inputProps={{
          disableFieldSet: true,
          style: {
            padding: "8px",
            color: "white",
          },
        }}
      />
      {error ? (
        <Typography sx={{ fontSize: "10px", color: "red" }}>{error}</Typography>
      ) : (
        ""
      )}
    </Box>
  );
};

export default CustomInputWithFormik;
