import { Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import Header from "../../CommonComponents/Header";
import BreakdownDetail from "../BreakdownDetail/BreakdownDetail";
import { BreakdownPageViewStyle } from "./BreakdownPageView.style";

const styles = StyleSheet.create(BreakdownPageViewStyle);

const BreakdownPageView = ({ projectName, sequencesToExport }) => {
  return (
    <Page size="A4" style={styles.page} wrap>
      <Header projectName={projectName} />
      <Text
        style={{
          fontWeight: "600",
          fontSize: "18px",
          marginBottom: "8px",
          fontFamily: "Poppins",
        }}
      >
        Breakdown
      </Text>
      {sequencesToExport.map((eachSequence, index) => (
        <View
          style={{ width: "100%" }}
          key={eachSequence.sequence.id}
          break={index > 0}
        >
          <View
            style={{
              width: "100%",
              padding: "2px 12px",
              border: "0.5px solid black",
              borderRadius: "10px",
              marginBottom: "18px",
            }}
          >
            <Text
              style={{
                fontWeight: "500",
                fontSize: "12px",
                color: "#606479",
                textAlign: "center",
                fontFamily: "Poppins",
              }}
            >
              {eachSequence.sequence.sceneNumber}.{" "}
              {eachSequence.sequence.sceneTitle}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "18px",
            }}
          >
            {eachSequence.ShotList.map((shot) => (
              <View key={shot.name} style={{ width: "100%" }} wrap={false}>
                <BreakdownDetail shot={shot} />
              </View>
            ))}
          </View>
        </View>
      ))}
    </Page>
  );
};

export default BreakdownPageView;
