import * as Icons from "@phosphor-icons/react";
import { useEffect, useState } from "react";

interface Props {
  sizes: {
    md: number;
    lg: number;
    xl: number;
  };
  icon: string;
  fill: string;
}

interface IconProps {
  size?: number;
  fill?: string;
}

const ResponsivePhosphorIcon = ({ sizes, icon, fill }: Props) => {
  const [size, setSize] = useState<number>(16);

  const handleWindowResize = () => {
    if (window.innerWidth > 600 && window.innerWidth < 960) {
      setSize(sizes.md);
    } else if (window.innerWidth > 960 && window.innerWidth < 1537) {
      setSize(sizes.lg);
    } else {
      setSize(sizes.xl);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    handleWindowResize();
  }, [sizes, icon, window]);

  // Retrieve the factory function from the mapping object
  const createIcon = iconComponents[icon];

  return <>{createIcon && createIcon({ size, fill })}</>;
};

export default ResponsivePhosphorIcon;

// Mapping object with factory functions for each icon
const iconComponents: Record<string, (props: IconProps) => JSX.Element | null> =
  {
    X: ({ size, fill }) => <Icons.X size={size} fill={fill} />,
    Plus: ({ size, fill }) => <Icons.Plus size={size} fill={fill} />,
    Check: ({ size, fill }) => <Icons.Check size={size} fill={fill} />,
    Circle: ({ size, fill }) => <Icons.Circle size={size} fill={fill} />,
    Users: ({ size, fill }) => <Icons.Users size={size} fill={fill} />,
    Trash: ({ size, fill }) => <Icons.Trash size={size} fill={fill} />,
    SignOut: ({ size, fill }) => <Icons.SignOut size={size} fill={fill} />,
    FilmStrip: ({ size, fill }) => <Icons.FilmStrip size={size} fill={fill} />,
    Warning: ({ size, fill }) => <Icons.Warning size={size} fill={fill} />,
    CaretRight: ({ size, fill }) => (
      <Icons.CaretRight size={size} fill={fill} />
    ),
    CaretLeft: ({ size, fill }) => <Icons.CaretLeft size={size} fill={fill} />,
    MapPin: ({ size, fill }) => <Icons.MapPin size={size} fill={fill} />,
    CheckSquareOffset: ({ size, fill }) => (
      <Icons.CheckSquareOffset size={size} fill={fill} />
    ),
    FilmSlate: ({ size, fill }) => <Icons.FilmSlate size={size} fill={fill} />,
    Info: ({ size, fill }) => <Icons.Info size={size} fill={fill} />,
    VideoCamera: ({ size, fill }) => (
      <Icons.VideoCamera size={size} fill={fill} />
    ),
    PencilLine: ({ size, fill }) => (
      <Icons.PencilLine size={size} fill={fill} />
    ),
    ClockCounterClockwise: ({ size, fill }) => (
      <Icons.ClockCounterClockwise size={size} fill={fill} />
    ),
    Gear: ({ size, fill }) => <Icons.Gear size={size} fill={fill} />,
    // Add other icons as needed
  };
