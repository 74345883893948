import axios from "axios";
import {
  MovieColabProjectDetailsResponse,
  MovieColabProjectListResponse,
  MovieColabSequencesResponse,
} from "../../types/movie-colab-types";
import { createCookie, readCookie } from "../apiResources";

const movieColabUrl = "https://movie-colab-stage-5etrn5mvdq-as.a.run.app";

let accessToken = readCookie("mc_access");

interface DataType {
  name: string;
  abbreviation: string;
  description: string;
}

interface logInData {
  email: string;
  password: string;
}

const config = {
  headers: {
    Authorization: `Bearer YOUR_ACCESS_TOKEN`,
  },
};

export const movieColabLoginApi = async (data: logInData) => {
  const res = await axios.post(
    "https://viga-sso-stage-u44si7afja-as.a.run.app/api/token/",
    data
  );
  return res;
};

export const createNewMovieColabProject = async (data: DataType) => {
  try {
    const response = await fetch(`${movieColabUrl}/project/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      if (response.status === 401) {
        await refreshMcAccessTokenApi();
        const retryResponse = await fetch(`${movieColabUrl}/project/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(data),
        });

        if (!retryResponse.ok) {
          throw new Error(`Request failed with status ${retryResponse.status}`);
        }

        const retryData = await retryResponse.json();
        return retryData;
      } else {
        throw new Error(`Request failed with status ${response.status}`);
      }
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const getMovieColabProjectDetails = async (id?: number | string) => {
  try {
    const response = await fetch(`${movieColabUrl}/project/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        await refreshMcAccessTokenApi();
        // Retry the original API call with the new access token
        const retryResponse = await fetch(`${movieColabUrl}/project/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!retryResponse.ok) {
          throw new Error(`Request failed with status ${retryResponse.status}`);
        }

        const retryData = await retryResponse.json();
        return retryData;
      } else {
        throw new Error(`Request failed with status ${response.status}`);
      }
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const getMovieColabProjectSequenceList = async (
  id?: number | string
) => {
  try {
    const response = await fetch(
      `${movieColabUrl}/trackables/shot-sequence/?page=1&project=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      if (response.status === 401) {
        await refreshMcAccessTokenApi();
        const retryResponse = await fetch(
          `${movieColabUrl}/trackables/shot-sequence/?page=1&project=${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (!retryResponse.ok) {
          throw new Error(`Request failed with status ${retryResponse.status}`);
        }

        const retryData = await retryResponse.json();
        return retryData;
      } else {
        throw new Error(`Request failed with status ${response.status}`);
      }
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const getMovieColabProjectsListApi = async () => {
  const url = `${movieColabUrl}/project/`;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        await refreshMcAccessTokenApi();
        const retryResponse = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!retryResponse.ok) {
          throw new Error(`Request failed with status ${retryResponse.status}`);
        }

        const retryData = await retryResponse.json();
        return retryData;
      } else {
        throw new Error(`Request failed with status ${response.status}`);
      }
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const refreshMcAccessTokenApi = async () => {
  const res = await axios.post(
    "https://viga-sso-stage-u44si7afja-as.a.run.app/api/token/refresh/",
    { refresh: readCookie("mc_refresh") }
  );
  createCookie("mc_access", res.data.access);
  accessToken = res.data.access;
};