import { makeStyles } from "@material-ui/core/styles";
import { themeType } from "../../../types/theme-types";

const useStyles = makeStyles((theme: themeType) => ({
  root: {
    width: "200px",
    border: "0.5px solid #606479",
    borderRadius: "5px",
    fontFamily: "Poppins",
    backgroundColor: "#1B1D28",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  sortTypes: {
    color: "#f9f9f9",
    fontSize: "12px !important",
    fontFamily: "Poppins",
  },
  btnContainer: {
    minWidth: "0px !important",
    padding: "5px !important",
  },
  clearBtn: {
    minWidth: "0px !important",
    padding: "5px !important",
    color: "#606479 !important",
  },
  closeBtn: {
    minWidth: "0px !important",
    padding: "5px !important",
    color: "#6C65D9 !important",
  },
}));

export default useStyles;
