import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  Typography,
} from "@mui/material";
import { Trash, X } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  deleteStoryBoardVersionApi,
  updateShotVersionImageApi,
} from "../../../Services/StoryboardService/StoryBoardService";
import DeleteModal from "../../ReusableComponents/DeleteModal/DeleteModal";
import useStyles from "./SelectStoryBoardShotVersionModal.styles";

interface Props {
  handleClose: () => void;
  shotDetail: any;
  updateRefreshCounter: () => void;
}

const SelectStoryBoardShotVersionModal = ({
  handleClose,
  shotDetail,
  updateRefreshCounter,
}: Props) => {
  const classes = useStyles();
  const imgUrl = "https://i.ibb.co/ZMWwZCc/pexels-binyamin-mellish-106399.jpg";
  const [deleteShotVersionData, setDeleteShotVersionData] = useState({
    isOpen: false,
    versionId: "",
  });
  const [storyBoardPaths, setStoryBoardPaths] = useState(
    shotDetail?.storyboardPaths
  );
  const [selectedVersion, setSelectedVersion] = useState<number>(
    shotDetail?.selectedStoryboardVersion
  );
  const [isShotVersionDeleting, setIsShotVersionDeleting] =
    useState<boolean>(false);

  const handleDeleteStoryBoardVersion = async () => {
    setIsShotVersionDeleting(true);
    try {
      const data = {
        breakdownId: shotDetail?.breakdownId,
        shotId: shotDetail?.shotId,
        storyboardVersion: deleteShotVersionData.versionId,
      };
      const promise = toast.promise(deleteStoryBoardVersionApi(data), {
        loading: "Deleting Shot Version",
        success: "Successfully Deleted shot",
        error: "Error on delete shot",
      });
      const res = await promise;
      updateRefreshCounter();
      setIsShotVersionDeleting(false);
      setStoryBoardPaths(
        storyBoardPaths.filter(
          (sin: any) => sin?.version !== deleteShotVersionData?.versionId
        )
      );
      setDeleteShotVersionData({
        isOpen: false,
        versionId: "",
      });
    } catch (error) {
      setIsShotVersionDeleting(false);
      toast.error("Error on delete shot version");
    }
  };

  const handleSelectStoryBoardVersion = async (version: number) => {
    const data = {
      shotId: shotDetail.shotId,
      breakdownId: shotDetail.breakdownId,
      selectedStoryboardVersion: version,
    };
    const promise = toast.promise(updateShotVersionImageApi(data), {
      loading: "Updating Shot Version",
      success: "Successfully updated shot version",
      error: "Error on update shot version",
    });
    try {
      const res = await promise;
      if (res.status === 200 || 201) {
        setSelectedVersion(version);
        updateRefreshCounter();
        handleClose();
      }
    } catch (error) {
      toast.error("Error on update shot version");
    }
  };

  useEffect(() => {
    setStoryBoardPaths(shotDetail?.storyboardPaths);
    setSelectedVersion(shotDetail?.selectedStoryboardVersion);
  }, [shotDetail]);

  return (
    <Box sx={{ height: "100%" }}>
      <Box className={classes.shotNameAndCross}>
        <Typography>{shotDetail?.shotNumberText || ""}</Typography>
        <Button onClick={() => handleClose()} sx={{ color: "white" }}>
          <X size={22} fill="white" />
        </Button>
      </Box>
      {!(shotDetail && storyBoardPaths && storyBoardPaths?.length) ? (
        <Box className={classes.noResultFound}>
          <Typography>No result found</Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ marginTop: "10px" }}>
            <Typography>Generate Version</Typography>
          </Box>
          <Box className={classes.shotsContainer}>
            {storyBoardPaths.map((eachPath: any) => (
              <Box
                className={classes.singleShot}
                key={eachPath?.id}
                sx={{
                  border:
                    selectedVersion === eachPath?.version
                      ? "2px solid #6C65D9"
                      : "",
                }}
              >
                <Box>
                  <Grid container>
                    <Grid item md={4}>
                      <img
                        src={eachPath.storyboardImageURL}
                        alt=""
                        className={classes.shotImage}
                      />
                    </Grid>
                    <Grid item md={8}>
                      <Typography
                        sx={{ pt: 1, color: "#8D8E93", fontSize: "14px" }}
                      >
                        <span style={{ color: "white" }}>
                          Shot Description -{" "}
                        </span>
                        {eachPath.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.selectAndDelete}>
                  <FormControlLabel
                    value="close"
                    label="Select version to export"
                    control={
                      <Radio
                        onClick={() =>
                          handleSelectStoryBoardVersion(eachPath?.version)
                        }
                        checked={selectedVersion === eachPath?.version}
                      />
                    }
                    sx={{
                      color: "#606479",
                    }}
                  />
                  <Button
                    sx={{ minWidth: "0px" }}
                    onClick={() =>
                      setDeleteShotVersionData({
                        isOpen: true,
                        versionId: eachPath.version,
                      })
                    }
                    disabled={selectedVersion === eachPath?.version}
                  >
                    <Trash
                      size={22}
                      style={{ cursor: "pointer" }}
                      fill={
                        selectedVersion === eachPath?.version ? "gray" : "red"
                      }
                    />
                  </Button>
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
      <Modal
        open={deleteShotVersionData.isOpen}
        className={classes.flexCenter}
        onClose={() =>
          setDeleteShotVersionData({
            isOpen: false,
            versionId: "",
          })
        }
      >
        <DeleteModal
          handleCloseDeleteModal={() =>
            setDeleteShotVersionData({
              isOpen: false,
              versionId: "",
            })
          }
          name={deleteShotVersionData.versionId}
          handleDelete={() => handleDeleteStoryBoardVersion()}
          type="shot version"
          isDeleting={isShotVersionDeleting}
        />
      </Modal>
    </Box>
  );
};

export default SelectStoryBoardShotVersionModal;
