import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { X } from "@phosphor-icons/react";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  ContextTypes,
  ScriptBreakdownContext,
} from "../../../Context/ContextDataProvider";
import { ROUTES } from "../../../Routes";
import { selectBreakdownVersionApi } from "../../../Services/breakdownServices/breakdownServices";
import {
  ProjectIdType,
  SequenceListType,
  SequenceType,
} from "../../../types/public-types";
import {
  FilledButton,
  OutlinedButton,
} from "../../ReusableComponents/Buttons/Buttons";
import Loader from "../../ReusableComponents/Loader/Loader";
import { GenerateNewBreakdownSvg } from "../../ReusableComponents/SvgAndIcons/GenerateNewBreakdownSvg";
import ResponsivePhosphorIcon from "../../ReusableComponents/SvgAndIcons/ResponsivePhosphorIcon";
import { StoryBoardIcon } from "../../ReusableComponents/SvgAndIcons/StoryBoardIcon";
import {
  ArrowDown,
  CharacterIcon,
  GrayDot,
  PlusWithDownArrow,
  PlusWithUpArrow,
  SelectAllIcon,
  WhiteDot,
} from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import ConfigureBreakdownModal from "../Modals/ConfigureBreakdownModal";
import useStyles from "./Breakdown.style";
import CharactersViewer from "./Characters/CharactersViewer";
import LocationsViewer from "./LocationsViewer";
import SequenceListView from "./SequenceListView";
import ShotsViewer from "./ShotsViewer";

interface Props {
  projectId: ProjectIdType;
  fileId: string;
  setSelectedSequencePages: any;
  setSelectedSequence: any;
  selectedSequence: SequenceType | null;
}

const BreakDown = ({
  projectId,
  fileId,
  setSelectedSequencePages,
  setSelectedSequence,
  selectedSequence,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    sequenceList,
    isSequenceListLoading,
    handleGetSequences,
  }: ContextTypes = useContext(ScriptBreakdownContext);

  const [filteredSequenceList, setFilteredSequenceList] =
    useState<SequenceListType>([]);
  const [isSequencesExpanded, setIsSequencesExpanded] = useState<boolean>(true);
  const [currentView, setCurrentView] = useState("sequences");
  const [sequenceSearchText, setSequenceSearchText] = useState<string>("");
  const [breakdownLevel, setBreakdownLevel] = useState<number>(3);
  const [isConfigureModalOpen, setIsConfigureModalOpen] =
    useState<boolean>(false);
  const [refreshSelectedBreakdownVersion, setRefreshSelectedBreakdownVersion] =
    useState<number>(0);
  const [createNewSceneModalData, setCreateNewSceneModalData] = useState({
    isOpen: false,
  });
  const [
    selectedScenesForGenerateBreakdown,
    setSelectedScenesForGenerateBreakdown,
  ] = useState<string[]>();
  const [selectedGenerateOption, setSelectedGenerateOption] =
    useState<string>("up");

  useEffect(() => {
    if (!isSequenceListLoading && sequenceList?.length) {
      setFilteredSequenceList(sequenceList);
      if (selectedSequence) {
        const selected = sequenceList?.find(
          (seq: SequenceType) => seq.id === selectedSequence.id
        );
        setSelectedSequence(selected ? selected : null);
        setSelectedSequencePages(selected?.pageNumbers);
      } else {
        setSelectedSequence(sequenceList[0]);
        setSelectedSequencePages(sequenceList[0]?.pageNumbers);
      }
    }
  }, [isSequenceListLoading, sequenceList]);

  const handleSelectUnselectSequenceForGenerate = async (
    sequence: SequenceType
  ) => {
    if (selectedScenesForGenerateBreakdown?.length) {
      const isSelected = selectedScenesForGenerateBreakdown?.some(
        (single: string) => single === sequence.id
      );
      if (isSelected) {
        const existing = selectedScenesForGenerateBreakdown.filter(
          (single: string) => single !== sequence.id
        );
        setSelectedScenesForGenerateBreakdown(existing);
      } else {
        setSelectedScenesForGenerateBreakdown((prev: any) => [
          ...prev,
          sequence.id,
        ]);
      }
    } else {
      setSelectedScenesForGenerateBreakdown([sequence.id]);
    }
  };

  const handleSelectUnselectAllSequenceForGenerate = async (type: string) => {
    if (type === "selectAll") {
      const ids = filteredSequenceList.map((single: SequenceType) => single.id);
      setSelectedScenesForGenerateBreakdown(ids);
    } else {
      setSelectedScenesForGenerateBreakdown([]);
    }
  };

  const handleSelectBreakdownVersion = async (id: any) => {
    toast.loading("Selecting Version");
    try {
      const res = await selectBreakdownVersionApi(
        fileId,
        selectedSequence?.id,
        id
      );
      if (res) {
        toast.success("Breakdown Version Selected");
        handleGetSequences(projectId, fileId);
      }
    } catch (error) {
      toast.error("Error On Select Breakdown");
    }
  };

  useEffect(() => {
    if (refreshSelectedBreakdownVersion) {
      handleGetSequences(projectId, fileId);
    }
  }, [refreshSelectedBreakdownVersion]);

  useEffect(() => {
    if (sequenceSearchText) {
      const filteredSequences = sequenceList.filter((single: SequenceType) =>
        single.sceneTitle
          .toLowerCase()
          .includes(sequenceSearchText.toLowerCase())
      );
      setFilteredSequenceList(filteredSequences);
    } else {
      setFilteredSequenceList(sequenceList);
    }
  }, [sequenceSearchText]);

  const buttonCommonStyle = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    color: "white",
    mb: 1,
    width: "100%",
    justifyContent: "start",
    borderRadius: "10px",
    p: 1,
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.breakDownText}>
        <Box></Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <GenerateNewBreakdownSvg />
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
              fontSize: { md: "16px", lg: "16px", xl: "18px" },
            }}
          >
            Break Down
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
          <Button>
            <ResponsivePhosphorIcon
              sizes={{ md: 22, lg: 24, xl: 28 }}
              icon="ClockCounterClockwise"
              fill="#606479"
            />
          </Button>
          <Button onClick={() => setIsConfigureModalOpen(true)}>
            <ResponsivePhosphorIcon
              sizes={{ md: 22, lg: 24, xl: 28 }}
              icon="Gear"
              fill="#606479"
            />
          </Button>
        </Box>
      </Box>
      <Box className={classes.leftMenuAndRightDetails}>
        <Box
          sx={{
            height: "94%",
            borderRadius: "10px",
          }}
        >
          <Grid container spacing={1} sx={{ height: "100%", width: "100%" }}>
            <Grid item md={3} sm={3} sx={{ height: "100%" }}>
              <Box className={classes.leftSideContents}>
                <Typography className={classes.breakDownElement}>
                  Break Down Elements
                </Typography>

                <Box sx={{ height: "100%" }}>
                  <Box>
                    <Button
                      onClick={() => {
                        setIsSequencesExpanded(!isSequencesExpanded);
                      }}
                      sx={{
                        ...buttonCommonStyle,
                        backgroundColor:
                          currentView === "sequences" ? "#252A38" : "",
                      }}
                    >
                      {isSequencesExpanded ? (
                        <ArrowDown />
                      ) : (
                        <ResponsivePhosphorIcon
                          sizes={{ md: 16, lg: 18, xl: 20 }}
                          icon="CaretRight"
                          fill="white"
                        />
                      )}
                      <ResponsivePhosphorIcon
                        sizes={{ md: 16, lg: 18, xl: 20 }}
                        icon="FilmStrip"
                        fill="white"
                      />
                      <Typography>Sequences</Typography>
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      // paddingLeft: "15px",
                      height: { md: "70%", lg: "72%", xl: "78%" },
                      overflow: "auto",
                      display: isSequencesExpanded ? "block" : "none",
                    }}
                  >
                    <Box className={classes.searchBoxContainer}>
                      <input
                        className={classes.searchInput}
                        placeholder="Search"
                        onChange={(e) => setSequenceSearchText(e.target.value)}
                        value={sequenceSearchText}
                      />
                      {sequenceSearchText ? (
                        <Button
                          sx={{
                            minWidth: "0px",
                            backgroundColor: "#1B1D28",
                            borderRadius: "10px",
                          }}
                          onClick={() => setSequenceSearchText("")}
                        >
                          <X size={18} fill="#606479" />
                        </Button>
                      ) : (
                        ""
                      )}
                    </Box>
                    <Box className={classes.flexBetween} px={2} mb={0}>
                      <Typography sx={{ color: "#606479" }}>
                        Selected -{" "}
                        <span style={{ color: "white", fontSize: "16px" }}>
                          {selectedScenesForGenerateBreakdown?.length}
                        </span>
                      </Typography>
                      <Box display="flex" alignItems="center" gap="2px">
                        <Button
                          onClick={() =>
                            setCreateNewSceneModalData({ isOpen: true })
                          }
                        >
                          <PlusWithUpArrow />
                        </Button>
                        <Button
                          onClick={() =>
                            setCreateNewSceneModalData({ isOpen: true })
                          }
                        >
                          <PlusWithDownArrow />
                        </Button>
                        <Button
                          disabled={
                            selectedScenesForGenerateBreakdown?.length === 0
                          }
                          onClick={() =>
                            setCreateNewSceneModalData({ isOpen: true })
                          }
                        >
                          <GenerateNewBreakdownSvg />
                        </Button>
                      </Box>
                    </Box>
                    {selectedScenesForGenerateBreakdown?.length ? (
                      <Box className={classes.flexBetween} px={2} mb={1}>
                        <Button
                          className={classes.selectAllButton}
                          onClick={() =>
                            handleSelectUnselectAllSequenceForGenerate(
                              "selectAll"
                            )
                          }
                        >
                          <SelectAllIcon />
                          <Typography>Select All</Typography>
                        </Button>
                        <Button
                          className={classes.clearAllButton}
                          onClick={() =>
                            handleSelectUnselectAllSequenceForGenerate(
                              "unselectAll"
                            )
                          }
                        >
                          <X size={20} fill="#6C65D9" />
                          <Typography>Clear</Typography>
                        </Button>
                      </Box>
                    ) : (
                      ""
                    )}
                    {!isSequenceListLoading ? (
                      <>
                        <SequenceListView
                          selectedSequence={selectedSequence}
                          sequenceList={filteredSequenceList}
                          setSelectedSequence={setSelectedSequence}
                          currentView={currentView}
                          setCurrentView={setCurrentView}
                          selectedScenesForGenerateBreakdown={
                            selectedScenesForGenerateBreakdown
                          }
                          handleSelectUnselectSequenceForGenerate={
                            handleSelectUnselectSequenceForGenerate
                          }
                        />
                      </>
                    ) : (
                      <Loader />
                    )}
                  </Box>
                  <Box>
                    <Button
                      onClick={() => {
                        setCurrentView("characters");
                        setSelectedSequence(null);
                      }}
                      sx={{
                        ...buttonCommonStyle,
                        backgroundColor:
                          currentView === "characters" ? "#252A38" : "",
                        border:
                          currentView === "characters"
                            ? "2px solid #6C65D9"
                            : "",
                      }}
                    >
                      {currentView === "characters" ? (
                        <WhiteDot />
                      ) : (
                        <GrayDot />
                      )}
                      <CharacterIcon />
                      <Typography>Characters</Typography>
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      onClick={() => {
                        setCurrentView("locations");
                        setSelectedSequence(null);
                      }}
                      sx={{
                        ...buttonCommonStyle,
                        backgroundColor:
                          currentView === "locations" ? "#252A38" : "",
                        border:
                          currentView === "locations"
                            ? "2px solid #6C65D9"
                            : "",
                      }}
                    >
                      {currentView === "locations" ? <WhiteDot /> : <GrayDot />}
                      <ResponsivePhosphorIcon
                        sizes={{ md: 12, lg: 16, xl: 20 }}
                        icon="MapPin"
                        fill="white"
                      />
                      <Typography>Locations</Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              md={9}
              sm={9}
              sx={{ height: { md: "90%", lg: "92%", xl: "98%" } }}
            >
              <Box className={classes.rightSideContainer}>
                {currentView === "sequences" ? (
                  <ShotsViewer
                    selectedSequence={selectedSequence}
                    setSelectedSequencePages={setSelectedSequencePages}
                    projectId={projectId}
                    fileId={fileId}
                    handleSelectBreakdownVersion={handleSelectBreakdownVersion}
                    handleUpdateBreakdownVersionRefresh={() =>
                      setRefreshSelectedBreakdownVersion(
                        refreshSelectedBreakdownVersion + 1
                      )
                    }
                    breakdownLevel={breakdownLevel}
                  />
                ) : currentView === "characters" ? (
                  <CharactersViewer projectId={projectId} fileId={fileId} />
                ) : (
                  <LocationsViewer projectId={projectId} />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.bottomButtonContainer}>
          <OutlinedButton
            text="Story Board"
            disabled={false}
            handleClick={() => navigate(ROUTES.STORYBOARD.VIEW(projectId))}
            fullWidth={false}
            minWidth={80}
            startIcon={<StoryBoardIcon fill="#F9F9F9" />}
          />
          <FilledButton
            text="Export Preview"
            disabled={false}
            handleClick={() => navigate(ROUTES.EXPORT_PREVIEW.VIEW(projectId))}
            fullWidth={false}
            minWidth={80}
            startIcon={
              <ResponsivePhosphorIcon
                sizes={{ md: 12, lg: 16, xl: 20 }}
                icon="SignOut"
                fill="white"
              />
            }
            isLoading={false}
          />
        </Box>
      </Box>
      <Modal
        open={isConfigureModalOpen}
        className={classes.flexCenter}
        onClose={() => setIsConfigureModalOpen(false)}
      >
        <ConfigureBreakdownModal
          handleClose={() => setIsConfigureModalOpen(false)}
          setBreakdownLevel={setBreakdownLevel}
          breakdownLevel={breakdownLevel}
        />
      </Modal>
    </Box>
  );
};

export default BreakDown;
