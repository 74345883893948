import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { EnvelopeSimple, Eye, EyeSlash } from "@phosphor-icons/react";
import { signInWithPopup } from "firebase/auth";
import { useFormik } from "formik";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { ScriptBreakdownContext } from "../../Context/ContextDataProvider";
import { ROUTES } from "../../Routes";
import { createCookie } from "../../Services/apiResources";
import { UserSignUpApi } from "../../Services/authServices/authServices";
import { auth, googleProvider } from "../../Services/firebase";
import { Logo } from "../ReusableComponents/SvgAndIcons/ScriptVizLogo";
import { GoogleIcon } from "../ReusableComponents/SvgAndIcons/SvgIcons";
import useStyles from "./AuthenticationStyles.style";
import RightSideWithLogo from "./RightSideWithLogo";

const SignUpPageOverview = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { getUserData } = useContext(ScriptBreakdownContext);
  const [seePassword, setSeePassword] = useState<boolean>(false);
  const [isSigningIn, setIsSigningIn] = useState<boolean>(false);

  //Validators
  const SignUpValidationSchema = yup.object({
    firstName: yup
      .string()
      .label("firstName")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 50")
      .required("First Name Is Required"),
    lastName: yup
      .string()
      .label("lastName")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 50")
      .required("Last Name Is Required"),
    email: yup.string().label("email").required("Email is required"),
    password: yup.string().label("password").required("Password is required"),
    confirmPassword: yup
      .string()
      .label("confirmPassword")
      .required("Confirm Password is required"),
  });
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: SignUpValidationSchema,
    onSubmit: async (values) => {
      setIsSigningIn(true);
      const data = {
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNo: " ",
      };
      if (values.password !== values.confirmPassword) {
        formik.errors.confirmPassword = "didn't match";
      } else {
        try {
          const res = await UserSignUpApi(data);
          if (res.status === 200) {
            toast.success("Successfully created account");
            setIsSigningIn(false);
            navigate(ROUTES.LOG_IN);
          }
        } catch (error) {
          setIsSigningIn(false);
          //@ts-ignore
          toast.error(`${error?.response?.data?.message}`);
        }
      }
    },
  });

  const handleSignInUsingGoogle = async () => {
    try {
      const data: any = await signInWithPopup(auth, googleProvider);
      if (data?.user?.email) {
        toast.success("Logged in successfully");
        createCookie("refresh", data.user?.stsTokenManager?.refreshToken, "");
        createCookie("access", data.user?.stsTokenManager?.accessToken, "");
        getUserData();
        navigate(ROUTES.HOME);
      }
    } catch (error) {
      toast.error("Error on log in");
      console.log(error);
    }
  };
  return (
    <Box className={classes.root}>
      <Grid container spacing={4} sx={{ height: "100%" }}>
        <Grid item md={6} sx={{ height: "100%" }}>
          <Box className={classes.leftSideContainer}>
            <Box>
              <Logo />
            </Box>
            <Box>
              <Typography variant="h4">Create Your Account</Typography>
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <Box className={classes.inputsContainer}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    width: "100%",
                  }}
                >
                  <Box
                    className={classes.inputLabelAndField}
                    sx={{ width: "100%" }}
                  >
                    <Typography className={classes.inputLabel}>
                      First name <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      placeholder="First Name"
                      type="text"
                      name="firstName"
                      fullWidth
                      variant="standard"
                      className={classes.inputField}
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <FirstNameIcon />
                          </InputAdornment>
                        ),
                        disableUnderline: true,
                      }}
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <Typography sx={{ color: "red", fontSize: "10px" }}>
                        {formik.errors.firstName}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    className={classes.inputLabelAndField}
                    sx={{ width: "100%" }}
                  >
                    <Typography className={classes.inputLabel}>
                      Last name <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      placeholder="Last Name"
                      type="text"
                      name="lastName"
                      fullWidth
                      variant="standard"
                      className={classes.inputField}
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <LastNameIcon />
                          </InputAdornment>
                        ),
                        disableUnderline: true,
                      }}
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <Typography sx={{ color: "red", fontSize: "10px" }}>
                        {formik.errors.lastName}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box className={classes.inputLabelAndField}>
                  <Typography className={classes.inputLabel}>
                    Email <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    placeholder="Enter your email address"
                    type="email"
                    name="email"
                    fullWidth
                    variant="standard"
                    className={classes.inputField}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <EnvelopeSimple size={22} fill="#606479" />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                    }}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <Typography sx={{ color: "red", fontSize: "10px" }}>
                      {formik.errors.email}
                    </Typography>
                  )}
                </Box>
                <Box className={classes.inputLabelAndField}>
                  <Typography className={classes.inputLabel}>
                    Password <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    placeholder="Password"
                    type={`${seePassword ? "text" : "password"}`}
                    name="password"
                    fullWidth
                    variant="standard"
                    className={classes.inputField}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {seePassword ? (
                            <EyeSlash
                              size={22}
                              fill="#606479"
                              style={{ cursor: "pointer" }}
                              onClick={() => setSeePassword(!seePassword)}
                            />
                          ) : (
                            <Eye
                              size={20}
                              fill="#606479"
                              style={{ cursor: "pointer" }}
                              onClick={() => setSeePassword(!seePassword)}
                            />
                          )}
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                    }}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <Typography sx={{ color: "red", fontSize: "10px" }}>
                      {formik.errors.password}
                    </Typography>
                  )}
                </Box>
                <Box className={classes.inputLabelAndField}>
                  <Typography className={classes.inputLabel}>
                    Confirm Password <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    placeholder="Confirm Password"
                    type={`${seePassword ? "text" : "password"}`}
                    name="confirmPassword"
                    fullWidth
                    variant="standard"
                    className={classes.inputField}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                  {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword && (
                      <Typography sx={{ color: "red", fontSize: "10px" }}>
                        {formik.errors.confirmPassword}
                      </Typography>
                    )}
                </Box>
                <FormControlLabel
                  required
                  control={<Checkbox sx={{ color: "#606479" }} />}
                  label="I agree to Terms and Conditions"
                  sx={{ color: "#606479", fontSize: "12px" }}
                />
              </Box>
              <Box sx={{ mt: 6 }}>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.loginOrSignupButton}
                  type="submit"
                  disabled={isSigningIn}
                >
                  {isSigningIn ? (
                    <CircularProgress size={22} color="primary" />
                  ) : (
                    "Sign Up"
                  )}
                </Button>
              </Box>
            </form>
            <Box>
              <Typography sx={{ textAlign: "center", color: "#606479" }}>
                Already have an account ?{" "}
                <Link
                  to={ROUTES.LOG_IN}
                  style={{ color: "#6C65D9", textDecoration: "none" }}
                >
                  Log In
                </Link>{" "}
              </Typography>
            </Box>
            <Box>
              <Button
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                className={classes.gmailAndAppleButton}
                disableElevation
                onClick={handleSignInUsingGoogle}
              >
                <GoogleIcon />
                <Typography>Log In With Gmail</Typography>
              </Button>
              {/* <Button
                fullWidth
                variant="contained"
                className={classes.gmailAndAppleButton}
                disableElevation
              >
                <AppleLogo />
                <Typography>Log In With Apple</Typography>
              </Button> */}
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} sx={{ height: "100%" }}>
          <RightSideWithLogo />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignUpPageOverview;

const FirstNameIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.87868 12.1775C3.31607 12.7401 3 13.5032 3 14.2988C3 14.6967 3.15804 15.0782 3.43934 15.3595C3.72064 15.6408 4.10218 15.7988 4.5 15.7988H9V11.2988H6C5.20435 11.2988 4.44129 11.6149 3.87868 12.1775Z"
        stroke="#606479"
        strokeLinejoin="round"
      />
      <path
        d="M9 8.29883V6.04883V3.79883C7.75736 3.79883 6.75 4.80619 6.75 6.04883C6.75 7.29147 7.75736 8.29883 9 8.29883Z"
        stroke="#606479"
      />
    </svg>
  );
};

const LastNameIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00002 11.2988L9 15.7989H13.5C13.8978 15.7989 14.2794 15.6409 14.5607 15.3596C14.842 15.0783 15 14.6967 15 14.2989C15 13.5033 14.684 12.7402 14.1213 12.1776C13.5587 11.615 12.7957 11.2989 12 11.2989L9.00002 11.2988Z"
        stroke="#606479"
        strokeLinejoin="round"
      />
      <path
        d="M9.00007 8.29883C10.2427 8.29883 11.2501 7.29147 11.2501 6.04883C11.2501 4.80619 10.2427 3.79883 9.00007 3.79883C9.00007 6.21634 9 4.80619 9 6.04883L9.00007 8.29883Z"
        stroke="#606479"
      />
    </svg>
  );
};
