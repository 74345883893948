import { Box, Typography } from "@mui/material";
import useStyles from "./NoDataFoundCreditPage.style";
import { NoDataFoundCreditPageSvg } from "../../ReusableComponents/SvgAndIcons/NoDataFoundCreditPageSvg";

const NoDataFoundCreditPage = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <NoDataFoundCreditPageSvg />
      <Box className={classes.card}>
        <Box className={classes.cardContent}>
          <Typography sx={{ fontSize: "14px !important", fontWeight: 600 }}>
            No data found!
          </Typography>
          <Typography
            sx={{
              fontSize: "12px !important",
              textAlign: "center",
              fontWeight: 500,
            }}
          >
            We will update your Credit Usage as soon as there is transaction.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NoDataFoundCreditPage;
