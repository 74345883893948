import { Box, Button, Grid, Typography } from "@mui/material";
import { Slideshow, SortAscending } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { getAllLocationsApi } from "../../../Services/characterAndLocationServices/characterAndLocationServices";
import { Location, ProjectIdType } from "../../../types/public-types";
import Loader from "../../ReusableComponents/Loader/Loader";
import { WhiteDot } from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import useStyles from "./Breakdown.style";
import { toast } from "react-hot-toast";

interface Props {
  projectId: ProjectIdType;
}
const LocationsViewer = ({ projectId }: Props) => {
  const classes = useStyles();
  const [isLocationsLoaded, setIsLocationsLoaded] = useState(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    getAllLocations();
  }, [projectId]);

  const getAllLocations = async () => {
    try {
      const res = await getAllLocationsApi(projectId);
      setLocations(res.data.locations);
      setTotal(res.data.size);
      setIsLocationsLoaded(true);
    } catch (error) {
      toast.error("Error on getting locations");
    }
  };
  return (
    <div>
      <Box className={classes.rightSideTitle}>
        <Typography className={classes.dotAndText} sx={{ py: 1 }}>
          <WhiteDot />
          <span>Locations</span>
        </Typography>
        <Typography sx={{ fontSize: "12px" }}>Total-{total}</Typography>
      </Box>
      <Box className={classes.rightSideTools}>
        <Button
          sx={{
            display: "flex",
            color: "#606479",
            gap: "5px",
            alignItems: "center",
            minWidth: "0px",
          }}
        >
          <Slideshow size={22} />
          <span>Card View</span>
        </Button>
        <Button
          sx={{
            display: "flex",
            color: "#606479",
            gap: "5px",
            alignItems: "center",
            minWidth: "0px",
          }}
        >
          <SortAscending size={22} />
          <span>Sort</span>
        </Button>
      </Box>
      <Box className={classes.shortListContainer}>
        {isLocationsLoaded ? (
          <>
            {locations.length ? (
              <>
                {locations.map((singleCharacter: Location) => (
                  <Grid
                    container
                    className={classes.singleCharacterOrLocationCard}
                  >
                    <Grid item md={4}>
                      <Typography sx={{ textAlign: "start" }}>
                        {singleCharacter.name}
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography sx={{ textAlign: "center" }}>
                        <span style={{ color: "#8D8E94" }}>Sequences : </span>
                        {singleCharacter.linkedSequence.length}
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography sx={{ textAlign: "end" }}>
                        <span style={{ color: "#8D8E94" }}>Shots : </span>
                        {singleCharacter.linkedShots.length}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </>
            ) : (
              <p>No Characters found</p>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Box>
    </div>
  );
};

export default LocationsViewer;
