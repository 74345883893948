import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Chip,
  Modal,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import {
  CoinIconSmall,
  CreditPricingLogo,
} from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import { ScriptBreakdownContext } from "../../../Context/ContextDataProvider";
import PaymentSuccessfulModal from "../CreditPageModals/PaymentSuccessfulModal";
import PaymentFailModal from "../CreditPageModals/PaymentFailModal";
import { ProductPricing } from "../../../types/public-types";
import { purchaseProduct } from "../../../Services/creditPageServices/creditPageServices";
import toast from "react-hot-toast";

type Props = {
  creditPricing: ProductPricing;
};

const CreditPricing = ({ creditPricing }: Props) => {
  const location = useLocation();
  const { availableCredits } = useContext(ScriptBreakdownContext);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isSuccessfulModalOpen, setSuccessfulModalOpen] =
    useState<boolean>(false);
  const [isFailModalOpen, setFailModalOpen] = useState<boolean>(false);
  const decodedUrl = decodeURIComponent(location.search.substring(1));
  const searchParams = new URLSearchParams(decodedUrl);

  useEffect(() => {
    if (searchParams.get("paymentstatus")) {
      if (searchParams.get("paymentstatus") === "success") {
        handleSuccessfulModal();
        setTimeout(() => {
          setSuccessfulModalOpen(false);
        }, 5000);
      } else if (searchParams.get("paymentstatus") === "fail") {
        handleFailModal();
        setTimeout(() => {
          setFailModalOpen(false);
        }, 5000);
      }
      searchParams.delete("paymentstatus");
      searchParams.delete("creditQuantity");
      const updatedUrl = `${
        window.location.pathname
      }?${searchParams.toString()}`;
      window.history.replaceState({ path: updatedUrl }, "", updatedUrl);
    }
  }, []);

  const handleSuccessfulModal = () => {
    setSuccessfulModalOpen(!isSuccessfulModalOpen);
  };

  const handleFailModal = () => {
    setFailModalOpen(!isFailModalOpen);
  };

  const buyCredits = async () => {
    setShowLoader(true);
    const encodeSuccess = encodeURIComponent(
      `paymentstatus=success&creditQuantity=${creditPricing.metadata?.creditQuantity}`
    );
    const encodeFail = encodeURIComponent(
      `paymentstatus=fail&creditQuantity=${creditPricing.metadata?.creditQuantity}`
    );
    const successUrl = `${window.location.href}?${encodeSuccess}`;
    const failUrl = `${window.location.href}?${encodeFail}`;
    try {
      const res = await purchaseProduct(
        creditPricing.default_price,
        successUrl,
        failUrl
      );
      toast.success("redirecting..");
      window.location.href = res.data.url;
    } catch (error) {
      toast.error("Error on redirecting to stripe");
    }
  };

  return (
    <Box
      style={{
        height: "100%",
        width: "23%",
        borderRadius: "20px",
        padding: "18px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#1B1D28",
      }}
    >
      <Typography
        style={{
          display: "flex",
          gap: "8px",
          fontSize: "18px",
          fontFamily: "Poppins",
          fontWeight: "400",
          color: "#f9f9f9",
          alignItems: "center",
        }}
      >
        <CreditPricingLogo />
        {creditPricing.name}
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <Chip
          style={{
            height: "44px",
            padding: "4px 6px 4px 4px",
            borderRadius: "27px",
            color: "white",
            fontSize: "18px",
          }}
          avatar={<CoinIconSmall />}
          label={creditPricing.metadata?.creditQuantity}
          variant="outlined"
        />
        <Tooltip
          title={
            creditPricing.description
              ? creditPricing.description
              : `Grants ${creditPricing.metadata?.creditQuantity} credits to your account`
          }
        >
          <Typography
            style={{
              width: "175px",
              height: "45px",
              fontSize: "10px",
              textAlign: "center",
              fontFamily: "Poppins",
              fontWeight: "400",
              color: "#606479",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: 2,
            }}
          >
            {creditPricing.description
              ? creditPricing.description
              : `Grants ${creditPricing.metadata?.creditQuantity} credits to your account`}
          </Typography>
        </Tooltip>
      </div>
      <Typography
        style={{ fontSize: "18px", fontWeight: "500", fontFamily: "Poppins" }}
      >
        {creditPricing.metadata?.currency} {creditPricing.metadata?.cost}
      </Typography>
      <Button
        sx={{
          minWidth: "0px",
          padding: "4px 8px !important",
          borderRadius: "5px",
          backgroundColor: "#6C65D9",
          color: "#f9f9f9",
          "&:hover": {
            backgroundColor: "rgba(108, 101, 217, 0.5)",
          },
        }}
        disabled={showLoader}
        onClick={buyCredits}
      >
        {showLoader ? <CircularProgress /> : "Buy Credits"}
      </Button>
      <Modal
        open={isSuccessfulModalOpen}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={handleSuccessfulModal}
      >
        <PaymentSuccessfulModal
          handleSuccessfulModal={setSuccessfulModalOpen}
          creditQuantity={searchParams.get("creditQuantity")}
          availableCredits={availableCredits}
        />
      </Modal>
      <Modal
        open={isFailModalOpen}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={handleFailModal}
      >
        <PaymentFailModal
          handleFailModal={setFailModalOpen}
          creditQuantity={searchParams.get("creditQuantity")}
        />
      </Modal>
    </Box>
  );
};

export default CreditPricing;
