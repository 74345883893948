import axios from "axios";

const azureService = process.env.REACT_APP_BASE_URL_AZURE_SERVICE || "";
const baseUrl = process.env.REACT_APP_BASE_URL;
const generationService = process.env.REACT_APP_GEN_SERVICE;
interface CancelationResponse {}

export const cancelStoryBoardGeneration = async (requestPayload: any) => {
  const res = await axios.post<CancelationResponse>(
    `${generationService}`,
    requestPayload
  );
  return res;
};

// create storyboard
// POST http://20.212.145.115:8000/generate/
// PayloadEG:
// {
//     "projectId": 6,
//     "sequenceId": 1,
//     "breakdownId": 8,
//     "shot_list": [
//         {
//             "shotNumberText": "Shot_002:",
//             "dialogue": "",
//             "Description": "The camera pans across the interior of an insanely expensive restaurant on the Upper East Side. The decorblends    chi-chi and rustic elements, with swagged silk curtains, handwritten menus, and pale pink tablecloths adornedwith     arrangements of moss, twigs, and exotic flowers.",
//             "Camera_Position": "Wide shot capturing the opulent interior design.",
//             "Midjourney_Prompt": "Cinematic It is 3 am. Bateman is standing at an ATM, listening to the comforting sound of fresh bills thudding out of the machine.  Low angle shot, capturing the ATM machine and Bateman in the dimly lit street.",
//             "breakdownId": "AO5c9Dh4qjS1GJixvNzw",
//             "projectId": "mnh5VdqsSqSaDF1Vzrrl",
//             "sequenceId": "HwydsPJ2jKZ6WURocYRY",
//             "shotNumber": 1,
//             "shotId": "ekiklfnvmvxhsb5"
//         }
//     ]
// }

export const generateStoryBoard = async (requestPayload: any) => {
  const res = await axios.post<Response>(
    `${baseUrl}storyboard/generate-storyboard`,
    requestPayload
  );
  return res;
};

export const deleteStoryBoardVersionApi = async (data: any) => {
  const res = await axios.post<any>(
    `${baseUrl}storyboard/delete-storyboardVersion/`,
    data
  );
  return res;
};

interface UpdateShotParams {
  shotId: string;
  breakdownId: string;
  selectedStoryboardVersion: number;
}

export const updateShotVersionImageApi = async ({
  shotId,
  breakdownId,
  selectedStoryboardVersion,
}: UpdateShotParams) => {
  const res = await axios.patch(`${baseUrl + `storyboard/set-storyboard`}`, {
    shotId,
    breakdownId,
    selectedStoryboardVersion,
  });
  return res;
};
