import React, { useContext, useState, useEffect } from "react";
import { Button, Chip, Menu, MenuItem, Modal, Tooltip } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { CaretDown, User, Warning } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { ScriptBreakdownContext } from "../../Context/ContextDataProvider";
import { ROUTES } from "../../Routes";
import { deleteCookie } from "../../Services/apiResources";
import { ShotQueueStats, UserDetailsType } from "../../types/public-types";
import {
  Logo,
  SmallLogo,
} from "../ReusableComponents/SvgAndIcons/ScriptVizLogo";
import {
  CoinIconXsmall,
  EditProfileIcon,
  FreeCreditCoinIcon,
  LogOutIcon,
  TermsAndConditionIcon,
} from "../ReusableComponents/SvgAndIcons/SvgIcons";
import EditUserProfileModal from "../Utilities/Modals/EditUserProfileModal";
import ReportIssueModal from "../Utilities/Modals/ReportIssueModal";
import ReportSuccessfulModal from "../Utilities/Modals/ReportSuccessfulModal";
import QueueDetailModal from "./QueueDetailModal/QueueDetailModal";
import useStyles from "./Navigation.style";
import { FilledButton } from "../ReusableComponents/Buttons/Buttons";

const NavbarContainer = styled(AppBar)({
  zIndex: 2,
  boxShadow: "none",
  backgroundColor: "#252A38 !important",
});

const LeftContainer = styled("div")({
  display: "flex",
  alignItems: "flex-end",
  cursor: "pointer",
});

const MiddleContainer = styled("div")(({ theme }) => ({
  flexGrow: 1,
  textAlign: "center",
}));

const RightContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "8px",
});
interface Props {
  projectName: string;
  setProjectName: (name: string) => void;
}

interface ContextTypes {
  availableCredits: number;
  loggedInUserDetails: UserDetailsType;
  freeCredits: number;
  shotQueueDetails: any;
  isQueueLoading: boolean;
  shotStats: ShotQueueStats;
}

const Navigation = ({ projectName, setProjectName }: Props) => {
  const navigate = useNavigate();
  const {
    availableCredits,
    loggedInUserDetails,
    freeCredits,
    shotQueueDetails,
    isQueueLoading,
    shotStats,
  }: ContextTypes = useContext(ScriptBreakdownContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [editProfileModal, setEditProfileModal] = useState<boolean>(false);
  const [resportIssueModal, setReportIssueModal] = useState<boolean>(false);
  const [reportSuccessfullModal, setReportSuccessfullModal] =
    useState<boolean>(false);
  const [openQueueDetailModal, setOpenQueueDetailModal] =
    useState<boolean>(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    deleteCookie("access");
    deleteCookie("refresh");
    // window.location.href = SSO_URL_FRONTEND + "logout/" + APP_URL;
    window.location.href = ROUTES.LOG_IN;
  };

  // Define an array of paths where you want to hide the navigation
  const hideNavigationOnPaths = [
    "/log-in",
    "/log-in/",
    "/sign-up",
    "/sign-up/",
    "/forget-password",
    "/forget-password/",
    "/setup-new-password",
    "/setup-new-password/",
  ];

  // Conditionally render the Navigation component
  const renderNavigation = !hideNavigationOnPaths.includes(
    window.location.pathname
  );

  return (
    <NavbarContainer position="sticky">
      <Toolbar
        sx={{
          mx: 4,
          display: renderNavigation ? "" : "none",
          minHeight: { sm: "45px", md: "45px", lg: "45px", xl: "64px" },
        }}
      >
        <LeftContainer
          onClick={() => {
            setProjectName("Script Viz");
            navigate("/");
          }}
        >
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "block",
              },
            }}
          >
            <Logo />
          </Box>
          <Box
            sx={{
              display: {
                xs: "block",
                sm: "block",
                md: "block",
                lg: "block",
                xl: "none",
              },
            }}
          >
            <SmallLogo />
          </Box>
        </LeftContainer>
        <MiddleContainer>
          <Box className={classes.flexCenter}>
            <Typography
              variant="h2"
              sx={{
                marginLeft: "-55px",
                fontSize: { md: "16px", lg: "16px", xl: "18px" },
              }}
            >
              {projectName ? projectName : "Script BreakDown"}
            </Typography>
          </Box>
        </MiddleContainer>
        <RightContainer>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "50px",
              padding: "8px 6px 6px 10px",
              backgroundColor: "#1B1D28",
              borderRadius: "0px 0px 10px 10px",
            }}
          >
            <Box>
              <Typography className={classes.shotDetailText}>
                {shotStats.Completed} shots{" "}
                <span style={{ color: "#8D8E93" }}>done</span>
              </Typography>
              <Typography className={classes.shotDetailTextPercentage}>
                {(shotStats.Completed / shotQueueDetails.length) * 100}%
                Generated
              </Typography>
            </Box>
            <FilledButton
              text="View More"
              disabled={false}
              minWidth={70}
              isLoading={false}
              fullWidth={false}
              handleClick={() => setOpenQueueDetailModal(true)}
            />
          </Box>
          <Tooltip title={`${freeCredits ? freeCredits : 0} free credits`}>
            <Chip
              style={{ color: "white", padding: "4px" }}
              avatar={<FreeCreditCoinIcon />}
              label={freeCredits ? freeCredits : 0}
              variant="outlined"
              onClick={() => {
                navigate(ROUTES.CREDITS_PAGE);
              }}
            />
          </Tooltip>
          <Tooltip
            title={`${availableCredits ? availableCredits : 0} credits left`}
          >
            <Chip
              style={{ color: "white", padding: "4px" }}
              avatar={<CoinIconXsmall />}
              label={availableCredits ? availableCredits : 0}
              variant="outlined"
              onClick={() => {
                navigate(ROUTES.CREDITS_PAGE);
              }}
            />
          </Tooltip>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{ color: "white" }}
          >
            {loggedInUserDetails?.photoPath ? (
              <img
                src={loggedInUserDetails?.photoPath}
                alt=""
                className={classes.userThumbnail}
              />
            ) : (
              <User size={25} />
            )}
            <CaretDown size={25} />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              className: `${classes.menuList}`,
            }}
          >
            <MenuItem
              onClick={() => {
                setEditProfileModal(true);
                handleClose();
              }}
              className={classes.editMenu}
            >
              <EditProfileIcon />
              <Typography>Edit Profile</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setReportIssueModal(true);
                handleClose();
              }}
              className={classes.editMenu}
            >
              <Warning size={22} />
              <Typography>Report an issue</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                navigate(ROUTES.TERMS_AND_CONDITIONS);
              }}
              className={classes.editMenu}
            >
              <TermsAndConditionIcon />
              <Typography>Terms & Conditions</Typography>
            </MenuItem>
            <MenuItem onClick={logOut} className={classes.logOutMenu}>
              <LogOutIcon />
              <Typography>Log Out</Typography>
            </MenuItem>
          </Menu>
        </RightContainer>
      </Toolbar>
      <Modal
        open={editProfileModal}
        onClose={() => setEditProfileModal(false)}
        className={classes.flexCenter}
      >
        <EditUserProfileModal handleClose={() => setEditProfileModal(false)} />
      </Modal>
      <Modal
        open={resportIssueModal}
        onClose={() => setReportIssueModal(false)}
        className={classes.flexCenter}
      >
        <ReportIssueModal
          handleClose={() => setReportIssueModal(false)}
          onpenReportSuccessModal={() => setReportSuccessfullModal(true)}
        />
      </Modal>
      <Modal
        open={reportSuccessfullModal}
        onClose={() => setReportSuccessfullModal(false)}
        className={classes.flexCenter}
      >
        <ReportSuccessfulModal
          handleClose={() => setReportSuccessfullModal(false)}
          openReportIssueModal={() => setReportIssueModal(true)}
        />
      </Modal>
      <Modal
        open={openQueueDetailModal}
        onClose={() => setOpenQueueDetailModal(false)}
        className={classes.flexCenter}
      >
        <QueueDetailModal
          handleClose={() => setOpenQueueDetailModal(false)}
          shotQueueDetails={shotQueueDetails}
          isLoading={isQueueLoading}
          shotStats={shotStats}
        />
      </Modal>
    </NavbarContainer>
  );
};

export default Navigation;
