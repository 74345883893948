import { makeStyles } from "@material-ui/core/styles";
import { themeType } from "../../../types/theme-types";

const useStyles = makeStyles((theme: themeType) => ({
  // create new project modal styles
  root: {
    width: "45%",
    backgroundColor: "#1B1D28",
    padding: "20px",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  createProjectRoot: {
    width: "30%",
    backgroundColor: "#1B1D28",
    padding: "20px",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  createProjectButton: {
    width: "100%",
    backgroundColor: "#252A38",
    borderRadius: "10px",
    padding: "10px",
    color: "white",
    border: "1px solid #606479",
  },
  projectDetails: {
    backgroundColor: "#252A38",
    padding: "6px 10px",
    borderRadius: "10px",
    marginBottom: "10px",
  },
  backButton: {
    borderColor: "#6C65D9",
    color: "white",
    width: "150px",
    borderRadius: "10px",
  },
  exportButton: {
    backgroundColor: "#6C65D9",
    borderRadius: "10px",
  },

  //add new shot modal styles
  descriptionTitleAndAutoBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "4px !important",
  },
  emptyBlock: {
    width: "400px !important",
    display: "block !important",
    height: "16px !important",
    border: "1px solid #000000 !important",
    backgroundColor: "#252A38 !important",
  },
  autoGenerateButton: {
    display: "flex",
    gap: "6px",
    alignItems: "center",
  },
  descriptionInput: {
    height: "80px",
    width: "98%",
    backgroundColor: "#252A38",
    border: "none",
    borderRadius: "10px",
    resize: "none",
    outline: "none",
    padding: "6px 10px",
    color: "#606479",
    fontSize: "14px",
    fontFamily: "poppins",
  },
  addShotButton: {
    backgroundColor: "#6C65D9",
    color: "white",
    padding: "5px 20px",
    borderRadius: "10px",
  },
  cancelButton: {
    border: "2px solid #6C65D9",
    color: "white",
    padding: "5px 20px",
    borderRadius: "10px",
    marginLeft: "20px",
  },

  //configure modal styles
  configureModalRoot: {
    width: "600px",
    backgroundColor: "#1B1D28",
    padding: "20px",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "40px",
  },
  //common styles

  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexAndGap: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
}));

export default useStyles;
