import { Text, View, Image } from "@react-pdf/renderer";
import VigaLogo from "../SvgComponents/VigaLogo.png";
import { ScriptVizIconTwo } from "../SvgComponents/SvgComponents";

const Footer = () => (
  <View
    style={{
      height: "86px",
      width: "100%",
      display: "flex",
      marginTop: "auto",
      marginBottom: "10px",
    }}
    fixed={true}
  >
    <View style={{ width: "100%", height: "43px" }} />
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <ScriptVizIconTwo />
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "2px",
        }}
      >
        <Image src={VigaLogo} style={{ width: "55px", height: "15px" }} />
        <Text
          style={{
            fontFamily: "Poppins",
            fontSize: "6px",
            fontWeight: "medium",
          }}
        >
          Entertianment Technology
        </Text>
      </View>
    </View>
  </View>
);

export default Footer;
