import {
  Box,
  Button,
  Chip,
  Grid,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useStyles from "./SingleShotOfStoryBoard.styles";
import {
  ArrowUUpLeft,
  ArrowUUpRight,
  Eraser,
  Plus,
  Sparkle,
  Trash,
  X,
} from "@phosphor-icons/react";
import {
  ContextTypes,
  ScriptBreakdownContext,
} from "../../../Context/ContextDataProvider";
import { Character } from "../../../types/public-types";
import {
  CoinIconSmall,
  CoinIconXsmall,
} from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import { swapCharacterFaceApi } from "../../../Services/characterAndLocationServices/characterAndLocationServices";
import toast from "react-hot-toast";

interface Props {
  baseImageFilePath: string | null;
  baseImageFileUrl: string | null;
  baseImageVersionNumber: string | null;
  handleClose: () => void;
  projectId: string;
  breakdownId: string;
  shotId: string;
  sequenceId: string;
  shotNumberText: string;
}

interface SelectedCharacterType {
  characterEthnicity: string;
  characterReferenceImages: any[];
  characterDescription: string;
  characterGender: string;
  characterName: string;
  characterAge: number;
  charId: string;
  projectId: string;
  fileId: string;
  characterId: string;
  index: number;
}

const FaceChangeModal = ({
  baseImageFilePath,
  baseImageFileUrl,
  baseImageVersionNumber,
  handleClose,
  projectId,
  breakdownId,
  shotId,
  sequenceId,
  shotNumberText,
}: Props) => {
  const classes = useStyles();
  const fileId = localStorage.getItem("fileId");
  console.log(fileId, projectId);

  const {
    characterListData,
    isCharacterListLoading,
    handleGetCharacterList,
  }: ContextTypes = useContext(ScriptBreakdownContext);
  const [selectedCharacter, setSelectedCharacter] = useState<string>();
  const [index, setIndex] = useState<number>(0);
  const [selectedCharactersForSwap, setSelectedCharactersForSwap] =
    useState<SelectedCharacterType[]>();

  const handleSelectCharacter = () => {
    const selected = characterListData?.find(
      (sin: Character) => sin.charId === selectedCharacter
    );
    const isIndexAvailable = selectedCharactersForSwap?.some(
      (sin: SelectedCharacterType) => sin.index === index
    );

    const isSelected = selectedCharactersForSwap?.some(
      (sin: SelectedCharacterType) => sin.charId === selectedCharacter
    );

    if (!isIndexAvailable && !isSelected) {
      if (selectedCharactersForSwap?.length && selected) {
        setSelectedCharactersForSwap([
          ...selectedCharactersForSwap,
          { ...selected, index },
        ]);
      } else {
        selected && setSelectedCharactersForSwap([{ ...selected, index }]);
      }
    } else {
      toast.error("index or character is already selected");
    }
  };

  const handleRemoveSelectedCharacter = (id: string) => {
    const selected = selectedCharactersForSwap?.filter(
      (sin: SelectedCharacterType) => sin.charId !== id
    );
    setSelectedCharactersForSwap(selected);
  };

  const handleFaceSync = async () => {
    const data = {
      projectId,
      breakdownId,
      sequenceId,
      fileId: "some_file_id",
      shotId,
      shotNumberText,
      baseImageVersionPath: baseImageFilePath,
      baseImageVersionNumber,
      characters: selectedCharactersForSwap?.map(
        (single: SelectedCharacterType) => {
          return {
            characterIndex: single?.index,
            characterId: single.charId,
            characterRefImagePath:
              single?.characterReferenceImages[0]?.imagePath,
            characterFileName: single?.characterReferenceImages[0]?.imageName,
          };
        }
      ),
    };
    toast
      .promise(
        swapCharacterFaceApi(data), // API call
        {
          loading: "Swapping face in progress...", // Loader message
          success: "Face swapped successfully!", // Success message
          error: "Face swapping failed. Please try again.", // Error message
        }
      )
      .then((res) => {
        console.log(res);
        handleClose(); // If you need to do something with the result
      })
      .catch((error) => {
        console.log(error); // If you want to log or handle the error
      });
    console.log(data);
  };

  useEffect(() => {
    if (projectId && fileId) {
      handleGetCharacterList(projectId, fileId);
    }
  }, [projectId, fileId]);

  return (
    <Box className={classes.faceChangeModalContainer}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h4">Face Sync</Typography>
        <Button onClick={handleClose}>
          <X size={25} fill="white" />
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <img
            src={baseImageFileUrl ?? ""}
            className={classes.characterImage}
            alt="no img"
          />
          <Box
            bgcolor="#252A38"
            mt={1}
            py={1}
            borderRadius="5px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={2}
            color="white"
          >
            <Button>
              <ArrowUUpLeft size={20} />
            </Button>
            <Button>
              <ArrowUUpRight size={20} />
            </Button>
            <Button>
              <Eraser size={20} />
            </Button>
            <Button>
              <ArrowUUpLeft size={20} />
            </Button>
          </Box>
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            fullWidth
            startIcon={<Sparkle />}
            endIcon={
              <Chip
                style={{ color: "white", padding: "4px" }}
                avatar={<CoinIconXsmall />}
                label={2}
                variant="outlined"
              />
            }
            onClick={handleFaceSync}
            disabled={selectedCharactersForSwap?.length === 0}
          >
            Apply Face Sync
          </Button>
        </Grid>
        <Grid item md={6}>
          <Typography className={classes.inputLabel} mb={1}>
            Mark characters in your shot with indices arranged from left to
            right
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={1}
            mb={1}
          >
            <Box className={classes.inputsContainer}>
              <Box className={classes.inputLabelAndField}>
                <Typography className={classes.inputLabel}>Index</Typography>
                <TextField
                  placeholder="Enter your email address"
                  type="number"
                  name="email"
                  fullWidth
                  variant="standard"
                  className={classes.inputField}
                  value={index}
                  onChange={(e) => setIndex(Number(e.target.value))}
                />
              </Box>
            </Box>
            <Box className={classes.inputsContainer} sx={{ width: "100%" }}>
              <Box className={classes.inputLabelAndField}>
                <Typography className={classes.inputLabel}>
                  Select & Add A character
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={1}
                >
                  <select
                    // fullWidth
                    className={classes.inputField}
                    style={{ height: "40px", width: "100%", color: "white" }}
                    name="characterGender"
                    placeholder="Select Character"
                    onChange={(e: any) => setSelectedCharacter(e.target.value)}
                    defaultValue="none"
                  >
                    <option
                      value="none"
                      style={{
                        backgroundColor: "",
                        zIndex: 9999,
                        opacity: 1,
                        width: "100%",
                        color: "white",
                      }}
                      disabled={true}
                    >
                      None
                    </option>
                    {characterListData?.map((single: Character) => (
                      <option
                        value={single?.charId}
                        style={{
                          backgroundColor:
                            selectedCharactersForSwap?.some(
                              (sin: Character) => sin.charId === single?.charId
                            ) || !single?.characterReferenceImages[0]?.signedUrl
                              ? "#252A38" // background for disabled items
                              : "#252A38", // background for enabled items
                          zIndex: 9999,
                          opacity: 1,
                          width: "100%",
                          color: "white",
                        }}
                        key={single?.charId}
                        disabled={
                          selectedCharactersForSwap?.some(
                            (sin: Character) => sin.charId === single?.charId
                          ) || !single?.characterReferenceImages[0]?.signedUrl
                        }
                      >
                        {single?.characterName}
                      </option>
                    ))}
                  </select>
                  <Button
                    sx={{
                      border: "2px solid #6C65D9",
                      borderRadius: "5px",
                      height: "40px",
                      px: 2,
                      color: "white",
                    }}
                    onClick={() => {
                      handleSelectCharacter();
                      // setSelectedCharacter("none");
                      // setIndex(0);
                    }}
                  >
                    <Plus size={22} />
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Typography className={classes.inputLabel}>
            Only characters with reference images are listed
          </Typography>
          <hr />
          <Box>
            {selectedCharactersForSwap?.length ? (
              <Box display="flex" flexDirection="column" gap={1} mt={1}>
                {selectedCharactersForSwap?.map(
                  (single: SelectedCharacterType) => (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      py={1}
                      px={1}
                      bgcolor="#252A38"
                      borderRadius="5px"
                      key={single.charId}
                    >
                      <Box display="flex" alignItems="center" gap={1}>
                        {/* <Radio /> */}
                        <Typography>
                          {single?.index}-{single?.characterName}
                        </Typography>
                      </Box>
                      <Box>
                        <Button
                          onClick={() =>
                            handleRemoveSelectedCharacter(single?.charId)
                          }
                        >
                          <Trash fill="red" size={22} />
                        </Button>
                      </Box>
                    </Box>
                  )
                )}
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FaceChangeModal;
