import { Box } from "@mui/material";
import LogInPageOverview from "../../Components/Authentications/LogInPageOverview";

const LogInPage = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <LogInPageOverview />
    </Box>
  );
};

export default LogInPage;
