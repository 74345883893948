import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { BreakdownDetailStyle } from "./BreakdownDetail.style";
import {
  CameraAngleIcon,
  CharatersIcon,
  LocationIcon,
} from "../../SvgComponents/SvgComponents";

const styles = StyleSheet.create(BreakdownDetailStyle);

const BreakdownDetail = ({ shot }) => (
  <View
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "8.5px",
    }}
  >
    <View style={styles.textContainer}>
      <Text style={styles.textStyleThree}>{shot.name}</Text>
      <Text style={styles.textStyleTwo}>{shot.desc}</Text>
    </View>
    <View style={styles.textContainer}>
      <View style={styles.iconContainer}>
        <View style={{ width: "18%" }}>
          <CharatersIcon />
        </View>
        <Text style={styles.textStyle}>Charaters</Text>
      </View>
      <Text style={styles.textStyleTwo}>{shot.char}</Text>
    </View>
    <View style={styles.textContainer}>
      <View style={styles.iconContainer}>
        <View style={{ width: "18%" }}>
          <LocationIcon />
        </View>
        <Text style={styles.textStyle}>Location</Text>
      </View>
      <Text style={styles.textStyleTwo}>{shot.loc}</Text>
    </View>
    <View style={styles.textContainer}>
      <View style={styles.iconContainer}>
        <View style={{ width: "18%" }}>
          <CameraAngleIcon />
        </View>
        <Text style={styles.textStyle}>Camera Angle</Text>
      </View>
      <Text style={styles.textStyleTwo}>{shot.camera_position}</Text>
    </View>
  </View>
);

export default BreakdownDetail;
