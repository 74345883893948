import { Box, Typography, Chip, Button } from "@mui/material";
import { XCircle } from "@phosphor-icons/react";
import { CoinIconSmall } from "../../ReusableComponents/SvgAndIcons/SvgIcons";

type Props = {
  handleFailModal: (value: boolean) => void;
  creditQuantity: string | null;
};

const PaymentFailModal = ({
  handleFailModal,
  creditQuantity,
}: Props) => {
  return (
    <Box
      style={{
        width: "290px",
        height: "300px",
        borderRadius: "20px",
        backgroundColor: "#1B1D28",
        padding: "17px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <XCircle size={60} color="#FF0000" weight="fill" />
      <span
        style={{
          marginTop: "12px",
          marginBottom: "24px",
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: "500",
        }}
      >
        Payment Failed
      </span>
      <Chip
        style={{
          height: "44px",
          padding: "4px 6px 4px 4px",
          borderRadius: "27px",
          color: "red",
          fontSize: "18px",
        }}
        avatar={<CoinIconSmall />}
        label={creditQuantity}
        variant="outlined"
      />
      <span
        style={{
          fontFamily: "Poppins",
          fontSize: "12px",
          marginTop: "16px",
          marginBottom: "34px",
          fontWeight: "500",
          textAlign: "center",
          color: "#FF0000",
        }}
      >
        Your transaction has failed due to some technical errors, Please try
        again.
      </span>

      <Button
        sx={{
          minWidth: "120px",
          padding: "6px",
          borderRadius: "5px",
          backgroundColor: "#6C65D9",
          color: "#f9f9f9",
          fontFamily: "Poppins",
          fontWeight: "400",
          "&:hover": {
            backgroundColor: "rgba(108, 101, 217, 0.5)",
          },
        }}
        onClick={() => handleFailModal(false)}
      >
        Try Again
      </Button>
    </Box>
  );
};

export default PaymentFailModal;
