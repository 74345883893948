import { makeStyles } from "@material-ui/core/styles";
import { themeType } from "../../../types/theme-types";

const useStyles = makeStyles((theme: themeType) => ({
  "@global": {
    body: {
      fontFamily: "Poppins !important",
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0px 219px",
    height: "100%",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    marginBottom: "10px",
    marginTop: "6px",
  },
  text: {
    fontSize: "18px !important",
    fontFamily: "Poppins !important",
  },
  subHeader: {
    fontSize: "18px !important",
    color: "#6C65D9",
    paddingBottom: "6px !important",
  },
}));

export default useStyles;
