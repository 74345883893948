import { Page, Text, View, Image } from "@react-pdf/renderer";
import Header from "../../CommonComponents/Header";
import BreakdownDetail from "../BreakdownDetail/BreakdownDetail";
import { NoImageSmall } from "../../SvgComponents/SvgComponents";

const StoryboardBreakdownPageView = ({ projectName, sequencesToExport }) => {
  return (
    <Page
      size="A4"
      style={{
        backgroundColor: "#FFFFFF",
        padding: "21px 18px 18px 18px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "18px",
        position: "relative",
      }}
      wrap
    >
      <Header projectName={projectName} />
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          marginBottom: "8px",
          gap: "211px",
        }}
      >
        <Text
          style={{ fontWeight: "600", fontSize: "18px", fontFamily: "Poppins" }}
        >
          Storyboard
        </Text>
        <Text
          style={{ fontWeight: "600", fontSize: "18px", fontFamily: "Poppins" }}
        >
          Breakdown
        </Text>
      </View>
      {sequencesToExport.map((eachSequence, index) => (
        <View
          style={{ width: "100%" }}
          key={eachSequence.sequence.id}
          break={index > 0}
        >
          <View
            style={{
              width: "100%",
              padding: "2px 12px",
              border: "0.5px solid black",
              borderRadius: "10px",
              marginBottom: "18px",
            }}
          >
            <Text
              style={{
                fontWeight: "500",
                fontSize: "12px",
                color: "#606479",
                textAlign: "center",
                fontFamily: "Poppins",
              }}
            >
              {eachSequence.sequence.sceneNumber}.{" "}
              {eachSequence.sequence.sceneTitle}
            </Text>
          </View>
          <View style={{ width: "100%" }}>
            {eachSequence.ShotList.map((shot) => (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  gap: "6px",
                  marginBottom: "14px",
                  width: "100%",
                }}
                key={shot.name}
                wrap={false}
              >
                {shot.image == null ? (
                  <NoImageSmall />
                ) : (
                  <Image
                    style={{
                      width: "140px",
                      height: "80px",
                      borderRadius: "2px",
                    }}
                    src={shot.image}
                  />
                )}
                <View style={{ width: "413px" }}>
                  <BreakdownDetail shot={shot} />
                </View>
              </View>
            ))}
          </View>
        </View>
      ))}
    </Page>
  );
};

export default StoryboardBreakdownPageView;
