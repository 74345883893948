export const BreakdownDetailStyle = {
  textStyle: {
    fontSize: "8px",
    fontFamily: "Poppins",
    fontWeight: "medium",
    color: "#606479",
  },
  textStyleTwo: {
    width: "80%",
    fontSize: "8px",
    fontFamily: "Poppins",
    fontWeight: "medium",
    color: "#8D8E93",
  },
  textStyleThree: {
    fontFamily: "Poppins",
    fontWeight: "semibold",
    fontSize: "10px",
    width: "20%",
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "5px",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "20%",
  },
};
