import { Box, Button, Grid, Typography } from "@mui/material";
import { ArrowSquareOut, Circle, Plus } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { getMovieColabProjectsListApi } from "../../../Services/movieColabServices/movieColabServices";
import { MovieColabProjectDetailsResponse } from "../../../types/movie-colab-types";
import {
  FilledButton,
  OutlinedButton,
} from "../../ReusableComponents/Buttons/Buttons";
import Loader from "../../ReusableComponents/Loader/Loader";
import { SelectedCircleIcon } from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import useStyles from "./ModalStyles";

interface Props {
  currentSelectedMcProjectId: string | number;
  handleCreateNewProjectModalState: () => void;
  handleClose: () => void;
  setSelectedMovieColabProjectId: (id: string | number) => void;
}

const ChooseProjectModal = ({
  currentSelectedMcProjectId,
  handleCreateNewProjectModalState,
  handleClose,
  setSelectedMovieColabProjectId,
}: Props) => {
  const classes = useStyles();
  const [projects, setProjects] =
    useState<MovieColabProjectDetailsResponse[]>();
  const [selectedProject, setSelectedProject] = useState<number | string>(
    currentSelectedMcProjectId
  );
  const [isProjectsLoaded, setIsProjectsLoaded] = useState<boolean>(false);

  const getMovieColabProjectList = async () => {
    try {
      const res = await getMovieColabProjectsListApi();
      setProjects(res.results);
      setIsProjectsLoaded(true);
    } catch (error) {
      console.log(error);
      setIsProjectsLoaded(true);
    }
  };

  useEffect(() => {
    getMovieColabProjectList();
  }, []);

  return (
    <Box className={classes.root}>
      <Box>
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          Export To Movie colab
        </Typography>
      </Box>
      <Box>
        <Box sx={{ mb: 1 }}>
          <Button
            className={classes.createProjectButton}
            onClick={() => handleCreateNewProjectModalState()}
          >
            <Plus size={22} />
            <Typography sx={{ ml: 2 }}>Create New Project</Typography>
          </Button>
        </Box>
        <Box sx={{ padding: "6px 20px 6px 10px", color: "#606479" }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item md={4}>
              <Typography sx={{ fontSize: "12px" }}>Project Name</Typography>
            </Grid>
            <Grid item md={5}>
              <Typography sx={{ textAlign: "center", fontSize: "12px" }}>
                Project Description
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography sx={{ textAlign: "center", fontSize: "12px" }}>
                Code
              </Typography>
            </Grid>
            <Grid item md={1}>
              <Typography sx={{ textAlign: "right", fontSize: "12px" }}>
                Actions
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ height: "400px", overflowY: "auto", paddingRight: "10px" }}>
          {isProjectsLoaded ? (
            <>
              {projects?.length ? (
                <>
                  {projects?.map(
                    (singleProject: MovieColabProjectDetailsResponse) => (
                      <Box className={classes.projectDetails}>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item md={4}>
                            <Box className={classes.flexAndGap}>
                              {selectedProject === singleProject.id ? (
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setSelectedProject(0)}
                                >
                                  <SelectedCircleIcon />
                                </span>
                              ) : (
                                <Circle
                                  fill="#6C65D9"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setSelectedProject(singleProject.id)
                                  }
                                  size={15}
                                />
                              )}
                              <img
                                src={
                                  singleProject?.thumbnail ||
                                  "https://images.pexels.com/photos/577514/pexels-photo-577514.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                                }
                                alt=""
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  borderRadius: "10px",
                                }}
                              />
                              <Typography sx={{ fontSize: "14px" }}>
                                {singleProject?.name}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item md={5}>
                            <Typography
                              sx={{ fontSize: "12px", color: "#606479" }}
                            >
                              {singleProject.description}
                            </Typography>
                          </Grid>
                          <Grid item md={2}>
                            <Typography
                              sx={{ fontSize: "12px", textAlign: "center" }}
                            >
                              {singleProject.abbreviation}
                            </Typography>
                          </Grid>
                          <Grid item md={1}>
                            <Box sx={{ textAlign: "right" }}>
                              <ArrowSquareOut
                                size={25}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  window.open(
                                    `https://movie-colab.stage.vigastudios.com/${singleProject.id}/sequence`
                                  )
                                }
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    )
                  )}
                </>
              ) : (
                <Typography sx={{ textAlign: "center", mt: 10 }}>
                  No Projects Found
                </Typography>
              )}
            </>
          ) : (
            <Loader />
          )}
        </Box>
      </Box>
      <Box
        className={classes.flexCenter}
        sx={{
          gap: "20px",
        }}
      >
        <FilledButton
          text="Confirm Project"
          disabled={!selectedProject}
          handleClick={() => {
            setSelectedMovieColabProjectId(selectedProject);
            handleClose();
          }}
          fullWidth={false}
          minWidth={100}
          isLoading={false}
        />
        <OutlinedButton
          text="Back"
          disabled={false}
          handleClick={() => handleClose()}
          fullWidth={false}
          minWidth={100}
        />
      </Box>
    </Box>
  );
};

export default ChooseProjectModal;
