import { Box, Button, Modal, Typography } from "@mui/material";
import { CaretLeft } from "@phosphor-icons/react";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProjectIdType } from "../../../types/public-types";
import { FileImportIcon } from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import InvalidFileFormatModal from "./InvalidFileFormatModal";
import ManuallyUploadScenes from "./ManuallyUploadScene";
import useStyles from "./ScriptFileInput.style";

type Props = {
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  projectId: ProjectIdType;
  updateRefreshCounter: () => void;
  showInvalidFileModal: boolean;
  setShowInvalidFileModal: (e: boolean) => void;
};

const ScriptFileInput = ({
  handleFileChange,
  projectId,
  updateRefreshCounter,
  showInvalidFileModal,
  setShowInvalidFileModal,
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const classes = useStyles();
  const [uploadType, setUploadType] = useState<string>("script");
  const [isUnsavedManualSceneAvailable, setIsUnsavedManualSceneAvailable] =
    useState<boolean>(false);

  const handleButtonClick = () => {
    fileInputRef.current!.click();
  };

  return (
    <Box className={classes.flexCenter} sx={{ height: "100%" }}>
      <Box
        className={classes.root}
        sx={{ width: { md: "600px", lg: "70%", xl: "70%" } }}
      >
        <Button onClick={() => navigate("/")} sx={{ color: "white", mb: 1 }}>
          <CaretLeft size={18} /> <Typography>Back to Projects</Typography>
        </Button>
        <Typography className={classes.title}>Script File Input</Typography>
        <Box className={classes.titleInputActionContainer}>
          <Box
            sx={{
              borderBottom: "1px solid #6C65D9",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              className={
                uploadType === "script"
                  ? classes.activeButton
                  : classes.inActiveButton
              }
              onClick={() => {
                if (isUnsavedManualSceneAvailable) {
                  const changeTab = window.confirm(
                    "You have Unsaved Changes. Do you want to switch tab?"
                  );
                  if (changeTab) {
                    setUploadType("script");
                  }
                } else {
                  setUploadType("script");
                }
              }}
              sx={{ borderRadius: "6px 0px 0px 0px" }}
            >
              <Typography sx={{ textAlign: "center" }}>
                Upload Script
              </Typography>
            </Button>
            <Button
              className={
                uploadType === "manually"
                  ? classes.activeButton
                  : classes.inActiveButton
              }
              onClick={() => setUploadType("manually")}
              sx={{ borderRadius: "0px 6px 0px 0px" }}
            >
              <Typography>Enter Scenes Manually</Typography>
            </Button>
          </Box>
          {uploadType === "script" ? (
            <Box className={`${classes.flexCenter} ${classes.heightWidthFull}`}>
              <Box className={classes.heightWidthFull}>
                <Box
                  className={classes.flexCenter}
                  sx={{ height: "85%", marginTop: "20px" }}
                >
                  <Box className={classes.uploadScriptContainer}>
                    <Typography variant="h5" sx={{ fontSize: "24px" }}>
                      Upload the Script file Here !
                    </Typography>
                    <Typography className={classes.textGray}>
                      Format Allowed : "
                      <span style={{ color: "white" }}>
                        .pdf, .fountain, .fdx
                      </span>
                      "
                    </Typography>
                    <input
                      type="file"
                      accept=".pdf, .fountain"
                      onChange={handleFileChange}
                      ref={fileInputRef}
                      style={{ display: "none" }}
                    />
                    <Button
                      // variant="contained"
                      className={classes.importScriptButton}
                      onClick={handleButtonClick}
                    >
                      <FileImportIcon />
                      <Typography sx={{ fontWeight: 400, fontSize: "12px" }}>
                        Upload Script
                      </Typography>
                    </Button>
                    <Typography sx={{ mt: 5 }} className={classes.textGray}>
                      Format Example -{" "}
                      <span>
                        <a
                          href="https://fountain.io/syntax/#section-overview"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#6C65D9",
                            cursor: "pointer",
                          }}
                        >
                          Fountain Screenplay Format
                        </a>
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box className={classes.heightWidthFull}>
              <ManuallyUploadScenes
                projectId={projectId}
                updateRefreshCounter={updateRefreshCounter}
                setIsUnsavedManualSceneAvailable={
                  setIsUnsavedManualSceneAvailable
                }
              />
            </Box>
          )}
        </Box>
      </Box>
      {/* {!isPdfValid ? (
        <Box className={classes.errorCard}>
          <Card
            sx={{
              backgroundColor: "#252A38",
              padding: "10px 20px",
              borderRadius: "10px",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                alignItems: "center",
              }}
            >
              <Typography variant="h4" sx={{ color: "red" }}>
                Script Format Error
              </Typography>
              <Typography variant="h6" sx={{ color: "white" }}>
                Please upload with the correct format
              </Typography>
              <Button
                sx={{
                  width: "300px",
                  color: "white",
                  padding: "10px",
                  borderRadius: "10px",
                  border: "2px solid #6C65D9",
                }}
              >
                Format Guidelines
              </Button>
            </CardContent>
          </Card>
        </Box>
      ) : (
        ""
      )} */}
      <Modal
        open={showInvalidFileModal}
        className={classes.flexCenter}
        onClose={() => setShowInvalidFileModal(false)}
      >
        <InvalidFileFormatModal
          handleCloseDeleteModal={() => setShowInvalidFileModal(false)}
        />
      </Modal>
    </Box>
  );
};

export default ScriptFileInput;
