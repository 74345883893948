import axios from "axios";
import { Character, Location, ProjectIdType } from "../../types/public-types";

const baseUrl = process.env.REACT_APP_BASE_URL;

export interface CharactersResponse {
  size: number;
  characters: Character[];
}

export interface LocationsResponse {
  size: number;
  locations: Location[];
}

export const getAllCharactersApi = async (
  projectId: ProjectIdType,
  fileId: string
) => {
  const res = await axios.get<CharactersResponse>(
    `${baseUrl + `character/?projectId=${projectId}&fileId=${fileId}`}`
  );
  return res;
};

export const addNewCharacterApi = async (data: any) => {
  const res = await axios.post<any>(`${baseUrl + "character/"}`, data);
  return res;
};

export const generateCharacterSignedUrl = async (data: any) => {
  const res = await axios.post<any>(
    `${baseUrl + "character/upload-image/"}`,
    data
  );
  return res;
};

export const getAllLocationsApi = async (projectId: ProjectIdType) => {
  const res = await axios.get<LocationsResponse>(
    `${baseUrl + "location/" + projectId}`
  );
  return res;
};

export const getAllPredictedCharactersApi = async (
  projectId: ProjectIdType,
  fileId: string
) => {
  const res = await axios.post<any>(
    `${baseUrl + `character/character-predict/`}`,
    { projectId, fileId }
  );
  return res;
};

export const addNewGeneratedCharacterApi = async (data: any) => {
  const res = await axios.post<any>(
    `${baseUrl + "character/character-add/"}`,
    data
  );
  return res;
};
export const addNewCharacterImageApi = async (data: any) => {
  const res = await axios.post<any>(
    `${baseUrl + "character/add-character-image/"}`,
    data
  );
  return res;
};

export const deleteCharacterApi = async (data: any) => {
  const res = await axios.delete<any>(
    `${
      baseUrl +
      `character/projectId=${data.projectId}&characterId=${data?.characterId}`
    }`,
    data
  );
  return res;
};

interface FaceSwapBody {
  projectId: string;
  breakdownId: string;
  fileId: string;
  shotId: string;
  baseImageFilePath: string;
  baseImageVersionNumber: number;
  characters: {
    characterIndex: number;
    characterId: string;
    characterRefImagePath: string | undefined;
    characterName: string;
  }[];
}
export const swapCharacterFaceApi = async (data: any) => {
  const res = await axios.post<any>(
    `${baseUrl + `storyboard/generate-facesync/`}`,
    data
  );
  return res;
};
