import { Button, CircularProgress } from "@mui/material";
import { ReactNode } from "react";

type FilledButtonProps = {
  text: string;
  disabled: boolean;
  handleClick: () => void;
  fullWidth: boolean;
  minWidth: number;
  isLoading: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
};
export const FilledButton = ({
  text,
  disabled,
  handleClick,
  fullWidth,
  minWidth,
  isLoading,
  startIcon,
  endIcon,
}: FilledButtonProps) => {
  return (
    <Button
      sx={{
        borderRadius: "5px",
        backgroundColor:
          disabled || isLoading ? "#606479" : "#6C65D9 !important",
        "&:hover": {
          backgroundColor: "#1B1D28 !important",
          border: "1px solid #6C65D9 !important",
        },
        color: disabled ? "white" : "white",
        padding: "6px 20px",
        minWidth: `${minWidth}px`,
      }}
      disabled={disabled || isLoading}
      fullWidth={fullWidth}
      onClick={() => handleClick()}
      variant="contained"
      startIcon={startIcon}
      endIcon={endIcon}
    >
      <span>{text}</span>
      {isLoading ? (
        <CircularProgress size={15} sx={{ ml: 2, color: "#6C65D9" }} />
      ) : (
        ""
      )}
    </Button>
  );
};

type OutlinedButtonProps = {
  text: string;
  disabled: boolean;
  handleClick: () => void;
  fullWidth: boolean;
  minWidth: number;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
};

export const OutlinedButton = ({
  text,
  disabled,
  handleClick,
  fullWidth,
  minWidth,
  startIcon,
  endIcon,
}: OutlinedButtonProps) => {
  return (
    <Button
      sx={{
        borderRadius: "5px",
        border: "1px solid #6C65D9",
        color: disabled ? "white" : "white",
        padding: "6px 20px",
        minWidth: `${minWidth}px`,
      }}
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={() => handleClick()}
      variant="outlined"
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {text}
    </Button>
  );
};
