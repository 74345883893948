import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    height: "54%",
    backgroundColor: "#1B1D28",
    borderRadius: "10px",
    padding: "0px 8px",
  },
  tableHeaderCells: {
    backgroundColor: "#1B1D28 !important",
    color: "rgba(249, 249, 249, 0.5) !important",
    fontFamily: "Poppins",
    width: "16.6%",
  },
  //table Items
  tableCellsGrey: {
    color: "rgba(249, 249, 249, 0.5) !important",
    fontFamily: "Poppins",
  },
  tableCellWhite: {
    color: "rgba(249, 249, 249, 1) !important",
    fontFamily: "Poppins",
  },
  tableCellsSuccess: {
    color: "#00FF0A !important",
    fontFamily: "Poppins",
  },
  tableCellsFail: {
    color: "#FF0000 !important",
    fontFamily: "Poppins",
  },
  wordWraps: {
    width: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down(1485)]: {
      width: "100px",
    },
    [theme.breakpoints.down("md")]: {
      width: "260px",
    },
  },
  text: {
    fontSize: "12px !important",
    [theme.breakpoints.down("lg")]: {
      fontSize: "10px !important",
    },
    color: "#606479 !important",
    fontFamily: "Poppins",
  },
}));

export default useStyles;
