import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  Select,
  Typography,
  Chip,
} from "@mui/material";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import useStyles from "./SingleShotThumbnailGenerateModal.styles";
import { useEffect, useState } from "react";
import { promptList } from "../../Utilities/MidjourneyPromptList";
import NoStoryBoard from "../../Utilities/NoStoryboard.png";
import { CoinIconXsmall } from "../../ReusableComponents/SvgAndIcons/SvgIcons";

type Props = {
  handleShotsThumbnailModal: () => void;
  handleSubmit: (
    selectedStyle: any,
    description: string,
    cameraOption: string,
    cameraSettings: string
  ) => void;
  singleSelectedShot: any;
};

const SingleShotThumbnailGenerateModal = ({
  handleShotsThumbnailModal,
  singleSelectedShot,
  handleSubmit,
}: Props) => {
  const classes = useStyles();
  const [selectStyleDropdown, setSelectStyleDropdown] = useState(false);
  const [cameraSettings, setCameraSettings] = useState<string>("none");
  const [cameraOption, setCameraOption] = useState<string>("");

  const [selectedStyle, setSelectedStyle] = useState(promptList[0]);

  //users input description
  const [userDescription, setUserDescription] = useState("");

  const handleChangeCameraSettings = (settings: string) => {
    console.log(settings);

    if (settings === cameraSettings) {
      setCameraSettings("");
      setCameraOption("");
    } else {
      setCameraSettings(settings);
      setCameraOption("");
    }
  };

  const optionCloseShots = [
    { id: 1, name: "Close-up", value: "Close-up" },
    { id: 2, name: "Medium Close-up", value: "Medium Close-up" },
    { id: 3, name: "Extreme Close-up", value: "Extreme Close-up" },
    { id: 4, name: "Wide Close-up", value: "Wide Close-up" },
  ];

  const optionsLongShots = [
    { id: 1, name: "Wide Shot", value: "Wide Shot" },
    { id: 2, name: "Extreme Wide Shot", value: "Extreme Wide Shot" },
    { id: 3, name: "Full Shot", value: "Full Shot" },
    { id: 4, name: "Medium Full Shot", value: "Medium Full Shot" },
    { id: 5, name: "Long Shot", value: "Long Shot" },
    { id: 6, name: "Extreme Long Shot", value: "Extreme Long Shot" },
  ];

  const optionMediumShots = [
    { id: 1, name: "Medium Shot", value: "Medium Shot" },
    { id: 1, name: "Close Shot", value: "Close Shot" },
    { id: 1, name: "Medium Close Shot", value: "Medium Close Shot" },
  ];

  const imgUrl = "https://i.ibb.co/ZMWwZCc/pexels-binyamin-mellish-106399.jpg";

  useEffect(() => {
    //filters out and selects the last selected storyboard style
    const promptStyleId = localStorage.getItem("storyBoardStyle");
    if (promptStyleId) {
      const savedPromptStyle = promptList?.find(
        (prompt) => prompt.id === parseInt(promptStyleId)
      );
      setSelectedStyle(savedPromptStyle || promptList[0]);
    }
  }, []);

  return (
    <Box className={classes.container}>
      <Typography className={classes.header}>
        Re-Generate Shot Thumbnails
      </Typography>
      <Box>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Box className={classes.leftSideContainer}>
              <Box>
                <Typography sx={{ mb: 1, fontWeight: 600, fontSize: "20px" }}>
                  {singleSelectedShot.shotNumberText}
                </Typography>
                {singleSelectedShot.storyboardPaths &&
                singleSelectedShot.storyboardPaths.length ? (
                  <img
                    src={
                      singleSelectedShot.storyboardPaths[
                        singleSelectedShot.storyboardPaths.length - 1
                      ]?.storyboardImageURL ||
                      NoStoryBoard ||
                      ""
                    }
                    alt=""
                    height="180px"
                    width="100%"
                  />
                ) : (
                  <img
                    src={NoStoryBoard || ""}
                    alt=""
                    height="180px"
                    width="100%"
                  />
                )}
                <Typography sx={{ fontSize: "12px", mt: 1 }}>
                  <span>Shot Description - </span>
                  <span style={{ color: "#8D8E93" }}>
                    {singleSelectedShot.description}
                  </span>
                </Typography>
              </Box>
              <Box>
                <Box sx={{ mt: 5 }}>
                  <Typography sx={{ mb: 1 }}>Story board Style</Typography>
                  <Box
                    className={classes.selectedStoryBoardStyle}
                    onClick={() => setSelectStyleDropdown(!selectStyleDropdown)}
                  >
                    <img
                      src={selectedStyle.image}
                      alt=""
                      height="50px"
                      width="100px"
                      style={{ borderRadius: "10px 0px 0px 10px" }}
                    />
                    <Typography>Style name: {selectedStyle.name}</Typography>
                    {selectStyleDropdown ? (
                      <CaretUp size={20} />
                    ) : (
                      <CaretDown size={20} />
                    )}
                  </Box>
                </Box>
                {selectStyleDropdown && (
                  <Box className={classes.dropdownContainer}>
                    {promptList.map((prompt: any, index: number) => (
                      <Box
                        className={classes.selectStoryBoardStyle}
                        sx={{
                          border:
                            selectedStyle.id === prompt.id
                              ? "2px solid #6C65D9"
                              : "",
                          borderRadius: "10px",
                        }}
                        onClick={() => setSelectedStyle(prompt)}
                      >
                        <img
                          src={prompt?.image}
                          alt=""
                          height="50px"
                          width="100px"
                          style={{ borderRadius: "10px 0px 0px 10px" }}
                        />
                        <Typography>{prompt?.name}</Typography>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box className={classes.rightSideContainer}>
              <Box>
                <Typography sx={{ mb: 1 }}>Description</Typography>
                <textarea
                  placeholder="Add description here"
                  className={classes.descriptionInput}
                  onChange={(event) => setUserDescription(event.target.value)}
                  value={userDescription}
                />
              </Box>
              <Box position="relative">
                <Typography sx={{ color: "#606479", fontSize: "12px" }}>
                  Camera Settings
                </Typography>
                <Box className={classes.line} />
              </Box>
              <Box>
                <FormControlLabel
                  value="none"
                  label="Auto"
                  control={
                    <Radio
                      checked={cameraSettings === "none"}
                      onClick={() => handleChangeCameraSettings("none")}
                      sx={{ color: "#606479" }}
                    />
                  }
                  sx={{
                    color: cameraSettings === "none" ? "white" : "#606479",
                  }}
                />
              </Box>
              <Box>
                <FormControlLabel
                  value="close"
                  label="Close-ups"
                  control={
                    <Radio
                      checked={cameraSettings === "close"}
                      onClick={() => {
                        handleChangeCameraSettings("close");
                        setCameraOption(optionCloseShots[0].value);
                      }}
                      sx={{ color: "#606479" }}
                    />
                  }
                  sx={{
                    color: cameraSettings === "close" ? "white" : "#606479",
                  }}
                />
                <FormControl className={classes.formControl}>
                  <Select
                    value={cameraOption}
                    onChange={(e) => setCameraOption(e.target.value)}
                    className={classes.select}
                    variant="standard"
                    disableUnderline
                    disabled={cameraSettings !== "close"}
                  >
                    {optionCloseShots.map((option) => (
                      <MenuItem
                        value={option.value}
                        className={classes.menuItem}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FormControlLabel
                  value="long"
                  label="Long-shots"
                  control={
                    <Radio
                      checked={cameraSettings === "long"}
                      onClick={() => {
                        handleChangeCameraSettings("long");
                        setCameraOption(optionsLongShots[0].value);
                      }}
                      sx={{ color: "#606479" }}
                    />
                  }
                  sx={{
                    color: cameraSettings === "long" ? "white" : "#606479",
                  }}
                />
                <FormControl className={classes.formControl}>
                  <Select
                    value={cameraOption}
                    onChange={(e) => setCameraOption(e.target.value)}
                    className={classes.select}
                    variant="standard"
                    disableUnderline
                    disabled={cameraSettings !== "long"}
                  >
                    {optionsLongShots.map((option) => (
                      <MenuItem
                        value={option.value}
                        className={classes.menuItem}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FormControlLabel
                  value="medium"
                  label="Medium-shots"
                  control={
                    <Radio
                      checked={cameraSettings === "medium"}
                      onClick={() => {
                        handleChangeCameraSettings("medium");
                        setCameraOption(optionMediumShots[0].value);
                      }}
                      sx={{ color: "#606479" }}
                    />
                  }
                  sx={{
                    color: cameraSettings === "medium" ? "white" : "#606479",
                  }}
                />
                <FormControl className={classes.formControl}>
                  <Select
                    value={cameraOption}
                    onChange={(e) => setCameraOption(e.target.value)}
                    className={classes.select}
                    variant="standard"
                    disableUnderline
                    disabled={cameraSettings !== "medium"}
                  >
                    {optionMediumShots.map((option) => (
                      <MenuItem
                        value={option.value}
                        className={classes.menuItem}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.btnContainer}>
        <Button
          className={classes.cancelButton}
          onClick={handleShotsThumbnailModal}
        >
          Cancel
        </Button>
        <Button
          className={classes.generateButton}
          endIcon={
            <Chip
              style={{ color: "white", padding: "4px" }}
              avatar={<CoinIconXsmall />}
              label="1"
              variant="outlined"
            />
          }
          onClick={() =>
            handleSubmit(
              selectedStyle,
              userDescription,
              cameraOption,
              cameraSettings
            )
          }
        >
          Generate
        </Button>
      </Box>
    </Box>
  );
};

export default SingleShotThumbnailGenerateModal;
