export const StoryBoardAnimatedData = {
  nm: "fluent:image-copy-20-regular",
  ddd: 0,
  h: 104,
  w: 104,
  meta: { g: "LottieFiles Figma v45" },
  layers: [
    {
      ty: 0,
      nm: "Frame 544",
      sr: 1,
      st: 0,
      op: 121,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: {
          a: 1,
          k: [
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [15.79, 15.53],
              t: 0,
            },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [20, 19.5], t: 60 },
            { s: [15.79, 15.53], t: 120 },
          ],
        },
        s: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100, 100], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100, 100], t: 60 },
            { s: [100, 100], t: 120 },
          ],
        },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [78.03, 25.27],
              t: 0,
            },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [78, 25.5], t: 60 },
            { s: [78.03, 25.27], t: 120 },
          ],
        },
        r: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 60 },
            { s: [0], t: 120 },
          ],
        },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 60 },
            { s: [100], t: 120 },
          ],
        },
      },
      w: 40,
      h: 39,
      refId: "1",
      ind: 1,
    },
    {
      ty: 4,
      nm: "Vector",
      sr: 1,
      st: 0,
      op: 121,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: {
          a: 1,
          k: [
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [36.32, 36.32],
              t: 0,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [36.32, 36.32],
              t: 60,
            },
            { s: [36.32, 36.32], t: 120 },
          ],
        },
        s: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100, 100], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100, 100], t: 60 },
            { s: [100, 100], t: 120 },
          ],
        },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [52, 52], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [52, 52], t: 60 },
            { s: [52, 52], t: 120 },
          ],
        },
        r: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 60 },
            { s: [0], t: 120 },
          ],
        },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 60 },
            { s: [100], t: 120 },
          ],
        },
      },
      shapes: [
        {
          ty: "sh",
          bm: 0,
          hd: false,
          nm: "",
          d: 1,
          ks: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0.69, 0.02],
                      [0.94, 0.97],
                      [0, 1.34],
                      [-0.94, 0.97],
                      [-1.34, 0.04],
                      [-0.65, -0.25],
                      [-0.5, -0.48],
                      [-0.27, -0.64],
                      [0, -0.69],
                      [0.27, -0.64],
                      [0.5, -0.48],
                      [0.65, -0.25],
                    ],
                    o: [
                      [-0.65, 0.25],
                      [-1.34, -0.04],
                      [-0.94, -0.97],
                      [0, -1.34],
                      [0.94, -0.97],
                      [0.69, -0.02],
                      [0.65, 0.25],
                      [0.5, 0.48],
                      [0.27, 0.64],
                      [0, 0.69],
                      [-0.27, 0.64],
                      [-0.5, 0.48],
                      [0, 0],
                    ],
                    v: [
                      [30.56, 22.99],
                      [28.53, 23.33],
                      [24.97, 21.76],
                      [23.51, 18.16],
                      [24.97, 14.55],
                      [28.53, 12.98],
                      [30.56, 13.33],
                      [32.29, 14.44],
                      [33.46, 16.14],
                      [33.87, 18.16],
                      [33.46, 20.18],
                      [32.29, 21.88],
                      [30.56, 22.99],
                    ],
                  },
                ],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0.69, 0.02],
                      [0.94, 0.97],
                      [0, 1.34],
                      [-0.94, 0.97],
                      [-1.34, 0.04],
                      [-0.65, -0.25],
                      [-0.5, -0.48],
                      [-0.27, -0.64],
                      [0, -0.69],
                      [0.27, -0.64],
                      [0.5, -0.48],
                      [0.65, -0.25],
                    ],
                    o: [
                      [-0.65, 0.25],
                      [-1.34, -0.04],
                      [-0.94, -0.97],
                      [0, -1.34],
                      [0.94, -0.97],
                      [0.69, -0.02],
                      [0.65, 0.25],
                      [0.5, 0.48],
                      [0.27, 0.64],
                      [0, 0.69],
                      [-0.27, 0.64],
                      [-0.5, 0.48],
                      [0, 0],
                    ],
                    v: [
                      [30.56, 22.99],
                      [28.53, 23.33],
                      [24.97, 21.76],
                      [23.51, 18.16],
                      [24.97, 14.55],
                      [28.53, 12.98],
                      [30.56, 13.33],
                      [32.29, 14.44],
                      [33.46, 16.14],
                      [33.87, 18.16],
                      [33.46, 20.18],
                      [32.29, 21.88],
                      [30.56, 22.99],
                    ],
                  },
                ],
                t: 60,
              },
              {
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0.69, 0.02],
                      [0.94, 0.97],
                      [0, 1.34],
                      [-0.94, 0.97],
                      [-1.34, 0.04],
                      [-0.65, -0.25],
                      [-0.5, -0.48],
                      [-0.27, -0.64],
                      [0, -0.69],
                      [0.27, -0.64],
                      [0.5, -0.48],
                      [0.65, -0.25],
                    ],
                    o: [
                      [-0.65, 0.25],
                      [-1.34, -0.04],
                      [-0.94, -0.97],
                      [0, -1.34],
                      [0.94, -0.97],
                      [0.69, -0.02],
                      [0.65, 0.25],
                      [0.5, 0.48],
                      [0.27, 0.64],
                      [0, 0.69],
                      [-0.27, 0.64],
                      [-0.5, 0.48],
                      [0, 0],
                    ],
                    v: [
                      [30.56, 22.99],
                      [28.53, 23.33],
                      [24.97, 21.76],
                      [23.51, 18.16],
                      [24.97, 14.55],
                      [28.53, 12.98],
                      [30.56, 13.33],
                      [32.29, 14.44],
                      [33.46, 16.14],
                      [33.87, 18.16],
                      [33.46, 20.18],
                      [32.29, 21.88],
                      [30.56, 22.99],
                    ],
                  },
                ],
                t: 120,
              },
            ],
          },
        },
        {
          ty: "sh",
          bm: 0,
          hd: false,
          nm: "",
          d: 1,
          ks: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, -4.13],
                      [0, 0],
                      [-2.92, -2.92],
                      [-4.13, 0],
                      [0, 0],
                      [-2.92, 2.92],
                      [0, 4.13],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.92, -1.55],
                      [0, 0],
                      [2.35, 0],
                      [1.66, -1.66],
                      [0, 0],
                      [0, 1.92],
                      [0, 0],
                      [-1.95, 1.95],
                      [-2.75, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.92, -2.92],
                    ],
                    o: [
                      [-2.92, 2.92],
                      [0, 0],
                      [0, 4.13],
                      [2.92, 2.92],
                      [0, 0],
                      [4.13, 0],
                      [2.92, -2.92],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 1.92],
                      [0, 0],
                      [-1.66, -1.66],
                      [-2.35, 0],
                      [0, 0],
                      [-0.91, -1.55],
                      [0, 0],
                      [0, -2.75],
                      [1.95, -1.95],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.13, 0],
                      [0, 0],
                    ],
                    v: [
                      [14.94, 4.56],
                      [10.38, 15.57],
                      [10.38, 46.7],
                      [14.94, 57.7],
                      [25.94, 62.26],
                      [57.07, 62.26],
                      [68.08, 57.7],
                      [72.64, 46.7],
                      [72.64, 29.55],
                      [67.45, 29.55],
                      [67.45, 46.7],
                      [66.01, 51.97],
                      [47.77, 33.73],
                      [41.5, 31.14],
                      [35.23, 33.73],
                      [17, 51.97],
                      [15.57, 46.7],
                      [15.57, 15.57],
                      [18.6, 8.23],
                      [25.94, 5.19],
                      [41.51, 5.19],
                      [41.51, 0],
                      [25.94, 0],
                      [14.94, 4.56],
                    ],
                  },
                ],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, -4.13],
                      [0, 0],
                      [-2.92, -2.92],
                      [-4.13, 0],
                      [0, 0],
                      [-2.92, 2.92],
                      [0, 4.13],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.92, -1.55],
                      [0, 0],
                      [2.35, 0],
                      [1.66, -1.66],
                      [0, 0],
                      [0, 1.92],
                      [0, 0],
                      [-1.95, 1.95],
                      [-2.75, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.92, -2.92],
                    ],
                    o: [
                      [-2.92, 2.92],
                      [0, 0],
                      [0, 4.13],
                      [2.92, 2.92],
                      [0, 0],
                      [4.13, 0],
                      [2.92, -2.92],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 1.92],
                      [0, 0],
                      [-1.66, -1.66],
                      [-2.35, 0],
                      [0, 0],
                      [-0.91, -1.55],
                      [0, 0],
                      [0, -2.75],
                      [1.95, -1.95],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.13, 0],
                      [0, 0],
                    ],
                    v: [
                      [14.94, 4.56],
                      [10.38, 15.57],
                      [10.38, 46.7],
                      [14.94, 57.7],
                      [25.94, 62.26],
                      [57.07, 62.26],
                      [68.08, 57.7],
                      [72.64, 46.7],
                      [72.64, 29.55],
                      [67.45, 29.55],
                      [67.45, 46.7],
                      [66.01, 51.97],
                      [47.77, 33.73],
                      [41.5, 31.14],
                      [35.23, 33.73],
                      [17, 51.97],
                      [15.57, 46.7],
                      [15.57, 15.57],
                      [18.6, 8.23],
                      [25.94, 5.19],
                      [41.51, 5.19],
                      [41.51, 0],
                      [25.94, 0],
                      [14.94, 4.56],
                    ],
                  },
                ],
                t: 60,
              },
              {
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, -4.13],
                      [0, 0],
                      [-2.92, -2.92],
                      [-4.13, 0],
                      [0, 0],
                      [-2.92, 2.92],
                      [0, 4.13],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.92, -1.55],
                      [0, 0],
                      [2.35, 0],
                      [1.66, -1.66],
                      [0, 0],
                      [0, 1.92],
                      [0, 0],
                      [-1.95, 1.95],
                      [-2.75, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.92, -2.92],
                    ],
                    o: [
                      [-2.92, 2.92],
                      [0, 0],
                      [0, 4.13],
                      [2.92, 2.92],
                      [0, 0],
                      [4.13, 0],
                      [2.92, -2.92],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 1.92],
                      [0, 0],
                      [-1.66, -1.66],
                      [-2.35, 0],
                      [0, 0],
                      [-0.91, -1.55],
                      [0, 0],
                      [0, -2.75],
                      [1.95, -1.95],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.13, 0],
                      [0, 0],
                    ],
                    v: [
                      [14.94, 4.56],
                      [10.38, 15.57],
                      [10.38, 46.7],
                      [14.94, 57.7],
                      [25.94, 62.26],
                      [57.07, 62.26],
                      [68.08, 57.7],
                      [72.64, 46.7],
                      [72.64, 29.55],
                      [67.45, 29.55],
                      [67.45, 46.7],
                      [66.01, 51.97],
                      [47.77, 33.73],
                      [41.5, 31.14],
                      [35.23, 33.73],
                      [17, 51.97],
                      [15.57, 46.7],
                      [15.57, 15.57],
                      [18.6, 8.23],
                      [25.94, 5.19],
                      [41.51, 5.19],
                      [41.51, 0],
                      [25.94, 0],
                      [14.94, 4.56],
                    ],
                  },
                ],
                t: 120,
              },
            ],
          },
        },
        {
          ty: "sh",
          bm: 0,
          hd: false,
          nm: "",
          d: 1,
          ks: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [1.92, 0],
                      [0, 0],
                      [1.55, 0.92],
                      [0, 0],
                      [-0.98, 0],
                      [-0.69, -0.69],
                    ],
                    o: [
                      [0, 0],
                      [-1.55, 0.91],
                      [0, 0],
                      [-1.92, 0],
                      [0, 0],
                      [0.69, -0.69],
                      [0.98, 0],
                      [0, 0],
                    ],
                    v: [
                      [44.11, 37.4],
                      [62.34, 55.64],
                      [57.07, 57.07],
                      [25.94, 57.07],
                      [20.67, 55.64],
                      [38.9, 37.4],
                      [41.51, 36.32],
                      [44.11, 37.4],
                    ],
                  },
                ],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [1.92, 0],
                      [0, 0],
                      [1.55, 0.92],
                      [0, 0],
                      [-0.98, 0],
                      [-0.69, -0.69],
                    ],
                    o: [
                      [0, 0],
                      [-1.55, 0.91],
                      [0, 0],
                      [-1.92, 0],
                      [0, 0],
                      [0.69, -0.69],
                      [0.98, 0],
                      [0, 0],
                    ],
                    v: [
                      [44.11, 37.4],
                      [62.34, 55.64],
                      [57.07, 57.07],
                      [25.94, 57.07],
                      [20.67, 55.64],
                      [38.9, 37.4],
                      [41.51, 36.32],
                      [44.11, 37.4],
                    ],
                  },
                ],
                t: 60,
              },
              {
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [1.92, 0],
                      [0, 0],
                      [1.55, 0.92],
                      [0, 0],
                      [-0.98, 0],
                      [-0.69, -0.69],
                    ],
                    o: [
                      [0, 0],
                      [-1.55, 0.91],
                      [0, 0],
                      [-1.92, 0],
                      [0, 0],
                      [0.69, -0.69],
                      [0.98, 0],
                      [0, 0],
                    ],
                    v: [
                      [44.11, 37.4],
                      [62.34, 55.64],
                      [57.07, 57.07],
                      [25.94, 57.07],
                      [20.67, 55.64],
                      [38.9, 37.4],
                      [41.51, 36.32],
                      [44.11, 37.4],
                    ],
                  },
                ],
                t: 120,
              },
            ],
          },
        },
        {
          ty: "sh",
          bm: 0,
          hd: false,
          nm: "",
          d: 1,
          ks: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [2.19, 0],
                      [0, 0],
                      [4.38, 4.38],
                      [0, 6.19],
                      [0, 0],
                      [-3.19, 2.85],
                      [0, 0],
                      [-3.41, -3.41],
                      [-4.82, 0],
                      [0, 0],
                      [2, -0.89],
                    ],
                    o: [
                      [-2, 0.89],
                      [0, 0],
                      [-6.19, 0],
                      [-4.38, -4.38],
                      [0, 0],
                      [0, -4.61],
                      [0, 0],
                      [0, 4.82],
                      [3.41, 3.41],
                      [0, 0],
                      [-1.46, 1.63],
                      [0, 0],
                    ],
                    v: [
                      [53.05, 71.29],
                      [46.7, 72.64],
                      [23.35, 72.64],
                      [6.84, 65.8],
                      [0, 49.29],
                      [0, 25.94],
                      [5.19, 14.34],
                      [5.19, 49.29],
                      [10.51, 62.13],
                      [23.35, 67.45],
                      [58.3, 67.45],
                      [53.05, 71.29],
                    ],
                  },
                ],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [2.19, 0],
                      [0, 0],
                      [4.38, 4.38],
                      [0, 6.19],
                      [0, 0],
                      [-3.19, 2.85],
                      [0, 0],
                      [-3.41, -3.41],
                      [-4.82, 0],
                      [0, 0],
                      [2, -0.89],
                    ],
                    o: [
                      [-2, 0.89],
                      [0, 0],
                      [-6.19, 0],
                      [-4.38, -4.38],
                      [0, 0],
                      [0, -4.61],
                      [0, 0],
                      [0, 4.82],
                      [3.41, 3.41],
                      [0, 0],
                      [-1.46, 1.63],
                      [0, 0],
                    ],
                    v: [
                      [53.05, 71.29],
                      [46.7, 72.64],
                      [23.35, 72.64],
                      [6.84, 65.8],
                      [0, 49.29],
                      [0, 25.94],
                      [5.19, 14.34],
                      [5.19, 49.29],
                      [10.51, 62.13],
                      [23.35, 67.45],
                      [58.3, 67.45],
                      [53.05, 71.29],
                    ],
                  },
                ],
                t: 60,
              },
              {
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [2.19, 0],
                      [0, 0],
                      [4.38, 4.38],
                      [0, 6.19],
                      [0, 0],
                      [-3.19, 2.85],
                      [0, 0],
                      [-3.41, -3.41],
                      [-4.82, 0],
                      [0, 0],
                      [2, -0.89],
                    ],
                    o: [
                      [-2, 0.89],
                      [0, 0],
                      [-6.19, 0],
                      [-4.38, -4.38],
                      [0, 0],
                      [0, -4.61],
                      [0, 0],
                      [0, 4.82],
                      [3.41, 3.41],
                      [0, 0],
                      [-1.46, 1.63],
                      [0, 0],
                    ],
                    v: [
                      [53.05, 71.29],
                      [46.7, 72.64],
                      [23.35, 72.64],
                      [6.84, 65.8],
                      [0, 49.29],
                      [0, 25.94],
                      [5.19, 14.34],
                      [5.19, 49.29],
                      [10.51, 62.13],
                      [23.35, 67.45],
                      [58.3, 67.45],
                      [53.05, 71.29],
                    ],
                  },
                ],
                t: 120,
              },
            ],
          },
        },
        {
          ty: "fl",
          bm: 0,
          hd: false,
          nm: "",
          c: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [0.9765, 0.9765, 0.9765],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [0.9765, 0.9765, 0.9765],
                t: 60,
              },
              { s: [0.9765, 0.9765, 0.9765], t: 120 },
            ],
          },
          r: 2,
          o: {
            a: 1,
            k: [
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 60 },
              { s: [100], t: 120 },
            ],
          },
        },
      ],
      ind: 2,
    },
  ],
  v: "5.7.0",
  fr: 60,
  op: 120,
  ip: 0,
  assets: [
    {
      nm: "[Asset] Frame 544",
      id: "1",
      layers: [
        {
          ty: 4,
          nm: "Vector",
          sr: 1,
          st: 0,
          op: 121,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: {
              a: 1,
              k: [
                {
                  o: { x: 0, y: 0 },
                  i: { x: 0.58, y: 1 },
                  s: [15.79, 15.53],
                  t: 0,
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 0.58, y: 1 },
                  s: [20, 19.67],
                  t: 60,
                },
                { s: [15.79, 15.53], t: 120 },
              ],
            },
            s: {
              a: 1,
              k: [
                {
                  o: { x: 0, y: 0 },
                  i: { x: 0.58, y: 1 },
                  s: [100, 100],
                  t: 0,
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 0.58, y: 1 },
                  s: [100, 100],
                  t: 60,
                },
                { s: [100, 100], t: 120 },
              ],
            },
            sk: { a: 0, k: 0 },
            p: {
              a: 1,
              k: [
                {
                  o: { x: 0, y: 0 },
                  i: { x: 0.58, y: 1 },
                  s: [15.79, 15.53],
                  t: 0,
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 0.58, y: 1 },
                  s: [20, 19.5],
                  t: 60,
                },
                { s: [15.79, 15.53], t: 120 },
              ],
            },
            r: {
              a: 1,
              k: [
                { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 0 },
                { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 60 },
                { s: [0], t: 120 },
              ],
            },
            sa: { a: 0, k: 0 },
            o: {
              a: 1,
              k: [
                { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
                { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 60 },
                { s: [100], t: 120 },
              ],
            },
          },
          ef: [],
          shapes: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              nm: "",
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 0.58, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-1.75, 0.3],
                          [-2.27, 2.17],
                          [-0.67, 3.07],
                          [0, 0],
                          [-0.39, -1.73],
                          [0, 0],
                          [-2.27, -2.16],
                          [-3.08, -0.54],
                          [1.76, -0.31],
                          [2.27, -2.16],
                          [0.7, -3.05],
                          [0, 0],
                          [0.38, 1.73],
                          [0, 0],
                          [2.27, 2.17],
                          [3.09, 0.54],
                        ],
                        o: [
                          [-1.75, -0.31],
                          [3.09, -0.54],
                          [2.27, -2.17],
                          [0, 0],
                          [0.38, -1.73],
                          [0, 0],
                          [0.7, 3.05],
                          [2.27, 2.16],
                          [1.76, 0.3],
                          [-3.08, 0.54],
                          [-2.27, 2.16],
                          [0, 0],
                          [-0.39, 1.72],
                          [0, 0],
                          [-0.67, -3.07],
                          [-2.27, -2.17],
                          [0, 0],
                        ],
                        v: [
                          [1.31, 17.1],
                          [1.31, 13.98],
                          [9.53, 9.82],
                          [14.04, 1.79],
                          [14.14, 1.3],
                          [17.37, 1.29],
                          [17.5, 1.85],
                          [22.05, 9.84],
                          [30.27, 13.97],
                          [30.27, 17.1],
                          [22.05, 21.23],
                          [17.51, 29.22],
                          [17.38, 29.78],
                          [14.15, 29.76],
                          [14.05, 29.28],
                          [9.54, 21.25],
                          [1.31, 17.1],
                        ],
                      },
                    ],
                    t: 0,
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 0.58, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-2.21, 0.38],
                          [-2.87, 2.75],
                          [-0.85, 3.88],
                          [0, 0],
                          [-0.5, -2.19],
                          [0, 0],
                          [-2.88, -2.73],
                          [-3.91, -0.68],
                          [2.22, -0.39],
                          [2.87, -2.73],
                          [0.88, -3.87],
                          [0, 0],
                          [0.48, 2.19],
                          [0, 0],
                          [2.87, 2.75],
                          [3.92, 0.68],
                        ],
                        o: [
                          [-2.21, -0.39],
                          [3.92, -0.68],
                          [2.87, -2.75],
                          [0, 0],
                          [0.48, -2.19],
                          [0, 0],
                          [0.88, 3.87],
                          [2.88, 2.73],
                          [2.22, 0.38],
                          [-3.91, 0.68],
                          [-2.87, 2.73],
                          [0, 0],
                          [-0.5, 2.18],
                          [0, 0],
                          [-0.85, -3.89],
                          [-2.87, -2.75],
                          [0, 0],
                        ],
                        v: [
                          [1.66, 21.65],
                          [1.66, 17.71],
                          [12.07, 12.44],
                          [17.78, 2.27],
                          [17.91, 1.65],
                          [22, 1.63],
                          [22.17, 2.34],
                          [27.93, 12.46],
                          [38.33, 17.69],
                          [38.33, 21.66],
                          [27.93, 26.89],
                          [22.17, 37],
                          [22.01, 37.71],
                          [17.92, 37.69],
                          [17.79, 37.09],
                          [12.08, 26.91],
                          [1.66, 21.65],
                        ],
                      },
                    ],
                    t: 60,
                  },
                  {
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-1.75, 0.3],
                          [-2.27, 2.17],
                          [-0.67, 3.07],
                          [0, 0],
                          [-0.39, -1.73],
                          [0, 0],
                          [-2.27, -2.16],
                          [-3.08, -0.54],
                          [1.76, -0.31],
                          [2.27, -2.16],
                          [0.7, -3.05],
                          [0, 0],
                          [0.38, 1.73],
                          [0, 0],
                          [2.27, 2.17],
                          [3.09, 0.54],
                        ],
                        o: [
                          [-1.75, -0.31],
                          [3.09, -0.54],
                          [2.27, -2.17],
                          [0, 0],
                          [0.38, -1.73],
                          [0, 0],
                          [0.7, 3.05],
                          [2.27, 2.16],
                          [1.76, 0.3],
                          [-3.08, 0.54],
                          [-2.27, 2.16],
                          [0, 0],
                          [-0.39, 1.72],
                          [0, 0],
                          [-0.67, -3.07],
                          [-2.27, -2.17],
                          [0, 0],
                        ],
                        v: [
                          [1.31, 17.09],
                          [1.31, 13.98],
                          [9.53, 9.82],
                          [14.04, 1.79],
                          [14.14, 1.3],
                          [17.37, 1.29],
                          [17.5, 1.85],
                          [22.05, 9.84],
                          [30.26, 13.97],
                          [30.26, 17.1],
                          [22.05, 21.23],
                          [17.51, 29.22],
                          [17.38, 29.77],
                          [14.15, 29.76],
                          [14.05, 29.28],
                          [9.54, 21.24],
                          [1.31, 17.09],
                        ],
                      },
                    ],
                    t: 120,
                  },
                ],
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              nm: "",
              c: {
                a: 1,
                k: [
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 0.58, y: 1 },
                    s: [0.6275, 0.604, 0.9844],
                    t: 0,
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 0.58, y: 1 },
                    s: [0.6275, 0.604, 0.9844],
                    t: 60,
                  },
                  { s: [0.6275, 0.604, 0.9844], t: 120 },
                ],
              },
              r: 1,
              o: {
                a: 1,
                k: [
                  { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
                  { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 60 },
                  { s: [100], t: 120 },
                ],
              },
            },
          ],
          ind: 1,
        },
        {
          ty: 4,
          nm: "Frame 544 Bg",
          sr: 1,
          st: 0,
          op: 121,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: {
              a: 1,
              k: [
                {
                  o: { x: 0, y: 0 },
                  i: { x: 0.58, y: 1 },
                  s: [15.79, 15.53],
                  t: 0,
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 0.58, y: 1 },
                  s: [20, 19.5],
                  t: 60,
                },
                { s: [15.79, 15.53], t: 120 },
              ],
            },
            s: {
              a: 1,
              k: [
                {
                  o: { x: 0, y: 0 },
                  i: { x: 0.58, y: 1 },
                  s: [100, 100],
                  t: 0,
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 0.58, y: 1 },
                  s: [100, 100],
                  t: 60,
                },
                { s: [100, 100], t: 120 },
              ],
            },
            sk: { a: 0, k: 0 },
            p: {
              a: 1,
              k: [
                {
                  o: { x: 0, y: 0 },
                  i: { x: 0.58, y: 1 },
                  s: [15.79, 15.53],
                  t: 0,
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 0.58, y: 1 },
                  s: [20, 19.5],
                  t: 60,
                },
                { s: [15.79, 15.53], t: 120 },
              ],
            },
            r: {
              a: 1,
              k: [
                { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 0 },
                { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 60 },
                { s: [0], t: 120 },
              ],
            },
            sa: { a: 0, k: 0 },
            o: {
              a: 1,
              k: [
                { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
                { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 60 },
                { s: [100], t: 120 },
              ],
            },
          },
          ef: [],
          shapes: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              nm: "",
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 0.58, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [0, 0],
                          [31.58, 0],
                          [31.58, 31.06],
                          [0, 31.06],
                        ],
                      },
                    ],
                    t: 0,
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 0.58, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [0, 0],
                          [40, 0],
                          [40, 39],
                          [0, 39],
                        ],
                      },
                    ],
                    t: 60,
                  },
                  {
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [0, 0],
                          [31.58, 0],
                          [31.58, 31.06],
                          [0, 31.06],
                        ],
                      },
                    ],
                    t: 120,
                  },
                ],
              },
            },
          ],
          ind: 2,
        },
      ],
    },
  ],
};
