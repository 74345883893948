import { Document, Font } from "@react-pdf/renderer";
import CoverPage from "./CoverPage/CoverPage";
import IndexPage from "./IndexPage/IndexPage";
// import CharactersPage from "./CharactersPage/CharactersPage";
// import LocationsPage from "./LocationsPage/LocationsPage";
import StoryBoardPage from "./StoryBoardPage/StoryBoardPage";
import BreakdownPageView from "./StoryboardBreakdownPage/BreakdownPageView/BreakdownPageView";
import StoryboardBreakdownPageView from "./StoryboardBreakdownPage/StoryboardBreakdownPageView/StoryboardBreakdownPageView";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf",
      fontWeight: 400,
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf",
      fontWeight: 500,
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf",
      fontWeight: 600,
    },
  ],
});
const ScriptBreakPdfDocument = ({
  sequencesToExport,
  projectName,
  selectedPdfFormat,
  shotCount,
  sequenceCount,
  loggedInUser,
}) => {
  return (
    <Document>
      {selectedPdfFormat.Cover && (
        <CoverPage
          projectName={projectName}
          shotCount={shotCount}
          sequenceCount={sequenceCount}
          loggedInUser={loggedInUser}
        />
      )}
      {selectedPdfFormat.Index && (
        <IndexPage
          projectName={projectName}
          sequencesToExport={sequencesToExport}
        />
      )}
      {selectedPdfFormat.Storyboard &&
        selectedPdfFormat.Breakdown === false && (
          <StoryBoardPage
            projectName={projectName}
            sequencesToExport={sequencesToExport}
          />
        )}
      {selectedPdfFormat.Breakdown &&
        selectedPdfFormat.Storyboard === false && (
          <BreakdownPageView
            projectName={projectName}
            sequencesToExport={sequencesToExport}
          />
        )}
      {selectedPdfFormat.Breakdown && selectedPdfFormat.Storyboard && (
        <StoryboardBreakdownPageView
          projectName={projectName}
          sequencesToExport={sequencesToExport}
        />
      )}
    </Document>
  );
};

export default ScriptBreakPdfDocument;
