const CharacterSvg = () => {
  return (
    <svg
      width="300"
      height="250"
      viewBox="0 0 204 185"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.802421 132.309C5.71789 93.8545 51.2359 81.0533 72.7565 80.5604C150.625 74.6465 146.75 123.903 146.914 159.059L73.4809 159.059L0.0480211 159.059C0.0487468 149.203 -0.286081 140.824 0.802421 132.309Z"
        fill="#6C65D9"
      />
      <path
        d="M24.197 89.0759C29.3225 67.391 55.5743 62.9555 68.0596 63.4483C90.927 56.3514 106.83 69.3624 111.922 76.755C118.493 85.7904 120.497 104.65 75.945 107.804C31.3924 110.958 22.8828 96.6328 24.197 89.0759Z"
        fill="#A09AFB"
      />
      <path
        d="M46.3732 72.319C54.2586 67.5878 65.7581 67.7192 70.5222 68.3763C81.0362 69.6906 99.8956 74.6847 91.2216 84.1473C82.5477 93.6099 65.5939 90.0614 58.2013 87.1043C50.973 84.1472 38.4878 77.0503 46.3732 72.319Z"
        fill="#252A38"
      />
      <path
        d="M55.7377 86.1199L55.2449 59.9995L82.8438 68.8706L84.3223 88.5841C76.4369 92.5268 61.6517 89.0769 55.7377 86.1199Z"
        fill="#E5986D"
      />
      <path
        d="M83.8295 80.2056C74.3671 81.7826 64.7732 73.9629 61.159 69.856L83.3367 72.813L83.8295 80.2056Z"
        fill="#252A38"
      />
      <path
        d="M92.3692 42.5758C94.8509 58.4419 92.5532 72.5404 81.3648 74.2904C62.1441 77.2968 54.3342 64.7793 51.8525 48.9132C49.3708 33.0471 56.429 18.7664 67.6173 17.0164C78.8057 15.2663 89.8875 26.7097 92.3692 42.5758Z"
        fill="#E6986D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M84.2541 32.5893C83.308 32.7297 82.4607 33.223 81.9926 33.6243C81.5882 33.9709 80.9793 33.9241 80.6326 33.5197C80.286 33.1152 80.3328 32.5064 80.7373 32.1597C81.4191 31.5753 82.5924 30.8858 83.9709 30.6812C85.398 30.4694 87.0297 30.7859 88.4538 32.21C88.8304 32.5867 88.8304 33.1973 88.4538 33.574C88.0771 33.9506 87.4665 33.9506 87.0898 33.574C86.1483 32.6325 85.1515 32.4561 84.2541 32.5893Z"
        fill="#1B1D28"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.4124 39.3247C71.9424 39.2717 72.4151 39.6584 72.4681 40.1884L72.9609 45.1168C73.0139 45.6468 72.6272 46.1194 72.0972 46.1724C71.5672 46.2254 71.0946 45.8387 71.0416 45.3087L70.5487 40.3803C70.4957 39.8503 70.8824 39.3777 71.4124 39.3247Z"
        fill="#1B1D28"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M85.7042 37.8471C86.2342 37.7941 86.7069 38.1808 86.7599 38.7109L87.2527 43.6392C87.3057 44.1692 86.919 44.6419 86.389 44.6949C85.859 44.7479 85.3864 44.3612 85.3334 43.8312L84.8405 38.9028C84.7875 38.3728 85.1742 37.9001 85.7042 37.8471Z"
        fill="#1B1D28"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M80.6829 43.5457C81.0596 43.169 81.6702 43.169 82.0469 43.5457C83.4327 44.9315 85.6087 49.0202 84.7851 55.1975C84.7147 55.7255 84.2296 56.0965 83.7016 56.0261C83.1736 55.9557 82.8027 55.4706 82.8731 54.9426C83.6265 49.2919 81.597 45.8237 80.6829 44.9096C80.3063 44.533 80.3063 43.9223 80.6829 43.5457Z"
        fill="#1B1D28"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M74.3054 60.4047C73.9157 58.7899 75.5828 57.7417 76.8857 58.3208L83.2986 61.171C84.3035 61.6176 84.7323 62.8127 84.2405 63.7963L83.8642 64.5489C83.6658 64.9457 83.3189 65.2989 82.8413 65.4701C81.6051 65.9132 79.8426 66.1498 78.1682 65.4844C76.4341 64.7953 74.9836 63.2151 74.3054 60.4047ZM76.2275 60.1391C76.8001 62.3271 77.8626 63.2873 78.8805 63.6918C79.9701 64.1248 81.2038 63.9984 82.1472 63.6695L82.5152 62.9336L76.2275 60.1391Z"
        fill="#1B1D28"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M73.2455 34.8135C70.5857 34.1485 68.8612 35.6441 68.3678 36.3841C68.0723 36.8273 67.4735 36.9471 67.0303 36.6516C66.5871 36.3562 66.4674 35.7573 66.7628 35.3141C67.5837 34.0828 70.0648 32.03 73.7133 32.9421C74.2301 33.0713 74.5442 33.5949 74.4151 34.1117C74.2859 34.6285 73.7622 34.9426 73.2455 34.8135Z"
        fill="#1B1D28"
      />
      <path
        d="M61.4957 45.7953C62.3864 51.49 61.5618 56.5502 57.546 57.1783C50.6473 58.2574 47.8442 53.7646 46.9534 48.0699C46.0627 42.3752 48.596 37.2496 52.6118 36.6215C56.6275 35.9934 60.605 40.1006 61.4957 45.7953Z"
        fill="#E6986D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M50.7615 46.3477C51.2941 46.3477 51.726 46.7795 51.726 47.3121C51.726 47.9753 51.8404 48.9525 52.1498 49.7261C52.471 50.5291 52.8445 50.7832 53.2257 50.7832C53.7583 50.7832 54.1901 51.215 54.1901 51.7477C54.1901 52.2803 53.7583 52.7121 53.2257 52.7121C51.6355 52.7121 50.7769 51.4877 50.3588 50.4425C49.929 49.368 49.797 48.1274 49.797 47.3121C49.797 46.7795 50.2288 46.3477 50.7615 46.3477Z"
        fill="#1B1D28"
      />
      <path
        d="M100.586 18.1073C94.672 29.1469 82.0225 25.3356 76.437 22.05C73.2826 32.6952 62.6376 33.3854 57.7095 32.3998C60.0146 37.01 59.7401 41.9011 59.0406 44.8414C58.7055 46.2499 57.2179 46.8157 55.8623 46.3074L50.3169 44.2279C43.22 47.7763 36.6816 42.7493 35.0387 39.7923C30.3075 27.1757 40.7883 21.7216 45.3881 21.0645C46.5709 11.2076 50.4808 6.77203 52.7807 5.29356C68.1573 -4.95747 74.3014 2.00797 75.4513 6.77207C78.2112 4.40645 84.1581 3.48649 86.7866 3.32221C93.8506 3.65076 106.5 7.06777 100.586 18.1073Z"
        fill="#1B1D28"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.1843 102.457C33.4159 101.977 33.9925 101.776 34.4722 102.007C39.1271 104.254 51.8308 108.5 65.0169 107.336C65.5475 107.289 66.0156 107.682 66.0624 108.212C66.1092 108.743 65.717 109.211 65.1864 109.258C51.5621 110.46 38.5069 106.097 33.6336 103.744C33.1539 103.513 32.9528 102.936 33.1843 102.457Z"
        fill="#252A38"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M112.949 95.1738C113.392 95.4693 113.512 96.0681 113.217 96.5113C112.117 98.1612 110.129 99.8548 107.526 101.315C104.908 102.783 101.609 104.049 97.8181 104.807C97.2958 104.912 96.7877 104.573 96.6832 104.051C96.5787 103.529 96.9175 103.02 97.4398 102.916C101.042 102.196 104.149 100.997 106.583 99.6323C109.032 98.2582 110.74 96.7484 111.612 95.4413C111.907 94.9981 112.506 94.8783 112.949 95.1738Z"
        fill="#252A38"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M65.4684 120.648C65.9964 120.577 66.4815 120.948 66.5519 121.476L65.5958 121.604L64.6398 121.731C64.5694 121.203 64.9404 120.718 65.4684 120.648ZM65.5958 121.604L64.6398 121.731L64.6411 121.741L64.6451 121.771L64.6608 121.893C64.6745 122.001 64.6944 122.16 64.7192 122.365C64.7687 122.776 64.8374 123.369 64.9138 124.099C65.0668 125.559 65.2501 127.561 65.3722 129.728C65.4944 131.898 65.5545 134.22 65.4639 136.324C65.3724 138.451 65.1297 140.27 64.6894 141.48C64.5074 141.981 64.7657 142.534 65.2662 142.716C65.7668 142.899 66.3202 142.64 66.5022 142.14C67.0476 140.64 67.2978 138.577 67.3911 136.407C67.4854 134.215 67.4222 131.824 67.298 129.62C67.1737 127.413 66.9874 125.379 66.8323 123.898C66.7547 123.157 66.6848 122.553 66.6342 122.134C66.6089 121.924 66.5885 121.761 66.5742 121.649L66.5578 121.522L66.5535 121.488L66.552 121.477C66.552 121.477 66.5519 121.476 65.5958 121.604Z"
        fill="#252A38"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M89.6972 118.916C89.6972 118.916 89.6972 118.916 89.6972 118.916L89.6984 118.921L89.7031 118.94C89.7075 118.958 89.7143 118.986 89.7235 119.023C89.742 119.096 89.7703 119.207 89.8086 119.351C89.8853 119.64 90.002 120.064 90.1607 120.597C90.4783 121.663 90.9632 123.163 91.6301 124.891C92.9682 128.358 95.0203 132.692 97.8903 136.279C98.2231 136.695 98.1557 137.302 97.7397 137.635C97.3238 137.968 96.7169 137.9 96.3841 137.484C93.3401 133.679 91.203 129.142 89.8306 125.586C89.1422 123.802 88.6413 122.253 88.3121 121.147C88.1474 120.595 88.0254 120.152 87.9442 119.846C87.9036 119.693 87.8732 119.574 87.8527 119.492C87.8424 119.452 87.8347 119.42 87.8294 119.398L87.8232 119.373L87.8215 119.366L87.821 119.364C87.821 119.364 87.8207 119.363 88.759 119.139L87.8207 119.363C87.6974 118.845 88.0174 118.325 88.5356 118.201C89.0537 118.078 89.5738 118.398 89.6972 118.916Z"
        fill="#252A38"
      />
      <path
        d="M86.2461 158.695C81.8105 161.652 76.0607 162.966 75.4036 165.101L59.6328 155.738C80.332 146.867 83.289 156.231 86.2461 158.695Z"
        fill="#E5986D"
      />
      <path
        d="M17.7421 184.32C2.95697 181.362 -0.000153468 165.592 0 156.721L22.1777 157.707L55.6907 152.779C69.8844 151.99 76.8827 160.171 77.3755 165.592C77.8684 171.014 32.5273 187.278 17.7421 184.32Z"
        fill="#6C65D9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.1608 156.667C71.464 156.229 72.0648 156.119 72.5028 156.423C73.6425 157.212 75.3201 158.659 76.7229 160.484C78.1158 162.297 79.3252 164.598 79.3252 167.072C79.3252 167.605 78.8934 168.037 78.3607 168.037C77.828 168.037 77.3962 167.605 77.3962 167.072C77.3962 165.209 76.4699 163.321 75.1933 161.66C73.9266 160.011 72.4007 158.698 71.4048 158.009C70.9669 157.705 70.8576 157.104 71.1608 156.667Z"
        fill="#252A38"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M53.5299 153.805C54.0522 153.701 54.5603 154.039 54.6648 154.562C54.7693 155.084 54.4305 155.592 53.9082 155.697C47.6532 156.948 42.0276 157.072 37.9654 156.885C35.9331 156.791 34.2889 156.619 33.1487 156.469C32.5785 156.395 32.1341 156.326 31.8299 156.275C31.6778 156.249 31.5607 156.228 31.4805 156.214C31.4404 156.206 31.4095 156.2 31.3881 156.196L31.363 156.191L31.3558 156.19L31.3535 156.19C31.3532 156.19 31.3522 156.189 31.5413 155.244"
        fill="#252A38"
      />
      <path
        d="M153.216 85.7765L148.518 91.9415C147.772 92.9202 148.079 94.3339 149.163 94.9159L158.645 100.006C159.137 100.27 159.717 100.316 160.245 100.134L192.761 88.8788C194.893 88.141 194.363 84.9888 192.107 84.9888H154.807C154.183 84.9888 153.595 85.2801 153.216 85.7765Z"
        fill="#E5986D"
      />
      <path
        d="M168.045 104.249C158.271 115.619 150.632 138.65 146.425 152.607C145.742 154.875 143.262 156.067 141.069 155.174L119.816 146.529C118.022 145.799 116.999 143.887 117.48 142.01C123.216 119.661 138.547 98.8729 147.414 89.1378C148.631 87.8015 150.602 87.5335 152.177 88.4196L167.137 96.8347C169.699 98.2755 169.96 102.02 168.045 104.249Z"
        fill="#6C64D9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M138.005 99.1481C138.442 99.4535 138.548 100.055 138.242 100.491C134.87 105.308 129.179 113.862 126.814 123.319C126.685 123.836 126.161 124.15 125.645 124.021C125.128 123.892 124.814 123.368 124.943 122.851C127.401 113.019 133.283 104.213 136.662 99.3851C136.967 98.9487 137.569 98.8426 138.005 99.1481Z"
        fill="#252A38"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M161.695 94.1476C161.963 93.6875 162.554 93.5321 163.014 93.8005L168.801 97.1761C169.261 97.4445 169.416 98.0351 169.148 98.4952C168.88 98.9553 168.289 99.1107 167.829 98.8423L162.042 95.4667C161.582 95.1983 161.427 94.6077 161.695 94.1476Z"
        fill="#252A38"
      />
    </svg>
  );
};

export default CharacterSvg;
