import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Eye, EyeSlash } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routes";
import { SetupNewPasswordApi } from "../../Services/authServices/authServices";
import { PasswordResetSuccessImage } from "../ReusableComponents/SvgAndIcons/PasswordResetSuccessImage";
import { Logo } from "../ReusableComponents/SvgAndIcons/ScriptVizLogo";
import { CheckIcon } from "../ReusableComponents/SvgAndIcons/SvgIcons";
import useStyles from "./AuthenticationStyles.style";
import RightSideWithLogo from "./RightSideWithLogo";

const SetupNewPasswordComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [seePassword, setSeePassword] = useState<boolean>(false);
  const [isPasswordReset, setIsPasswordReset] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [oobCode, setOobCode] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isBothPasswordMatched, setIsBothPasswordMatched] = useState(true);

  useEffect(() => {
    const urlObject = new URL(window.location.href);
    setEmail(urlObject.searchParams.get("email") ?? "");
    setOobCode(urlObject.searchParams.get("oobCode") ?? "");
    setApiKey(urlObject.searchParams.get("apiKey") ?? "");
  }, [window.location.href]);

  const handlePassword = (pass: string) => {
    setPassword(pass);
    if (pass === confirmPassword) {
      setIsBothPasswordMatched(true);
    } else {
      setIsBothPasswordMatched(false);
    }
  };

  const handleConfirmPassword = (pass: string) => {
    setConfirmPassword(pass);
    if (password === pass) {
      setIsBothPasswordMatched(true);
    } else {
      setIsBothPasswordMatched(false);
    }
  };

  const handleSetupNewPassword = async () => {
    const data = {
      email,
      oobCode,
      apiKey,
      newPassword: password,
    };
    try {
      const promise = toast.promise(SetupNewPasswordApi(data), {
        loading: "Updating new password",
        success: "Password updated successfully",
        error: "Error on Update Password",
      });
      const res = await promise;
      if (res.status === 200 || 201) {
        setIsPasswordReset(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box className={classes.root}>
      <Grid container spacing={4} sx={{ height: "100%" }}>
        <Grid item md={6} sx={{ height: "100%" }}>
          <Box className={classes.leftSideContainer}>
            <Box>
              <Logo />
            </Box>
            <Box>
              {isPasswordReset ? (
                <>
                  <Typography variant="h4">Password Has been</Typography>
                  <Box className={classes.flexGap}>
                    <Typography variant="h4">Changed successfully</Typography>
                    <CheckIcon />
                  </Box>
                </>
              ) : (
                <Typography variant="h4">Setup a new password</Typography>
              )}
            </Box>
            <Box className={classes.inputLabelAndField} sx={{ mb: 4 }}>
              <Typography className={classes.inputLabel}>Email</Typography>
              <Typography>{email}</Typography>
            </Box>
            {!isPasswordReset && (
              <Box className={classes.inputsContainer}>
                <Box className={classes.inputLabelAndField}>
                  <Typography className={classes.inputLabel}>
                    Enter New Password <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    placeholder="New Password"
                    type={`${seePassword ? "text" : "password"}`}
                    name="password"
                    fullWidth
                    variant="standard"
                    className={classes.inputField}
                    value={password}
                    onChange={(e) => handlePassword(e.target?.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {seePassword ? (
                            <EyeSlash
                              size={22}
                              fill="#606479"
                              style={{ cursor: "pointer" }}
                              onClick={() => setSeePassword(!seePassword)}
                            />
                          ) : (
                            <Eye
                              size={20}
                              fill="#606479"
                              style={{ cursor: "pointer" }}
                              onClick={() => setSeePassword(!seePassword)}
                            />
                          )}
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                    }}
                  />
                </Box>
                <Box className={classes.inputLabelAndField}>
                  <Typography className={classes.inputLabel}>
                    Confirm New Password <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    placeholder="Confirm New Password"
                    type={`${seePassword ? "text" : "password"}`}
                    name="confirmPassword"
                    fullWidth
                    variant="standard"
                    className={classes.inputField}
                    value={confirmPassword}
                    onChange={(e) => handleConfirmPassword(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                  <Typography sx={{ fontSize: "10px", color: "red" }}>
                    {isBothPasswordMatched
                      ? null
                      : "Both Password didn't match"}
                  </Typography>
                </Box>
              </Box>
            )}
            <Box>
              {isPasswordReset ? (
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.loginOrSignupButton}
                  onClick={() => navigate(ROUTES.LOG_IN)}
                >
                  <Typography>Back to log in</Typography>
                </Button>
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.loginOrSignupButton}
                  onClick={() => handleSetupNewPassword()}
                  disabled={password !== confirmPassword}
                >
                  <Typography>Confirm New Password</Typography>
                </Button>
              )}
            </Box>
            <Divider sx={{ backgroundColor: "#606479", mx: 4 }} />
            {isPasswordReset ? (
              <Box className={classes.flexCenter}>
                <PasswordResetSuccessImage />
              </Box>
            ) : (
              <Box>
                <Typography sx={{ textAlign: "center", color: "#606479" }}>
                  Remember the password?{" "}
                  <Link
                    to={ROUTES.LOG_IN}
                    style={{ color: "#6C65D9", textDecoration: "none" }}
                  >
                    Back to Log in
                  </Link>{" "}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item md={6} sx={{ height: "100%" }}>
          <RightSideWithLogo />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SetupNewPasswordComponent;
