import { InputAdornment, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { EnvelopeSimple, Eye, EyeSlash } from "@phosphor-icons/react";
import { useState } from "react";
import toast from "react-hot-toast";
import { createCookie } from "../../../Services/apiResources";
import { movieColabLoginApi } from "../../../Services/movieColabServices/movieColabServices";
import {
  FilledButton,
  OutlinedButton,
} from "../../ReusableComponents/Buttons/Buttons";
import { MovieColaIcon } from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import useStyles from "./ModalStyles";

interface Props {
  handleClose: () => void;
  handleSuccess: () => void;
}

const MovieColabLoginModal = ({ handleClose, handleSuccess }: Props) => {
  const classes = useStyles();
  const [seePassword, setSeePassword] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const handleLogin = async () => {
    setIsLoggingIn(true);
    try {
      const res = await movieColabLoginApi({
        email,
        password,
      });
      createCookie("mc_refresh", res.data.refresh);
      createCookie("mc_access", res.data.access);
      setIsLoggingIn(false);
      handleSuccess();
      toast.success("Logged in movie colab successfully");
    } catch (error) {
      console.log(error);
      setIsLoggingIn(false);
      toast.error("Error on log in to movie colab");
    }
  };
  return (
    <Box className={classes.movieColabLoginRoot}>
      <Box className={classes.flexCenter} sx={{ gap: "20px" }}>
        <MovieColaIcon height={35} width={45} />
        <Typography variant="h4" sx={{ color: "white" }}>
          Movie Colab Log In
        </Typography>
      </Box>
      <Box>
        <Box sx={{ mb: 2 }}>
          <Typography>
            Email Id<span style={{ color: "#FF0000" }}>*</span>
          </Typography>
          <TextField
            placeholder="Enter your email address"
            type="email"
            name="email"
            fullWidth
            variant="standard"
            className={classes.inputField}
            value={email}
            onChange={(e) => setEmail(e.target?.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <EnvelopeSimple size={22} fill="#606479" />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
          />
        </Box>
        <Box>
          <Typography>
            Password<span style={{ color: "#FF0000" }}>*</span>
          </Typography>
          <TextField
            placeholder="password"
            type={seePassword ? "text" : "password"}
            name="email"
            fullWidth
            variant="standard"
            className={classes.inputField}
            value={password}
            onChange={(e) => setPassword(e.target?.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {seePassword ? (
                    <EyeSlash
                      size={22}
                      fill="#606479"
                      onClick={() => setSeePassword(false)}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <Eye
                      size={22}
                      fill="#606479"
                      onClick={() => setSeePassword(true)}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
          />
        </Box>
      </Box>
      <Box className={classes.flexCenter}>
        <OutlinedButton
          text="Go To MovieColab page"
          disabled={false}
          handleClick={() => console.log("clk")}
          fullWidth={false}
          minWidth={100}
        />
      </Box>
      <Box className={classes.flexCenter} sx={{ gap: "20px" }}>
        <FilledButton
          text="Log In"
          disabled={false}
          handleClick={() => handleLogin()}
          fullWidth={false}
          minWidth={100}
          isLoading={isLoggingIn}
        />
        <OutlinedButton
          text="Back"
          disabled={false}
          handleClick={() => handleClose()}
          fullWidth={false}
          minWidth={100}
        />
      </Box>
    </Box>
  );
};

export default MovieColabLoginModal;
