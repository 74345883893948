import { Box, TextField, Typography } from "@mui/material";
import { useState } from "react";
import toast from "react-hot-toast";
import { createNewMovieColabProject } from "../../../Services/movieColabServices/movieColabServices";
import {
  FilledButton,
  OutlinedButton,
} from "../../ReusableComponents/Buttons/Buttons";
import CustomInputField from "../../ReusableComponents/CustomInputField/CustomInputField";
import useStyles from "./ModalStyles";

interface Props {
  handleClose: () => void;
  handleSuccess: (id: number) => void;
}

const CreateNewMcProjectModal = ({ handleClose, handleSuccess }: Props) => {
  const classes = useStyles();
  const [name, setName] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [isNewProjectCreating, setIsNewProjectCreating] =
    useState<boolean>(false);

  const handleCreateNewMovieColabProject = async () => {
    setIsNewProjectCreating(true);
    toast.loading("Creating new movie colab project");
    try {
      const res = await createNewMovieColabProject({
        name: name,
        abbreviation: code,
        description: description,
      });
      if (res) {
        toast.success("Project Created");
        handleSuccess(res?.id);
        handleClose();
        setIsNewProjectCreating(false);
      }
    } catch (error) {
      toast.error("Error On Create Movie colab project");
      setIsNewProjectCreating(false);
    }
  };
  return (
    <Box className={classes.createProjectRoot}>
      <Box>
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          Create New Project
        </Typography>
      </Box>
      <Box>
        <Box sx={{ my: 2 }}>
          <CustomInputField
            title="Project Name"
            isRequired={true}
            placeholder="Enter Your Project Name"
            handleChange={(e) => setName(e)}
            value={name}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <CustomInputField
            title="Project Code"
            isRequired={true}
            placeholder="Enter Your Project Code"
            handleChange={(e) => setCode(e)}
            value={code}
          />
        </Box>
        <Box>
          <Typography sx={{ mb: 2 }}>
            Project Description <span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            multiline
            type="text"
            variant="standard"
            placeholder="Enter Description"
            fullWidth
            sx={{
              backgroundColor: "#252A38",
              color: "white",
              borderRadius: "10px",
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                height: "80px",
                display: "flex",
                alignItems: "flex-start",
              },
            }}
            inputProps={{
              disableFieldSet: true,
              style: {
                padding: "8px",
                color: "white",
              },
            }}
          />
        </Box>
      </Box>
      <Box
        className={classes.flexCenter}
        sx={{
          gap: "20px",
        }}
      >
        <OutlinedButton
          text="Close"
          disabled={false}
          handleClick={() => handleClose()}
          fullWidth={false}
          minWidth={100}
        />
        <FilledButton
          text="Create New Project"
          disabled={
            name?.length && code?.length && description?.length ? false : true
          }
          handleClick={() => handleCreateNewMovieColabProject()}
          fullWidth={false}
          minWidth={100}
          isLoading={isNewProjectCreating}
        />
      </Box>
    </Box>
  );
};

export default CreateNewMcProjectModal;
