export const IndexPageStyle = {
  page: {
    backgroundColor: "#FFFFFF",
    padding: "21px 36px 25px 36px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "18px",
    position: "relative",
  },
  borderBox: {
    width: "100%",
  },
  borderBoxOne: {
    width: "100%",
    border: "1px solid black",
    borderRadius: "10px",
    padding: "8px",
  },
  tableItem: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textStyleOne: {
    fontSize: "8px",
    color: "#606479",
    fontFamily: "Poppins",
  },
  tableTextOne: {
    fontSize: "8px",
    color: "#606479",
    fontFamily: "Poppins",
    width: "50%",
    textAlign: "right",
    paddingRight: "5px",
  },
  textStyleTwo: {
    fontSize: "10px",
    color: "#606479",
    fontFamily: "Poppins",
    width: "50%",
    textAlign: "left",
    paddingRight: "5px",
  },
  textStyleThree: {
    fontFamily: "Poppins",
    fontSize: "10px",
    fontWeight: "medium",
    color: "#6C65D9",
  },
};
