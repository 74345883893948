import { Box, Grid } from "@mui/material";
import Skeleton from "react-loading-skeleton";

const HomePageSkeleton = () => {
  return (
    <Box>
      <Grid container spacing={2}>
        {[1, 2, 3, 4, 5, 6].map((index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
            <Skeleton
              height={200}
              width="100%"
              style={{ borderRadius: "10px" }}
              baseColor="#252A38"
              highlightColor="#606479"
            />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2}>
        {[1, 2, 3, 4].map((index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
            <Skeleton
              height={200}
              width="100%"
              style={{ borderRadius: "10px" }}
              baseColor="#252A38"
              highlightColor="#606479"
            />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ position: "absolute", bottom: "20px" }}>
        <Skeleton
          height={30}
          width="150px"
          style={{ borderRadius: "10px" }}
          baseColor="#606479"
          highlightColor="#252A38"
        />
      </Box>
    </Box>
  );
};

export default HomePageSkeleton;
