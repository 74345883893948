import { Box, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import {
  ScriptBreakdownIcon,
  ScriptIcon,
} from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import BottomTools from "../BottomTools/BottomTools";

type Props = {
  noOfPages: number;
  pdfFile: string;
  onDocumentLoadSuccess: ({ numPages }: { numPages: number }) => void;
  isBreakDownGenerated: boolean;
  selectedPages: number[];
};
const ScriptBreakdownPreview = ({
  noOfPages,
  pdfFile,
  onDocumentLoadSuccess,
  isBreakDownGenerated,
  selectedPages,
}: Props) => {
  const [scale, setScale] = useState<number>(1);
  const pdfContainerRef = useRef<HTMLDivElement | null>(null);

  const handleZoomIn = () => {
    setScale((prevScale) => prevScale + 0.1);
  };

  const handleZoomOut = () => {
    setScale((prevScale) => prevScale - 0.1);
  };

  const scrollToPage = (pageNumber: number) => {
    if (pdfContainerRef.current) {
      const pageElement = pdfContainerRef.current.querySelector(
        `div[data-page-number="${pageNumber}"]`
      );
      if (pageElement) {
        pageElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  };

  useEffect(() => {
    if (selectedPages && selectedPages?.length) {
      scrollToPage(selectedPages[0]);
    } else {
      scrollToPage(0);
    }
  }, [selectedPages]);

  return (
    <Box
      sx={{ height: "100%", backgroundColor: "#000000", padding: "0px 5px" }}
    >
      <Box
        sx={{
          height: "6%",
          backgroundColor: "#000000",
          display: "flex",
          alignItems: "center",
          gap: "8px",
          paddingLeft: "20px",
        }}
      >
        <ScriptIcon />
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
            fontSize: { md: "16px", lg: "16px", xl: "18px" },
          }}
        >
          Script Preview
        </Typography>
      </Box>
      <Box
        sx={{
          height: "92%",
          backgroundColor: "#252A38",
          padding: "10px",
        }}
      >
        {pdfFile && isBreakDownGenerated ? (
          <Box
            ref={pdfContainerRef}
            sx={{
              height: "91%",
              overflow: "auto",
              padding: "10px 0px 0px 10px",
              backgroundColor: "white",
              borderRadius: "10px 10px 0px 0px ",
            }}
          >
            <Box
              sx={{
                height: "98%",
                overflow: "auto",
                // padding: "5px",
                borderRadius: "20px",
                margin: "5px",
              }}
            >
              {pdfFile && (
                <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                  {Array.from(new Array(noOfPages), (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      scale={scale}
                      width={500}
                      renderMode="canvas"
                      className="page"
                    />
                  ))}
                </Document>
              )}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              height: "90%",
              padding: "10px",
              backgroundColor: "transparent",
              borderRadius: "10px 10px 0px 0px ",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "30px",
              flexDirection: "column",
            }}
          >
            <ScriptBreakdownIcon />
            <Typography variant="h5" color="#606479">
              Your Script Preview will be shown here
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            padding: "0px 10px",
            height: "7%",
            display: "flex",
            alignItems: "center",
            backgroundColor: pdfFile && isBreakDownGenerated ? "white" : "",
            paddingTop: "10px",
            borderRadius: "0px 0px 10px 10px",
          }}
        >
          <BottomTools
            disableAll={pdfFile && isBreakDownGenerated ? false : true}
            totalPages={noOfPages}
            handleZoomIn={handleZoomIn}
            handleZoomOut={handleZoomOut}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ScriptBreakdownPreview;
