import { Card } from "@material-ui/core";
import { Box, Button, CardContent, Grid, Typography } from "@mui/material";
import { Image } from "@phosphor-icons/react";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ScriptBreakdownContext } from "../../../Context/ContextDataProvider";
import {
  ForgetPasswordResetApi,
  UpdateUserProfileApi,
} from "../../../Services/authServices/authServices";
import { UserDetailsType } from "../../../types/public-types";
import {
  FilledButton,
  OutlinedButton,
} from "../../ReusableComponents/Buttons/Buttons";
import CustomInputField from "../../ReusableComponents/CustomInputField/CustomInputField";
import useStyles from "./EditUserProfileModal.style";

interface Props {
  handleClose: () => void;
}

interface ContextTypes {
  getUserData: () => void;
  loggedInUserDetails: UserDetailsType;
}

const EditUserProfileModal = ({ handleClose }: Props) => {
  const classes = useStyles();
  const { getUserData, loggedInUserDetails }: ContextTypes = useContext(
    ScriptBreakdownContext
  );
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isHoveredOnImage, setIsHoveredOnImage] = useState<boolean>(false);
  const [newImage, setNewImage] = useState<File | null>(null);
  const [oldImage, setOldImage] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [isUserProfileUpdating, setIsUserProfileUpdating] =
    useState<boolean>(false);

  const storage = getStorage();
  const handleUpdateUserProfile = async () => {
    setIsUserProfileUpdating(true);
    const fileRef = ref(
      storage,
      `users/${loggedInUserDetails?.user_id}/avatar.png`
    );
    const bufferData = await newImage?.arrayBuffer();
    try {
      if (newImage) {
        await uploadBytes(fileRef, bufferData!, {
          contentType: "image/png",
        });
        toast.success("Profile Picture Updated");
      }
      if (userName?.length) {
        const res = await UpdateUserProfileApi(userName);
        if (res) {
          toast.success("Profile Updated Successfully");
          handleClose();
          getUserData();
        }
      } else {
        toast.error("Please Enter a valid name");
      }
      setIsUserProfileUpdating(false);
    } catch (error) {
      setIsUserProfileUpdating(false);
      console.error("Error uploading file:", error);
    }
  };

  const handleResetPassword = async () => {
    try {
      const res = await ForgetPasswordResetApi(loggedInUserDetails?.email);
      if (res.status === 200) {
        toast.success("Reset link sent successfully");
        handleClose();
      }
    } catch (error) {
      toast.error("Error on sending reset url");
    }
  };

  useEffect(() => {
    setUserName(loggedInUserDetails?.firstName || "");
    setOldImage(loggedInUserDetails?.photoPath || "");
  }, [loggedInUserDetails]);

  const handleRemoveImage = () => {
    setNewImage(null);
    setOldImage("");
    setIsHoveredOnImage(false);
  };
  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContents}>
        <Box>
          <Typography variant="h4" className={classes.title}>
            Edit Profile
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <Typography sx={{ mb: 1 }}>Profile Picture</Typography>
              <Box className={classes.profilePictureContainer}>
                {isHovered ? (
                  <Typography className={classes.chooseText}>
                    Click to choose
                  </Typography>
                ) : null}
                {newImage || oldImage ? (
                  <Box
                    onMouseEnter={() => setIsHoveredOnImage(true)}
                    onMouseLeave={() => setIsHoveredOnImage(false)}
                  >
                    <img
                      src={
                        newImage
                          ? URL.createObjectURL(newImage)
                          : oldImage
                          ? oldImage
                          : ""
                      }
                      alt="Profile"
                      className={classes.image}
                    />
                    {isHoveredOnImage ? (
                      <Button
                        className={classes.imageCrossButton}
                        sx={{
                          position: "absolute",
                        }}
                        onClick={() => handleRemoveImage()}
                      >
                        X
                      </Button>
                    ) : null}
                  </Box>
                ) : (
                  <label>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        setNewImage(
                          e?.target?.files ? e?.target?.files[0] : null
                        );
                        setIsHovered(false);
                      }}
                      style={{
                        display: "none",
                      }}
                      multiple={false}
                    />
                    <Box
                      className={classes.chooseImageIcon}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <Image size={100} />
                    </Box>
                  </label>
                )}
                {isHovered || isHoveredOnImage ? (
                  <Typography className={classes.changeText}>
                    Change Profile picture
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
            <Grid item md={8}>
              <Box className={classes.rightSide}>
                <Box>
                  <Typography>Email ID</Typography>
                  <Typography className={classes.emailText}>
                    {loggedInUserDetails?.email}
                  </Typography>
                </Box>
                <CustomInputField
                  value={userName}
                  handleChange={(e) => setUserName(e)}
                  placeholder="User Name"
                  title="user name"
                  isRequired={false}
                />
                <Box className={classes.flexBetween}>
                  <Typography sx={{ width: "60%", fontSize: "14px" }}>
                    Reset Password
                  </Typography>
                  <Typography
                    sx={{ textAlign: "center", width: "90%", fontSize: "14px" }}
                  >
                    Send a reset password link to the registered email ID
                  </Typography>
                  <Box
                    sx={{
                      width: "60%",
                      textAlign: "right",
                    }}
                  >
                    <Button
                      className={classes.sendLinkButton}
                      onClick={() => handleResetPassword()}
                    >
                      <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                        Send Link
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.buttons}>
          <FilledButton
            text="Save"
            handleClick={() => handleUpdateUserProfile()}
            disabled={
              loggedInUserDetails?.firstName === userName &&
              userName.length !== 0 &&
              !newImage
                ? true
                : false
            }
            fullWidth={false}
            minWidth={150}
            isLoading={isUserProfileUpdating}
          />
          <OutlinedButton
            text="Cancel"
            handleClick={() => handleClose()}
            disabled={false}
            fullWidth={false}
            minWidth={150}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default EditUserProfileModal;
