import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: "24px",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      display: "flex",
      flexDirection: "column !important",
      gap: "15px",
    },
  },
  insideContainer: {
    boxSizing: "border-box",
    marginTop: "24px",
    height: "94%",
    width: "46.5%",
    borderRadius: "20px",
    backgroundColor: "#252A38",
    padding: "12px 16px 18px 16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "800px",
    },
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
