import { makeStyles } from "@material-ui/core/styles";
import { themeType } from "../../../types/theme-types";

const useStyles = makeStyles((theme: themeType) => ({
  container: {
    width: "100%",
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  sortBtn: {
    color: "#6C65D9 !important",
    fontSize: "10px",
  },
  newProjectBtn: {
    minWidth: "0px !important",
    padding: "4px 12px !important",
    backgroundColor: "#6C65D9 !important",
    fontSize: "10px",
    color: "#F9F9F9 !important",
    borderRadius: "5px !important",
    "&:hover": {
      backgroundColor: "rgba(108, 101, 217, 0.5) !important",
    },
  },
  plusIcon: {
    fontSize: "14px",
  },
  projectText: {
    fontSize: "12px",
    color: "#f9f9f9",
    fontFamily: "Poppins",
    fontWeight: 500,
  },
}));

export default useStyles;
