import { Box, Typography } from "@mui/material";
import { PencilLine } from "@phosphor-icons/react";
import {
  MovieColabProjectDetailsResponse,
  MovieColabSingleSequenceType,
} from "../../types/movie-colab-types";
import Loader from "../ReusableComponents/Loader/Loader";
import useStyles from "./ExportPreview.style";

interface Props {
  movieColabProjectDetails?: MovieColabProjectDetailsResponse;
  movieColabSequences?: MovieColabSingleSequenceType[];
  isMovieColabProjectDetailsLoaded?: boolean;
  handleOpenChooseProjectModal: () => void;
}

const MovieColabProjectDetails = ({
  handleOpenChooseProjectModal,
  movieColabProjectDetails,
  movieColabSequences,
  isMovieColabProjectDetailsLoaded,
}: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.mcProjectDetailsRoot}>
      <Box className={classes.flexBetween}>
        <Typography>username@mailid.com</Typography>
        <PencilLine
          fill="white"
          size={20}
          onClick={() => handleOpenChooseProjectModal()}
          style={{ cursor: "pointer" }}
        />
      </Box>
      <Box>
        <Typography sx={{ textAlign: "center" }}>Project Details</Typography>
      </Box>
      {isMovieColabProjectDetailsLoaded ? (
        <Box className={classes.mcProjectDetails}>
          <Box className={classes.flexAndGap}>
            <img
              src={
                movieColabProjectDetails?.thumbnail ||
                "https://images.pexels.com/photos/577514/pexels-photo-577514.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
              }
              alt=""
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "10px",
              }}
            />
            <Typography>{movieColabProjectDetails?.name}</Typography>
          </Box>
          <Box className={classes.flexBetween}>
            <Typography sx={{ fontSize: "14px", color: "white" }}>
              {movieColabProjectDetails?.abbreviation}
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              {movieColabProjectDetails?.created_at &&
                new Intl.DateTimeFormat("en-US", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                }).format(new Date(movieColabProjectDetails?.created_at!))}
            </Typography>
          </Box>
          <Typography sx={{ fontSize: "12px" }}>
            {movieColabProjectDetails?.description}
          </Typography>
        </Box>
      ) : (
        <Loader />
      )}
      <Box>
        <Typography sx={{ textAlign: "center" }}>Existing Sequences</Typography>
      </Box>
      <Box className={classes.mcProjectSequences}>
        {movieColabSequences?.length ? (
          <>
            {movieColabSequences.map((si: MovieColabSingleSequenceType) => (
              <Box className={classes.mcProjectSingleSequence}>
                <Typography>{`Sequence ${si.code}`}</Typography>
                {/* <Typography>
                  Shot : <span style={{ color: "white" }}>52</span>
                </Typography> */}
              </Box>
            ))}
          </>
        ) : (
          "No Existing Sequence"
        )}
      </Box>
    </Box>
  );
};

export default MovieColabProjectDetails;
