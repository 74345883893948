import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
} from "@mui/material";
import { DotsThree, PencilLine, Trash } from "@phosphor-icons/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SingleProjectType } from "../../../types/public-types";
import { NoProjectSvg } from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import useStyles from "./ProjectPageOverview.styles";

type Props = {
  projectDetails: SingleProjectType;
  totalPages?: number | null;
  handleOpenDeleteModal: (details: SingleProjectType) => void;
  handleOpenNewOrEditModal?: (
    type: string,
    details?: SingleProjectType
  ) => void;
};
const SingleProjectCard = ({
  projectDetails,
  handleOpenDeleteModal,
  handleOpenNewOrEditModal,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [projectMenuState, setProjectMenuState] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(projectMenuState);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProjectMenuState(event.currentTarget);
  };
  const handleClose = () => {
    setProjectMenuState(null);
  };

  return (
    <Card className={classes.card} elevation={0}>
      <CardActionArea>
        {projectDetails?.thumbnail ? (
          <CardMedia
            component="img"
            height="100"
            image={projectDetails?.thumbnail}
            alt="green iguana"
            onClick={() => navigate(`/project/${projectDetails.id}`)}
          />
        ) : (
          <Box
            onClick={() => navigate(`/project/${projectDetails.id}`)}
            className={classes.noThumbnailContainer}
          >
            <NoProjectSvg />
          </Box>
        )}
        <CardContent sx={{ padding: "2px 8px 8px 8px" }}>
          <Grid container justifyContent="space-between">
            <Box sx={{ width: "80%" }}>
              <Tooltip title={projectDetails?.name}>
                <Typography
                  variant="h6"
                  component="div"
                  color="white"
                  className={classes.projectName}
                >
                  {projectDetails?.name}
                </Typography>
              </Tooltip>
              <Typography variant="body2" fontSize={12} color="#606479">
                Last Edited on{" "}
                {projectDetails?.updated_at &&
                  new Intl.DateTimeFormat("en-US", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  }).format(new Date(projectDetails?.updated_at))}
              </Typography>
            </Box>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleClick(e);
              }}
              className={classes.threeDotsButton}
            >
              <DotsThree size={22} color="#606479" />
            </Button>
          </Grid>
        </CardContent>
      </CardActionArea>
      <Menu
        anchorEl={projectMenuState}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          className: `${classes.menuList}`,
        }}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleOpenNewOrEditModal &&
              handleOpenNewOrEditModal("edit", projectDetails);
            handleClose();
          }}
          className={classes.editMenu}
        >
          <PencilLine size={22} />
          <span>Edit Project</span>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleOpenDeleteModal(projectDetails);
            handleClose();
          }}
          className={classes.deleteMenu}
        >
          <Trash size={22} />
          <span>Delete Project</span>
        </MenuItem>
      </Menu>
    </Card>
  );
};

export default SingleProjectCard;
