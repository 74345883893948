import { Box } from "@mui/material";
import CreditPricing from "./CreditPricing";
import { ProductPricing } from "../../../types/public-types";

type Props = {
  productsList: ProductPricing[];
};

const CreditPricingOverview = ({ productsList }: Props) => {
  return (
    <Box
      style={{
        height: "31%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        columnGap: "18px",
        flexWrap: "wrap",
        flexDirection: "row",
        marginBottom: "24px",
      }}
    >
      {productsList.map((creditPricing: ProductPricing) => {
        return (
          <CreditPricing key={creditPricing.id} creditPricing={creditPricing} />
        );
      })}
    </Box>
  );
};

export default CreditPricingOverview;
