import axios from "axios";
import { ProjectIdType } from "../../types/public-types";

const baseUrl = process.env.REACT_APP_BASE_URL;

interface fileUploadResponse {
  data: {
    fileId: string;
    fileName: string;
    filePath: string;
    fountainFilePath: string;
  };
}

export const uploadFile = async (data: {
  fileName: string;
  projectId: ProjectIdType;
  fileType: string;
}) => {
  const res = await axios.post<fileUploadResponse>(
    `${baseUrl + `file?fileName=${data.fileName}&projectId=${data.projectId}&fileType=${data.fileType}`}`
  );
  return res;
};

export const validateUpload = async (
  filePath: string,
  fileId: string,
  projectId: ProjectIdType
) => {
  const res = await axios.post(`${baseUrl + "file/validate"}`, {
    filePath,
    fileId,
    projectId,
  });
  return res;
};

interface SingleSceneInput {
  sceneNumber: number;
  sceneTitle: string;
  sceneContent: string;
  projectId: ProjectIdType;
}
interface uploadSceneInput {
  projectId: ProjectIdType;
  scenes: SingleSceneInput[];
}

export const uploadScenesManuallyApi = async (data: uploadSceneInput) => {
  const res = await axios.post(`${baseUrl + "file/create-scenes/"}`, data);
  return res;
};

