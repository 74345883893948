import { Box, Button, Grid, Typography } from "@mui/material";
import toast from "react-hot-toast";
import {
  FilledButton,
  OutlinedButton,
} from "../../ReusableComponents/Buttons/Buttons";
import useStyles from "./ModalStyles";

interface Props {
  handleClose: () => void;
  setBreakdownLevel: (e: number) => void;
  breakdownLevel: number;
}

const ConfigureBreakdownModal = ({
  handleClose,
  breakdownLevel,
  setBreakdownLevel,
}: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.configureModalRoot}>
      <Typography variant="h1" sx={{ textAlign: "center" }}>
        Configure Sequence Break Down
      </Typography>
      <Box>
        <Typography sx={{ color: "#606479" }}>Length Of the shot</Typography>
        <Box sx={{ backgroundColor: "#252A38", mt: 1, borderRadius: "5px" }}>
          <Grid container>
            <Grid item md={4}>
              <Button
                fullWidth
                sx={{
                  textAlign: "center",
                  borderRadius: "5px 0px 0px 5px",
                  backgroundColor: breakdownLevel === 1 ? "#6C65D9" : "",
                  color: "white",
                }}
                onClick={() => setBreakdownLevel(1)}
              >
                Low
              </Button>
            </Grid>
            <Grid item md={4}>
              <Button
                fullWidth
                sx={{
                  textAlign: "center",
                  borderLeft: breakdownLevel === 3 ? "1px solid white" : "",
                  borderRight: breakdownLevel === 1 ? "1px solid white" : "",
                  borderRadius: "0px",
                  backgroundColor: breakdownLevel === 2 ? "#6C65D9" : "",
                  color: "white",
                }}
                onClick={() => setBreakdownLevel(2)}
              >
                Moderate
              </Button>
            </Grid>
            <Grid item md={4}>
              <Button
                fullWidth
                sx={{
                  textAlign: "center",
                  backgroundColor: breakdownLevel === 3 ? "#6C65D9" : "",
                  borderRadius: "0px 5px 5px 0px",
                  color: "white",
                }}
                onClick={() => setBreakdownLevel(3)}
              >
                High
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={classes.flexCenter}>
        <Box className={classes.flexAndGap} sx={{ gap: "40px !important" }}>
          <FilledButton
            text="Save"
            fullWidth={false}
            disabled={false}
            handleClick={() => {
              toast.success("Configurations Saved");
              handleClose();
            }}
            isLoading={false}
            minWidth={150}
          />
          <OutlinedButton
            text="Cancel"
            fullWidth={false}
            disabled={false}
            handleClick={() => {
              setBreakdownLevel(3);
              handleClose();
            }}
            minWidth={150}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ConfigureBreakdownModal;
