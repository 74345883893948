import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  AppleLogo,
  GoogleIcon,
  UserIcon,
} from "../ReusableComponents/SvgAndIcons/SvgIcons";
import useStyles from "./AuthenticationStyles.style";
import RightSideWithLogo from "./RightSideWithLogo";
import { EnvelopeSimple, Eye, EyeSlash } from "@phosphor-icons/react";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routes";
import { UserLoginApi } from "../../Services/authServices/authServices";

import { useFormik } from "formik";
import * as yup from "yup";
import toast from "react-hot-toast";
import { createCookie } from "../../Services/apiResources";
import { Logo } from "../ReusableComponents/SvgAndIcons/ScriptVizLogo";
import { auth, googleProvider } from "../../Services/firebase";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { ScriptBreakdownContext } from "../../Context/ContextDataProvider";

const LogInPageOverview = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { getUserData } = useContext(ScriptBreakdownContext);
  const [seePassword, setSeePassword] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  //Validators
  const SignUpValidationSchema = yup.object({
    email: yup.string().label("email").required("Email is required"),
    password: yup.string().label("password").required("Password is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: SignUpValidationSchema,
    onSubmit: async (values) => {
      setIsLoggingIn(true);
      const data = {
        email: values.email,
        password: values.password,
      };
      try {
        const res = await UserLoginApi(data);
        // call sign-in so that all firebase methods are authenticated internally
        signInWithEmailAndPassword(auth, values.email, values.password);
        if (res.status === 200) {
          toast.success("Logged in successfully");
          createCookie("refresh", res.data.refreshToken, "");
          createCookie("access", res.data.accessToken, "");
          setIsLoggingIn(false);
          getUserData();
          navigate(ROUTES.HOME);
        }
      } catch (error) {
        toast.error("Invalid email or password");
        setIsLoggingIn(false);
        setError(true);
        formik.resetForm();
      }
    },
  });

  const handleSignInUsingGoogle = async () => {
    try {
      const data: any = await signInWithPopup(auth, googleProvider);
      if (data?.user?.email) {
        toast.success("Logged in successfully");
        createCookie("refresh", data.user?.stsTokenManager?.refreshToken, "");
        createCookie("access", data.user?.stsTokenManager?.accessToken, "");
        getUserData();
        navigate(ROUTES.HOME);
      }
    } catch (error) {
      toast.error("Error on log in");
      console.log(error);
    }
  };
  return (
    <Box className={classes.root}>
      <Grid container spacing={4} sx={{ height: "100%" }}>
        <Grid item md={6} sx={{ height: "100%" }}>
          <Box className={classes.leftSideContainer}>
            <Box>
              <Logo />
            </Box>
            <Box>
              <Typography variant="h4">Welcome Back!</Typography>
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <Box className={classes.inputsContainer}>
                <Box className={classes.inputLabelAndField}>
                  <Typography className={classes.inputLabel}>
                    Email <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    placeholder="Enter your email address"
                    type="email"
                    name="email"
                    fullWidth
                    variant="standard"
                    className={classes.inputField}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <EnvelopeSimple size={22} fill="#606479" />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                    }}
                  />
                  {formik.errors.email && (
                    <Typography sx={{ color: "red", fontSize: "10px" }}>
                      {formik.errors.email}
                    </Typography>
                  )}
                </Box>
                <Box className={classes.inputLabelAndField}>
                  <Typography className={classes.inputLabel}>
                    Password <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    placeholder="Password"
                    type={`${seePassword ? "text" : "password"}`}
                    name="password"
                    fullWidth
                    variant="standard"
                    className={classes.inputField}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {seePassword ? (
                            <EyeSlash
                              size={22}
                              fill="#606479"
                              style={{ cursor: "pointer" }}
                              onClick={() => setSeePassword(!seePassword)}
                            />
                          ) : (
                            <Eye
                              size={20}
                              fill="#606479"
                              style={{ cursor: "pointer" }}
                              onClick={() => setSeePassword(!seePassword)}
                            />
                          )}
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                    }}
                  />
                  {formik.errors.password && (
                    <Typography sx={{ color: "red", fontSize: "10px" }}>
                      {formik.errors.password}
                    </Typography>
                  )}
                  {error && (
                    <Typography sx={{ color: "red", fontSize: "10px" }}>
                      invalid email or password. try again
                    </Typography>
                  )}
                </Box>
                <Box className={classes.flexBetween}>
                  <FormControlLabel
                    control={<Checkbox sx={{ color: "#606479" }} />}
                    label="Remember Me"
                    sx={{ color: "#606479", fontSize: "12px" }}
                  />
                  <Link
                    to={ROUTES.FORGET_PASSWORD}
                    style={{ color: "#606479" }}
                  >
                    Forget Password?
                  </Link>
                </Box>
              </Box>
              <Box sx={{ mt: 6 }}>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.loginOrSignupButton}
                  type="submit"
                  disabled={isLoggingIn}
                >
                  {isLoggingIn ? (
                    <CircularProgress size={22} color="primary" />
                  ) : (
                    "Log In"
                  )}
                </Button>
              </Box>
            </form>
            <Divider sx={{ backgroundColor: "#606479", mx: 4 }} />
            <Box>
              <Typography sx={{ textAlign: "center", color: "#606479" }}>
                Don’t have an account?{" "}
                <Link
                  to={ROUTES.SIGN_UP}
                  style={{ color: "#6C65D9", textDecoration: "none" }}
                >
                  Sign Up
                </Link>{" "}
              </Typography>
            </Box>
            <Box>
              <Button
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                className={classes.gmailAndAppleButton}
                disableElevation
                onClick={handleSignInUsingGoogle}
              >
                <GoogleIcon />
                <Typography>Log In With Gmail</Typography>
              </Button>
              {/* <Button
                fullWidth
                variant="contained"
                className={classes.gmailAndAppleButton}
                disableElevation
              >
                <AppleLogo />
                <Typography>Log In With Apple</Typography>
              </Button> */}
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} sx={{ height: "100%" }}>
          <RightSideWithLogo />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LogInPageOverview;
