import ProjectsPageOverview from "../../Components/ProjectsPageComponents/ProjectsPageOverview/ProjectsPageOverview";

const ProjectsPage = () => {
  return (
    <div>
      <ProjectsPageOverview />
    </div>
  );
};

export default ProjectsPage;
