export const ScanningIconData = {
  nm: "1",
  ddd: 0,
  h: 100,
  w: 124,
  meta: { g: "LottieFiles Figma v45" },
  layers: [
    {
      ty: 4,
      nm: "Rectangle 317",
      sr: 1,
      st: 0,
      op: 121,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [63, 9], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [63, 9], t: 60 },
            { s: [63, 9], t: 120 },
          ],
        },
        s: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100, 100], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100, 100], t: 60 },
            { s: [100, 100], t: 120 },
          ],
        },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [62, -9], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [62, 110], t: 60 },
            { s: [62, -9], t: 120 },
          ],
        },
        r: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 60 },
            { s: [0], t: 120 },
          ],
        },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 60 },
            { s: [100], t: 120 },
          ],
        },
      },
      shapes: [
        {
          ty: "sh",
          bm: 0,
          hd: false,
          nm: "",
          d: 1,
          ks: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [-1.96, 0],
                      [0, 0],
                      [0, -1.99],
                      [0, 0],
                      [1.96, 0],
                      [0, 0],
                      [0, 1.99],
                      [0, 0],
                    ],
                    o: [
                      [0, -1.99],
                      [0, 0],
                      [1.96, 0],
                      [0, 0],
                      [0, 1.99],
                      [0, 0],
                      [-1.96, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0, 3.6],
                      [3.55, 0],
                      [122.45, 0],
                      [126, 3.6],
                      [126, 14.4],
                      [122.45, 18],
                      [3.55, 18],
                      [0, 14.4],
                      [0, 3.6],
                    ],
                  },
                ],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [-1.96, 0],
                      [0, 0],
                      [0, -1.99],
                      [0, 0],
                      [1.96, 0],
                      [0, 0],
                      [0, 1.99],
                      [0, 0],
                    ],
                    o: [
                      [0, -1.99],
                      [0, 0],
                      [1.96, 0],
                      [0, 0],
                      [0, 1.99],
                      [0, 0],
                      [-1.96, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0, 3.6],
                      [3.55, 0],
                      [122.45, 0],
                      [126, 3.6],
                      [126, 14.4],
                      [122.45, 18],
                      [3.55, 18],
                      [0, 14.4],
                      [0, 3.6],
                    ],
                  },
                ],
                t: 60,
              },
              {
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [-1.96, 0],
                      [0, 0],
                      [0, -1.99],
                      [0, 0],
                      [1.96, 0],
                      [0, 0],
                      [0, 1.99],
                      [0, 0],
                    ],
                    o: [
                      [0, -1.99],
                      [0, 0],
                      [1.96, 0],
                      [0, 0],
                      [0, 1.99],
                      [0, 0],
                      [-1.96, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0, 3.6],
                      [3.55, 0],
                      [122.45, 0],
                      [126, 3.6],
                      [126, 14.4],
                      [122.45, 18],
                      [3.55, 18],
                      [0, 14.4],
                      [0, 3.6],
                    ],
                  },
                ],
                t: 120,
              },
            ],
          },
        },
        {
          ty: "fl",
          bm: 0,
          hd: false,
          nm: "",
          c: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [0.6275, 0.604, 0.9844],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [0.6275, 0.604, 0.9844],
                t: 60,
              },
              { s: [0.6275, 0.604, 0.9844], t: 120 },
            ],
          },
          r: 1,
          o: {
            a: 1,
            k: [
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 60 },
              { s: [100], t: 120 },
            ],
          },
        },
      ],
      ind: 1,
    },
    {
      ty: 4,
      nm: "draft",
      sr: 1,
      st: 0,
      op: 121,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [37, 46.5], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [37, 46.5], t: 60 },
            { s: [37, 46.5], t: 120 },
          ],
        },
        s: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100, 100], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100, 100], t: 60 },
            { s: [100, 100], t: 120 },
          ],
        },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [61, 48.5], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [61, 48.5], t: 60 },
            { s: [61, 48.5], t: 120 },
          ],
        },
        r: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 60 },
            { s: [0], t: 120 },
          ],
        },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 60 },
            { s: [100], t: 120 },
          ],
        },
      },
      shapes: [
        {
          ty: "sh",
          bm: 0,
          hd: false,
          nm: "",
          d: 1,
          ks: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [1.81, 1.82],
                      [0, 2.56],
                      [0, 0],
                      [-1.81, 1.82],
                      [-2.54, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [1.81, -1.82],
                      [2.54, 0],
                      [0, 0],
                    ],
                    o: [
                      [-2.54, 0],
                      [-1.81, -1.82],
                      [0, 0],
                      [0, -2.56],
                      [1.81, -1.82],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 2.56],
                      [-1.81, 1.82],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [9.25, 93],
                      [2.72, 90.27],
                      [0, 83.7],
                      [0, 9.3],
                      [2.72, 2.73],
                      [9.25, 0],
                      [46.25, 0],
                      [74, 27.9],
                      [74, 83.7],
                      [71.28, 90.27],
                      [64.75, 93],
                      [9.25, 93],
                    ],
                  },
                ],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [1.81, 1.82],
                      [0, 2.56],
                      [0, 0],
                      [-1.81, 1.82],
                      [-2.54, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [1.81, -1.82],
                      [2.54, 0],
                      [0, 0],
                    ],
                    o: [
                      [-2.54, 0],
                      [-1.81, -1.82],
                      [0, 0],
                      [0, -2.56],
                      [1.81, -1.82],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 2.56],
                      [-1.81, 1.82],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [9.25, 93],
                      [2.72, 90.27],
                      [0, 83.7],
                      [0, 9.3],
                      [2.72, 2.73],
                      [9.25, 0],
                      [46.25, 0],
                      [74, 27.9],
                      [74, 83.7],
                      [71.28, 90.27],
                      [64.75, 93],
                      [9.25, 93],
                    ],
                  },
                ],
                t: 60,
              },
              {
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [1.81, 1.82],
                      [0, 2.56],
                      [0, 0],
                      [-1.81, 1.82],
                      [-2.54, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [1.81, -1.82],
                      [2.54, 0],
                      [0, 0],
                    ],
                    o: [
                      [-2.54, 0],
                      [-1.81, -1.82],
                      [0, 0],
                      [0, -2.56],
                      [1.81, -1.82],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 2.56],
                      [-1.81, 1.82],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [9.25, 93],
                      [2.72, 90.27],
                      [0, 83.7],
                      [0, 9.3],
                      [2.72, 2.73],
                      [9.25, 0],
                      [46.25, 0],
                      [74, 27.9],
                      [74, 83.7],
                      [71.28, 90.27],
                      [64.75, 93],
                      [9.25, 93],
                    ],
                  },
                ],
                t: 120,
              },
            ],
          },
        },
        {
          ty: "sh",
          bm: 0,
          hd: false,
          nm: "",
          d: 1,
          ks: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [41.63, 32.55],
                      [41.63, 9.3],
                      [9.25, 9.3],
                      [9.25, 83.7],
                      [64.75, 83.7],
                      [64.75, 32.55],
                      [41.63, 32.55],
                    ],
                  },
                ],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [41.63, 32.55],
                      [41.63, 9.3],
                      [9.25, 9.3],
                      [9.25, 83.7],
                      [64.75, 83.7],
                      [64.75, 32.55],
                      [41.63, 32.55],
                    ],
                  },
                ],
                t: 60,
              },
              {
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [41.63, 32.55],
                      [41.63, 9.3],
                      [9.25, 9.3],
                      [9.25, 83.7],
                      [64.75, 83.7],
                      [64.75, 32.55],
                      [41.63, 32.55],
                    ],
                  },
                ],
                t: 120,
              },
            ],
          },
        },
        {
          ty: "sh",
          bm: 0,
          hd: false,
          nm: "",
          d: 1,
          ks: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: false,
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [9.25, 9.3],
                      [9.25, 32.55],
                    ],
                  },
                ],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: false,
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [9.25, 9.3],
                      [9.25, 32.55],
                    ],
                  },
                ],
                t: 60,
              },
              {
                s: [
                  {
                    c: false,
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [9.25, 9.3],
                      [9.25, 32.55],
                    ],
                  },
                ],
                t: 120,
              },
            ],
          },
        },
        {
          ty: "fl",
          bm: 0,
          hd: false,
          nm: "",
          c: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [0.9765, 0.9765, 0.9765],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [0.9765, 0.9765, 0.9765],
                t: 60,
              },
              { s: [0.9765, 0.9765, 0.9765], t: 120 },
            ],
          },
          r: 1,
          o: {
            a: 1,
            k: [
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 60 },
              { s: [100], t: 120 },
            ],
          },
        },
      ],
      ind: 2,
    },
  ],
  v: "5.7.0",
  fr: 60,
  op: 120,
  ip: 0,
  assets: [],
};
