export const NoDataFoundCreditPageSvg = () => {
  return (
    <svg
      width="380"
      height="150"
      viewBox="0 0 286 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M274.955 107.39H276.455V105.89V13C276.455 6.64873 271.307 1.5 264.955 1.5H19.3008C12.9495 1.5 7.80078 6.64874 7.80078 13V105.89V107.39H9.30078H274.955Z"
        fill="#C6C4E4"
        stroke="#6C65D9"
        stroke-width="3"
      />
      <path
        d="M0 109.959C0 107.712 1.8218 105.89 4.06911 105.89H281.931C284.178 105.89 286 107.712 286 109.959H0Z"
        fill="#B5B2E4"
      />
      <rect
        x="114.599"
        y="47.0176"
        width="16.5142"
        height="95.0818"
        rx="5"
        transform="rotate(90 114.599 47.0176)"
        fill="#B5B2E4"
      />
      <rect
        x="114.098"
        y="71.5386"
        width="16.5142"
        height="95.0818"
        rx="5"
        transform="rotate(90 114.098 71.5386)"
        fill="#B5B2E4"
      />
      <path
        d="M21.3395 91.9837C23.8679 72.2037 47.2813 65.6191 58.351 65.3656C98.4048 62.3236 96.4115 87.66 96.4959 105.743L20.9515 105.743C20.9518 100.674 20.7796 96.3638 21.3395 91.9837Z"
        fill="#6C65D9"
      />
      <path
        d="M33.3733 69.7457C36.0098 58.5915 49.5131 56.31 55.9352 56.5635C67.6978 52.913 75.8776 59.6055 78.4973 63.4081C81.8773 68.0557 82.9081 77.7565 59.9913 79.3789C37.0745 81.0013 32.6973 73.6328 33.3733 69.7457Z"
        fill="#A09AFB"
      />
      <path
        d="M44.7805 61.1258C48.8366 58.6921 54.7517 58.7597 57.2022 59.0977C62.6104 59.7737 72.3112 62.3426 67.8495 67.21C63.3879 72.0773 54.6672 70.2521 50.8646 68.731C47.1466 67.2099 40.7244 63.5594 44.7805 61.1258Z"
        fill="#252A38"
      />
      <path
        d="M49.5973 68.2243L49.3438 54.7886L63.54 59.3517L64.3005 69.4918C60.2444 71.5199 52.6393 69.7453 49.5973 68.2243Z"
        fill="#E5986D"
      />
      <path
        d="M64.0465 65.182C59.1792 65.9932 54.2443 61.9709 52.3853 59.8584L63.793 61.3794L64.0465 65.182Z"
        fill="#252A38"
      />
      <path
        d="M68.4398 45.8269C69.7163 53.988 68.5345 61.2399 62.7794 62.1401C52.8927 63.6865 48.8755 57.2478 47.599 49.0867C46.3224 40.9255 49.953 33.5798 55.708 32.6797C61.4631 31.7795 67.1633 37.6657 68.4398 45.8269Z"
        fill="#E6986D"
      />
      <path
        d="M52.052 47.4829C52.5102 50.4121 52.086 53.015 50.0204 53.3381C46.4718 53.8931 45.03 51.5822 44.5718 48.6529C44.1136 45.7237 45.4167 43.0872 47.4823 42.7641C49.5479 42.441 51.5938 44.5537 52.052 47.4829Z"
        fill="#E6986D"
      />
      <path
        d="M46.5308 48.2632C46.5308 49.0237 46.7843 50.5447 47.7983 50.5447"
        stroke="#1B1D28"
        stroke-linecap="round"
      />
      <path
        d="M60.4736 55.3613C60.7271 56.2908 61.2341 57.6429 64.0227 57.6429"
        stroke="#1B1D28"
        stroke-linecap="round"
      />
      <path
        d="M62.7793 41.3301C63.3708 40.8231 64.858 40.1133 66.0749 41.3301"
        stroke="#1B1D28"
        stroke-linecap="round"
      />
      <path
        d="M57.7095 44.6484L57.963 47.1835"
        stroke="#1B1D28"
        stroke-linecap="round"
      />
      <path
        d="M65.061 43.8877L65.3145 46.4227"
        stroke="#1B1D28"
        stroke-linecap="round"
      />
      <path
        d="M62.7793 46.6763C63.3708 47.2678 64.4524 49.2113 64.0468 52.2534"
        stroke="#1B1D28"
        stroke-linecap="round"
      />
      <path
        d="M55.6812 42.8509C56.0192 42.3439 57.1008 41.4313 58.7232 41.8369"
        stroke="#1B1D28"
        stroke-linecap="round"
      />
      <path
        d="M72.6661 33.2408C69.624 38.9193 63.1174 36.9588 60.2444 35.2688C58.6218 40.7444 53.1463 41.0994 50.6113 40.5925C51.5226 42.415 51.6501 44.3229 51.497 45.795C51.3472 47.235 49.8805 47.8284 48.5248 47.3201L46.8088 46.6766C43.1583 48.5018 39.7951 45.916 38.95 44.395C36.5164 37.9053 41.9075 35.0999 44.2735 34.7618C44.8819 29.6917 46.8931 27.4101 48.0761 26.6496C55.9855 21.3767 59.1458 24.9596 59.7373 27.4102C61.157 26.1933 64.2159 25.7201 65.568 25.6356C69.2015 25.8046 75.7081 27.5623 72.6661 33.2408Z"
        fill="#1B1D28"
      />
      <path
        d="M38.4429 76.8433C40.8934 78.0263 47.5183 80.2402 54.4137 79.6318"
        stroke="#252A38"
        stroke-linecap="round"
      />
      <path
        d="M78.7502 73.2944C77.7361 74.8155 74.9476 76.59 71.145 77.3505"
        stroke="#252A38"
        stroke-linecap="round"
      />
      <path
        d="M54.6675 86.4766C54.6675 86.4766 55.6815 94.0817 54.6675 96.8702"
        stroke="#252A38"
        stroke-linecap="round"
      />
      <path
        d="M66.582 85.209C66.582 85.209 67.8496 90.5326 70.8916 94.3351"
        stroke="#252A38"
        stroke-linecap="round"
      />
      <path
        d="M66.2657 105.843C63.934 107.398 60.9114 108.088 60.5659 109.211L52.2754 104.289C63.1567 99.6253 64.7112 104.548 66.2657 105.843Z"
        fill="#E5986D"
      />
      <path
        d="M30.2536 119.315C22.4812 117.76 20.9267 109.47 20.9268 104.806L32.5854 105.324L50.2027 102.733C57.6642 102.319 61.3432 106.619 61.6022 109.47C61.8613 112.32 38.026 120.869 30.2536 119.315Z"
        fill="#6C65D9"
      />
      <path
        d="M58.7524 105.066C59.8751 105.843 62.1205 107.968 62.1205 110.248"
        stroke="#252A38"
        stroke-linecap="round"
      />
      <path
        d="M49.1664 103.771C42.6894 105.066 37.5078 104.03 37.5078 104.03"
        stroke="#252A38"
        stroke-linecap="round"
      />
    </svg>
  );
};
