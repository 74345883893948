import { makeStyles } from "@material-ui/core/styles";
import { themeType } from "../../../types/theme-types";

const useStyles = makeStyles((theme: themeType) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  noResultFound: {
    height: "200px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  shotNameAndCross: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    overflow: "hidden",
  },

  shotsContainer: {
    marginTop: "10px !important",
    height: "95% !important",
    overflowY: "scroll",
    paddingRight: "10px !important",
  },
  singleShot: {
    marginBottom: "10px",
    backgroundColor: "#1B1D28",
    color: "white",
    paddingRight: "10px",
    paddingBottom: "6px",
    borderRadius: "10px",
  },
  shotImage: {
    height: "80px",
    width: "130px",
    borderRadius: "10px 0px 0px 0px",
  },
  selectAndDelete: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "10px",
  },
}));

export default useStyles;
