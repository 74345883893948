import { Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { ScriptVizIcon } from "../SvgComponents/SvgComponents";
import { CoverPageStyle } from "./CoverPage.style";

const styles = StyleSheet.create(CoverPageStyle);

const CoverPage = ({ projectName, shotCount, sequenceCount, loggedInUser }) => {
  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = new Intl.DateTimeFormat("en", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(currentDate);
    return formattedDate;
  };

  return (
    <Page size="A4" style={styles.page}>
      <Text
        style={{
          fontSize: "40px",
          fontWeight: "medium",
          fontFamily: "Poppins",
        }}
      >
        {projectName}
      </Text>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Text style={styles.textStyle}>exported By</Text>
        <Text
          style={{
            fontSize: "18px",
            fontWeight: "semibold",
            marginTop: "3px",
            fontFamily: "Poppins",
          }}
        >
          {loggedInUser}
        </Text>
      </View>

      <View style={styles.projectDetailBox}>
        <View style={styles.projectDetail}>
          <Text style={styles.projectDetailTextOne}>Sequence</Text>
          <Text style={styles.projectDetailTextTwo}>{sequenceCount}</Text>
        </View>
        <View style={styles.projectDetail}>
          <Text style={styles.projectDetailTextOne}>Shot</Text>
          <Text style={styles.projectDetailTextTwo}>{shotCount()}</Text>
        </View>
      </View>

      <Text style={styles.textStyle}>{getCurrentDate()}</Text>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ScriptVizIcon />
        <Text
          style={{
            marginTop: "7px",
            fontWeight: "medium",
            fontSize: "18px",
            marginBottom: "3px",
            fontFamily: "Poppins",
          }}
        >
          Script Viz
        </Text>
        <Text style={styles.textStyle}>by VIGA ET</Text>
      </View>
    </Page>
  );
};

export default CoverPage;
