import { Box } from "@mui/material";
import { useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import "./App.css";
import Navigation from "./Components/Navigation/Navigation";
import SmallScreenPrompt from "./Components/ReusableComponents/SmallScreenPrompt/SmallScreenPrompt";
import ToastMessage from "./Components/ReusableComponents/ToastMessage/ToastMessage";
import ScriptBreakdownDataProvider from "./Context/ContextDataProvider";
import CreditPage from "./Pages/CreditPage/CreditPage";
import ExportPreviewPage from "./Pages/ExportPreviewPage/ExportPreviewPage";
import ForgetPasswordPage from "./Pages/ForgetPasswordPage/ForgetPasswordPage";
import LogInPage from "./Pages/LogInPage/LogInPage";
import ProjectDetailsPage from "./Pages/ProjectDetailsPage/ProjectDetailsPage";
import ProjectsPage from "./Pages/ProjectsPage/ProjectsPage";
import SequenceStoryBoardPage from "./Pages/SequenceStoryBoardPage/SequenceStoryBoardPage";
import SetupNewPasswordPage from "./Pages/SetupNewPasswordPage/SetupNewPasswordPage";
import SignUpPage from "./Pages/SignupPage/SignupPage";
import StoryBoardPage from "./Pages/StoryBoardPage/StoryBoardPage";
import TermsAndCondition from "./Pages/TermsAndCondition/TermsAndCondition";
import { ProtectedRoute } from "./Services/ProtectedRoute";
import UnauthorizedAccessPage from "./Pages/UnauthorizedAccessPage/UnauthorizedAccessPage";

function App() {
  const [projectName, setProjectName] = useState<string>("Script Viz");

  return (
    <>
      <Box
        sx={{
          display: {
            sm: "none",
            xs: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
        }}
      >
        <Router>
          <ScriptBreakdownDataProvider>
            <ToastMessage />
            <Navigation
              projectName={projectName ? projectName : ""}
              setProjectName={setProjectName}
            />
            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <ProjectsPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/log-in" element={<LogInPage />} />
              <Route path="/sign-up" element={<SignUpPage />} />
              <Route path="/forget-password" element={<ForgetPasswordPage />} />
              <Route
                path="/unauthorized"
                element={<UnauthorizedAccessPage />}
              />
              <Route
                path="/setup-new-password"
                element={<SetupNewPasswordPage />}
              />
              <Route
                path="/project/:projectId"
                element={
                  <ProtectedRoute>
                    <ProjectDetailsPage setProjectName={setProjectName} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/project/:projectId/storyboard"
                element={
                  <ProtectedRoute>
                    <StoryBoardPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/project/:projectId/storyboard/sequence/:sequenceId"
                element={
                  <ProtectedRoute>
                    <SequenceStoryBoardPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/project/:projectId/export-preview"
                element={
                  <ProtectedRoute>
                    <ExportPreviewPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/credit-page"
                element={
                  <ProtectedRoute>
                    <CreditPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/termsandcondition"
                element={<TermsAndCondition />}
              />
            </Routes>
          </ScriptBreakdownDataProvider>
        </Router>
      </Box>
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
            xl: "none",
          },
        }}
      >
        <SmallScreenPrompt />
      </Box>
    </>
  );
}

export default App;
