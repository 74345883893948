import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Check,
  Clock,
  X,
  FilmSlate,
  Info,
  ArrowClockwise,
} from "@phosphor-icons/react";
import useStyles from "./QueueDetailModal.style";
import Loader from "../../ReusableComponents/Loader/Loader";
import {
  ContextTypes,
  ScriptBreakdownContext,
} from "../../../Context/ContextDataProvider";
import { useContext } from "react";

interface Props {
  handleClose: () => void;
  shotQueueDetails: any;
  isLoading: boolean;
  shotStats: {
    InQueue: number;
    InProgress: number;
    Completed: number;
    Failure: number;
  };
}

const QueueDetailModal = ({
  handleClose,
  shotQueueDetails,
  isLoading,
  shotStats,
}: Props) => {
  const classes = useStyles();
  const { getQueues }: ContextTypes = useContext(ScriptBreakdownContext);

  function formatDate(utcDateStr: string): string {
    const utcDate = new Date(utcDateStr);

    const dateOptions: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    };

    const dateFormatter = new Intl.DateTimeFormat("en-GB", dateOptions);
    const formattedDate = dateFormatter.format(utcDate);

    return formattedDate;
  }

  function formatTime(utcDateStr: string): string {
    const utcDate = new Date(utcDateStr);

    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    const timeFormatter = new Intl.DateTimeFormat("en-GB", timeOptions);
    const formattedTime = timeFormatter.format(utcDate);

    return formattedTime;
  }

  return (
    <Box
      sx={{
        backgroundColor: "#1B1D28",
        padding: "24px",
        borderRadius: "10px",
        width: "850px",
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="h4">Queue Details</Typography>
          <Box
            sx={{
              backgroundColor: "#252A38",
              borderRadius: "5px",
              padding: "6px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                width: "33%",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                backgroundColor: "#1B1D28",
                padding: "6px 10px",
              }}
            >
              <FilmSlate size={18} />
              <Typography>{shotQueueDetails.length}</Typography>
            </Box>
            <Box
              sx={{
                width: "33%",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                padding: "6px 10px",
                color: "#03BE54",
              }}
            >
              <Check size={18} />
              <Typography>{shotStats.Completed}</Typography>
            </Box>
            <Box
              sx={{
                width: "33%",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                padding: "6px 10px",
                color: "#6C65D9",
              }}
            >
              <Clock size={18} />
              <Typography>{shotStats.InProgress}</Typography>
            </Box>
            <Box
              sx={{
                width: "33%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                padding: "6px 10px",
                color: "#F02828",
              }}
            >
              <Info size={18} />
              <Typography>{shotStats.Failure}</Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
          <Button onClick={getQueues}>
            <Tooltip title="Refresh">
              <ArrowClockwise size={28} color="white" />
            </Tooltip>
          </Button>
          <Button onClick={handleClose}>
            <X size={28} color="white" />
          </Button>
        </Box>
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer sx={{ maxHeight: "400px", mt: 2 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>Shot</TableCell>
                <TableCell align="center" className={classes.tableHeader}>
                  Project
                </TableCell>
                <TableCell align="center" className={classes.tableHeader}>
                  Start Date & Time
                </TableCell>
                <TableCell align="right" className={classes.tableHeader}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shotQueueDetails.length > 0 &&
                shotQueueDetails.map((eachQueue: any) => {
                  return (
                    <TableRow key={eachQueue.generationQueueId}>
                      <TableCell
                        sx={{ paddingRight: "8px !important" }}
                        className={classes.tableCells}
                      >
                        {eachQueue.shotNumberText}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCells}>
                        {eachQueue.projectId}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCells}>
                        {eachQueue.generationStartedAt ? (
                          <Typography>
                            <span style={{ color: "#8D8E93" }}>
                              {formatDate(eachQueue.generationStartedAt)},
                            </span>{" "}
                            {formatTime(eachQueue.generationStartedAt)}
                          </Typography>
                        ) : (
                          "--"
                        )}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableCells}
                        style={{
                          color:
                            eachQueue.status === 0
                              ? "#6C65D9"
                              : eachQueue.status === 1
                              ? "#FFCC4D"
                              : eachQueue.status === 2
                              ? "#03BE54"
                              : eachQueue.status === 3
                              ? "#F02828"
                              : "white",
                        }}
                      >
                        {eachQueue.status === 0
                          ? "InQueue"
                          : eachQueue.status === 1
                          ? "In Progress"
                          : eachQueue.status === 2
                          ? "Completed"
                          : eachQueue.status === 3 && "Failure"}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default QueueDetailModal;
