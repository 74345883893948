import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  addNewProjectButton: {
    backgroundColor: "#252A38 !important",
    borderRadius: "10px",
    height: "100%",
    border: "2px dashed #606479 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    color: "#606479 !important",
  },
  projectsContainer: {
    height: "77vh",
    overflowY: "auto",
    paddingRight: "15px",
    [theme.breakpoints.down("lg")]: {
      height: "74vh",
    },
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  footerText: {
    fontSize: "12px",
    fontFamily: "Poppins",
    color: "#606479",
  },
  footerTextTwo: {
    fontSize: "12px",
    fontFamily: "Poppins",
    color: "#f9f9f9",
  },

  // Single project card styles
  card: {
    backgroundColor: "#252A38 !important",
    borderRadius: "10px !important",
    width: "100%",
    height: "200px",
  },
  noThumbnailContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "130px",
    border: "1px solid #606479",
    borderRadius: "10px",
  },
  menuList: {
    backgroundColor: "#252A38",
    borderRadius: "20px",
    color: "white",
    border: "1px solid #606479",
    padding: "4px 10px",
  },
  projectName: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  threeDotsButton: {
    minWidth: "0px !important",
    padding: "5px !important",
  },
  editMenu: {
    borderBottom: "2px solid #606479 !important",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    paddingBottom: "6px",
  },
  deleteMenu: {
    color: "red !important",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  //Common styles
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
