import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const sendReports = async (reportName: string, reportDetail: string) => {
  const res = await axios.post(`${baseUrl + `report`}`, {
    reportName: reportName,
    reportDetail: reportDetail,
  });
  return res;
};
