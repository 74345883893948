import { Box, Checkbox, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  FilledButton,
  OutlinedButton,
} from "../../../ReusableComponents/Buttons/Buttons";
import useStyles from "./CharactersStyles";
import { ConfirmCharacterSvg } from "../../../ReusableComponents/SvgAndIcons/IdentifyCharactersSvg";
import {
  addNewGeneratedCharacterApi,
  getAllPredictedCharactersApi,
} from "../../../../Services/characterAndLocationServices/characterAndLocationServices";

interface Props {
  handleClose: () => void;
  handleUpdate: () => void;
  projectId: string;
  fileId: string;
}

const ConfirmCharactersModal = ({
  handleClose,
  projectId,
  fileId,
  handleUpdate,
}: Props) => {
  const classes = useStyles();
  const [generatedCharacters, setGeneratedCharacters] = useState<string[]>();
  const [selectedCharacters, setSelectedCharacters] = useState<string[]>();

  useEffect(() => {
    handleGetPredictedCharacters();
  }, []);

  const handleGetPredictedCharacters = async () => {
    try {
      const res = await getAllPredictedCharactersApi(projectId, fileId);
      setGeneratedCharacters(res.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmCharacter = async () => {
    try {
      const res = await addNewGeneratedCharacterApi({
        projectId,
        fileId,
        characters: selectedCharacters,
      });
      handleUpdate();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectUnselect = (character: string) => {
    if (selectedCharacters?.length) {
      if (selectedCharacters.includes(character)) {
        const others = selectedCharacters?.filter(
          (sin: string) => sin !== character
        );
        setSelectedCharacters(others);
      } else {
        setSelectedCharacters([...selectedCharacters, character]);
      }
    } else {
      setSelectedCharacters([character]);
    }
  };

  return (
    <div className={classes.confirmCharactersModalRoot}>
      <Typography variant="h5" textAlign="center">
        Confirm Characters
      </Typography>
      <Box>
        <Grid container spacing={2} mt={2}>
          <Grid item md={6}>
            <Typography className={classes.inputLabel}>
              Character Image
            </Typography>
            <Box>
              <Box
                width="95%"
                height="300px"
                bgcolor="#252A38"
                // display="flex"
                // flexDirection="column"
                // alignItems="center"
                // justifyContent="center"
                borderRadius={4}
                gap={2}
                px={2}
                overflow="auto"
              >
                {generatedCharacters?.map((single: string) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    key={single}
                  >
                    <span>{single}</span>
                    <Checkbox
                      onClick={() => handleSelectUnselect(single)}
                      checked={selectedCharacters?.some(
                        (sin: string) => sin === single
                      )}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
            <Typography className={classes.inputLabel}>
              Selected Character {selectedCharacters?.length}/
              {generatedCharacters?.length}
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            <Box>
              <ConfirmCharacterSvg />
            </Box>
          </Grid>
        </Grid>

        <div
          style={{
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <FilledButton
            text="save"
            handleClick={() => handleConfirmCharacter()}
            disabled={false}
            isLoading={false}
            fullWidth={false}
            minWidth={200}
          />
          <OutlinedButton
            text="Cancel"
            handleClick={handleClose}
            disabled={false}
            // isLoading={false}
            fullWidth={false}
            minWidth={200}
          />
        </div>
      </Box>
    </div>
  );
};

export default ConfirmCharactersModal;
