import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TransactionDetail } from "../../../types/public-types";
import NoDataFoundCreditPage from "../NoDataFoundCreditPage/NoDataFoundCreditPage";
import useStyles from "./PaymentHistoryTable.style";
import PaymentHistoryTableItems from "./PaymentHistoryTableItems";

type Props = {
  allTransactionsHistory: TransactionDetail[];
  isLoading: any;
};

const PaymentHistoryTable = ({ allTransactionsHistory, isLoading }: Props) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="left" className={classes.tableHeaderCells}>
              Date
            </TableCell>
            <TableCell align="center" className={classes.tableHeaderCells}>
              Credit Type
            </TableCell>
            <TableCell align="center" className={classes.tableHeaderCells}>
              Details
            </TableCell>
            <TableCell align="center" className={classes.tableHeaderCells}>
              Status
            </TableCell>
            <TableCell align="center" className={classes.tableHeaderCells}>
              Amount
            </TableCell>
            <TableCell align="right" className={classes.tableHeaderCells}>
              Total Balance
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ position: "relative" }}>
          {!isLoading.transactionList && allTransactionsHistory.length > 0 ? (
            allTransactionsHistory.map((eachTransaction: TransactionDetail) => {
              return (
                <PaymentHistoryTableItems eachTransaction={eachTransaction} />
              );
            })
          ) : (
            <Box
              style={{ position: "absolute", left: "34%", marginTop: "15%" }}
            >
              <NoDataFoundCreditPage />
            </Box>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaymentHistoryTable;
