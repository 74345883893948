import { makeStyles } from "@material-ui/core/styles";
import { themeType } from "../../../../types/theme-types";

const useStyles = makeStyles((theme: themeType) => ({
  rightSideTitle: {
    height: "5%",
    backgroundColor: "#1B1D28",
    borderRadius: "10px",
    marginBottom: "10px",
    border: "2px solid #6C65D9",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4px 2px 4px 8px",
  },
  dotAndText: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },

  rightSideTools: {
    height: "4%",
    marginBottom: "5px",
    display: "flex",
    alignItems: "center",
    padding: "0px 5px",
    justifyContent: "space-between",
  },

  shortListContainer: {
    overflowY: "auto",
    paddingRight: "8px",
  },

  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  flexEnd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "relative",
  },

  selectAllButton: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    color: "#606479 !important",
    padding: "0px !important",
  },
  clearAllButton: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    color: "#6C65D9 !important",
  },

  // select genres modal style
  inputField: {
    outline: "none",
    borderRadius: "5px",
    backgroundColor: "#252A38",
    padding: "4px 8px",
    "& .MuiInputBase-input": {
      color: "white",
      padding: "10px",
    },
  },

  inputLabelAndField: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    width: "100%",
  },

  inputLabel: {
    color: "#606479",
    fontSize: "12px",
  },

  inputsContainer: {
    display: "flex",
    gap: "10px",
  },

  //character settings modal style
  characterSettingsModalRoot: {
    width: "800px !important",
    fontFamily: "poppins",
    backgroundColor: "#1B1D28",
    padding: "20px",
    borderRadius: "20px",
  },

  imageInput: {
    cursor: "pointer",
    opacity: 0,
    width: "100%",
    height: "100%",
    fontSize: "0",
    "&:hover": {
      cursor: "pointer",
    },
    borderRadius: "0px",
  },
  iconStyle: {
    width: "100%",
    marginTop: "30%",
    color: "red",
  },

  imageInputAndNameAndAbbreviation: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "15px",
    alignItems: "start",
  },

  imageViewerDiv: {
    width: "100%",
    height: "150px",
    position: "relative",
    borderRadius: "0px",
  },

  removeThumbnailIcon: {
    color: "#fff",
    backgroundColor: "red",
    position: "absolute",
    height: "24px",
    width: "24px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    right: "-8px",
    top: "-8px",
    cursor: "pointer",
    borderColor: "transparent",
  },
  imageContentDiv: {
    width: "100%",
    height: "100%",
    display: "grid",
    // borderRadius: "10px",
    overflow: "hidden",
  },
  projectThumbnail: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    // borderRadius: "10px",
    objectFit: "cover",
  },
  cardContentDiv2: {
    width: "100%",
    height: "150px",
    display: "grid",
    // marginTop: "2%",
    placeItems: "center",
    backgroundColor: "#252A38",
    borderRadius: "10px 10px 0px 0px",
    backgroundPosition: "center",
    backgroundSize: "25px",
    backgroundRepeat: "no-repeat",
  },

  //confirm character settings modal style
  confirmCharactersModalRoot: {
    width: "800px !important",
    fontFamily: "poppins",
    backgroundColor: "#1B1D28",
    padding: "20px",
    borderRadius: "20px",
  },
}));

export default useStyles;
