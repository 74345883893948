import { Button, Grid } from "@mui/material";
import {
  FrameCorners,
  MagnifyingGlass,
  Minus,
  Plus,
} from "@phosphor-icons/react";
import React from "react";
import useStyles from "./BottomTools.style";

type Props = {
  totalPages?: number | null;
  handleZoomIn?: () => void;
  handleZoomOut?: () => void;
  disableAll?: boolean;
};

const BottomTools = ({
  totalPages,
  handleZoomIn,
  handleZoomOut,
  disableAll,
}: Props) => {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{ backgroundColor: "#1B1D28", borderRadius: "10px" }}
    >
      <Button
        onClick={handleZoomOut}
        className={disableAll ? classes.disabledButton : classes.button}
      >
        <Minus size={22} />
      </Button>
      <Button
        onClick={handleZoomIn}
        className={disableAll ? classes.disabledButton : classes.button}
      >
        <Plus size={22} />
      </Button>
      <Button className={disableAll ? classes.disabledButton : classes.button}>
        <span className={classes.currentPage}> 0 </span> of {totalPages || 0}
      </Button>
      <Button className={disableAll ? classes.disabledButton : classes.button}>
        <MagnifyingGlass size={22} />
      </Button>
      <Button className={disableAll ? classes.disabledButton : classes.button}>
        <FrameCorners size={22} />
      </Button>
    </Grid>
  );
};

export default BottomTools;
