import {
  TableCell,
  TableRow,
  Typography,
  Chip,
  Box,
  Tooltip,
} from "@mui/material";
import useStyles from "./CreditUsageTable.style";
import {
  CoinIconXsmall,
  FreeCreditCoinIcon,
} from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import { CreditLog } from "../../../types/public-types";
import clsx from "clsx";

type Props = {
  creditUsageDetail: CreditLog;
};

const CreditUsageTableItems = ({ creditUsageDetail }: Props) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell align="left">
        <Typography variant="h6" className={classes.tableCellsWhite}>
          {creditUsageDetail.date.split(" ").slice(0, 3).join(" ")}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="h6" className={classes.tableCellsGray}>
          {creditUsageDetail.spentOn.split("_").join(" ")}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Tooltip title={creditUsageDetail.details}>
          <Typography
            variant="h6"
            className={clsx(classes.tableCellsWhite, classes.wordWraps)}
          >
            {creditUsageDetail.details}
          </Typography>
        </Tooltip>
        <Tooltip title={creditUsageDetail.sequenceId}>
          <Typography
            className={clsx(
              classes.tableCellsGray,
              classes.wordWraps,
              classes.text
            )}
          >
            Shots: {creditUsageDetail.sequenceId}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <Tooltip title={creditUsageDetail.project}>
          <Typography
            className={clsx(classes.tableCellsGray, classes.wordWraps)}
          >
            {creditUsageDetail.project}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="right">
        {creditUsageDetail.creditDeductionType === 2 ? (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "3px",
              color: "#00FF0A",
              padding: "4px 0px 4px 6px",
              border: "1px solid #606479",
              borderRadius: "30px",
            }}
          >
            {creditUsageDetail.amount} <FreeCreditCoinIcon /> <CoinIconXsmall />
          </Box>
        ) : (
          <Chip
            style={{ color: "white", padding: "4px" }}
            avatar={
              creditUsageDetail.creditDeductionType === 1 ? (
                <FreeCreditCoinIcon />
              ) : (
                <CoinIconXsmall />
              )
            }
            label={creditUsageDetail.amount}
            variant="outlined"
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default CreditUsageTableItems;
