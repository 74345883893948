import { Box, Grid } from "@mui/material";
import Skeleton from "react-loading-skeleton";

const CreditPageSkeleton = () => {
  return (
    <Box sx={{ height: `calc(100vh - 130px)`, marginTop: "24px" }}>
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item md={12} lg={6} sm={12} sx={{ height: "100%" }}>
          <Box
            sx={{
              backgroundColor: "#252A38",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "10px",
              gap: "40px",
              height: "100%",
            }}
          >
            <Skeleton
              height={20}
              width="250px"
              highlightColor="#252A38"
              baseColor="#606479"
            />
            <Skeleton
              height={100}
              width="400px"
              style={{ borderRadius: "10px" }}
              baseColor="#1B1D28"
              highlightColor="#606479"
            />
            <Skeleton
              height={10}
              width="200px"
              highlightColor="#252A38"
              baseColor="#606479"
            />
            <Skeleton
              height={10}
              width="250px"
              highlightColor="#252A38"
              baseColor="#606479"
            />
            <Skeleton
              height={400}
              width="800px"
              style={{ borderRadius: "10px" }}
              baseColor="#1B1D28"
              highlightColor="#606479"
            />
          </Box>
        </Grid>
        <Grid item md={12} lg={6} sm={12} sx={{ height: "100%" }}>
          <Box
            sx={{
              backgroundColor: "#252A38",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "10px",
              gap: "20px",
              height: "100%",
            }}
          >
            <Skeleton
              height={20}
              width="250px"
              highlightColor="#252A38"
              baseColor="#606479"
            />
            <Grid container spacing={2}>
              {[1, 2, 3].map((index) => (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={index}>
                  <Skeleton
                    height={300}
                    width="100%"
                    style={{ borderRadius: "10px" }}
                    baseColor="#1B1D28"
                    highlightColor="#606479"
                  />
                </Grid>
              ))}
            </Grid>
            <Skeleton
              height={20}
              width="250px"
              highlightColor="#252A38"
              baseColor="#606479"
            />
            <Skeleton
              height={350}
              width="800px"
              style={{ borderRadius: "10px" }}
              baseColor="#1B1D28"
              highlightColor="#606479"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreditPageSkeleton;
