import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    fontFamily: "poppins",
  },
  card: {
    position: "absolute",
    top: "0px",
    right: "30px",
    color: "black",
    width: "320px",
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    height: "200px",
    alignItems: "center",
    justifyContent: "center",
  },

  flexAndGap: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  buttonDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5%",
  },

  yesButton: {
    height: "35px",
    borderRadius: "5px !important",
    color: "white !important",
    fontSize: "12px !important",
    width: "150px",
  },
  noButton: {
    height: "35px",
    borderRadius: "5px !important",
    border: `1px solid #6C65D9 !important`,
    color: "#6C65D9 !important",
    cursor: "pointer",
    fontSize: "12px !important",
    width: "135px",
  },
}));

export default useStyles;
