import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import ProjectDetailsOverview from "../../Components/ProjectDetailsComponents/ProjectDetailsOverview/ProjectDetailsOverview";

interface Props {
  setProjectName: (name: string) => void;
}

const ProjectDetailsPage = ({ setProjectName }: Props) => {
  const { projectId } = useParams();
  return (
    <Box
      sx={{
        mx: 4,
        height: {
          sm: `calc(100vh - 45px)`,
          md: `calc(100vh - 45px)`,
          lg: `calc(100vh - 45px)`,
          xl: `calc(100vh - 65px)`,
        },
      }}
    >
      <ProjectDetailsOverview
        projectId={projectId || ""}
        setProjectName={setProjectName}
      />
    </Box>
  );
};

export default ProjectDetailsPage;
