import { Box, Button, Popover, Typography } from "@mui/material";
import { SortAscending } from "@phosphor-icons/react";
import { useState } from "react";
import { SingleProjectType } from "../../../types/public-types";
import SortModal from "../../ReusableComponents/SortModal/SortModal";
import useStyles from "./ProjectPageTools.styles";

type Props = {
  handleOpenNewOrEditModal: (type: string, details?: SingleProjectType) => void;
  handleSubmitSort: (sortBy: any) => void;
  sortByProjects: string;
};

const ProjectPageTools = ({
  handleOpenNewOrEditModal,
  handleSubmitSort,
  sortByProjects,
}: Props) => {
  const classes = useStyles();
  const [popoverComponent, setPopoverComponent] = useState(null);

  const handleOpenSortModal = (event: any) => {
    setPopoverComponent(event.currentTarget);
  };

  const handleCloseSortModal = () => {
    setPopoverComponent(null);
  };

  return (
    <Box className={classes.container}>
      <Typography className={classes.projectText}>My Projects</Typography>
      <Box className={classes.btnContainer}>
        <Box>
          <Button
            onClick={handleOpenSortModal}
            className={classes.sortBtn}
            startIcon={<SortAscending color="#6C65D9" />}
          >
            Sort
          </Button>
          <Popover
            open={popoverComponent !== null}
            anchorEl={popoverComponent}
            onClose={handleCloseSortModal}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ marginTop: "10px" }}
          >
            <SortModal
              handleCloseSortModal={handleCloseSortModal}
              handleSubmitSort={handleSubmitSort}
              sortByProjects={sortByProjects}
            />
          </Popover>
        </Box>
        <Button
          className={classes.newProjectBtn}
          startIcon={<Typography className={classes.plusIcon}>+</Typography>}
          onClick={() => handleOpenNewOrEditModal("new")}
        >
          Add new project
        </Button>
      </Box>
    </Box>
  );
};

export default ProjectPageTools;
