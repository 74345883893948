export const LoadingIconData = {
  nm: "dashicons:text",
  ddd: 0,
  h: 126,
  w: 126,
  meta: { g: "LottieFiles Figma v45" },
  layers: [
    {
      ty: 4,
      nm: "Vector",
      sr: 1,
      st: 0,
      op: 241,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: {
          a: 1,
          k: [
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [19.13, 18.82],
              t: 0,
            },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [22, 21.5], t: 120 },
            { s: [19.13, 18.82], t: 240 },
          ],
        },
        s: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100, 100], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100, 100], t: 120 },
            { s: [100, 100], t: 240 },
          ],
        },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [94.53, 30.61],
              t: 0,
            },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [92, 30.5], t: 120 },
            { s: [94.53, 30.61], t: 240 },
          ],
        },
        r: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 120 },
            { s: [0], t: 240 },
          ],
        },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 120 },
            { s: [100], t: 240 },
          ],
        },
      },
      shapes: [
        {
          ty: "sh",
          bm: 0,
          hd: false,
          nm: "",
          d: 1,
          ks: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [-2.12, 0.37],
                      [-2.75, 2.63],
                      [-0.81, 3.72],
                      [0, 0],
                      [-0.48, -2.09],
                      [0, 0],
                      [-2.75, -2.61],
                      [-3.74, -0.65],
                      [2.13, -0.37],
                      [2.75, -2.61],
                      [0.84, -3.7],
                      [0, 0],
                      [0.46, 2.09],
                      [0, 0],
                      [2.75, 2.63],
                      [3.75, 0.65],
                    ],
                    o: [
                      [-2.12, -0.37],
                      [3.75, -0.65],
                      [2.75, -2.63],
                      [0, 0],
                      [0.46, -2.09],
                      [0, 0],
                      [0.84, 3.7],
                      [2.75, 2.61],
                      [2.13, 0.37],
                      [-3.74, 0.65],
                      [-2.75, 2.61],
                      [0, 0],
                      [-0.48, 2.09],
                      [0, 0],
                      [-0.81, -3.72],
                      [-2.75, -2.63],
                      [0, 0],
                    ],
                    v: [
                      [1.59, 20.71],
                      [1.59, 16.94],
                      [11.55, 11.9],
                      [17.01, 2.17],
                      [17.13, 1.58],
                      [21.05, 1.56],
                      [21.2, 2.24],
                      [26.72, 11.92],
                      [36.67, 16.92],
                      [36.67, 20.72],
                      [26.72, 25.72],
                      [21.21, 35.4],
                      [21.05, 36.07],
                      [17.14, 36.06],
                      [17.02, 35.48],
                      [11.55, 25.74],
                      [1.59, 20.71],
                    ],
                  },
                ],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [-2.43, 0.42],
                      [-3.16, 3.01],
                      [-0.93, 4.25],
                      [0, 0],
                      [-0.55, -2.39],
                      [0, 0],
                      [-3.16, -2.98],
                      [-4.3, -0.74],
                      [2.45, -0.43],
                      [3.16, -2.98],
                      [0.97, -4.23],
                      [0, 0],
                      [0.53, 2.39],
                      [0, 0],
                      [3.16, 3.01],
                      [4.31, 0.74],
                    ],
                    o: [
                      [-2.43, -0.43],
                      [4.31, -0.75],
                      [3.16, -3.01],
                      [0, 0],
                      [0.53, -2.39],
                      [0, 0],
                      [0.97, 4.23],
                      [3.16, 2.98],
                      [2.45, 0.42],
                      [-4.3, 0.74],
                      [-3.16, 2.98],
                      [0, 0],
                      [-0.55, 2.38],
                      [0, 0],
                      [-0.94, -4.25],
                      [-3.16, -3.01],
                      [0, 0],
                    ],
                    v: [
                      [1.82, 23.67],
                      [1.82, 19.36],
                      [13.28, 13.6],
                      [19.56, 2.48],
                      [19.7, 1.8],
                      [24.2, 1.78],
                      [24.38, 2.56],
                      [30.72, 13.62],
                      [42.16, 19.33],
                      [42.16, 23.67],
                      [30.73, 29.39],
                      [24.39, 40.45],
                      [24.21, 41.22],
                      [19.71, 41.2],
                      [19.57, 40.54],
                      [13.29, 29.41],
                      [1.82, 23.67],
                    ],
                  },
                ],
                t: 120,
              },
              {
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [-2.12, 0.37],
                      [-2.75, 2.63],
                      [-0.81, 3.72],
                      [0, 0],
                      [-0.48, -2.09],
                      [0, 0],
                      [-2.75, -2.61],
                      [-3.74, -0.65],
                      [2.13, -0.37],
                      [2.75, -2.61],
                      [0.84, -3.7],
                      [0, 0],
                      [0.46, 2.09],
                      [0, 0],
                      [2.75, 2.63],
                      [3.75, 0.65],
                    ],
                    o: [
                      [-2.12, -0.37],
                      [3.75, -0.65],
                      [2.75, -2.63],
                      [0, 0],
                      [0.46, -2.09],
                      [0, 0],
                      [0.84, 3.7],
                      [2.75, 2.61],
                      [2.13, 0.37],
                      [-3.74, 0.65],
                      [-2.75, 2.61],
                      [0, 0],
                      [-0.48, 2.09],
                      [0, 0],
                      [-0.81, -3.72],
                      [-2.75, -2.63],
                      [0, 0],
                    ],
                    v: [
                      [1.59, 20.71],
                      [1.59, 16.94],
                      [11.55, 11.9],
                      [17.01, 2.17],
                      [17.13, 1.58],
                      [21.05, 1.56],
                      [21.2, 2.24],
                      [26.72, 11.92],
                      [36.67, 16.92],
                      [36.67, 20.72],
                      [26.72, 25.72],
                      [21.21, 35.4],
                      [21.05, 36.07],
                      [17.14, 36.06],
                      [17.02, 35.48],
                      [11.55, 25.74],
                      [1.59, 20.71],
                    ],
                  },
                ],
                t: 240,
              },
            ],
          },
        },
        {
          ty: "fl",
          bm: 0,
          hd: false,
          nm: "",
          c: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [0.6275, 0.604, 0.9844],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [0.6275, 0.604, 0.9844],
                t: 120,
              },
              { s: [0.6275, 0.604, 0.9844], t: 240 },
            ],
          },
          r: 1,
          o: {
            a: 1,
            k: [
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 120 },
              { s: [100], t: 240 },
            ],
          },
        },
      ],
      ind: 1,
    },
    {
      ty: 4,
      nm: "Vector",
      sr: 1,
      st: 0,
      op: 241,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: {
          a: 1,
          k: [
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [43.06, 37.67],
              t: 0,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [43.28, 37.67],
              t: 120,
            },
            { s: [43.06, 37.67], t: 240 },
          ],
        },
        s: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100, 100], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100, 100], t: 120 },
            { s: [100, 100], t: 240 },
          ],
        },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [63, 63], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [63.22, 63], t: 120 },
            { s: [63, 63], t: 240 },
          ],
        },
        r: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 120 },
            { s: [0], t: 240 },
          ],
        },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 120 },
            { s: [100], t: 240 },
          ],
        },
      },
      shapes: [
        {
          ty: "sh",
          bm: 0,
          hd: false,
          nm: "",
          d: 1,
          ks: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                    ],
                    o: [
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [39.81, 0],
                      [45.19, 5.38],
                      [45.19, 5.38],
                      [39.81, 10.76],
                      [5.38, 10.76],
                      [0, 5.38],
                      [0, 5.38],
                      [5.38, 0],
                      [39.81, 0],
                    ],
                  },
                ],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                    ],
                    o: [
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [39.81, 0],
                      [45.19, 5.38],
                      [45.19, 5.38],
                      [39.81, 10.76],
                      [5.38, 10.76],
                      [0, 5.38],
                      [0, 5.38],
                      [5.38, 0],
                      [39.81, 0],
                    ],
                  },
                ],
                t: 120,
              },
              {
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                    ],
                    o: [
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [39.81, 0],
                      [45.19, 5.38],
                      [45.19, 5.38],
                      [39.81, 10.76],
                      [5.38, 10.76],
                      [0, 5.38],
                      [0, 5.38],
                      [5.38, 0],
                      [39.81, 0],
                    ],
                  },
                ],
                t: 240,
              },
            ],
          },
        },
        {
          ty: "sh",
          bm: 0,
          hd: false,
          nm: "",
          d: 1,
          ks: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                    ],
                    o: [
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [54.57, 21.53],
                      [59.96, 26.91],
                      [59.96, 26.91],
                      [54.57, 32.29],
                      [5.38, 32.29],
                      [0, 26.91],
                      [0, 26.91],
                      [5.38, 21.53],
                      [54.57, 21.53],
                    ],
                  },
                ],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                    ],
                    o: [
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [54.57, 21.53],
                      [59.96, 26.91],
                      [59.96, 26.91],
                      [54.57, 32.29],
                      [5.38, 32.29],
                      [0, 26.91],
                      [0, 26.91],
                      [5.38, 21.53],
                      [54.57, 21.53],
                    ],
                  },
                ],
                t: 120,
              },
              {
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                    ],
                    o: [
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [54.57, 21.53],
                      [59.96, 26.91],
                      [59.96, 26.91],
                      [54.57, 32.29],
                      [5.38, 32.29],
                      [0, 26.91],
                      [0, 26.91],
                      [5.38, 21.53],
                      [54.57, 21.53],
                    ],
                  },
                ],
                t: 240,
              },
            ],
          },
        },
        {
          ty: "sh",
          bm: 0,
          hd: false,
          nm: "",
          d: 1,
          ks: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                    ],
                    o: [
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [26.91, 43.06],
                      [32.29, 48.44],
                      [32.29, 48.44],
                      [26.91, 53.82],
                      [5.38, 53.82],
                      [0, 48.44],
                      [0, 48.44],
                      [5.38, 43.06],
                      [26.91, 43.06],
                    ],
                  },
                ],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                    ],
                    o: [
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [15.17, 43.06],
                      [20.55, 48.44],
                      [20.55, 48.44],
                      [15.17, 53.82],
                      [5.38, 53.82],
                      [0, 48.44],
                      [0, 48.44],
                      [5.38, 43.06],
                      [15.17, 43.06],
                    ],
                  },
                ],
                t: 120,
              },
              {
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                    ],
                    o: [
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [26.91, 43.06],
                      [32.29, 48.44],
                      [32.29, 48.44],
                      [26.91, 53.82],
                      [5.38, 53.82],
                      [0, 48.44],
                      [0, 48.44],
                      [5.38, 43.06],
                      [26.91, 43.06],
                    ],
                  },
                ],
                t: 240,
              },
            ],
          },
        },
        {
          ty: "sh",
          bm: 0,
          hd: false,
          nm: "",
          d: 1,
          ks: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                    ],
                    o: [
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [80.73, 43.06],
                      [86.11, 48.44],
                      [86.11, 48.44],
                      [80.73, 53.82],
                      [48.44, 53.82],
                      [43.06, 48.44],
                      [43.06, 48.44],
                      [48.44, 43.06],
                      [80.73, 43.06],
                    ],
                  },
                ],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                    ],
                    o: [
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [80.73, 43.06],
                      [86.11, 48.44],
                      [86.11, 48.44],
                      [80.73, 53.82],
                      [32.94, 53.82],
                      [27.55, 48.44],
                      [27.55, 48.44],
                      [32.94, 43.06],
                      [80.73, 43.06],
                    ],
                  },
                ],
                t: 120,
              },
              {
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                    ],
                    o: [
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [80.73, 43.06],
                      [86.11, 48.44],
                      [86.11, 48.44],
                      [80.73, 53.82],
                      [48.44, 53.82],
                      [43.06, 48.44],
                      [43.06, 48.44],
                      [48.44, 43.06],
                      [80.73, 43.06],
                    ],
                  },
                ],
                t: 240,
              },
            ],
          },
        },
        {
          ty: "sh",
          bm: 0,
          hd: false,
          nm: "",
          d: 1,
          ks: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                    ],
                    o: [
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [59.2, 64.58],
                      [64.58, 69.97],
                      [64.58, 69.97],
                      [59.2, 75.35],
                      [5.38, 75.35],
                      [0, 69.97],
                      [0, 69.97],
                      [5.38, 64.58],
                      [59.2, 64.58],
                    ],
                  },
                ],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                    ],
                    o: [
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [81.17, 64.58],
                      [86.55, 69.97],
                      [86.55, 69.97],
                      [81.17, 75.35],
                      [5.38, 75.35],
                      [0, 69.97],
                      [0, 69.97],
                      [5.38, 64.58],
                      [81.17, 64.58],
                    ],
                  },
                ],
                t: 120,
              },
              {
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                    ],
                    o: [
                      [2.97, 0],
                      [0, 0],
                      [0, 2.97],
                      [0, 0],
                      [-2.97, 0],
                      [0, 0],
                      [0, -2.97],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [59.2, 64.58],
                      [64.58, 69.97],
                      [64.58, 69.97],
                      [59.2, 75.35],
                      [5.38, 75.35],
                      [0, 69.97],
                      [0, 69.97],
                      [5.38, 64.58],
                      [59.2, 64.58],
                    ],
                  },
                ],
                t: 240,
              },
            ],
          },
        },
        {
          ty: "fl",
          bm: 0,
          hd: false,
          nm: "",
          c: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [0.9765, 0.9765, 0.9765],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [0.9765, 0.9765, 0.9765],
                t: 120,
              },
              { s: [0.9765, 0.9765, 0.9765], t: 240 },
            ],
          },
          r: 1,
          o: {
            a: 1,
            k: [
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 120 },
              { s: [100], t: 240 },
            ],
          },
        },
      ],
      ind: 2,
    },
  ],
  v: "5.7.0",
  fr: 60,
  op: 240,
  ip: 0,
  assets: [],
};
