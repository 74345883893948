import { Box, Chip, Button } from "@mui/material";
import { CheckCircle } from "@phosphor-icons/react";
import {
  CoinIconSmall,
  CoinIconXsmall,
} from "../../ReusableComponents/SvgAndIcons/SvgIcons";

type Props = {
  handleSuccessfulModal: (value: boolean) => void;
  creditQuantity: string | null;
  availableCredits: number;
};

const PaymentSuccessfulModal = ({
  handleSuccessfulModal,
  creditQuantity,
  availableCredits,
}: Props) => {
  return (
    <Box
      style={{
        width: "332px",
        borderRadius: "20px",
        backgroundColor: "#1B1D28",
        paddingTop: "18px",
        paddingBottom: "24px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CheckCircle size={60} color="#28982D" weight="fill" />
      <span
        style={{
          marginTop: "12px",
          marginBottom: "24px",
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: "500",
        }}
      >
        Payment Successful
      </span>
      <Chip
        style={{
          height: "44px",
          padding: "4px 6px 4px 4px",
          borderRadius: "27px",
          color: "white",
          fontSize: "18px",
          paddingTop: "4px",
        }}
        avatar={<CoinIconSmall />}
        label={creditQuantity}
        variant="outlined"
      />
      <span
        style={{
          fontFamily: "Poppins",
          fontSize: "12px",
          marginTop: "12px",
          marginBottom: "24px",
          fontWeight: "500",
        }}
      >
        {creditQuantity} Credits added to the account
      </span>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
          marginTop: "28px",
          marginBottom: "34px",
        }}
      >
        <span
          style={{
            fontWeight: "400",
            fontSize: "12px",
            fontFamily: "Poppins",
            color: "#606479",
          }}
        >
          Total Credits
        </span>
        <Chip
          style={{ color: "white", padding: "4px" }}
          icon={<CoinIconXsmall />}
          label={availableCredits}
          variant="outlined"
        />
      </Box>
      <Button
        sx={{
          minWidth: "120px",
          padding: "6px",
          borderRadius: "5px",
          backgroundColor: "#6C65D9",
          color: "#f9f9f9",
          fontFamily: "Poppins",
          fontWeight: "400",
          "&:hover": {
            backgroundColor: "rgba(108, 101, 217, 0.5)",
          },
        }}
        onClick={() => handleSuccessfulModal(false)}
      >
        Done
      </Button>
    </Box>
  );
};

export default PaymentSuccessfulModal;
