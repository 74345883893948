import { useEffect, useState } from "react";

import { LinearProgress, Typography } from "@material-ui/core";
import { Button } from "@mui/material";
import { get, off, onValue, ref } from "firebase/database";
import { realtimeDB } from "../../../Services/firebase";
import { ProjectIdType, SequenceType } from "../../../types/public-types";
import { cancelStoryBoardGeneration } from "../../../Services/StoryboardService/StoryBoardService";

interface StoryBoardProgressProps {
  projectId: ProjectIdType;
  sequenceId: SequenceType["id"];
  breakdownId: string | null;
  fetchShotListCallback: Function;
  newJobRefreshCounter: number;
  isBoardGenerationInProg: boolean;
  setIsBoardGenerationInProg: (changeState: boolean) => void;
}

function StoryBoardProgress({
  fetchShotListCallback,
  projectId,
  sequenceId,
  breakdownId,
  newJobRefreshCounter,
  isBoardGenerationInProg,
  setIsBoardGenerationInProg,
}: StoryBoardProgressProps) {
  const [collectiveProgress, setCollectiveProgress] = useState(0);
  const [completedShots, setCompletedShots] = useState(0);
  const [totalInProgressShots, setTotalInProgressShots] = useState(0);
  const [shotsInProgressList, setShotsInProgressList] = useState<any>([]);

  // gets job for given sequence.

  const breakdownJobRef = ref(
    realtimeDB,
    `jobs/${projectId}/${sequenceId}/${breakdownId}`
  );

  useEffect(() => {
    //check if job for sequence exists or not.
    //
    function subscribeToJob() {
      // since the job exists we will add a onValue lister to it.
      onValue(breakdownJobRef, (snapshot) => {
        const data = snapshot.val();

        // if there is no object realtime db will return null
        if (!data) {
          return;
        }
        // if there are valid fields
        setIsBoardGenerationInProg(true);
        setCollectiveProgress(data?.progress?.toFixed(2));
        setCompletedShots(data.shotsCompleted);
        setTotalInProgressShots(data.jobLength);
        // set list of all shots in progress
        const shotsIdList = Object.keys(data.shotStates || {});
        setShotsInProgressList(shotsIdList);

        // since a image is generated it gets transferred to script breakdown api!
        // check for completed shots count, if its greater then previous count make a refresh call to retrieve images.
        if (data.shotsCompleted >= completedShots) {
          // Condition where a new shot is created.
          fetchShotListCallback();
        }
        if (data.jobLength === data.shotsCompleted) {
          // case where job is completed
          setIsBoardGenerationInProg(false);
        }
      });
    }
    try {
      get(breakdownJobRef).then((snapshot) => {
        //subscribe to path even if it doesn't exist
        subscribeToJob();
      });
    } catch (error) {
      console.log("error establishing realtime db connection");
    }

    return () => {
      off(breakdownJobRef);
    };
  }, [newJobRefreshCounter]);

  async function handleGenerationCancel() {
    // get all jobs ids relevant to current sequence and in prog shots.
    // get the list of task from realtime db for particular breakdown

    //TODO add proper loader and error handling
    const requestPayload = [];
    for (let i = 0; i < shotsInProgressList.length; i++) {
      requestPayload.push({
        projectId,
        sequenceId,
        breakdownId,
        shotId: shotsInProgressList[i]
      })      
    }
    try {
      await cancelStoryBoardGeneration(requestPayload);
    } catch (error) {
      console.log(error)
    }
  
    // calculate job ids that are not completed and make an api call!
  }

  return (
    <>
      {isBoardGenerationInProg ? (
        <>
          <div style={{ display: "flex", width: "100%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography>
                  <span style={{ color: "#6C65D9" }}>
                    Shots Completed: {completedShots}/{totalInProgressShots}
                  </span>{" "}
                  {}
                </Typography>
                <Typography>
                  <span style={{ color: "#6C65D9" }}>
                    Progress: {collectiveProgress}%
                  </span>{" "}
                  {}
                </Typography>
              </div>
              <LinearProgress
                variant="determinate"
                style={{
                  width: "100%",
                  height: "5px",
                  background: "#1B1D28",
                  borderRadius: 5,
                }}
                value={collectiveProgress}
              />
            </div>
            <Button onClick={() => handleGenerationCancel()}>Cancel</Button>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default StoryBoardProgress;
