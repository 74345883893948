import { Page, Text, View, Image } from "@react-pdf/renderer";
import Header from "../CommonComponents/Header";
import { NoImage } from "../SvgComponents/SvgComponents";

const StoryBoardPage = ({ projectName, sequencesToExport }) => {
  return (
    <Page
      size="A4"
      style={{
        backgroundColor: "#FFFFFF",
        padding: "21px 36px 18px 36px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "12px",
        position: "relative",
      }}
      wrap={true}
    >
      <Header projectName={projectName} />
      <Text
        style={{
          fontWeight: "semibold",
          fontSize: "18px",
          fontFamily: "Poppins",
        }}
      >
        Storyboard
      </Text>
      {sequencesToExport.map((eachSequence) => (
        <View
          style={{ width: "100%" }}
          key={eachSequence.sequence.id}
          wrap={false}
        >
          <Text
            style={{
              fontSize: "12px",
              fontFamily: "Poppins",
              margin: "8px 0px",
            }}
          >
            {eachSequence.sequence.sceneNumber}.{" "}
            {eachSequence.sequence.sceneTitle}
          </Text>
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              columnGap: "3px",
              flexWrap: "wrap",
              rowGap: "8px",
            }}
          >
            {eachSequence.ShotList.map((shot) =>
              shot.image == null ? (
                <NoImage key={shot.name} />
              ) : (
                <Image
                  style={{
                    width: "170px",
                    height: "100px",
                    borderRadius: "2px",
                  }}
                  src={shot.image}
                  key={shot.name}
                />
              )
            )}
          </View>
        </View>
      ))}
    </Page>
  );
};

export default StoryBoardPage;
