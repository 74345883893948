import { Box, Typography } from "@mui/material";
import {
  ScriptBreakdownIcon,
  ScriptIcon,
} from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import "./ScriptBreakdownXMLPreview.css";
import { SequenceType } from "../../../types/public-types";
import { useEffect, useRef, useState } from "react";

type Props = {
  fileRenderType: any;
  scriptXMLContent: any;
  isBreakDownGenerated: boolean;
  selectedSequence: SequenceType | null;
};

export default function ScriptBreakdownXMLPreview({
  fileRenderType,
  scriptXMLContent,
  isBreakDownGenerated,
  selectedSequence,
}: Props) {
  const xmlPreviewContainerRef = useRef<HTMLDivElement | null>(null);
  const [sanitizedContent, setSanitizedContent] = useState("");
  const wrapInParentheses = (htmlContent: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    const parentheticalParagraphs = doc.querySelectorAll("p.parenthetical");
    const sceneTitles = doc.querySelectorAll("p.scene");

    parentheticalParagraphs.forEach((paragraph) => {
      const content = paragraph.innerHTML;
      paragraph.innerHTML = `(${content})`;
    });

    sceneTitles.forEach((eachScene, index) => {
      const content = eachScene.innerHTML;
      eachScene.innerHTML = `<span class="scenenumber">${
        index + 1
      }</span> - ${content}`;
    });

    // Create a new HTML string with the modified content
    const modifiedHTML = doc.body.innerHTML;

    // Clean up the temporary DOM to avoid potential memory leaks
    parser.parseFromString("<!doctype html>", "text/html");

    return modifiedHTML;
  };

  useEffect(() => {
    const content = wrapInParentheses(scriptXMLContent.script);
    setSanitizedContent(content);
  }, []);

  useEffect(() => {
    if (selectedSequence && selectedSequence.sceneTitle) {
      if (xmlPreviewContainerRef.current) {
        const sceneElements = Array.from(
          xmlPreviewContainerRef.current.getElementsByClassName("scenenumber")
        );
        for (let element of sceneElements) {
          if (element?.textContent === `${selectedSequence?.sceneNumber}`) {
            element.scrollIntoView({ behavior: "smooth" });
            break;
          }
        }
      }
    }
  }, [selectedSequence]);

  return (
    <Box
      sx={{ height: "100%", backgroundColor: "#000000", padding: "0px 5px" }}
    >
      <Box
        sx={{
          height: "6%",
          backgroundColor: "#000000",
          display: "flex",
          alignItems: "center",
          gap: "8px",
          paddingLeft: "20px",
        }}
      >
        <ScriptIcon />
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            fontSize: { md: "16px", lg: "16px", xl: "18px" },
          }}
        >
          Script Preview
        </Typography>
      </Box>
      <Box
        sx={{
          height: "92%",
          backgroundColor: "#252A38",
          padding: "10px",
        }}
      >
        {isBreakDownGenerated ? (
          <Box
            sx={{
              height: "91%",
              overflow: "auto",
              padding: "10px 0px 0px 10px",
              backgroundColor: "white",
              borderRadius: "10px 10px 0px 0px ",
            }}
          >
            <Box
              sx={{
                height: "98%",
                overflow: "auto",
                // padding: "5px",
                borderRadius: "20px",
                margin: "5px",
              }}
            >
              <div style={{ height: "100%", width: "100%" }}>
                <div
                  ref={xmlPreviewContainerRef}
                  style={{
                    height: "100%",
                    width: "80%",
                    color: "black",
                    backgroundColor: "white",
                    margin: "10px",
                    padding: "10px 30px",
                  }}
                  className="container"
                  dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                ></div>
              </div>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              height: "90%",
              padding: "10px",
              backgroundColor: "transparent",
              borderRadius: "10px 10px 0px 0px ",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "30px",
              flexDirection: "column",
            }}
          >
            <ScriptBreakdownIcon />
            <Typography variant="h5" color="#606479">
              Your Script Preview will be shown here
            </Typography>
          </Box>
        )}
        {/* <Box
          sx={{
            padding: "0px 10px",
            height: "7%",
            display: "flex",
            alignItems: "center",
            backgroundColor: pdfFile && isBreakDownGenerated ? "white" : "",
            paddingTop: "10px",
            borderRadius: "0px 0px 10px 10px",
          }}
        >
          <BottomTools
            disableAll={pdfFile && isBreakDownGenerated ? false : true}
            totalPages={noOfPages}
            handleZoomIn={handleZoomIn}
            handleZoomOut={handleZoomOut}
          />
        </Box> */}
      </Box>
    </Box>
  );
}
