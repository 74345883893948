import { Box, Typography } from "@mui/material";
import { Laptop } from "@phosphor-icons/react";
import { Logo } from "../SvgAndIcons/ScriptVizLogo";
import { RotateIcon } from "../SvgAndIcons/SvgIcons";

const SmallScreenPrompt = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: "column",
          height: "90vh",
          textAlign: "center",
        }}
      >
        <Box>
          <Logo />
        </Box>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <RotateIcon />
        </Box>
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <Laptop size={100} />
        </Box>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 500 }}>
            Rotate To
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: "#6C65D9",
              mt: "12px",
              fontSize: "36px",
              fontWeight: 500,
            }}
          >
            Landscape Mode
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h5"
            sx={{
              color: "#F9F9F9",
              mt: "12px",
              fontSize: "22px",
              fontWeight: 400,
            }}
          >
            For best experience, open in
          </Typography>
          <Typography
            variant="h5"
            sx={{ mt: "12px", fontSize: "28px", fontWeight: 600 }}
          >
            Laptop/Desktop
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h6"
            sx={{ color: "#606479", fontSize: "22px", fontWeight: 400 }}
          >
            Site address:
          </Typography>
          <Typography
            variant="h5"
            sx={{
              color: "#6C65D9",
              mt: "12px",
              fontSize: "28px",
              fontWeight: 600,
            }}
          >
            scripts.moviecolab.com
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SmallScreenPrompt;
