import axios from "axios";
import { UserDetailsType } from "../../types/public-types";

const baseUrl = process.env.REACT_APP_BASE_URL;

interface signUpData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNo: string;
}

interface logInData {
  email: string;
  password: string;
}

export const UserSignUpApi = async (data: signUpData) => {
  const res = await axios.post<any>(`${baseUrl + `user/signup/`}`, data);
  return res;
};

export const UserLoginApi = async (data: logInData) => {
  const res = await axios.post<any>(`${baseUrl + `user/login/`}`, data);
  return res;
};

export const ForgetPasswordResetApi = async (email: string) => {
  const res = await axios.post<any>(`${baseUrl + `user/reset/`}`, {
    email: email,
  });
  return res;
};

interface setupNewPasswordParams {
  email: string;
  newPassword: string;
  oobCode: string;
  apiKey: string;
}

export const SetupNewPasswordApi = async ({
  email,
  newPassword,
  oobCode,
  apiKey,
}: setupNewPasswordParams) => {
  const res = await axios.post<any>(`${baseUrl + `user/confirm-reset`}`, {
    email,
    newPassword,
    oobCode,
    apiKey,
  });
  return res;
};

interface UserProfileResponse {
  code: number;
  data: UserDetailsType;
}

export const GetUserProfileApi = async () => {
  const res = await axios.get<UserProfileResponse>(`${baseUrl + `user/`}`);
  return res;
};

export const UpdateUserProfileApi = async (firstName: string) => {
  const res = await axios.patch<any>(`${baseUrl + `user/update/`}`, {
    firstName: firstName,
    lastName: " ",
  });
  return res;
};
