import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button, Chip } from "@mui/material";
import { CaretLeft } from "@phosphor-icons/react";
import {
  CoinIcon,
  FreeCreditCoinIcon,
} from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import useStyles from "./CreditPageOverview.style";
import { ScriptBreakdownContext } from "../../../Context/ContextDataProvider";
import { ROUTES } from "../../../Routes";
import {
  ProductPricing,
  CreditLog,
  TransactionDetail,
} from "../../../types/public-types";
import {
  getAllTransactionHistory,
  getProductList,
  getCreditLogs,
} from "../../../Services/creditPageServices/creditPageServices";
import CreditPageSkeleton from "../../ReusableComponents/SkeletonLoaders/CreditPageSkeleton";
import CreditPricingOverview from "../CreditPricing/CreditPricingOverview";
import CreditUsageTable from "../CreditUsageTable/CreditUsageTable";
import PaymentHistoryTable from "../PaymentHistoryTable/PaymentHistoryTable";

const CreditPageOverview = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { availableCredits, freeCredits, getCredits } = useContext(
    ScriptBreakdownContext
  ); // gets total credits
  const [allTransactionsHistory, setAllTransactionsHistory] = useState<
    TransactionDetail[]
  >([]); //transaction history list
  const [productsList, setProductsList] = useState<ProductPricing[]>([]); // product pricing list
  const [creditUsageList, setCreditUsageList] = useState<CreditLog[]>([]); // credit useage list
  const [isLoading, setIsLoading] = useState<any>({
    transactionList: false,
    creditList: false,
    productList: false,
  }); // to show skeletonloader when all api call is made

  const getTransactionHistory = async () => {
    try {
      const result = await getAllTransactionHistory();
      setAllTransactionsHistory(result.data.transactions);
      setIsLoading((prevLoading: any) => ({
        ...prevLoading,
        transactionList: false,
      }));
    } catch (error) {
      console.log("error", error);
      setIsLoading((prevLoading: any) => ({
        ...prevLoading,
        transactionList: false,
      }));
    }
  };

  const handleProductsList = async () => {
    try {
      const result = await getProductList();
      setProductsList(result.data.data);
      setIsLoading((prevLoading: any) => ({
        ...prevLoading,
        productList: false,
      }));
    } catch (error) {
      console.log("error", error);
      setIsLoading((prevLoading: any) => ({
        ...prevLoading,
        productList: false,
      }));
    }
  };

  const getCreditUseageList = async () => {
    try {
      const result = await getCreditLogs();
      setCreditUsageList(result.data.data);
      setIsLoading((prevLoading: any) => ({
        ...prevLoading,
        creditList: false,
      }));
    } catch (error) {
      console.log("error", error);
      setIsLoading((prevLoading: any) => ({
        ...prevLoading,
        creditList: false,
      }));
    }
  };

  useEffect(() => {
    setIsLoading({
      transactionList: true,
      creditList: true,
      productList: true,
    });
    getCredits();
    getTransactionHistory();
    getCreditUseageList();
    handleProductsList();
  }, []);

  return (
    <Box style={{ height: "100%", width: "100%" }}>
      {!isLoading.transactionList &&
      !isLoading.creditList &&
      !isLoading.productList ? (
        <Box className={classes.root}>
          <Box className={classes.insideContainer}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "36px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  startIcon={<CaretLeft color="#606479" />}
                  style={{ color: "#606479" }}
                  onClick={() => navigate(ROUTES.HOME)}
                >
                  Back
                </Button>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    color: "#6C65D9",
                    fontWeight: "500",
                    marginLeft: "40px",
                  }}
                >
                  Credit Summary
                </Typography>
                <Chip
                  style={{ color: "white", padding: "4px" }}
                  avatar={<FreeCreditCoinIcon />}
                  label={`${freeCredits} free credits`}
                  variant="outlined"
                  onClick={() => {
                    navigate(ROUTES.CREDITS_PAGE);
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "28px",
                  alignSelf: "center",
                }}
              >
                <Typography
                  style={{
                    fontSize: "24px",
                    fontFamily: "Poppins",
                    color: "#f9f9f9",
                  }}
                >
                  Total Credits
                </Typography>
                <Chip
                  style={{
                    color: "white",
                    height: "55px",
                    padding: "4px 6px 4px 4px",
                    borderRadius: "30px",
                    fontSize: "24px",
                  }}
                  avatar={<CoinIcon />}
                  label={availableCredits}
                  variant="outlined"
                />
              </div>
              <Typography
                style={{
                  alignSelf: "center",
                  fontSize: "12px",
                  fontFamily: "Poppins",
                  color: "#F9F9F9",
                  fontWeight: "400",
                }}
              >
                Last Added:{" "}
                {allTransactionsHistory[0] &&
                  new Intl.DateTimeFormat("en-US", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  }).format(new Date(allTransactionsHistory[0]?.date))}
              </Typography>
              <Typography
                style={{
                  alignSelf: "center",
                  fontSize: "18px",
                  fontFamily: "Poppins",
                  color: "#6C65D9",
                }}
              >
                Credit Usage
              </Typography>
            </div>
            <CreditUsageTable
              creditUsageList={creditUsageList}
              isLoading={isLoading}
            />
          </Box>
          <Box className={classes.insideContainer}>
            <Typography
              style={{
                fontSize: "18px",
                fontFamily: "Poppins",
                fontWeight: "500",
                color: "#6C65D9",
                marginBottom: "12px",
              }}
            >
              Credit Pricing
            </Typography>
            <CreditPricingOverview productsList={productsList} />
            <Typography
              style={{
                fontSize: "18px",
                fontFamily: "Poppins",
                fontWeight: "500",
                color: "#6C65D9",
                marginBottom: "12px",
                marginTop: "24px",
              }}
            >
              Payment History
            </Typography>
            <PaymentHistoryTable
              allTransactionsHistory={allTransactionsHistory}
              isLoading={isLoading}
            />
          </Box>
        </Box>
      ) : (
        <CreditPageSkeleton />
      )}
    </Box>
  );
};

export default CreditPageOverview;
