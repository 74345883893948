export const promptList = [
  {
    id: 1,
    name: "Generic Starter Prompt",
    prompt: "breathtaking",
    image: "/promptImages/generic-starter-prompt.webp",
  },
  {
    id: 2,
    name: "Anime",
    prompt: "anime artwork",
    image: "/promptImages/anime-prompt.webp",
  },
  {
    id: 3,
    name: "Digital Art / Concept Art",
    prompt: "concept art",
    image: "/promptImages/digital-art.webp",
  },
  {
    id: 4,
    name: "Ethereal Fantasy Art",
    prompt: "ethereal fantasy concept art of",
    image: "/promptImages/Ethereal-Fantasy.webp",
  },
  {
    id: 5,
    name: "Photography",
    prompt: "cinematic photo",
    image: "/promptImages/photography.webp",
  },
  {
    id: 6,
    name: "Cinematography",
    prompt: "cinematic film still",
    image: "/promptImages/cinematography.webp",
  },
  {
    id: 7,
    name: "Analog film",
    prompt: "analog film photo",
    image: "/promptImages/analog-film.webp",
  },
  {
    id: 8,
    name: "Vaporwave",
    prompt: "vaporwave synthwave style",
    image: "/promptImages/vaporwave.webp",
  },
  {
    id: 9,
    name: "Isometric",
    prompt: "isometric style",
    image: "/promptImages/isomatric.webp",
  },
  {
    id: 10,
    name: "Low Poly",
    prompt: "low-poly style",
    image: "/promptImages/low-poly.webp",
  },
  {
    id: 11,
    name: "Claymation",
    prompt: "claymation style",
    image: "/promptImages/Claymation.webp",
  },
  {
    id: 12,
    name: "3D Model",
    prompt: "professional 3d model",
    image: "/promptImages/3dModel.webp",
  },
  {
    id: 13,
    name: "Origami",
    prompt: "origami style",
    image: "/promptImages/origami.webp",
  },
  {
    id: 14,
    name: "Line Art",
    prompt: "line art drawing",
    image: "/promptImages/line-art.webp",
  },
  {
    id: 15,
    name: "Pixel Art",
    prompt: "pixel-art",
    image: "/promptImages/pixel-art.webp",
  },
  {
    id: 16,
    name: "Texture",
    prompt: "texture",
    image: "/promptImages/texture.webp",
  },
];

/* export const PromptList = [
  {
    id: 1,
    name: "Generic Starter Prompt",
    image:
      "https://149868225.v2.pressablecdn.com/wp-content/uploads/2023/07/00081-4033189563.webp",
    prompt: "breathtaking",
  },
  {
    id: 2,
    name: "Anime",
    image:
      "https://149868225.v2.pressablecdn.com/wp-content/uploads/2023/07/00089-1604055581.webp",
    prompt: "anime artwork",
  },
  {
    id: 3,
    name: "Digital Art / Concept Art",
    image:
      "https://149868225.v2.pressablecdn.com/wp-content/uploads/2023/07/00091-366980197.webp",
    prompt: "concept art",
  },
  {
    id: 4,
    name: "Ethereal Fantasy Art",
    image:
      "https://149868225.v2.pressablecdn.com/wp-content/uploads/2023/07/00107-424816362.webp",
    prompt: "ethereal fantasy concept art of",
  },
  {
    id: 5,
    name: "Photography",
    image:
      "https://149868225.v2.pressablecdn.com/wp-content/uploads/2023/07/00098-2862674839.webp",
    prompt: "cinematic photo",
  },
]; */
