import { useState, useEffect, useRef } from "react";
import { pdf } from "@react-pdf/renderer";
import { Button, CircularProgress, Box, Modal } from "@mui/material";
import {
  ProjectIdType,
  SequenceType,
  SequnceExport,
} from "../../types/public-types";
import toast from "react-hot-toast";
import ScriptBreakPdfDocument from "../PdfViewer/ScriptBreakPdfDocument";
import { exportToPdfApi } from "../../Services/exportServices/export-services";
import LoadingAnimationWithProgress from "../Utilities/Modals/LoadingAnimationWithProgress";
import { StoryBoardAnimatedData } from "../Utilities/Lotties/StoryBoardAnimatedData";
import { DownloadIconData } from "../Utilities/Lotties/DownloadIconData";

interface Props {
  projectName: string;
  selectedPdfFormat: any;
  shotCount: () => number;
  sequenceCount: number;
  selectedSequences: SequenceType[];
  projectId: ProjectIdType;
}

function DownloadPdfButtons({
  projectName,
  selectedPdfFormat,
  shotCount,
  sequenceCount,
  selectedSequences,
  projectId,
}: Props) {
  const [loggedInUser, setLoggedInUser] = useState<string>(""); //stores loggedin Users username
  const [pdfDownloadButtonLoader, setPdfDownloadButtonLoader] = useState(false); //stores pdflink loader and api call loader state
  const [pdfSequence, setPdfSequence] = useState<SequenceType[]>([]); //stores exported sequence to compare with selected sequence
  const [sequencesToExport, setSequencesToExport] = useState<SequnceExport[]>(
    []
  ); //stores sequences to export
  const containerRef = useRef<HTMLElement | null>(null);

  const getUserInfo = async () => {
    const accessToken = document.cookie.replace(
      /(?:(?:^|.*;\s*)access\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    if (accessToken) {
      setLoggedInUser(JSON.parse(atob(accessToken.split(".")[1])).name);
    }
  };

  const generatePdf = async (result: SequnceExport[]) => {
    //generates pdf after api call and download the pdf
    setPdfDownloadButtonLoader(true);
    try {
      const blob = await pdf(
        <ScriptBreakPdfDocument
          sequencesToExport={result}
          projectName={projectName}
          selectedPdfFormat={selectedPdfFormat}
          shotCount={shotCount}
          sequenceCount={sequenceCount}
          loggedInUser={loggedInUser}
        />
      ).toBlob();

      const url = URL.createObjectURL(blob);
      // downloads the pdf
      const link = document.createElement("a");
      link.href = url;
      link.download = `${projectName}.pdf`;

      containerRef.current?.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      containerRef.current?.removeChild(link);
      setPdfDownloadButtonLoader(false);
    } catch (error) {
      setPdfDownloadButtonLoader(false);
      toast.error("an error occured while downloading pdf");
    }
  };

  const getDataForPdf = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setPdfDownloadButtonLoader(true);
    //gets data for api
    try {
      const result = await Promise.all(
        selectedSequences.map(async (sequence) => {
          const response = await exportToPdfApi(projectId, sequence.id);
          const obj = await response.data.data;
          return { sequence: sequence, ShotList: obj };
        })
      );
      setPdfSequence(selectedSequences);
      setSequencesToExport(result);
      //generates pdf
      generatePdf(result);
    } catch (error) {
      setPdfDownloadButtonLoader(false);
      toast.error("an error occured while downloading pdf");
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <Box ref={containerRef}>
      <Button
        style={{
          backgroundColor:
            pdfDownloadButtonLoader || sequenceCount < 1
              ? "#606479"
              : "#6C65D9",
          color: "#f9f9f9",
        }}
        disabled={pdfDownloadButtonLoader || sequenceCount < 1}
        fullWidth
        onClick={(event) => {
          //if no change in selected sequence just download the pdf with new download option else make api call
          pdfSequence === selectedSequences && pdfSequence.length > 0
            ? generatePdf(sequencesToExport)
            : getDataForPdf(event);
        }}
      >
        {pdfDownloadButtonLoader ? ( //while the api call
          <CircularProgress />
        ) : (
          //intial render
          "Download Now"
        )}
      </Button>
      <Modal
        open={pdfDownloadButtonLoader}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <LoadingAnimationWithProgress
          lottie={DownloadIconData}
          textOne="Downloading PDF..."
        />
      </Modal>
    </Box>
  );
}

export default DownloadPdfButtons;
