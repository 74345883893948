import { createContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ROUTES } from "../Routes";
import { readCookie } from "../Services/apiResources";
import { GetUserProfileApi } from "../Services/authServices/authServices";
import {
  BreakdownVersionListApiResponse,
  ShotListListApiResponse,
  getBreakdownVersionListApi,
  getSequenceListApi,
  getShotListApi,
} from "../Services/breakdownServices/breakdownServices";
import { getAvailableCredits } from "../Services/creditPageServices/creditPageServices";
import { getProjectDetails } from "../Services/projectPageServices/projectPageServices";
import {
  Character,
  SequenceListType,
  SingleProjectDetails,
  UserDetailsType,
  ShotQueueStats,
} from "../types/public-types";
import { getAllCharactersApi } from "../Services/characterAndLocationServices/characterAndLocationServices";
import { getShotQueueDetail } from "../Services/projectPageServices/projectPageServices";

export const ScriptBreakdownContext = createContext<any>(null);

const ScriptBreakdownDataProvider = ({ children }: { children: any }) => {
  const allData = ScriptBreakdownProviderHook();
  return (
    <ScriptBreakdownContext.Provider value={allData}>
      {children}
    </ScriptBreakdownContext.Provider>
  );
};

export default ScriptBreakdownDataProvider;

const ScriptBreakdownProviderHook = () => {
  const [availableCredits, setAvailableCredits] = useState<number>(0);
  const [freeCredits, setFreeCredits] = useState<number>(0);
  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<UserDetailsType>();

  //project data states
  const [projectDetailsData, setProjectDetailsData] =
    useState<ProjectDetailsDataType>();
  const [isProjectDetailsLoading, setIsProjectDetailsLoading] =
    useState<boolean>(true);

  //sequence list states
  const [sequenceList, setSequenceList] = useState<SequenceListType>();
  const [isSequenceListLoading, setIsSequenceListLoading] =
    useState<boolean>(true);

  //breakdown list data
  const [breakdownData, setBreakdownData] = useState<BreakdownDataType>();
  const [isBreakdownLoading, setIsBreakdownLoading] = useState<boolean>(true);

  //shot list data
  const [shotListData, setShotListData] = useState<ShotListDataType>();
  const [isShotListLoading, setIsShotListLoading] = useState<boolean>(true);

  //shot list data
  const [characterListData, setCharacterListData] = useState<Character[]>();
  const [isCharacterListLoading, setIsCharacterListLoading] =
    useState<boolean>(true);

  //shot queue data
  const [shotQueueDetails, setShotQueueDetails] = useState([]);
  const [shotStats, setShotStats] = useState({
    InQueue: 0,
    InProgress: 0,
    Completed: 0,
    Failure: 0,
  });
  const [isQueueLoading, setIsQueueLoading] = useState(false);

  const getQueues = async () => {
    setIsQueueLoading(true);
    try {
      const res = await getShotQueueDetail();
      setShotQueueDetails(res.data.data);
      const res1 = res.data.data.filter(
        (eachItem: any) => eachItem.status === 0
      );
      const res2 = res.data.data.filter(
        (eachItem: any) => eachItem.status === 1
      );
      const res3 = res.data.data.filter(
        (eachItem: any) => eachItem.status === 2
      );
      const res4 = res.data.data.filter(
        (eachItem: any) => eachItem.status === 3
      );
      setShotStats({
        InQueue: res1.length,
        InProgress: res2.length,
        Completed: res3.length,
        Failure: res4.length,
      });
      setIsQueueLoading(false);
    } catch (error) {
      console.log(error);
      setIsQueueLoading(false);
    }
  };

  const getCredits = async () => {
    try {
      const result = await getAvailableCredits();
      setAvailableCredits(result.data.availableCredits);
      setFreeCredits(result.data.freeCredits);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getUserData = async () => {
    try {
      const result = await GetUserProfileApi();
      setLoggedInUserDetails(result.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleGetProjectDetails = async (projectId: string) => {
    setIsProjectDetailsLoading(true);
    try {
      setIsSequenceListLoading(true);
      const res = await getProjectDetails(projectId);
      setProjectDetailsData({
        id: projectId,
        details: res?.data,
      });

      const accessToken = readCookie("access");
      if (accessToken) {
        const userId = JSON.parse(atob(accessToken?.split(".")[1])).user_id;
        if (userId === res.data.created_by) {
          setIsProjectDetailsLoading(false);
          handleGetSequences(projectId, res?.data?.linkedFile?.fileId);
          return res.data;
        } else {
          setSequenceList([]);
          toast.error("You do not have permission to access");
          window.location.href = ROUTES.UNAUTHORIZED;
        }
      }
    } catch (error) {
      console.log(error);
      setIsProjectDetailsLoading(false);
      toast.error("Error getting project details");
    }
  };

  const handleGetSequences = async (projectId: string, fileId?: string) => {
    setIsSequenceListLoading(true);
    try {
      const res = await getSequenceListApi(projectId, fileId);
      setSequenceList(res?.data?.sequences);
      handleGetBreakdownList(projectId, res?.data?.sequences[0]?.id);
      setIsSequenceListLoading(false);
    } catch (error) {
      setIsSequenceListLoading(false);
      console.log(error);
      toast.error("Error getting sequences");
    }
  };

  const handleGetBreakdownList = async (
    projectId: string,
    sequenceId: string
  ) => {
    setIsShotListLoading(true);
    setIsBreakdownLoading(true);
    try {
      const res = await getBreakdownVersionListApi(projectId, sequenceId);
      setBreakdownData({
        projectId,
        sequenceId,
        breakdownList: res?.data,
      });
      // handleGetShotList;
      if (res?.data?.breakdowns?.length) {
        handleGetShotList(res?.data?.breakdowns[0]?.breakdownId);
      } else {
        setIsShotListLoading(false);
        setShotListData({
          breakdownId: "",
          //@ts-ignore
          shotList: [],
        });
      }

      setIsBreakdownLoading(false);
    } catch (error) {
      setIsBreakdownLoading(false);
      console.log(error);
      toast.error("Error getting sequences");
    }
  };

  const handleGetShotList = async (breakdownId?: string) => {
    setIsShotListLoading(true);
    try {
      const res = await getShotListApi(breakdownId ?? "");
      setShotListData({
        breakdownId: breakdownId ? breakdownId : "",
        shotList: res?.data,
      });
      setIsShotListLoading(false);
    } catch (error) {
      toast.error("Error getting shots");
      setIsShotListLoading(false);
    }
  };

  const handleGetCharacterList = async (projectId: string, fileId: string) => {
    setIsCharacterListLoading(true);
    try {
      const res = await getAllCharactersApi(projectId, fileId);
      setCharacterListData(res.data?.characters);
      setIsCharacterListLoading(false);
    } catch (error) {
      toast.error("Error getting characters");
      setIsCharacterListLoading(false);
    }
  };

  useEffect(() => {
    getQueues();
    getCredits();
    getUserData();
  }, []);
  return {
    getCredits,
    availableCredits,
    loggedInUserDetails,
    getUserData,
    freeCredits,
    //project
    handleGetProjectDetails,
    projectDetailsData,
    isProjectDetailsLoading,
    //sequence
    handleGetSequences,
    sequenceList,
    isSequenceListLoading,
    //breakdown
    handleGetBreakdownList,
    breakdownData,
    isBreakdownLoading,
    //shot
    handleGetShotList,
    shotListData,
    isShotListLoading,
    //characters
    handleGetCharacterList,
    characterListData,
    isCharacterListLoading,
    //shotQueueDetail
    shotQueueDetails,
    isQueueLoading,
    shotStats,
    getQueues,
  };
};

export interface ContextTypes {
  getCredits: () => void;
  availableCredits: number;
  loggedInUserDetails: UserDetailsType;
  getUserData: () => void;
  freeCredits: number;
  //project
  handleGetProjectDetails: (projectId: string) => SingleProjectDetails;
  isProjectDetailsLoading: boolean;
  projectDetailsData: ProjectDetailsDataType;
  //sequence
  handleGetSequences: (projectId: string, fileId?: string) => void;
  sequenceList: SequenceListType;
  isSequenceListLoading: boolean;
  //breakdown
  handleGetBreakdownList: (projectId: string, sequenceId: string) => void;
  breakdownData: BreakdownDataType;
  isBreakdownLoading: boolean;
  //shot list
  handleGetShotList: (id: string) => void;
  shotListData: ShotListDataType;
  isShotListLoading: boolean;
  //character list
  handleGetCharacterList: (id: string, fileId: string) => void;
  characterListData: Character[];
  isCharacterListLoading: boolean;
  //shotQueueDetail
  shotQueueDetails: any;
  isQueueLoading: boolean;
  shotStats: ShotQueueStats;
  getQueues: () => void;
}

interface ProjectDetailsDataType {
  id: string;
  details: SingleProjectDetails;
}
interface BreakdownDataType {
  projectId: string;
  sequenceId: string;
  breakdownList: BreakdownVersionListApiResponse;
}

interface ShotListDataType {
  breakdownId: string;
  shotList: ShotListListApiResponse;
}