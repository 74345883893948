import { makeStyles } from "@material-ui/core/styles";
import { themeType } from "../../../types/theme-types";

const useStyles = makeStyles((theme: themeType) => ({
  container: {
    minHeight: "387px !important",
    width: "610px !important",
    borderRadius: "20px",
    backgroundColor: "#1B1D28",
    padding: "18px",
  },
  header: {
    fontSize: "24px !important",
    fontFamily: "Poppins !important",
    textAlign: "center",
    fontWeight: 500,
  },
  containerTwo: {
    marginTop: "18px",
    display: "flex",
    flexDirection: "column",
    gap: "11px",
  },
  labelText: {
    fontSize: "14px !important",
    fontFamily: "Poppins !important",
    fontWeight: 400,
    color: "#606479",
    marginBottom: "3px !important",
  },
  textField: {
    height: "80px",
    borderRadius: "10px",
    padding: "10px",
    backgroundColor: "#252A38",
    "& .MuiOutlinedInput-root": {
      color: "white",
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        visibility: "hidden",
      },
    },
  },
  styleBoardContainer: {
    width: "100%",
    height: "100px",
    padding: "5px",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 8,
    display: "inline-flex",
    overflowX: "auto",
  },
  styleBoard: {
    minWidth: "134px",
    height: "100%",
    borderRadius: "10px",
    backgroundColor: "#252A38",
    textAlign: "center",
    boxSizing: "border-box",
    border: "2px solid transparent",
  },
  selectedStyleBoard: {
    minWidth: "134px",
    height: "100%",
    borderRadius: "10px",
    backgroundColor: "#252A38",
    textAlign: "center",
    boxSizing: "border-box",
    border: "2px solid #6C65D9",
  },
  promptImage: {
    height: "80%",
    width: "100%",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  promptName: {
    fontSize: "10px !important",
    lineHeight: "0 !important",
    marginTop: "2px !important",
  },
  buttonContainer: {
    marginTop: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "24px",
  },
  cancelButton: {
    minHeight: "33px !important",
    minWidth: "150px !important",
    padding: "6px !important",
    borderRadius: "10px !important",
    fontFamily: "Poppins",
    border: "1px solid #6C65D9 !important",
  },
  generateButton: {
    minHeight: "40px !important",
    minWidth: "150px !important",
    padding: "6px !important",
    borderRadius: "10px !important",
    fontFamily: "Poppins",
    color: "white !important",
  },
}));

export default useStyles;
