import { Text, View } from "@react-pdf/renderer";

const Header = ({ projectName }) => (
  <View
    style={{
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    }}
    fixed={true}
  >
    <Text
      style={{ fontFamily: "Poppins", fontWeight: "medium", fontSize: "10px" }}
    >
      {projectName}
    </Text>
    <Text
      style={{ fontFamily: "Poppins", fontWeight: "medium", fontSize: "10px" }}
      render={({ pageNumber }) =>
        pageNumber < 10 ? `0${pageNumber}` : `${pageNumber}`
      }
      fixed
    />
  </View>
);

export default Header;
