import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { X } from "@phosphor-icons/react";
import { useState } from "react";
import { SingleProjectType } from "../../../types/public-types";
import { FilledButton } from "../../ReusableComponents/Buttons/Buttons";
import CustomInputField from "../../ReusableComponents/CustomInputField/CustomInputField";
import useStyles from "./CreateAndEditProjectModal.style";

type Props = {
  type: string;
  handleClose: () => void;
  handleClick: (data: { name: string }) => void;
  selectedProject?: SingleProjectType | null;
  addingOrEditing: boolean;
};

const CreateAndEditProjectModal = ({
  type,
  handleClose,
  handleClick,
  selectedProject,
  addingOrEditing,
}: Props) => {
  const classes = useStyles();
  const [name, setName] = useState(selectedProject?.name || "");

  return (
    <Card className={classes.root} elevation={0}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Typography variant="h4">
            {type === "new" ? "Add New" : "Edit"} Project
          </Typography>
          <Button sx={{ color: "white" }} onClick={() => handleClose()}>
            <X size={24} />
          </Button>
        </Grid>
        <Box sx={{ my: 2 }}>
          <CustomInputField
            title="Project Name"
            isRequired={true}
            placeholder="Enter Your Project Name"
            handleChange={(e) => setName(e)}
            value={name}
            handleEnter={(keyName) => {
              if (keyName === "Enter" && name.length) {
                handleClick({ name });
              }
            }}
          />
        </Box>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "center" }}>
        <FilledButton
          text={type === "new" ? "Create New Project" : "Save"}
          disabled={name.length ? false : true}
          handleClick={() => handleClick({ name })}
          fullWidth={false}
          minWidth={150}
          isLoading={addingOrEditing}
        />
      </CardActions>
    </Card>
  );
};

export default CreateAndEditProjectModal;
