import { makeStyles } from "@material-ui/core/styles";
import { themeType } from "../../../types/theme-types";

const useStyles = makeStyles((theme: themeType) => ({
  // input script form styles
  root: {
    height: "99%",
  },
  titleInputActionContainer: {
    border: "1px solid #6C65D9",
    height: "85%",
    marginTop: "20px",
    borderRadius: "10px",
    width: "100%",
  },
  title: {
    backgroundColor: "#606479",
    width: "100%",
    textAlign: "center",
    borderRadius: "5px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  uploadScriptContainer: {
    backgroundColor: "#1B1D28",
    width: "100%",
    textAlign: "center",
    borderRadius: "10px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "48px",
  },
  activeButton: {
    width: "100%",
    color: "white !important",
    backgroundColor: "#6C65D9 !important",
    padding: "8px 0px !important",
    "&:hover": {
      backgroundColor: "#6C65D0 !important",
    },
  },
  inActiveButton: {
    width: "100%",
    color: "#606479 !important",
    padding: "8px 0px !important",
    "&:hover": {
      color: "white !important",
    },
  },
  importScriptButton: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    borderRadius: "5px",
    backgroundColor: "#6C65D9 !important",
    padding: "6px, 12px, 6px, 12px !important",
    color: "white !important",
  },

  // manually scene add styles
  manualSceneAddRoot: {
    padding: "12px",
    height: "92%",
    fontFamily: "poppins",
    // width: "100%",
  },
  addNewSceneButton: {
    border: "2px solid #6C65D9 !important",
    color: "white !important",
    borderRadius: "5px !important",
    padding: "4px 8px !important",
    fontSize: "10px !important",
  },
  descriptionInput: {
    height: "150px",
    width: "98%",
    backgroundColor: "#252A38",
    border: "none",
    borderRadius: "10px",
    resize: "none",
    outline: "none",
    padding: "6px 10px",
    color: "white",
    fontFamily: "poppins",
  },
  submitButton: {
    backgroundColor: "#6C65D9",
    padding: "6px 12px",
    color: "white",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#6C65D0",
    },
  },
  scenesContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px",
    gap: "6px",
  },
  scene: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid transparent",
    borderRadius: "5px",
    padding: "4px",
    cursor: "pointer",
  },
  selectedScene: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid #606479",
    borderRadius: "5px",
    padding: "4px",
  },
  menuList: {
    width: "110px !important",
    backgroundColor: "#252A38",
    color: "white",
    border: "1px solid #606479",
    borderTop: "none",
    padding: "4px 6px !important",
  },
  menuItem: {
    fontSize: "10px !important",
  },

  // common styles
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexAndGap: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  heightWidthFull: {
    height: "100%",
    width: "100%",
  },
  textGray: {
    color: "#606479",
  },
  errorCard: {
    position: "absolute",
    bottom: "20px",
    right: "20px",
  },
}));

export default useStyles;
