import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { MapPin } from "@phosphor-icons/react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as yup from "yup";
import {
  autoGenerateOptionsApi,
  createNewShotApi,
} from "../../../Services/breakdownServices/breakdownServices";
import { ShotListType } from "../../../types/public-types";
import CustomInputWithFormik from "../../ReusableComponents/CustomInputField/CustomInputWithFormik";
import { GenerateNewBreakdownSvg } from "../../ReusableComponents/SvgAndIcons/GenerateNewBreakdownSvg";
import {
  Camera,
  CharacterIcon,
} from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import { LoadingIconData } from "../../Utilities/Lotties/LoadingIconData";
import LoadingAnimationWithProgress from "../../Utilities/Modals/LoadingAnimationWithProgress";
import useStyles from "./ModalStyles";

interface Props {
  handleClose: () => void;
  projectId: string;
  breakdownId?: string;
  sequenceId?: string;
  updateRefreshCounter: () => void;
  previousShotNumber: number;
  shotList: ShotListType;
}
const AddNewShotModal = ({
  handleClose,
  projectId,
  sequenceId,
  breakdownId,
  updateRefreshCounter,
  shotList,
  previousShotNumber,
}: Props) => {
  const classes = useStyles();
  const [currentShotNo, setCurrentShotNo] = useState<number>(0);
  const [canCreate, setCanCreate] = useState<boolean>(true);
  const [nextShotNumber, setNextShotNumber] = useState<number>(0);
  const [previousShotId, setPreviousShotId] = useState<string>();
  const [nextShotId, setNextShotId] = useState<string>();
  const [isAutoGenerating, setIsAutoGenerating] = useState<boolean>(false);

  useEffect(() => {
    const previousShot: any = shotList.find(
      (single: any) => single.shotNumber === previousShotNumber
    );
    setPreviousShotId(previousShot?.shotId);
    const previousShotIndex = shotList?.indexOf(previousShot!);
    if (previousShotIndex === shotList?.length - 1) {
      setCanCreate(true);
      setCurrentShotNo(previousShotNumber + 5);
    } else {
      const nextShot: any = shotList[previousShotIndex + 1];
      setNextShotId(nextShot?.shotId);
      setNextShotNumber(nextShot?.shotNumber);
      if (previousShot.shotNumber === nextShot.shotNumber - 1) {
        setCanCreate(false);
      } else {
        setCanCreate(true);
        const newShotNo = Math.floor(
          (previousShot.shotNumber + nextShot?.shotNumber) / 2
        );
        setCurrentShotNo(newShotNo);
      }
    }
  }, [shotList, previousShotNumber]);
  //Validators
  const AddShotValidationSchema = yup.object({
    description: yup.string().required("Description Is Required"),
    character: yup.string().required("Character Is Required"),
    location: yup.string().required("Location Is Required"),
    cameraAngle: yup.string().required("Camera angle Is Required"),
  });
  const formik = useFormik({
    initialValues: {
      description: "",
      character: "",
      location: "",
      cameraAngle: "",
    },
    validationSchema: AddShotValidationSchema,
    onSubmit: async (values) => {
      const data = {
        cameraPosition: values.cameraAngle,
        description: values.description,
        dialogue: "  ",
        location: values.location,
        characters: values.character,
        projectId: projectId,
        sequenceId: sequenceId,
        breakdownId: breakdownId,
        shotNumber: currentShotNo,
        shotNumberText: `SHOT_00${currentShotNo}`,
        Midjourney_Prompt: shotList[0]?.Midjourney_Prompt || ""
      };
      const promise = toast.promise(createNewShotApi(data), {
        loading: "Creating New Shot",
        success: "Successfully Created New shot",
        error: "Error on Create new shot",
      });
      try {
        const res = await promise;
        handleClose();
        updateRefreshCounter();
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleAutoGenerate = async () => {
    setIsAutoGenerating(true);
    try {
      const res = await autoGenerateOptionsApi({
        projectId,
        breakdownId: breakdownId,
        shotIds: [previousShotId, nextShotId],
        sequenceId: sequenceId,
      });

      if (res?.status === 200 || 201) {
        const autoGeneratedValues = {
          description: res?.data?.data?.description ?? "",
          character: res?.data?.data?.characters,
          location: res?.data?.data?.location,
          cameraAngle: res?.data?.data?.cameraPosition,
        };
        formik.setValues({
          ...formik.values,
          ...autoGeneratedValues,
        });
        setIsAutoGenerating(false);
      }
    } catch (error) {
      console.log(error);
      setIsAutoGenerating(false);
    }
  };

  return (
    <Box className={classes.root} sx={{ color: "#606479" }}>
      <Box className={classes.flexBetween}>
        <Typography>New Shot : {currentShotNo}</Typography>
        <Typography variant="h4" sx={{ color: "white" }}>
          Add New Shot
        </Typography>
        <Typography>#{`SHOT_00${currentShotNo}`}</Typography>
      </Box>
      <form
        style={{ display: "flex", flexDirection: "column", gap: "20px" }}
        onSubmit={formik.handleSubmit}
      >
        <Box>
          <Box className={classes.descriptionTitleAndAutoBtn}>
            <Typography>Description</Typography>
            <Box className={classes.emptyBlock} />
            <Button
              className={classes.autoGenerateButton}
              onClick={() => handleAutoGenerate()}
            >
              <GenerateNewBreakdownSvg />
              <Typography sx={{ color: "white" }}>Auto Generate</Typography>
            </Button>
          </Box>
          <textarea
            name="description"
            placeholder="Add description here"
            className={classes.descriptionInput}
            onChange={formik.handleChange}
            value={formik.values.description}
          />
          {formik.touched.description && formik.errors.description ? (
            <Typography sx={{ fontSize: "10px", color: "red" }}>
              {formik.errors.description}
            </Typography>
          ) : (
            ""
          )}
        </Box>
        <Box>
          <Box className={classes.flexAndGap}>
            <CharacterIcon />
            <Typography>Character</Typography>
          </Box>
          <CustomInputWithFormik
            name="character"
            placeholder="Add Characters here"
            handleChange={formik.handleChange}
            value={formik.values.character}
            error={
              formik.touched.character && formik.errors.character
                ? formik.errors.character
                : ""
            }
          />
        </Box>
        <Box>
          <Box className={classes.flexAndGap}>
            <MapPin />
            <Typography>Location</Typography>
          </Box>
          <CustomInputWithFormik
            name="location"
            placeholder="Add Location here"
            handleChange={formik.handleChange}
            value={formik.values.location}
            error={
              formik.touched.location && formik.errors.location
                ? formik.errors.location
                : ""
            }
          />
        </Box>
        <Box>
          <Box className={classes.flexAndGap}>
            <Camera />
            <Typography>Camera Angle</Typography>
          </Box>
          <CustomInputWithFormik
            name="cameraAngle"
            placeholder="Add Camera Angle here"
            handleChange={formik.handleChange}
            value={formik.values.cameraAngle}
            error={
              formik.touched.cameraAngle && formik.errors.cameraAngle
                ? formik.errors.cameraAngle
                : ""
            }
          />
        </Box>
        <Box className={classes.flexCenter}>
          <Box className={classes.flexAndGap}>
            {canCreate ? (
              <Button
                type="submit"
                variant="contained"
                className={classes.addShotButton}
              >
                Add New Shot
              </Button>
            ) : (
              <Typography sx={{ color: "red" }}>
                {`can not create shot between SHOT_00${previousShotNumber} & SHOT_00${nextShotNumber}`}
              </Typography>
            )}
            <Button
              className={classes.cancelButton}
              onClick={() => handleClose()}
              variant="outlined"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
      <Modal open={isAutoGenerating} className={classes.flexCenter}>
        <LoadingAnimationWithProgress
          lottie={LoadingIconData}
          textOne="Please wait, while we auto generate"
          textTwo="description for you"
        />
      </Modal>
    </Box>
  );
};

export default AddNewShotModal;
