export const CoverPageStyle = {
  page: {
    backgroundColor: "#FFFFFF",
    padding: "102px 36px 36px 18px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "90px",
  },
  projectDetailBox: {
    width: "180px",
    height: "60px",
    border: "1px solid black",
    borderRadius: "10px",
    padding: "8px 12px",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  projectDetail: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  projectDetailTextOne: {
    fontSize: "12px",
    fontWeight: "medium",
    color: "#606479",
    fontFamily: "Poppins",
  },
  projectDetailTextTwo: {
    fontSize: "18px",
    fontWeight: "medium",
    color: "#6C65D9",
    fontFamily: "Poppins",
  },
  textStyle: {
    fontSize: "12px",
    color: "#606479",
    fontFamily: "Poppins",
  },
};
