import { makeStyles } from "@material-ui/core/styles";
import { themeType } from "../../../types/theme-types";

const useStyles = makeStyles((theme: themeType) => ({
  "@global": {
    body: {
      backgroundColor: "#252A38",
    },
  },
  root: {
    boxSizing: "border-box",
    fontFamily: "Poppins",
    height: "100% !important",
    backgroundColor: "#252A38 !important",
  },
  pageHeader: {
    padding: "2px 40px 2px 40px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#1B1D28 !important",
  },
  headerContainers: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  btn: {
    minWidth: "0px",
    padding: "10px",
    fontSize: "10px",
    color: "#F9F9F9 !important",
    fontFamily: "Poppins !important",
  },
  boxContainerOne: {
    backgroundColor: "#1B1D28",
    borderRadius: "10px",
    // width: "13%",
    textAlign: "center",
    fontFamily: "Poppins",
    padding: "10px 6px",
    // marginLeft: "24px",
  },
  boxContainerTwo: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    padding: "8px 40px 0px 40px !important",
    height: "91.5% !important",
  },
  shotContainerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#1B1D28",
    padding: "5px 10px",
    // marginRight: "25px",
    borderRadius: "8px",
    border: "2px solid #6C65D9",
  },
  scrollContainerOne: {
    height: "96%",
    overflow: "auto",
    padding: "0px 10px",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  scrollContainerTwo: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    overflow: "auto",
    paddingRight: "6px !important",
  },
  dot: {
    width: "10px",
    height: "10px",
    background: "#F9F9F9",
    borderRadius: "50%",
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  shotImage: {
    height: "100px",
    width: "80%",
    border: "1px solid grey",
    borderRadius: "5px",
  },
  generateAllButton: {
    minHeight: "20px !important",
    minWidth: "0px !important",
    padding: "5px !important",
    borderRadius: "10px",
    textTransform: "none",
    fontFamily: "Poppins",
    backgroundColor: "#6C65D9 !important",
    color: "white !important",
    "&:hover": {
      backgroundColor: "rgba(108, 101, 217, 0.5)",
    },
  },
  prevNextButton: {
    color: "white !important",
    border: "1px solid #6C65D9 !important",
    padding: "5px !important",
  },
  floatingDiv: {
    position: "fixed",
    right: "50px",
    width: "450px",
    border: "2px solid #606479",
    borderRadius: "10px",
    backgroundColor: "#252A38",
    overflowX: "hidden",
    overflowY: "scroll",
    zIndex: 100,
    top: "180px",
    height: `calc(100vh - 250px)`,
  },
  flexAndGap: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  selectAllButton: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    color: "#606479 !important",
  },
  clearAllButton: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    color: "#6C65D9 !important",
  },
}));

export default useStyles;
