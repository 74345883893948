import { Box, Grid, Typography } from "@mui/material";
import { ArrowsMerge, CaretUpDown, WarningCircle } from "@phosphor-icons/react";
import {
  MovieColabProjectDetailsResponse,
  MovieColabSingleSequenceType,
} from "../../../types/movie-colab-types";
import { SequenceListType, SequenceType } from "../../../types/public-types";
import {
  FilledButton,
  OutlinedButton,
} from "../../ReusableComponents/Buttons/Buttons";
import { SelectedCircleIcon } from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import useStyles from "../ExportPreview.style";

interface Props {
  handleClose: () => void;
  movieColabProjectDetails?: MovieColabProjectDetailsResponse;
  sequenceList: SequenceListType;
  movieColabSequences?: MovieColabSingleSequenceType[];
  selectedSequences: SequenceType[];
  handleExportToMovieColab: () => void;
  isExporting: boolean;
}

const ExportToMovieColabModal = ({
  handleClose,
  movieColabProjectDetails,
  sequenceList,
  selectedSequences,
  handleExportToMovieColab,
  isExporting,
}: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.modalRoot}>
      <Box>
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          Export To Movie Colab
        </Typography>
      </Box>
      <Box className={classes.projectDetails}>
        <Grid container spacing={1} alignItems="center">
          <Grid item md={4}>
            <Box className={classes.flexAndGap}>
              <SelectedCircleIcon />
              <img
                src={
                  movieColabProjectDetails?.thumbnail ||
                  "https://images.pexels.com/photos/577514/pexels-photo-577514.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                }
                alt=""
                style={{ height: "40px", width: "40px", borderRadius: "10px" }}
              />
              <Typography sx={{ fontSize: "14px" }}>
                {movieColabProjectDetails?.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Typography sx={{ color: "#606479", fontSize: "14px" }}>
              {movieColabProjectDetails?.description}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Box className={classes.flexBetween}>
              <Typography sx={{ fontSize: "12px" }}>
                {movieColabProjectDetails?.abbreviation}
              </Typography>
              <Typography sx={{ color: "#606479", fontSize: "12px" }}>
                Sequence {sequenceList?.length}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        {selectedSequences?.map((singleSequence: SequenceType) => {
          return (
            <Box className={classes.conflictedSequence}>
              <Grid container alignItems="center">
                <Grid item md={6}>
                  <Typography sx={{ fontSize: "14px" }}>
                    {singleSequence?.sceneTitle}
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "#606479",
                      fontSize: "14px",
                    }}
                  >
                    Version {singleSequence.breakdownVersion}
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography sx={{ textAlign: "right", fontSize: "14px" }}>
                    {" "}
                    <span style={{ color: "#606479" }}> Shots : </span>
                    {singleSequence.breakdownShotCount}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Box>
      <Box className={classes.conflictWarning}>
        <WarningCircle size={60} fill="red" />
        <Typography sx={{ color: "#606479" }}>
          Unfortunately, there has been an error when exporting to MovieColab,
          as there may be sequences with the same name in MovieColab as in
          ScriptBreakdown
        </Typography>
      </Box>
      <Box>
        <Typography sx={{ color: "#606479", mb: 1, fontSize: "14px" }}>
          Choose an option
        </Typography>
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Box className={classes.mergeSequence}>
            <Typography sx={{ color: "#606479" }}>Merge Sequences</Typography>
            <ArrowsMerge size={40} style={{ transform: "rotate(270deg)" }} />
          </Box>
          <Box className={classes.separateSequence}>
            <Typography>Separate Sequences</Typography>
            <CaretUpDown size={40} />
          </Box>
        </Box>
      </Box>
      <Box
        className={classes.flexCenter}
        sx={{
          gap: "20px",
        }}
      >
        <FilledButton
          text={`Export To ${movieColabProjectDetails?.name || "MovieColab"}`}
          disabled={false}
          handleClick={() => handleExportToMovieColab()}
          fullWidth={false}
          minWidth={150}
          isLoading={isExporting}
        />
        <OutlinedButton
          text="Back"
          disabled={false}
          handleClick={() => handleClose()}
          fullWidth={false}
          minWidth={150}
        />
      </Box>
    </Box>
  );
};

export default ExportToMovieColabModal;
