import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import { X } from "@phosphor-icons/react";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as yup from "yup";
import {
  addNewCharacterApi,
  addNewCharacterImageApi,
  generateCharacterSignedUrl,
} from "../../../../Services/characterAndLocationServices/characterAndLocationServices";
import {
  FilledButton,
  OutlinedButton,
} from "../../../ReusableComponents/Buttons/Buttons";
import useStyles from "./CharactersStyles";
import GenerateCharacterImage from "./GenerateCharacterImage";
import { Character } from "../../../../types/public-types";

interface Props {
  projectId: string;
  fileId: string;
  handleClose: () => void;
  handleUpdate: () => void;
  type: string;
  characterDetails?: Character | null;
}

const CharacterSettingsModal = ({
  projectId,
  fileId,
  handleClose,
  handleUpdate,
  characterDetails,
  type,
}: Props) => {
  const storage = getStorage();
  const classes = useStyles();
  const [thumbnail, uploadThumbnailSet] = useState<any>();
  const [thumbnailTouched, setThumbnailTouch] = useState(false);
  const [currentAlias, setCurrentAlias] = useState("");
  const [addedAliases, setAddedAliases] = useState<string[]>();
  const [isGenerateImageUsingAi, setIsGenerateImageUsingAi] =
    useState<boolean>(false);
  const SignUpValidationSchema = yup.object({
    characterName: yup
      .string()
      .label("characterName")
      .required("Name is required"),
    /* characterGender: yup
      .string()
      .label("characterGender")
      .required("characterGender is required"),
    characterAge: yup
      .string()
      .label("characterAge")
      .required("characterAge is required"),
    characterEthnicity: yup
      .string()
      .label("characterEthnicity")
      .required("characterEthnicity is required"), */
  });
  const formik = useFormik({
    initialValues: {
      characterName: "",
      characterDescription: "null",
      /* characterDescription: "null",
      characterGender: "Male",
      characterAge: 10,
      characterEthnicity: "ll", */
    },
    validationSchema: SignUpValidationSchema,
    onSubmit: async (values) => {
      try {
        if (type === "new") {
          const data = {
            ...values,
            projectId,
            fileId,
            characterReferenceImages: thumbnail ? [`${thumbnail.name}`] : [],
          };
          const res = await addNewCharacterApi(data);
          if (thumbnail) {
            toast.success("Character Created successfully");
            const fileType = thumbnail.name.split(".").pop() || "";
            await handleUploadFileInFirebase(
              res?.data?.data?.characterReferenceImages[0]?.imagePath,
              await thumbnail.arrayBuffer(),
              fileType
            );
          } else {
            toast.success("Character Created successfully");
            handleClose();
            handleUpdate();
          }
        } else {
          const editData = {
            projectId,
            fileId,
            characterId: characterDetails?.charId,
            imageName: thumbnail?.name,
          };
          const res = await addNewCharacterImageApi(editData);
          const fileType = thumbnail.name.split(".").pop() || "";
          await handleUploadFileInFirebase(
            res?.data?.data?.imagePath,
            await thumbnail.arrayBuffer(),
            fileType
          );
        }
      } catch (error) {
        toast.error("Error on Character Create");
        console.log(error);
      }
    },
  });

  const handleUploadFileInFirebase = async (
    filePath: string,
    bufferData: ArrayBuffer,
    fileType: string
  ) => {
    const fileRef = ref(storage, filePath);
    try {
      await uploadBytes(fileRef, bufferData, {
        contentType: fileType,
      });
      toast.success("File Uploaded");
      handleUpdate();
      handleClose();
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleUploadThumbnail = async (characterId: string) => {
    try {
      const res = await generateCharacterSignedUrl({ projectId, characterId });
      if (res.data.data[0]?.signedUrl) {
        await fetch(res.data.data[0]?.signedUrl, {
          method: "PUT",
          headers: {
            "Content-Type": thumbnail.type,
          },
          body: thumbnail,
        });
        handleUpdate();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleThumbnailUpload = (e: any) => {
    let file = e.target.files[0];
    uploadThumbnailSet(file);
    setThumbnailTouch(true);
  };

  const removeThumbnail = () => {
    setThumbnailTouch(false);
    setIsHovered(false);
  };

  useEffect(() => {
    if (type === "edit" && characterDetails) {
      formik.setFieldValue("characterName", characterDetails?.characterName);
    }
  }, [type, characterDetails]);

  return (
    <div className={classes.characterSettingsModalRoot}>
      <Typography variant="h5" textAlign="center">
        Character Settings
      </Typography>
      <Box>
        <Grid container spacing={2} mt={2}>
          <Grid item md={4} display="flex" flexDirection="column" gap={1}>
            <Typography className={classes.inputLabel}>
              Character Image
            </Typography>
            <Box>
              <Box>
                {thumbnailTouched ? (
                  <div
                    className={classes.imageViewerDiv}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <div
                      className={classes.removeThumbnailIcon}
                      onClick={() => removeThumbnail()}
                      style={{
                        display: isHovered ? "flex" : "none",
                      }}
                    >
                      <X size={18} />
                    </div>
                    <div className={classes.imageContentDiv}>
                      <img
                        className={classes.projectThumbnail}
                        src={thumbnail ? URL.createObjectURL(thumbnail) : ""}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={classes.cardContentDiv2}>
                    <input
                      type="file"
                      className={classes.imageInput}
                      accept="image/png, image/jpeg"
                      id="thumbnailUpload"
                      onChange={(e) => handleThumbnailUpload(e)}
                    />
                  </div>
                )}
              </Box>
              {/* <Box
                width="100%"
                height="200px"
                bgcolor="#252A38"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                borderRadius={4}
                gap={2}
              >
                <Button variant="contained" sx={{ px: 2, borderRadius: "8px" }}>
                  Upload Image Manually
                </Button>
                <span>or</span>
                <Button
                  variant="outlined"
                  sx={{ borderRadius: "8px", px: 2 }}
                  onClick={() => setIsGenerateImageUsingAi(true)}
                >
                  Generate Using AI
                </Button>
              </Box> */}
            </Box>
          </Grid>
          <Grid item md={8} display="flex" flexDirection="column" gap={1}>
            <form
              onSubmit={formik.handleSubmit}
              style={{
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <Box className={classes.inputsContainer}>
                <Box className={classes.inputLabelAndField}>
                  <Typography className={classes.inputLabel}>Name</Typography>
                  <TextField
                    placeholder="Enter Name"
                    type="text"
                    name="characterName"
                    fullWidth
                    variant="standard"
                    className={classes.inputField}
                    value={formik.values.characterName}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.characterName && (
                    <Typography sx={{ color: "red", fontSize: "10px" }}>
                      {formik.errors.characterName}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="flex-end"
                justifyContent="space-between"
                gap={4}
              >
                <Box className={classes.inputsContainer} sx={{ width: "100%" }}>
                  <Box className={classes.inputLabelAndField}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography className={classes.inputLabel}>
                        Aliases
                      </Typography>
                      <span>{addedAliases?.length ?? 0}/10</span>
                    </Box>
                    <TextField
                      placeholder="Enter Alias"
                      type="text"
                      fullWidth
                      variant="standard"
                      className={classes.inputField}
                      value={currentAlias}
                      onChange={(e) => setCurrentAlias(e.target.value)}
                    />
                    {formik.errors.characterName && (
                      <Typography sx={{ color: "red", fontSize: "10px" }}>
                        {formik.errors.characterName}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Button
                  sx={{
                    width: "100px",
                    border: "2px solid red",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    setAddedAliases(
                      addedAliases?.length
                        ? [...addedAliases, currentAlias]
                        : [currentAlias]
                    );
                    setCurrentAlias("");
                  }}
                >
                  Add
                </Button>
              </Box>
              <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
                {addedAliases?.map((single: string) => (
                  <Box
                    key={single}
                    borderRadius="10px"
                    display="flex"
                    alignItems="center"
                    gap={4}
                    border="2px solid blue"
                    px={2}
                    py={1}
                  >
                    <span>{single}</span>
                    <Button>
                      <X />
                    </Button>
                  </Box>
                ))}
              </Box>
            </form>
          </Grid>
        </Grid>

        <div
          style={{
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <FilledButton
            text="save"
            handleClick={() => formik.handleSubmit()}
            disabled={false}
            isLoading={false}
            fullWidth={false}
            minWidth={200}
          />
          <OutlinedButton
            text="Cancel"
            handleClick={handleClose}
            disabled={false}
            // isLoading={false}
            fullWidth={false}
            minWidth={200}
          />
        </div>
      </Box>
      <Modal
        open={isGenerateImageUsingAi}
        onClose={() => setIsGenerateImageUsingAi(false)}
        className={classes.flexCenter}
      >
        <GenerateCharacterImage
          handleClose={() => setIsGenerateImageUsingAi(false)}
          handleUpdate={() => console.log("updated")}
        />
      </Modal>
    </div>
  );
};

export default CharacterSettingsModal;
