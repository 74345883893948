import { makeStyles } from "@material-ui/core/styles";
import { themeType } from "../../../types/theme-types";

const useStyles = makeStyles((theme: themeType) => ({
  root: {
    width: "450px",
    backgroundColor: "#1B1D28 !important",
    color: "white !important",
    borderRadius: "10px",
    padding: "10px",
  },
}));

export default useStyles;
