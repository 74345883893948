import { makeStyles } from "@material-ui/core/styles";
import { themeType } from "../../../types/theme-types";

const useStyles = makeStyles((theme: themeType) => ({
  container: {
    width: "40%",
    minHeight: "387px",
    borderRadius: "20px",
    backgroundColor: "#1B1D28",
    padding: "18px",
    fontFamily: "Poppins",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  header: {
    textAlign: "center",
    fontFamily: "Poppins !important",
    fontSize: "24px !important",
  },

  leftSideContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },

  selectedStoryBoardStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "4px !important",
    backgroundColor: "#252A38 !important",
    borderRadius: "10px",
    cursor: "pointer",
  },
  dropdownContainer: {
    position: "absolute",
    width: "20%",
    zIndex: 2,
    marginTop: "10px",
    maxHeight: "200px",
    overflowY: "auto",
    backgroundColor: "#252A38 !important",
    borderRadius: "10px",
    paddingRight: "6px",
  },
  selectStoryBoardStyle: {
    display: "flex",
    alignItems: "center",
    paddingRight: "4px",
    gap: "6px",
    marginBottom: "8px",
  },

  rightSideContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },

  line: {
    position: "absolute",
    bottom: "4px",
    left: "110px",
    right: "0",
    height: "2px",
    backgroundColor: "#606479",
  },
  textField: {
    height: "230px",
    width: "100%",
    borderRadius: "10px",
    padding: "10px",
    backgroundColor: "#252A38",
    "& .MuiOutlinedInput-root": {
      color: "white",
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        visibility: "hidden",
      },
    },
  },

  formControl: {
    width: "100%",
    backgroundColor: "#252A38",
    borderRadius: "10px",
  },
  select: {
    color: "white",
    backgroundColor: "transparent",
    border: "1px solid #606479",
    borderRadius: "10px",
    padding: "0 8px",
    "&:focus": {
      border: "none",
    },
  },
  menuItem: {
    color: "#ffffff",
    backgroundColor: "#252A38",
    "&:hover": {
      backgroundColor: "#606479",
    },
  },

  descriptionInput: {
    height: "80px",
    width: "96%",
    backgroundColor: "#252A38",
    border: "none",
    borderRadius: "10px",
    resize: "none",
    outline: "none",
    padding: "6px 10px",
    color: "#606479",
    fontSize: "14px",
    fontFamily: "poppins",
  },
  btnContainer: {
    // marginTop: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "24px",
  },

  cancelButton: {
    minHeight: "33px",
    minWidth: "150px",
    padding: "6px !important",
    borderRadius: "10px !important",
    fontFamily: "Poppins !important",
    border: "1px solid #6C65D9 !important",
    color: " #6C65D9 !important",
  },
  generateButton: {
    minHeight: "33px",
    minWidth: "150px",
    padding: "6px !important",
    borderRadius: "10px !important",
    fontFamily: "Poppins",
    backgroundColor: "#6C65D9 !important",
    color: "white !important",
    "&:hover": {
      backgroundColor: "rgba(108, 101, 217, 0.5)",
    },
  },
}));

export default useStyles;
