import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import StoryBoardOverview from "../../Components/StoryBoardComponent/StoryBoardOverviewi/StoryBoardOverview";

const StoryBoardPage = () => {
  const { projectId } = useParams();
  return (
    <Box sx={{ mx: 4, height: `calc(100vh - 65px)` }}>
      <StoryBoardOverview projectId={projectId || ""} />
    </Box>
  );
};

export default StoryBoardPage;
