import axios from "axios";
import { ProjectIdType } from "../../types/public-types";

const baseUrl = process.env.REACT_APP_BASE_URL;

interface ExportToMovieColabType {
  projectId?: string;
  moviecolabprojectid?: string | number;
  sequenceIds?: string[];
  refreshToken?: string | boolean;
  linkedFile: string;
}

export const exportToMovieColabApi = async (data: ExportToMovieColabType) => {
  const res = await axios.post<any>(
    `${baseUrl + "breakdown/generate-structure"}`,
    data
  );
  return res;
};

export const exportToPdfApi = async (
  projectId: ProjectIdType,
  sequenceId: string
) => {
  const res = await axios.post(`${baseUrl + `sequence/content-export/`}`, {
    projectId: projectId,
    sequenceId: sequenceId,
  });
  return res;
};
