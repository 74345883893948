import { Box, LinearProgress, Typography } from "@mui/material";
import Lottie from "react-lottie";
import CharacterSvg from "../../ReusableComponents/SvgAndIcons/CharacterSvg";

interface Props {
  lottie: any;
  textOne?: string;
  textTwo?: string;
}

const LoadingAnimationWithProgress = ({ lottie, textOne, textTwo }: Props) => {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: lottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Box>
      <Box sx={{ position: "relative" }}>
        <CharacterSvg />
        <Box
          sx={{
            position: "absolute",
            top: "30px",
            right: { md: "10px", lg: "10px", xl: "50px" },
          }}
        >
          <Lottie options={lottieOptions} height={80} width={80} />
        </Box>
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            bottom: "35px",
            zIndex: -1,
          }}
        >
          <LinearProgress />
        </Box>
      </Box>
      <Typography variant="h3" sx={{ textAlign: "center" }}>
        {textOne ?? ""} <br />
        {textTwo ?? ""}
      </Typography>
    </Box>
  );
};

export default LoadingAnimationWithProgress;
