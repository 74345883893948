import { TableCell, TableRow, Typography, Chip, Button } from "@mui/material";
import { Download } from "@phosphor-icons/react";
import { CoinIconXsmall } from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import useStyles from "./PaymentHistoryTable.style";
import { TransactionDetail } from "../../../types/public-types";
import clsx from "clsx";

type Props = {
  eachTransaction: TransactionDetail;
};

const PaymentHistoryTableItems = ({ eachTransaction }: Props) => {
  const classes = useStyles();

  const handleInvoiceLink = (e: any) => {
    e.preventDefault();
    eachTransaction.receipt_url &&
      window.open(eachTransaction.receipt_url, "_blank");
  };

  return (
    <TableRow>
      <TableCell align="left">
        <Typography variant="h6" className={classes.tableCellWhite}>
          {new Intl.DateTimeFormat("en-US", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          }).format(new Date(eachTransaction.date))}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="h6" className={classes.tableCellWhite}>
          {eachTransaction.credit_type}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography
          variant="h6"
          className={clsx(classes.tableCellWhite, classes.wordWraps)}
        >
          {eachTransaction.transactionId}
        </Typography>
        <Button
          startIcon={<Download size={14} />}
          className={classes.text}
          onClick={(e) => handleInvoiceLink(e)}
        >
          Download Invoice
        </Button>
      </TableCell>
      <TableCell
        align="center"
        className={
          eachTransaction.status === "failed"
            ? classes.tableCellsFail
            : classes.tableCellsSuccess
        }
      >
        <Typography variant="h6">{eachTransaction.status}</Typography>
      </TableCell>
      <TableCell
        align="center"
        className={
          eachTransaction.status === "failed"
            ? classes.tableCellsFail
            : classes.tableCellsSuccess
        }
      >
        {eachTransaction.creditQuantity} credits
      </TableCell>
      <TableCell align="right">
        <Chip
          style={{ color: "white", padding: "4px" }}
          avatar={<CoinIconXsmall />}
          label={eachTransaction.total_balance}
          variant="outlined"
        />
      </TableCell>
    </TableRow>
  );
};

export default PaymentHistoryTableItems;
