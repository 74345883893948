export const BreakdownPageViewStyle = {
  page: {
    backgroundColor: "#FFFFFF",
    padding: "21px 36px 18px 36px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
  },
  textStyle: {
    fontSize: "10px",
    fontFamily: "Poppins",
    fontWeight: "500",
    color: "#8D8E93",
    width: "20%",
  },
  textStyleTwo: {
    width: "80%",
    fontSize: "10px",
    fontFamily: "Poppins",
    fontWeight: "500",
    color: "#8D8E93",
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "5px",
  },
};
