import { Box, Grid, Typography } from "@mui/material";
import { Circle, Warning } from "@phosphor-icons/react";
import toast from "react-hot-toast";
import { SequenceListType, SequenceType } from "../../types/public-types";
import Loader from "../ReusableComponents/Loader/Loader";
import { SelectedCircleIcon } from "../ReusableComponents/SvgAndIcons/SvgIcons";
import useStyles from "./ExportPreview.style";
import { MovieColabSingleSequenceType } from "../../types/movie-colab-types";

interface Props {
  sequenceList: SequenceListType;
  selectedSequences?: SequenceType[];
  handleSelectOrUnselectSequence: (sequence: SequenceType) => void;
  selectedForPreview?: SequenceType;
  setSelectedForPreview: (sequence: SequenceType) => void;
  handleSelectOrUnselectAllSequences: () => void;
  isSequencesLoaded: boolean;
  isAllSequenceSelected: boolean;
  movieColabSequences?: MovieColabSingleSequenceType[];
}

const SequenceListOfPreview = ({
  sequenceList,
  selectedSequences,
  handleSelectOrUnselectSequence,
  selectedForPreview,
  setSelectedForPreview,
  handleSelectOrUnselectAllSequences,
  isSequencesLoaded,
  isAllSequenceSelected,
  movieColabSequences,
}: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.sequenceListRoot}>
      {isSequencesLoaded ? (
        <>
          {sequenceList?.length ? (
            <>
              <Box
                className={classes.selectAll}
                onClick={() => handleSelectOrUnselectAllSequences()}
              >
                {isAllSequenceSelected ? (
                  <SelectedCircleIcon />
                ) : (
                  <Circle size={18} />
                )}
                <Typography>
                  {isAllSequenceSelected ? "UnSelect All" : "Select All"}
                </Typography>
              </Box>
              <Box className={classes.sequencesContainer}>
                {sequenceList.map((singleSequence: SequenceType, index) => {
                  const exist = selectedSequences?.some(
                    (sin: SequenceType) => sin.id === singleSequence.id
                  );
                  const isConflicted = movieColabSequences?.some(
                    (single: MovieColabSingleSequenceType) =>
                      single.code === singleSequence.sceneTitle
                  );

                  return (
                    <Box
                      sx={{ py: "6px", borderBottom: "1px solid #1B1D28" }}
                      key={singleSequence.id}
                    >
                      <Box
                        sx={{
                          borderColor: isConflicted ? "red !important" : "",
                        }}
                        className={
                          selectedForPreview?.id === singleSequence.id && exist
                            ? classes.selectedAndPrevSequence
                            : selectedForPreview?.id === singleSequence.id
                            ? classes.previewSequence
                            : exist
                            ? classes.selectedSequence1
                            : classes.singleSequence
                        }
                        onClick={() => setSelectedForPreview(singleSequence)}
                      >
                        <Grid container alignItems="center">
                          <Grid item md={6}>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={(e) => {
                                  if (singleSequence?.breakdownVersion) {
                                    e.stopPropagation();
                                    handleSelectOrUnselectSequence(
                                      singleSequence
                                    );
                                  } else {
                                    e.stopPropagation();
                                    toast.error(
                                      "There is no version to select"
                                    );
                                  }
                                }}
                              >
                                {exist ? (
                                  <SelectedCircleIcon />
                                ) : (
                                  <Circle size={16} />
                                )}
                              </div>
                              <Typography
                                sx={{
                                  fontSize: "10px",
                                  color:
                                    selectedForPreview?.id ===
                                      singleSequence.id || exist
                                      ? "white"
                                      : "",
                                }}
                              >
                                {singleSequence.sceneTitle}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item md={3}>
                            {singleSequence?.breakdownVersion ? (
                              <Typography
                                sx={{ textAlign: "center", fontSize: "12px" }}
                              >
                                Version {singleSequence?.breakdownVersion}
                              </Typography>
                            ) : (
                              <div className={classes.flexCenter}>
                                <Warning size={20} fill="red" />
                              </div>
                            )}
                          </Grid>
                          <Grid item md={3}>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Typography sx={{ fontSize: "12px" }}>
                                Shots :{" "}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  color: exist ? "white" : "",
                                }}
                              >
                                {singleSequence?.breakdownShotCount || 0}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </>
          ) : (
            <p>No Sequences Found</p>
          )}
        </>
      ) : (
        <Loader />
      )}
    </Box>
  );
};

export default SequenceListOfPreview;

