import { Box, Typography } from "@mui/material";
import { CaretLeft, CaretRight, X } from "@phosphor-icons/react";
import Carousel from "react-multi-carousel";
import { SingleShotType } from "../../../types/public-types";
import NoStoryBoard from "../../Utilities/NoStoryboard.png";
import useStyles from "./SingleShotOfStoryBoard.styles";

interface Props {
  shot: SingleShotType;
  selectedImage: string;
  setSelectedImage: (image: string) => void;
  shotStoryBoardImages: string[];
  handleClose: () => void;
}

const FullScreenShotImageModal = ({
  selectedImage,
  shotStoryBoardImages,
  setSelectedImage,
  handleClose,
  shot,
}: Props) => {
  const classes = useStyles();
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 10,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 8,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 6,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        backgroundColor: "#07080ba3",
        padding: "40px",
      }}
    >
      <Box
        className={classes.flexBetween}
        sx={{
          my: 2,
        }}
      >
        <div></div>
        <Typography variant="h6">{shot?.shotNumberText}</Typography>
        <X
          size={30}
          onClick={() => handleClose()}
          style={{ cursor: "pointer" }}
        />
      </Box>
      <Box
        sx={{
          height: { sm: "70%", md: "70%", lg: "70%", xl: "80%" },
          width: "100%",
        }}
        className={classes.flexCenter}
      >
        <img src={selectedImage} alt="" height="100%" width="90%" />
        {shotStoryBoardImages.length > 1 ? (
          <>
            <CaretLeft
              className={classes.leftCaret}
              size={65}
              onClick={() => {
                if (shotStoryBoardImages.indexOf(selectedImage) !== 0) {
                  setSelectedImage(
                    shotStoryBoardImages[
                      shotStoryBoardImages.indexOf(selectedImage) - 1
                    ]
                  );
                }
              }}
            />
            <CaretRight
              className={classes.rightCaret}
              size={65}
              onClick={() => {
                if (
                  shotStoryBoardImages.indexOf(selectedImage) !==
                  shotStoryBoardImages?.length - 1
                ) {
                  setSelectedImage(
                    shotStoryBoardImages[
                      shotStoryBoardImages.indexOf(selectedImage) + 1
                    ]
                  );
                }
              }}
            />
          </>
        ) : (
          " "
        )}
      </Box>
      <Box
        sx={{
          mt: 1,
        }}
        className={classes.flexCenter}
      >
        <div style={{ width: "60%" }}>
          {shotStoryBoardImages.length ? (
            <Carousel
              arrows={false}
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              infinite={false}
              autoPlaySpeed={1000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {shotStoryBoardImages.map((image: string) => (
                <img
                  draggable={false}
                  key={image}
                  onClick={() => setSelectedImage(image)}
                  src={image}
                  alt={NoStoryBoard}
                  width="100px"
                  height="80px"
                  style={{
                    border:
                      selectedImage === image
                        ? "2px solid #6C65D9"
                        : "2px solid transparent",
                    borderRadius: "10px",
                  }}
                />
              ))}
            </Carousel>
          ) : (
            ""
          )}
        </div>
      </Box>
    </Box>
  );
};

export default FullScreenShotImageModal;
