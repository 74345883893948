import { Box } from "@mui/material";
import LogInImage from "../Utilities/ScriptBreakdownLogIn.png";

const RightSideWithLogo = () => {
  return (
    <Box sx={{ height: "100%" }}>
      <img
        src={LogInImage}
        alt=""
        style={{ height: "100%", borderRadius: "10px", width: "100%" }}
      />
    </Box>
  );
};

export default RightSideWithLogo;
