import { makeStyles } from "@material-ui/core/styles";
import { themeType } from "../../../types/theme-types";

const useStyles = makeStyles((theme: themeType) => ({
  root: {
    width: "45%",
    backgroundColor: "#1B1D28 !important",
    color: "#606479",
    borderRadius: "20px",
    padding: "20px",
  },
  cardContents: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  title: {
    color: "white",
    textAlign: "center",
  },

  profilePictureContainer: {
    backgroundColor: "#252A38",
    borderRadius: "10px !important",
    height: "200px !important",
    position: "relative",
  },

  chooseText: {
    position: "relative",
    top: "10px",
    textAlign: "center",
  },

  chooseImageIcon: {
    height: "100% !important",
    position: "absolute",
    top: "25%",
    display: "flex",
    justifyContent: "center",
    width: "100% !important",
    cursor: "pointer",
  },

  image: {
    height: "200px",
    width: "100%",
    borderRadius: "10px",
  },

  imageCrossButton: {
    top: "-10px !important",
    right: "-6px !important",
    backgroundColor: "red !important",
    width: "25px !important",
    minWidth: "0px !important",
    borderRadius: "50% !important",
    color: "white !important",
    padding: "0px !important",
  },

  changeText: {
    backgroundColor: "#484888",
    width: "100% !important",
    textAlign: "center",
    color: "white",
    borderRadius: "0px 0px 10px 10px !important",
    padding: "10px 0px !important",
    fontSize: "10px !important",
    position: "absolute",
    bottom: 0,
    opacity: 0.7,
  },

  rightSide: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100% !important",
  },

  emailText: {
    backgroundColor: "#202430",
    padding: "6px 20px  !important",
    borderRadius: "10px !important",
    color: "#6C65D9",
    marginTop: "10px !important",
  },
  flexBetween: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },

  sendLinkButton: {
    borderRadius: "5px !important",
    border: "2px solid #6C65D9 !important",
    color: "white !important",
    padding: "2px 8px !important",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    justifyContent: "center",
  },
}));

export default useStyles;
