import { Navigate } from "react-router-dom";
import { ROUTES } from "../Routes";
import { readCookie } from "./apiResources";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const token = readCookie("refresh");
  if (!token) {
    return <Navigate to={ROUTES.LOG_IN} replace />;
  }

  return children;
};
