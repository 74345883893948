import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Info, X } from "@phosphor-icons/react";
import useStyles from "./InsufficientCreditModal.style";
import { ROUTES } from "../../../Routes";

type Props = {
  handleCancel: () => void;
};

const InsufficientCreditModal = ({ handleCancel }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Box className={classes.root}>
      <CharacterWithCoinSvg />
      <Box className={classes.card}>
        <Box className={classes.cardContent}>
          <Box className={classes.flexAndGap}>
            <Typography variant="h5" sx={{ fontSize: "22px", fontWeight: 600 }}>
              Oops!
            </Typography>
            <Info weight="fill" size={22} fill="red" />
          </Box>
          <Typography sx={{ textAlign: "center", fontWeight: 500 }}>
            Seems like you’ve run out of credits. Time to get some more!
          </Typography>
          <Box className={classes.buttonDiv}>
            <Button
              className={classes.yesButton}
              type="submit"
              size="small"
              disableElevation
              onClick={(e) => {
                e.stopPropagation();
                navigate(ROUTES.CREDITS_PAGE);
              }}
            >
              Get More Credits
            </Button>
            <Button
              className={classes.noButton}
              type="submit"
              size="small"
              disableElevation
              onClick={(e) => {
                e.stopPropagation();
                handleCancel();
              }}
              startIcon={<X size={16} />}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InsufficientCreditModal;

const CharacterWithCoinSvg = () => {
  return (
    <svg
      width="600"
      height="220"
      viewBox="0 0 548 203"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M527 181.5H528.5V180V13C528.5 6.64873 523.351 1.5 517 1.5H28C21.6487 1.5 16.5 6.64872 16.5 13V180V181.5H18H527Z"
        fill="#C6C4E4"
        stroke="#6C65D9"
        stroke-width="3"
      />
      <rect
        x="197.142"
        y="78.7227"
        width="28.4091"
        height="163.567"
        rx="5"
        transform="rotate(90 197.142 78.7227)"
        fill="#B5B2E4"
      />
      <rect
        x="196.281"
        y="120.906"
        width="28.4091"
        height="163.567"
        rx="5"
        transform="rotate(90 196.281 120.906)"
        fill="#B5B2E4"
      />
      <path
        d="M0 187C0 183.134 3.13401 180 7 180H541C544.866 180 548 183.134 548 187H0Z"
        fill="#B5B2E4"
      />
      <path
        d="M189.5 107.145C203.71 107.145 215.229 95.3448 215.229 80.7883C215.229 66.2319 203.71 54.4316 189.5 54.4316C175.29 54.4316 163.771 66.2319 163.771 80.7883C163.771 95.3448 175.29 107.145 189.5 107.145Z"
        fill="#F4900C"
      />
      <path
        d="M189.5 104.371C203.71 104.371 215.229 92.5703 215.229 78.0139C215.229 63.4575 203.71 51.6572 189.5 51.6572C175.29 51.6572 163.771 63.4575 163.771 78.0139C163.771 92.5703 175.29 104.371 189.5 104.371Z"
        fill="#FFCC4D"
      />
      <path
        d="M190.177 101.596C201.769 101.596 211.167 91.6592 211.167 79.4012C211.167 67.1431 201.769 57.2061 190.177 57.2061C178.585 57.2061 169.188 67.1431 169.188 79.4012C169.188 91.6592 178.585 101.596 190.177 101.596Z"
        fill="#FFE8B6"
      />
      <path
        d="M190.177 98.8219C201.769 98.8219 211.167 89.1953 211.167 77.3204C211.167 65.4454 201.769 55.8188 190.177 55.8188C178.585 55.8188 169.188 65.4454 169.188 77.3204C169.188 89.1953 178.585 98.8219 190.177 98.8219Z"
        fill="#FFAC33"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M180.684 68.7563L190 77.0962L199.316 68.7563V84.5113H194.895V78.3414L190 82.7233L185.105 78.3414V84.5113H180.684V68.7563Z"
        fill="#F9F9F9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M175 86V68H179.421V86H175Z"
        fill="#F9F9F9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M200.579 86V68H205V86H200.579Z"
        fill="#F9F9F9"
      />
      <path
        d="M35.8198 156.257C40.2073 121.933 80.8366 110.506 100.046 110.066C169.551 104.787 166.092 148.754 166.239 180.134L100.692 180.134L35.1464 180.134C35.147 171.336 34.8482 163.858 35.8198 156.257Z"
        fill="#6C65D9"
      />
      <path
        d="M56.7013 117.668C61.2764 98.3122 84.7087 94.353 95.853 94.793C116.265 88.4583 130.459 100.072 135.005 106.67C140.87 114.735 142.659 131.569 102.892 134.385C63.124 137.2 55.5283 124.413 56.7013 117.668Z"
        fill="#A09AFB"
      />
      <path
        d="M76.4961 102.71C83.5346 98.4868 93.7991 98.6041 98.0516 99.1906C107.436 100.364 124.27 104.821 116.528 113.268C108.785 121.714 93.6526 118.547 87.0539 115.907C80.6019 113.268 69.4576 106.933 76.4961 102.71Z"
        fill="#252A38"
      />
      <path
        d="M84.8549 115.029L84.415 91.7139L109.05 99.6322L110.37 117.228C103.331 120.748 90.1338 117.668 84.8549 115.029Z"
        fill="#E5986D"
      />
      <path
        d="M109.929 109.75C101.483 111.157 92.9193 104.178 89.6934 100.512L109.489 103.151L109.929 109.75Z"
        fill="#252A38"
      />
      <path
        d="M117.552 76.1612C119.767 90.3233 117.716 102.908 107.73 104.47C90.5732 107.153 83.602 95.9801 81.3869 81.818C79.1717 67.6559 85.4718 54.909 95.4585 53.3469C105.445 51.7848 115.337 61.9992 117.552 76.1612Z"
        fill="#E6986D"
      />
      <path
        d="M107.001 69.5329C108.005 68.672 110.531 67.4668 112.597 69.5329"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M98.3916 74.3071L98.822 78.6115"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M110.875 73.0161L111.305 77.3205"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M107.001 77.751C108.005 78.7553 109.842 82.0554 109.153 87.2207"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M94.9482 72.116C95.5222 71.2552 97.3587 69.7056 100.114 70.3943"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M89.9942 79.0357C90.7893 84.1188 90.0532 88.6355 86.4688 89.1962C80.311 90.1593 77.8089 86.1491 77.0138 81.066C76.2187 75.9829 78.48 71.4078 82.0644 70.8471C85.6489 70.2865 89.1992 73.9526 89.9942 79.0357Z"
        fill="#E6986D"
      />
      <path
        d="M80.4131 80.3892C80.4131 81.7089 80.853 84.3483 82.6126 84.3483"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M111.999 95C111.999 95.4431 111.762 95.9205 111.227 96.3213C110.693 96.7224 109.908 97 108.999 97C108.09 97 107.305 96.7224 106.771 96.3213C106.236 95.9205 105.999 95.4431 105.999 95C105.999 94.5569 106.236 94.0795 106.771 93.6787C107.305 93.2776 108.09 93 108.999 93C109.908 93 110.693 93.2776 111.227 93.6787C111.762 94.0795 111.999 94.5569 111.999 95Z"
        stroke="#1B1D28"
        stroke-width="2"
      />
      <path
        d="M124.887 54.3208C119.608 64.1747 108.317 60.7728 103.331 57.84C100.516 67.3419 91.0141 67.9579 86.6152 67.0782C88.6058 71.0593 88.4413 75.2751 87.8631 77.9218C87.5541 79.3362 86.0707 79.9062 84.7151 79.3979L80.0166 77.6359C73.6819 80.8033 67.8457 76.3162 66.3793 73.6768C62.1562 62.4152 71.5114 57.5469 75.6172 56.9603C76.6729 48.1621 80.1629 44.2029 82.2158 42.8832C95.9409 33.7332 101.425 39.9505 102.452 44.2029C104.915 42.0914 110.223 41.2702 112.569 41.1236C118.875 41.4169 130.166 44.4669 124.887 54.3208Z"
        fill="#1B1D28"
      />
      <path
        d="M65.499 129.985C69.7515 132.038 81.2477 135.88 93.2131 134.824"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M135.444 123.827C133.685 126.467 128.846 129.546 122.247 130.866"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M93.6543 146.703C93.6543 146.703 95.4139 159.9 93.6543 164.739"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M114.33 144.502C114.33 144.502 116.53 153.74 121.808 160.339"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M112.087 179.809C108.128 182.448 102.996 183.621 102.409 185.527L88.332 177.17C106.808 169.251 109.448 177.609 112.087 179.809Z"
        fill="#E5986D"
      />
      <path
        d="M50.9401 202.682C37.7429 200.042 35.1034 185.966 35.1035 178.048L54.8993 178.927L84.8129 174.528C97.4822 173.824 103.729 181.127 104.169 185.966C104.609 190.805 64.1373 205.323 50.9401 202.682Z"
        fill="#6C65D9"
      />
      <path
        d="M99.3301 178.489C101.236 179.808 105.049 183.416 105.049 187.287"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M83.0536 176.289C72.0559 178.489 63.2578 176.729 63.2578 176.729"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M171.8 114.806L167.824 120.024C167.078 121.003 167.384 122.417 168.469 122.999L176.627 127.378C177.119 127.642 177.7 127.689 178.227 127.506L205.956 117.909C208.087 117.171 207.557 114.019 205.302 114.019H173.391C172.767 114.019 172.179 114.31 171.8 114.806Z"
        fill="#E5986D"
      />
      <path
        d="M184.664 131.728C176.254 141.916 169.66 161.628 165.941 173.915C165.255 176.182 162.775 177.379 160.581 176.486L142.384 169.084C140.59 168.354 139.566 166.441 140.056 164.567C145.196 144.935 158.565 126.703 166.464 117.965C167.676 116.624 169.648 116.355 171.224 117.241L183.698 124.258C186.259 125.699 186.535 129.461 184.664 131.728Z"
        fill="#6C64D9"
      />
      <path
        d="M157.793 127.362C154.78 131.666 149.615 139.414 147.463 148.023"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M180.176 122.627L185.341 125.64"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};
