import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { CoinIconXsmall } from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import { promptList } from "../../Utilities/MidjourneyPromptList";
import useStyles from "./SequenceThumbnailGenerateModal.styles";

type Props = {
  handleSequenceThumbnailModal: () => void;
  shotDescription: string;
  handleSubmit: (promptStyleIndex: any, description: string) => void;
  shotListLength: number;
  isThumbnailGenerating: boolean;
};

const SequenceThumbnailGenerateModal = ({
  handleSequenceThumbnailModal,
  handleSubmit,
  shotDescription,
  shotListLength,
  isThumbnailGenerating,
}: Props) => {
  const classes = useStyles();

  const noImageUrl =
    "https://images.unsplash.com/photo-1692607038324-6957c392410c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1932&q=80";

  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const [description, setDescription] = useState<string>(shotDescription || "");

  return (
    <Container className={classes.container}>
      <Typography className={classes.header}>
        Re-Generate Sequence Thumbnails
      </Typography>
      <Box className={classes.containerTwo}>
        <Box>
          <Typography className={classes.labelText}>Description</Typography>
          <TextField
            autoFocus={false}
            placeholder="Add your description here"
            className={classes.textField}
            fullWidth
            multiline
            rows={2}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </Box>
        <Box>
          <Typography className={classes.labelText}>
            Story Board Style (choose one style)
          </Typography>
          <Box className={classes.styleBoardContainer}>
            {promptList.map((prompt, index) => {
              return (
                <div
                  className={
                    selectedPrompt?.id === prompt.id
                      ? classes.selectedStyleBoard
                      : classes.styleBoard
                  }
                  key={prompt.id}
                  onClick={() => setSelectedPrompt(prompt)}
                >
                  <img
                    src={prompt?.image ? prompt.image : noImageUrl}
                    className={classes.promptImage}
                  />
                  <Typography className={classes.promptName}>
                    {prompt.name}
                  </Typography>
                </div>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box className={classes.buttonContainer}>
        <Button
          className={classes.cancelButton}
          onClick={handleSequenceThumbnailModal}
        >
          Cancel
        </Button>
        <Button
          className={classes.generateButton}
          endIcon={
            !isThumbnailGenerating && (
              <Chip
                style={{ color: "white", padding: "4px" }}
                avatar={<CoinIconXsmall />}
                label={shotListLength}
                variant="outlined"
              />
            )
          }
          sx={{
            backgroundColor: isThumbnailGenerating
              ? "#606479"
              : "#6C65D9 !important",
          }}
          onClick={() => handleSubmit(selectedPrompt, description)}
          disabled={isThumbnailGenerating}
        >
          {isThumbnailGenerating ? <CircularProgress size={20} /> : "Generate"}
        </Button>
      </Box>
    </Container>
  );
};

export default SequenceThumbnailGenerateModal;
