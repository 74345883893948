export const DownloadIconData = {
  nm: "Frame 551",
  ddd: 0,
  h: 87.77,
  w: 87.82,
  meta: { g: "LottieFiles Figma v45" },
  layers: [
    {
      ty: 4,
      nm: "vector",
      sr: 1,
      st: 0,
      op: 121,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [24.68, 31.4], t: 0 },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [24.68, 31.4],
              t: 60,
            },
            { s: [24.68, 31.4], t: 120 },
          ],
        },
        s: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100, 100], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100, 100], t: 60 },
            { s: [100, 100], t: 120 },
          ],
        },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [43.91, 31.4], t: 0 },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [43.91, 40.4],
              t: 60,
            },
            { s: [43.91, 31.4], t: 120 },
          ],
        },
        r: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 60 },
            { s: [0], t: 120 },
          ],
        },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 60 },
            { s: [100], t: 120 },
          ],
        },
      },
      shapes: [
        {
          ty: "sh",
          bm: 0,
          hd: false,
          nm: "",
          d: 1,
          ks: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.66, 0],
                      [0, 0],
                      [0, -1.66],
                      [0, 0],
                      [0, 0],
                      [-1.17, -1.21],
                      [0, 0],
                      [1.16, -1.16],
                      [0, 0],
                      [1.17, 1.17],
                      [0, 0],
                      [-1.14, 1.18],
                      [0, 0],
                      [-1.19, -1.19],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, -1.66],
                      [0, 0],
                      [1.66, 0],
                      [0, 0],
                      [0, 0],
                      [1.19, -1.19],
                      [0, 0],
                      [1.14, 1.18],
                      [0, 0],
                      [-1.17, 1.17],
                      [0, 0],
                      [-1.16, -1.16],
                      [0, 0],
                      [1.17, -1.21],
                      [0, 0],
                    ],
                    v: [
                      [6.21, 34.86],
                      [20.94, 49.6],
                      [20.94, 3],
                      [23.94, 0],
                      [25.42, 0],
                      [28.42, 3],
                      [28.42, 49.6],
                      [43.16, 34.86],
                      [47.44, 34.9],
                      [48.52, 36.02],
                      [48.49, 40.23],
                      [26.8, 61.91],
                      [22.56, 61.91],
                      [0.88, 40.23],
                      [0.84, 36.02],
                      [1.93, 34.9],
                      [6.21, 34.86],
                    ],
                  },
                ],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.66, 0],
                      [0, 0],
                      [0, -1.66],
                      [0, 0],
                      [0, 0],
                      [-1.17, -1.21],
                      [0, 0],
                      [1.16, -1.16],
                      [0, 0],
                      [1.17, 1.17],
                      [0, 0],
                      [-1.14, 1.18],
                      [0, 0],
                      [-1.19, -1.19],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, -1.66],
                      [0, 0],
                      [1.66, 0],
                      [0, 0],
                      [0, 0],
                      [1.19, -1.19],
                      [0, 0],
                      [1.14, 1.18],
                      [0, 0],
                      [-1.17, 1.17],
                      [0, 0],
                      [-1.16, -1.16],
                      [0, 0],
                      [1.17, -1.21],
                      [0, 0],
                    ],
                    v: [
                      [6.21, 34.86],
                      [20.94, 49.6],
                      [20.94, 3],
                      [23.94, 0],
                      [25.42, 0],
                      [28.42, 3],
                      [28.42, 49.6],
                      [43.16, 34.86],
                      [47.44, 34.9],
                      [48.52, 36.02],
                      [48.49, 40.23],
                      [26.8, 61.91],
                      [22.56, 61.91],
                      [0.88, 40.23],
                      [0.84, 36.02],
                      [1.93, 34.9],
                      [6.21, 34.86],
                    ],
                  },
                ],
                t: 60,
              },
              {
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.66, 0],
                      [0, 0],
                      [0, -1.66],
                      [0, 0],
                      [0, 0],
                      [-1.17, -1.21],
                      [0, 0],
                      [1.16, -1.16],
                      [0, 0],
                      [1.17, 1.17],
                      [0, 0],
                      [-1.14, 1.18],
                      [0, 0],
                      [-1.19, -1.19],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, -1.66],
                      [0, 0],
                      [1.66, 0],
                      [0, 0],
                      [0, 0],
                      [1.19, -1.19],
                      [0, 0],
                      [1.14, 1.18],
                      [0, 0],
                      [-1.17, 1.17],
                      [0, 0],
                      [-1.16, -1.16],
                      [0, 0],
                      [1.17, -1.21],
                      [0, 0],
                    ],
                    v: [
                      [6.21, 34.86],
                      [20.94, 49.6],
                      [20.94, 3],
                      [23.94, 0],
                      [25.42, 0],
                      [28.42, 3],
                      [28.42, 49.6],
                      [43.16, 34.86],
                      [47.44, 34.9],
                      [48.52, 36.02],
                      [48.49, 40.23],
                      [26.8, 61.91],
                      [22.56, 61.91],
                      [0.88, 40.23],
                      [0.84, 36.02],
                      [1.93, 34.9],
                      [6.21, 34.86],
                    ],
                  },
                ],
                t: 120,
              },
            ],
          },
        },
        {
          ty: "fl",
          bm: 0,
          hd: false,
          nm: "",
          c: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [0.9765, 0.9765, 0.9765],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [0.9765, 0.9765, 0.9765],
                t: 60,
              },
              { s: [0.9765, 0.9765, 0.9765], t: 120 },
            ],
          },
          r: 1,
          o: {
            a: 1,
            k: [
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 60 },
              { s: [100], t: 120 },
            ],
          },
        },
      ],
      ind: 1,
    },
    {
      ty: 4,
      nm: "Subtract",
      sr: 1,
      st: 0,
      op: 121,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: {
          a: 1,
          k: [
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [43.91, 12.35],
              t: 0,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [43.91, 12.35],
              t: 60,
            },
            { s: [43.91, 12.35], t: 120 },
          ],
        },
        s: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100, 100], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100, 100], t: 60 },
            { s: [100, 100], t: 120 },
          ],
        },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [43.91, 75.42],
              t: 0,
            },
            {
              o: { x: 0, y: 0 },
              i: { x: 0.58, y: 1 },
              s: [43.91, 75.42],
              t: 60,
            },
            { s: [43.91, 75.42], t: 120 },
          ],
        },
        r: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [0], t: 60 },
            { s: [0], t: 120 },
          ],
        },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
            { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 60 },
            { s: [100], t: 120 },
          ],
        },
      },
      shapes: [
        {
          ty: "sh",
          bm: 0,
          hd: false,
          nm: "",
          d: 1,
          ks: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [1.88, 1.88],
                      [0, 2.62],
                      [0, 0],
                      [-1.66, 0],
                      [0, 0],
                      [0, -1.66],
                      [0, 0],
                      [-3.87, 0],
                      [0, 0],
                      [0, 3.87],
                      [0, 0],
                      [-1.66, 0],
                      [0, 0],
                      [0, -1.66],
                      [0, 0],
                      [1.88, -1.88],
                      [2.62, 0],
                      [0, 0],
                    ],
                    o: [
                      [-2.62, 0],
                      [-1.88, -1.88],
                      [0, 0],
                      [0, -1.66],
                      [0, 0],
                      [1.66, 0],
                      [0, 0],
                      [0, 3.87],
                      [0, 0],
                      [3.87, 0],
                      [0, 0],
                      [0, -1.66],
                      [0, 0],
                      [1.66, 0],
                      [0, 0],
                      [0, 2.62],
                      [-1.88, 1.88],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [9.48, 24.69],
                      [2.79, 21.91],
                      [0, 15.22],
                      [0, 3],
                      [3, 0],
                      [4.48, 0],
                      [7.48, 3],
                      [7.48, 10.22],
                      [14.48, 17.22],
                      [73.34, 17.22],
                      [80.34, 10.22],
                      [80.34, 3],
                      [83.34, 0],
                      [84.82, 0],
                      [87.82, 3],
                      [87.82, 15.22],
                      [85.03, 21.91],
                      [78.34, 24.69],
                      [9.48, 24.69],
                    ],
                  },
                ],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [1.88, 1.88],
                      [0, 2.62],
                      [0, 0],
                      [-1.66, 0],
                      [0, 0],
                      [0, -1.66],
                      [0, 0],
                      [-3.87, 0],
                      [0, 0],
                      [0, 3.87],
                      [0, 0],
                      [-1.66, 0],
                      [0, 0],
                      [0, -1.66],
                      [0, 0],
                      [1.88, -1.88],
                      [2.62, 0],
                      [0, 0],
                    ],
                    o: [
                      [-2.62, 0],
                      [-1.88, -1.88],
                      [0, 0],
                      [0, -1.66],
                      [0, 0],
                      [1.66, 0],
                      [0, 0],
                      [0, 3.87],
                      [0, 0],
                      [3.87, 0],
                      [0, 0],
                      [0, -1.66],
                      [0, 0],
                      [1.66, 0],
                      [0, 0],
                      [0, 2.62],
                      [-1.88, 1.88],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [9.48, 24.69],
                      [2.79, 21.91],
                      [0, 15.22],
                      [0, 3],
                      [3, 0],
                      [4.48, 0],
                      [7.48, 3],
                      [7.48, 10.22],
                      [14.48, 17.22],
                      [73.34, 17.22],
                      [80.34, 10.22],
                      [80.34, 3],
                      [83.34, 0],
                      [84.82, 0],
                      [87.82, 3],
                      [87.82, 15.22],
                      [85.03, 21.91],
                      [78.34, 24.69],
                      [9.48, 24.69],
                    ],
                  },
                ],
                t: 60,
              },
              {
                s: [
                  {
                    c: true,
                    i: [
                      [0, 0],
                      [1.88, 1.88],
                      [0, 2.62],
                      [0, 0],
                      [-1.66, 0],
                      [0, 0],
                      [0, -1.66],
                      [0, 0],
                      [-3.87, 0],
                      [0, 0],
                      [0, 3.87],
                      [0, 0],
                      [-1.66, 0],
                      [0, 0],
                      [0, -1.66],
                      [0, 0],
                      [1.88, -1.88],
                      [2.62, 0],
                      [0, 0],
                    ],
                    o: [
                      [-2.62, 0],
                      [-1.88, -1.88],
                      [0, 0],
                      [0, -1.66],
                      [0, 0],
                      [1.66, 0],
                      [0, 0],
                      [0, 3.87],
                      [0, 0],
                      [3.87, 0],
                      [0, 0],
                      [0, -1.66],
                      [0, 0],
                      [1.66, 0],
                      [0, 0],
                      [0, 2.62],
                      [-1.88, 1.88],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [9.48, 24.69],
                      [2.79, 21.91],
                      [0, 15.22],
                      [0, 3],
                      [3, 0],
                      [4.48, 0],
                      [7.48, 3],
                      [7.48, 10.22],
                      [14.48, 17.22],
                      [73.34, 17.22],
                      [80.34, 10.22],
                      [80.34, 3],
                      [83.34, 0],
                      [84.82, 0],
                      [87.82, 3],
                      [87.82, 15.22],
                      [85.03, 21.91],
                      [78.34, 24.69],
                      [9.48, 24.69],
                    ],
                  },
                ],
                t: 120,
              },
            ],
          },
        },
        {
          ty: "fl",
          bm: 0,
          hd: false,
          nm: "",
          c: {
            a: 1,
            k: [
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [0.9765, 0.9765, 0.9765],
                t: 0,
              },
              {
                o: { x: 0, y: 0 },
                i: { x: 0.58, y: 1 },
                s: [0.9765, 0.9765, 0.9765],
                t: 60,
              },
              { s: [0.9765, 0.9765, 0.9765], t: 120 },
            ],
          },
          r: 1,
          o: {
            a: 1,
            k: [
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 0 },
              { o: { x: 0, y: 0 }, i: { x: 0.58, y: 1 }, s: [100], t: 60 },
              { s: [100], t: 120 },
            ],
          },
        },
      ],
      ind: 2,
    },
  ],
  v: "5.7.0",
  fr: 60,
  op: 120,
  ip: 0,
  assets: [],
};
