import {
  Button,
  Chip,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Check, MagnifyingGlass, X } from "@phosphor-icons/react";
import { useState } from "react";
import {
  FilledButton,
  OutlinedButton,
} from "../../ReusableComponents/Buttons/Buttons";
import useStyles from "./Breakdown.style";
import { GenerateNewBreakdownSvg } from "../../ReusableComponents/SvgAndIcons/GenerateNewBreakdownSvg";
import { CoinIconXsmallForShots } from "../../ReusableComponents/SvgAndIcons/SvgIcons";

const SelectGenresModal = () => {
  const classes = useStyles();
  const [selectedGenre, setSelectedGenre] = useState<string>("");
  const genres = [
    "thriller",
    "drama",
    "action",
    "comedy",
    "romance",
    "animation",
    "horror",
  ];
  return (
    <div className={classes.selectGenreRoot}>
      <Grid container spacing={2}>
        <Grid item md={2}></Grid>
        <Grid item md={8}>
          <Typography variant="h5" textAlign="center">
            Select Genres
          </Typography>
        </Grid>
        <Grid item md={2} alignItems="flex-end">
          <X />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={2}>
        <Grid item md={6} display="flex" flexDirection="column" gap={1}>
          <Typography>Select Genres</Typography>
          <TextField
            placeholder="Search"
            type="text"
            name="email"
            fullWidth
            variant="standard"
            className={classes.inputField}
            // value={formik.values.email}
            // onChange={formik.handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MagnifyingGlass
                    size={22}
                    fill="#606479"
                    style={{ marginLeft: "8px" }}
                  />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
          />
          <div
            style={{
              backgroundColor: "#252A38",
              padding: "8px 16px",
              borderRadius: "5px 0px 0px 5px",
            }}
          >
            {genres.map((sin) => (
              <Button
                fullWidth
                key={sin}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                onClick={() =>
                  selectedGenre === sin
                    ? setSelectedGenre("")
                    : setSelectedGenre(sin)
                }
              >
                <Typography color="white">{sin}</Typography>
                {selectedGenre === sin ? <Check size={22} /> : ""}
              </Button>
            ))}
          </div>
        </Grid>
        <Grid item md={6} display="flex" flexDirection="column" gap={1}>
          <Typography>Genre/s Selected</Typography>
          <div
            style={{
              backgroundColor: "#252A38",
              padding: "8px 16px",
              borderRadius: "5px 0px 0px 5px",
            }}
          >
            <Typography>{selectedGenre}</Typography>
          </div>
        </Grid>
      </Grid>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <FilledButton
          text="save"
          handleClick={() => console.log("clk")}
          disabled={false}
          isLoading={false}
          fullWidth={false}
          minWidth={100}
        />
        <OutlinedButton
          text="save"
          handleClick={() => console.log("clk")}
          disabled={false}
          // isLoading={false}
          fullWidth={false}
          minWidth={200}
          endIcon={
            <div
              style={{
                backgroundColor: "#6C65D9",
                display: "flex",
                alignItems: "center",
                padding: "1px 20px",
                borderRadius: "5px",
              }}
            >
              <GenerateNewBreakdownSvg />
              <Chip
                sx={{
                  height: { md: "22px", lg: "22px", xl: "30px" },
                  borderRadius: "27px",
                  pl: "2px",
                }}
                avatar={<CoinIconXsmallForShots />}
                label="2"
                variant="outlined"
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default SelectGenresModal;
