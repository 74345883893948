import { Box, Grid, Modal } from "@mui/material";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import React, { useContext, useState } from "react";
import { toast } from "react-hot-toast";
import { pdfjs } from "react-pdf";
import {
  ContextTypes,
  ScriptBreakdownContext,
} from "../../../Context/ContextDataProvider";
import {
  uploadFile,
  validateUpload,
} from "../../../Services/fileServices/fileServices";
import {
  LinkedFileType,
  ProjectIdType,
  SequenceType,
  SingleProjectDetails,
} from "../../../types/public-types";
import Loader from "../../ReusableComponents/Loader/Loader";
import { ScanningIconData } from "../../Utilities/Lotties/ScanningIconData";
import LoadingAnimationWithProgress from "../../Utilities/Modals/LoadingAnimationWithProgress";
import BreakDown from "../BreakDown/BreakDown";
import ScriptBreakdownPreview from "../ScriptBreakdownPreview/ScriptBreakdownPreview";
import ScriptBreakdownXMLPreview from "../ScriptBreakdownPreview/ScriptBreakdownXMLPreview";
import ScriptFileInput from "../ScriptFileInput/ScriptFileInput";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {
  projectId: ProjectIdType;
  setProjectName: (name: string) => void;
};

const ProjectDetailsOverview: React.FC<Props> = ({
  projectId,
  setProjectName,
}: Props) => {
  const storage = getStorage();
  const {
    handleGetProjectDetails,
    projectDetailsData,
    isProjectDetailsLoading,
  }: ContextTypes = useContext(ScriptBreakdownContext);

  //states
  const [numPages, setNumPages] = useState<number>(0);
  const [pdfFileUrl, setPdfFileUrl] = useState<string>("");
  const [linkedFile, setLinkedFile] = useState<LinkedFileType>();
  const [isFileSelecting, setIsFileSelecting] = useState<boolean>(false);
  const [isPdfValid, setIsPdfValid] = useState<boolean>(false);
  const [showInvalidFileModal, setShowInvalidFileModal] =
    useState<boolean>(false);
  const [isFileLoaded, setIsFileLoaded] = useState<boolean>(false);
  //states for storing scene xmls
  const [fileRenderType, setFileRenderType] = useState<string>("pdf"); //could be pdf or xml
  const [scriptXMLContent, setScriptXMLContent] = useState<any | string>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };
  const [isInitialLoading, setIsInitialLoading] = useState<Boolean>(true);
  const [selectedSequencePages, setSelectedSequencePages] = useState<number[]>(
    []
  );
  const [selectedSequence, setSelectedSequence] = useState<SequenceType | null>(
    null
  );

  const [refReshCounter, setRefreshCounter] = useState(0);

  const updateRefreshCounter = () => {
    setRefreshCounter(refReshCounter + 1);
  };

  /* const handleGetProjectDetailsFunction = async () => {
    setIsInitialLoading(true);
    try {
      const projectDetails = await handleGetProjectDetails(projectId);
      handleSetAll(projectDetails);
    } catch (error) {
      toast.error("error getting details");
      setIsInitialLoading(false);
      setIsFileLoaded(true);
      setLinkedFile(undefined);
    }
  }; */

  const handleSetAll = (projectDetails: SingleProjectDetails) => {
    setProjectName(projectDetails?.name);
    if (!projectDetails?.linkedFile?.isValid) {
      //the file is invalid upload again
      setIsPdfValid(false);
      setShowInvalidFileModal(true);
      setIsInitialLoading(false);
      setIsFileLoaded(false);
      setLinkedFile(undefined);
      setIsPdfValid(false);
    }
    if (projectDetails?.linkedFile) {
      //check the file type...
      //if fountain file is available then use fountain file only
      setLinkedFile(projectDetails.linkedFile);
      setIsInitialLoading(false);
      localStorage.setItem("fileId", projectDetails?.linkedFile?.fileId);

      if (projectDetails.linkedFile.fountainFilePath) {
        //get the tokens from file and set that for render
        setScriptXMLContent(projectDetails.linkedFile.html);
        setFileRenderType("xml");
        setIsFileLoaded(true);
        setIsPdfValid(true);
      } else {
        const fileRef = ref(storage, projectDetails.linkedFile.filePath);
        getDownloadURL(fileRef)
          .then((url) => {
            setPdfFileUrl(url);
            setIsFileLoaded(true);
          })
          .catch((error) => {
            console.error("Error getting download URL:", error);
            setIsInitialLoading(false);
          });
      }
    } else {
      setIsInitialLoading(false);
      setIsFileLoaded(true);
      setLinkedFile(undefined);
      setIsPdfValid(false);
    }
  };

  React.useEffect(() => {
    if (projectDetailsData?.id !== projectId) {
      setIsInitialLoading(true);
      handleGetProjectDetails(projectId);
    } else {
      if (!isProjectDetailsLoading && projectDetailsData?.details) {
        handleSetAll(projectDetailsData?.details);
      } else {
        setIsInitialLoading(false);
        setIsFileLoaded(true);
        setLinkedFile(undefined);
      }
    }
  }, [projectId, refReshCounter, isProjectDetailsLoading, projectDetailsData]);

  /*          <***      Select a file        ***> */
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // get the file type and if it is fountain or pdf based on that curate result
    const selectedFile = event.target.files?.[0];
    let fileName = "";
    if (selectedFile) {
      setIsFileSelecting(true);
      setNumPages(0);
      const fileType = selectedFile.name.split(".").pop() || "";
      fileName = selectedFile.name.split(".")[0];

      // Extract text from the PDF file
      setIsPdfValid(false);

      handleGenerateFileUrlToUpload(
        await selectedFile.arrayBuffer(),
        fileName,
        fileType
      );
    }
  };

  /*          <***      file upload process end        ***> */
  const handleGenerateFileUrlToUpload = async (
    bufferData: ArrayBuffer,
    name: string,
    fileType: string
  ) => {
    try {
      const res = await uploadFile({
        fileName: name.replace(`${fileType}`, ""),
        projectId: projectId,
        fileType: fileType,
      });
      toast.success("File Path Created successfully");
      if (fileType === "fountain") {
        // get the fountain file path and upload there automatically
        handleUploadFileInFirebase(
          res.data.data.fountainFilePath,
          bufferData,
          res.data.data.fileId,
          fileType
        );
      } else {
        handleUploadFileInFirebase(
          res.data.data.filePath,
          bufferData,
          res.data.data.fileId,
          fileType
        );
      }
    } catch (error) {
      toast.error("error on file path creation");
      setIsFileSelecting(false);
    }
  };

  const handleUploadFileInFirebase = async (
    filePath: string,
    bufferData: ArrayBuffer,
    fileId: string,
    fileType: string
  ) => {
    const fileRef = ref(storage, filePath);
    const contentType =
      fileType === "fountain" ? "text/plain" : "application/pdf";
    try {
      await uploadBytes(fileRef, bufferData, {
        contentType,
      });
      toast.success("File Uploaded");
      handleValidateFileUpload(filePath, fileId);
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsFileSelecting(false);
    }
  };

  const handleValidateFileUpload = async (filePath: string, fileId: string) => {
    try {
      const res = await validateUpload(filePath, fileId, projectId);
      if (res?.status === 200) {
        toast.success("File validated");
        setIsFileSelecting(false);
      } else {
        setIsFileSelecting(false);
      }
      // setIsFileLoaded(true);
      handleGetProjectDetails(projectId);
    } catch (error) {
      toast.error("Invalid file");
      setIsFileSelecting(false);
      setIsPdfValid(false);
    }
  };
  /*          <***      file upload process end        ***> */

  return (
    <Box sx={{ /* mx: 10, */ height: "100%" }}>
      {!isInitialLoading && isFileLoaded ? (
        <>
          {fileRenderType === "pdf" && pdfFileUrl && isPdfValid ? (
            <Grid
              container
              rowSpacing={1}
              columnSpacing={0}
              sx={{ height: "100%" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                sx={{ height: { sm: "90%", md: "90%", lg: "90%", xl: "100%" } }}
              >
                <ScriptBreakdownPreview
                  noOfPages={numPages}
                  onDocumentLoadSuccess={onDocumentLoadSuccess}
                  pdfFile={pdfFileUrl}
                  isBreakDownGenerated={true}
                  selectedPages={selectedSequencePages}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={8}
                xl={8}
                sx={{ height: "100%" }}
              >
                <BreakDown
                  projectId={projectId}
                  fileId={linkedFile?.fileId ? linkedFile?.fileId : ""}
                  setSelectedSequencePages={setSelectedSequencePages}
                  setSelectedSequence={setSelectedSequence}
                  selectedSequence={selectedSequence}
                />
              </Grid>
            </Grid>
          ) : fileRenderType === "xml" && scriptXMLContent && isPdfValid ? (
            <Grid
              container
              rowSpacing={1}
              columnSpacing={0}
              sx={{ height: "100%" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                sx={{ height: { sm: "90%", md: "90%", lg: "90%", xl: "100%" } }}
              >
                <ScriptBreakdownXMLPreview
                  scriptXMLContent={scriptXMLContent}
                  fileRenderType={fileRenderType}
                  isBreakDownGenerated={true}
                  selectedSequence={selectedSequence}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={8}
                xl={8}
                sx={{ height: "100%" }}
              >
                <BreakDown
                  projectId={projectId}
                  fileId={linkedFile?.fileId ? linkedFile?.fileId : ""}
                  setSelectedSequencePages={setSelectedSequencePages}
                  setSelectedSequence={setSelectedSequence}
                  selectedSequence={selectedSequence}
                />
              </Grid>
            </Grid>
          ) : (
            <ScriptFileInput
              handleFileChange={handleFileChange}
              showInvalidFileModal={showInvalidFileModal}
              setShowInvalidFileModal={setShowInvalidFileModal}
              projectId={projectId}
              updateRefreshCounter={updateRefreshCounter}
            />
          )}
          <Modal open={isFileSelecting}>
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "20px",
                justifyContent: "center",
              }}
            >
              <LoadingAnimationWithProgress
                lottie={ScanningIconData}
                textOne="Please wait, while we go"
                textTwo="through your script."
              />
            </div>
          </Modal>
        </>
      ) : (
        <Loader />
      )}
    </Box>
  );
};

export default ProjectDetailsOverview;
