import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import ExportPreviewOverview from "../../Components/ExportPreviewComponents/ExportPreviewOverview";

const ExportPreviewPage = () => {
  const { projectId } = useParams();

  return (
    <Box sx={{ mx: 4, height: `calc(100vh - 65px)` }}>
      <ExportPreviewOverview projectId={projectId ? projectId : ""} />
    </Box>
  );
};

export default ExportPreviewPage;
