import { Box, Button, Grid, Radio, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { SequenceType } from "../../../types/public-types";
import ResponsivePhosphorIcon from "../../ReusableComponents/SvgAndIcons/ResponsivePhosphorIcon";
import {
  CheckMarkIcon,
  CheckMarkIconTwo,
  GrayDot,
  WhiteDot,
} from "../../ReusableComponents/SvgAndIcons/SvgIcons";
interface Props {
  sequenceList: SequenceType[];
  selectedSequence?: SequenceType | null;
  setSelectedSequence: any;
  currentView: string;
  setCurrentView: (viewName: string) => void;
  selectedScenesForGenerateBreakdown: any;
  handleSelectUnselectSequenceForGenerate: (e: SequenceType) => void;
}
const SequenceListView = ({
  sequenceList,
  selectedSequence,
  setSelectedSequence,
  currentView,
  setCurrentView,
  selectedScenesForGenerateBreakdown,
  handleSelectUnselectSequenceForGenerate,
}: Props) => {
  const [hoveredId, setHoveredId] = useState<string | null>("");
  return (
    <Box sx={{ pl: 2, pr: 1 }}>
      {sequenceList?.length ? (
        <>
          {sequenceList.map((singleSequence: SequenceType) => {
            const isSelectedForGenerate =
              selectedScenesForGenerateBreakdown?.some(
                (single: any) => single === singleSequence?.id
              );

            return (
              <Button
                key={singleSequence.id}
                sx={{
                  backgroundColor:
                    currentView === "sequences" &&
                    singleSequence.id === selectedSequence?.id
                      ? "#252A38"
                      : "",
                  border:
                    currentView === "sequences" &&
                    singleSequence.id === selectedSequence?.id
                      ? "1px solid #6C65D9"
                      : "",
                  borderRadius: "10px",
                  width: "100%",
                  paddingLeft: "8px",
                  paddingRight: "10px",
                  // p: 1,
                }}
                onClick={() => {
                  setCurrentView("sequences");
                  setSelectedSequence(singleSequence);
                }}
                onMouseEnter={() => setHoveredId(singleSequence.id)}
                onMouseLeave={() => setHoveredId(null)}
              >
                <Grid container columnSpacing={1} alignItems="center">
                  <Grid item md={11} lg={10}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "8px",
                        color:
                          currentView === "sequences" &&
                          singleSequence.id === selectedSequence?.id
                            ? "white"
                            : "gray",
                        fontWeight: 500,
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    >
                      {isSelectedForGenerate ? (
                        <Radio
                          size="small"
                          checked
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelectUnselectSequenceForGenerate(
                              singleSequence
                            );
                          }}
                        />
                      ) : singleSequence.id === hoveredId ? (
                        <Radio
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelectUnselectSequenceForGenerate(
                              singleSequence
                            );
                          }}
                        />
                      ) : singleSequence.id === selectedSequence?.id ? (
                        <WhiteDot />
                      ) : (
                        <GrayDot />
                      )}

                      <Typography color={isSelectedForGenerate && "white"}>
                        {singleSequence.sceneNumber + " "}
                      </Typography>
                      <Tooltip
                        title={singleSequence.sceneTitle}
                        placement="top"
                      >
                        <Typography
                          color={isSelectedForGenerate && "white"}
                          sx={{
                            textAlign: "start",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            display: "inline-block",
                          }}
                        >
                          {singleSequence.sceneTitle}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Grid>
                  <Grid item md={1} lg={2}>
                    <Box
                      sx={{
                        pr: 2,
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "flex-end",
                      }}
                    >
                      {singleSequence.selectedBreakdown ? (
                        <>
                          {singleSequence.id === selectedSequence?.id ? (
                            <CheckMarkIconTwo />
                          ) : (
                            <CheckMarkIcon />
                          )}
                        </>
                      ) : (
                        <ResponsivePhosphorIcon
                          sizes={{ md: 12, lg: 16, xl: 20 }}
                          icon="Warning"
                          fill="red"
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Button>
            );
          })}
        </>
      ) : (
        <p>No Sequence found</p>
      )}
    </Box>
  );
};

export default SequenceListView;
