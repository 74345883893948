import { Box, Button, Divider, Grid, Modal, Typography } from "@mui/material";
import { CaretLeft, Lightbulb } from "@phosphor-icons/react";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  ContextTypes,
  ScriptBreakdownContext,
} from "../../Context/ContextDataProvider";
import { ROUTES } from "../../Routes";
import { readCookie } from "../../Services/apiResources";
import { exportToMovieColabApi } from "../../Services/exportServices/export-services";
import {
  getMovieColabProjectDetails,
  getMovieColabProjectSequenceList,
} from "../../Services/movieColabServices/movieColabServices";
import {
  MovieColabProjectDetailsResponse,
  MovieColabSingleSequenceType,
} from "../../types/movie-colab-types";
import {
  ProjectIdType,
  SequenceListType,
  SequenceType,
  SequnceExport,
} from "../../types/public-types";
import {
  FilledButton,
  OutlinedButton,
} from "../ReusableComponents/Buttons/Buttons";
import { MovieColaIcon } from "../ReusableComponents/SvgAndIcons/SvgIcons";
import DownloadPdfButtons from "./DownloadPdfButtons";
import useStyles from "./ExportPreview.style";
import ChooseProjectModal from "./Modals/ChooseProjectModal";
import CreateNewMcProjectModal from "./Modals/CreateNewProjectMcModal";
import ExportToMovieColabModal from "./Modals/ExportToMovieColabModal";
import MovieColabLoginModal from "./Modals/MovieColabLoginModal";
import MovieColabProjectDetails from "./MovieColabProjectDetails";
import PDFDownloadOptions from "./PDFDownloadOptions";
import SequenceListOfPreview from "./SequenceListOfPreview";
import ShotsListOfPreview from "./ShotsListOfPreview";

interface Props {
  projectId: ProjectIdType;
}

const ExportPreviewOverview = ({ projectId }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    isProjectDetailsLoading,
    projectDetailsData,
    handleGetProjectDetails,
    isSequenceListLoading,
    sequenceList: preLoadedSequenceList,
    handleGetSequences,
    isShotListLoading,
    handleGetShotList,
    shotListData,
  }: ContextTypes = useContext(ScriptBreakdownContext);
  const [sequenceList, setSequenceList] = useState<SequenceListType>([]);
  const [selectedSequences, setSelectedSequences] = useState<SequenceType[]>(
    []
  );
  const [selectedForPreview, setSelectedForPreview] = useState<SequenceType>();
  const [isSequencesLoaded, setIsSequencesLoaded] = useState<boolean>(false);
  const [
    isMovieColabProjectDetailsLoaded,
    setIsMovieColabProjectDetailsLoaded,
  ] = useState<boolean>(true);
  const [movieColabProjectDetails, setMovieColabProjectDetails] =
    useState<MovieColabProjectDetailsResponse>();
  const [movieColabSequences, setMovieColabSequences] =
    useState<MovieColabSingleSequenceType[]>();

  const [isAllSequenceSelected, setIsAllSequenceSelected] = useState(false);
  const [projectName, setProjectName] = useState<string>(""); //stores project name
  const [linkedFileId, setLinkedFileId] = useState<string>(""); //stores project name
  const [loggedInUser, setLoggedInUser] = useState<string>(""); //stores loggedin Users username
  const [selectedDownloadOption, setSelctedDownloadOption] =
    useState<string>("download-pdf"); //download options
  const [selectedPdfFormat, setSelectedPdfFormat] = useState({
    Cover: true,
    Index: true,
    Breakdown: true,
    Storyboard: true,
  }); //pdf options
  const [sequencesToExport, setSequencesToExport] = useState<SequnceExport[]>(
    []
  ); //stores sequences to export
  const [pdfDownloadButtonLoader, setPdfDownloadButtonLoader] = useState({
    showLoader: false,
    showDownloadLink: false,
  }); //stores pdflink loader and api call loader state
  const [movieColabLogInModalState, setMovieColabLogInModalState] =
    useState<boolean>(false);
  const [createNewMcProjectModalState, setCreateNewMcProjectModalState] =
    useState<boolean>(false);
  const [chooseMcProjectModalState, setChooseMcProjectModalState] =
    useState<boolean>(false);
  const [selectedMovieColabProjectId, setSelectedMovieColabProjectId] =
    useState<string | number>(0);
  const handleOpenCreateNewMcProjectState = () => {
    setChooseMcProjectModalState(false);
    setCreateNewMcProjectModalState(true);
  };
  const [exportToMovieColabModalState, setExportToMovieColabModalState] =
    useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);

  /* const handleGetProjectDetails = async () => {
    try {
      const res = await getProjectDetails(projectId);
      setProjectName(res.data.name);
      if (res.data.linkedFile.fileId) {
        setLinkedFileId(res.data.linkedFile.fileId);
        handleGetSequences(res.data.linkedFile.fileId);
      }
    } catch (error) {
      toast.error("error getting details");
      setIsSequencesLoaded(true);
    }
  }; */

  /* const handleGetSequences = async (fileId: string) => {
    try {
      const res = await getSequenceListApi(projectId, fileId);
      if (res?.data?.sequences?.length) {
        setSequenceList(res.data.sequences);
        // setSelectedSequences([res.data.sequences[0]]);
        setSelectedForPreview(res.data.sequences[0]);
        setIsSequencesLoaded(true);
      }
    } catch (error) {
      toast.error("Error getting sequences");
      setIsSequencesLoaded(true);
    }
  }; */
  const handleSelectOrUnselectSequence = (sequence: SequenceType) => {
    const exist = selectedSequences?.some(
      (sin: SequenceType) => sin.id === sequence.id
    );
    if (exist) {
      setSelectedSequences(
        selectedSequences?.filter((sin: SequenceType) => sin.id !== sequence.id)
      );
    } else {
      setSelectedSequences([...selectedSequences!, sequence]);
    }
  };

  const handleSelectOrUnselectAllSequences = () => {
    if (isAllSequenceSelected) {
      setSelectedSequences([]);
      setIsAllSequenceSelected(false);
    } else {
      const selected = sequenceList.filter(
        (sis: SequenceType) => sis.breakdownVersion
      );
      setSelectedSequences(selected);
      setIsAllSequenceSelected(true);
    }
  };

  const handleGetMovieColabProjectDetails = async () => {
    setIsMovieColabProjectDetailsLoaded(false);
    try {
      const res = await getMovieColabProjectDetails(
        selectedMovieColabProjectId
      );
      setMovieColabProjectDetails(res);
      setIsMovieColabProjectDetailsLoaded(true);
    } catch (error) {
      toast.error("Error getting Movie Colab project details");
      setIsMovieColabProjectDetailsLoaded(true);
    }
  };

  const handleGetMovieColabProjectSequences = async () => {
    try {
      const res = await getMovieColabProjectSequenceList(
        selectedMovieColabProjectId
      );
      setMovieColabSequences(res.results);
    } catch (error) {
      toast.error("Error getting Movie Colab project details");
    }
  };

  const getTotalShotCount = () => {
    let total = 0;
    selectedSequences?.map((si) => {
      total += Number(si.breakdownShotCount);
    });
    return total;
  };

  const handleExportToMovieColab = async () => {
    setIsExporting(true);
    const selectedSequencesIds = selectedSequences?.map(
      (sin: SequenceType) => sin.id
    );
    const data = {
      projectId: projectId,
      moviecolabprojectid: selectedMovieColabProjectId,
      sequenceIds: selectedSequencesIds,
      // refreshToken: readCookie("mc_refresh"),
      refreshToken: readCookie("mc_refresh"),
      linkedFile: linkedFileId,
    };
    const promise = toast.promise(exportToMovieColabApi(data), {
      loading: "Exporting To Movie Colab",
      success: "Exported To Movie Colab",
      error: "Error on Export To Movie Colab",
    });
    try {
      const res = await promise;
      if (res.status === 200) {
        handleGetMovieColabProjectDetails();
        handleGetMovieColabProjectSequences();
        setExportToMovieColabModalState(false);
        setIsExporting(false);
      }
    } catch (error) {
      console.log(error);
      setIsExporting(false);
    }
  };

  const handlePdfOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPdfFormat({
      ...selectedPdfFormat,
      [event.target.name]: event.target.checked,
    });
  };

  const checkSequenceConflicts = () => {
    let hasConflict = false;
    selectedSequences.map((singleSequence: SequenceType) => {
      const isExist = movieColabSequences?.some(
        (sin: MovieColabSingleSequenceType) =>
          sin.code === singleSequence.sceneTitle
      );
      if (isExist) {
        hasConflict = true;
      }
    });
    return hasConflict;
  };

  useEffect(() => {
    if (!isSequenceListLoading) {
      if (preLoadedSequenceList?.length) {
        setSequenceList(preLoadedSequenceList);
        // setSelectedSequences([res.data.sequences[0]]);
        setSelectedForPreview(preLoadedSequenceList[0]);
        setIsSequencesLoaded(true);
      }
    }
  }, [isSequenceListLoading, preLoadedSequenceList]);

  useEffect(() => {
    if (projectId !== projectDetailsData?.id) {
      handleGetProjectDetails(projectId);
    }
  }, [projectId, projectDetailsData?.id]);

  /* useEffect(() => {
    if (!isSequenceListLoading) {
      if (preLoadedSequenceList?.length) {
        setSequenceList(preLoadedSequenceList);
        // setSelectedSequences([res.data.sequences[0]]);
        setSelectedForPreview(preLoadedSequenceList[0]);
        setIsSequencesLoaded(true);
      }
    }
  }, [isShotListLoading]); */

  useEffect(() => {
    if (projectId) {
      if (!isProjectDetailsLoading && projectDetailsData?.id !== projectId) {
        handleGetProjectDetails(projectId);
      }
    }
  }, [projectId]);

  useEffect(() => {
    if (selectedMovieColabProjectId) {
      handleGetMovieColabProjectDetails();
      handleGetMovieColabProjectSequences();
    }
  }, [selectedMovieColabProjectId]);

  return (
    <Box sx={{ mt: 1 }} className={classes.root}>
      <Box sx={{ mb: 1 }}>
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={2}>
            <Button
              sx={{ color: "white" }}
              onClick={() => navigate(ROUTES.PROJECTS.VIEW(projectId))}
            >
              <CaretLeft size={20} />
              <Typography>Back</Typography>
            </Button>
          </Grid>
          <Grid item md={8} sx={{ height: "100%" }}>
            <Grid container spacing={2} sx={{ height: "100%" }}>
              <Grid item md={4}>
                <Typography sx={{ textAlign: "center" }}>
                  Sequence List
                </Typography>
              </Grid>
              <Grid item md={8} sx={{ height: "100%" }}>
                <Typography sx={{ textAlign: "center" }}>
                  Export Preview
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ height: "90%" }}>
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={2} className={classes.heightWidthFull}>
            <Box className={classes.radioButtons}>
              <Button
                className={
                  selectedDownloadOption === "download-pdf"
                    ? classes.selectedRadio
                    : classes.outlinedRadio
                }
                onClick={() => setSelctedDownloadOption("download-pdf")}
              >
                <Typography>Download PDF</Typography>
              </Button>
              <Divider sx={{ backgroundColor: "#606479" }} />
              <Button
                className={
                  selectedDownloadOption === "export-to-moviecolab"
                    ? classes.selectedRadio
                    : classes.outlinedRadio
                }
                onClick={() => {
                  if (readCookie("mc_refresh")) {
                    setSelctedDownloadOption("export-to-moviecolab");
                    setMovieColabLogInModalState(false);
                    setChooseMcProjectModalState(true);
                  } else {
                    setMovieColabLogInModalState(true);
                  }
                }}
              >
                <Typography>Export To Movie Colab</Typography>
              </Button>
              <Divider sx={{ backgroundColor: "#606479" }} />
              <Button
                className={
                  selectedDownloadOption === "share"
                    ? classes.selectedRadio
                    : classes.outlinedRadio
                }
                onClick={() => setSelctedDownloadOption("share")}
              >
                <Typography>Share Option</Typography>
              </Button>
            </Box>
            {selectedDownloadOption == "export-to-moviecolab" &&
            selectedMovieColabProjectId ? (
              <MovieColabProjectDetails
                movieColabProjectDetails={movieColabProjectDetails}
                movieColabSequences={movieColabSequences}
                isMovieColabProjectDetailsLoaded={
                  isMovieColabProjectDetailsLoaded
                }
                handleOpenChooseProjectModal={() =>
                  setChooseMcProjectModalState(true)
                }
              />
            ) : (
              ""
            )}
          </Grid>
          <Grid item md={8} sx={{ height: "100%" }}>
            <Grid container spacing={2} sx={{ height: "100%" }}>
              <Grid item md={4} sx={{ height: "100%" }}>
                <SequenceListOfPreview
                  sequenceList={sequenceList}
                  movieColabSequences={movieColabSequences}
                  selectedSequences={selectedSequences}
                  handleSelectOrUnselectSequence={
                    handleSelectOrUnselectSequence
                  }
                  selectedForPreview={selectedForPreview}
                  setSelectedForPreview={setSelectedForPreview}
                  handleSelectOrUnselectAllSequences={
                    handleSelectOrUnselectAllSequences
                  }
                  isSequencesLoaded={isSequencesLoaded}
                  isAllSequenceSelected={isAllSequenceSelected}
                />
              </Grid>
              <Grid item md={8} sx={{ height: "100%" }}>
                <ShotsListOfPreview selectedForPreview={selectedForPreview} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={2} sx={{ height: "100%" }}>
            <Box className={classes.bdElements}>
              <Box sx={{ width: "100%" }}>
                <Typography sx={{ textAlign: "center", mb: 2 }}>
                  Export Report
                </Typography>
                <Box
                  className={classes.buttons}
                  sx={{ width: "100%", color: "#606479" }}
                >
                  <Box className={classes.flexBetween} sx={{ my: 1 }}>
                    <Typography>Sequences</Typography>
                    <Typography>{selectedSequences?.length || 0}</Typography>
                  </Box>
                  <Box className={classes.flexBetween}>
                    <Typography>Shots</Typography>
                    <Typography>{getTotalShotCount()}</Typography>
                  </Box>
                </Box>
              </Box>
              {selectedDownloadOption === "download-pdf" && (
                <PDFDownloadOptions
                  selectedPdfFormat={selectedPdfFormat}
                  handlePdfOptions={handlePdfOptions}
                />
              )}

              {selectedDownloadOption === "download-pdf" ? (
                <DownloadPdfButtons
                  projectId={projectId}
                  projectName={projectName}
                  selectedSequences={selectedSequences}
                  selectedPdfFormat={selectedPdfFormat}
                  shotCount={getTotalShotCount}
                  sequenceCount={selectedSequences.length}
                />
              ) : selectedDownloadOption === "export-to-moviecolab" ? (
                <FilledButton
                  text={`Export To ${
                    movieColabProjectDetails?.name || "MovieColab"
                  }`}
                  disabled={
                    selectedMovieColabProjectId && selectedSequences?.length
                      ? false
                      : true
                  }
                  handleClick={() => {
                    if (checkSequenceConflicts()) {
                      setExportToMovieColabModalState(true);
                    } else {
                      handleExportToMovieColab();
                    }
                  }}
                  fullWidth={true}
                  minWidth={150}
                  isLoading={false}
                />
              ) : (
                ""
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.footer}>
        <Lightbulb size={20} fill="#6C65D9" />
        <Typography sx={{ color: "#6C65D9" }}>Tips</Typography>
        <Typography>
          - Selected shots can be transferred to movie colab by selecting export
          to movie colab
        </Typography>
      </Box>
      <Modal
        open={movieColabLogInModalState}
        className={classes.flexCenter}
        onClose={() => {
          setMovieColabLogInModalState(false);
          setSelctedDownloadOption("download-pdf");
        }}
      >
        <MovieColabLoginModal
          handleClose={() => {
            setMovieColabLogInModalState(false);
            setSelctedDownloadOption("download-pdf");
          }}
          handleSuccess={() => {
            setMovieColabLogInModalState(false);
            setChooseMcProjectModalState(true);
          }}
        />
      </Modal>
      <Modal
        open={chooseMcProjectModalState}
        className={classes.flexCenter}
        onClose={() => setChooseMcProjectModalState(false)}
      >
        <ChooseProjectModal
          currentSelectedMcProjectId={selectedMovieColabProjectId}
          handleCreateNewProjectModalState={handleOpenCreateNewMcProjectState}
          handleClose={() => setChooseMcProjectModalState(false)}
          setSelectedMovieColabProjectId={setSelectedMovieColabProjectId}
        />
      </Modal>
      <Modal
        open={createNewMcProjectModalState}
        className={classes.flexCenter}
        onClose={() => setCreateNewMcProjectModalState(false)}
      >
        <CreateNewMcProjectModal
          handleClose={() => setCreateNewMcProjectModalState(false)}
          handleSuccess={(projectId: number) => {
            setSelectedMovieColabProjectId(projectId);
            setSelctedDownloadOption("export-to-moviecolab");
          }}
        />
      </Modal>
      <Modal
        open={exportToMovieColabModalState}
        className={classes.flexCenter}
        onClose={() => setExportToMovieColabModalState(false)}
      >
        <ExportToMovieColabModal
          handleClose={() => setExportToMovieColabModalState(false)}
          movieColabProjectDetails={movieColabProjectDetails}
          sequenceList={sequenceList}
          movieColabSequences={movieColabSequences}
          selectedSequences={selectedSequences}
          handleExportToMovieColab={handleExportToMovieColab}
          isExporting={isExporting}
        />
      </Modal>
      <Modal open={isExporting} className={classes.flexCenter}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            alignItems: "center",
          }}
        >
          <MovieColaIcon height={250} width={250} />
          <Typography>Exporting to Movie Colab Please wait</Typography>
          <OutlinedButton
            text="Cancel"
            disabled={false}
            handleClick={() => console.log("canecl")}
            fullWidth={false}
            minWidth={100}
          />
        </Box>
      </Modal>
      {/* <Modal
        open={pdfModal}
        className={classes.flexCenter}
        style={{ height: "1000px", width: "950px" }}
        onClose={() => setPdfModal(false)}
      >
        <PDFViewer style={{ height: "100%", width: "950px" }}>
          <ScriptBreakPdfDocument
            sequencesToExport={sequencesToExport}
            projectName={projectName}
            selectedPdfFormat={selectedPdfFormat}
            shotCount={getTotalShotCount}
            sequenceCount={selectedSequences.length}
            loggedInUser={loggedInUser}
          />
        </PDFViewer>
      </Modal> */}
    </Box>
  );
};

export default ExportPreviewOverview;
