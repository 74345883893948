import { Box } from "@mui/material";
import ScaleLoader from "react-spinners/ScaleLoader";
export default function Loader() {
  return (
    <Box
      sx={{
        height: "400px",
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ScaleLoader
        color="#6C65D9"
        height={60}
        style={{
          display: "flex",
          verticalAlign: "middle",
        }}
      />
    </Box>
  );
}
