import { Box } from "@mui/material";
import ForgetPasswordComponent from "../../Components/Authentications/ForgetPasswordComponent";

const ForgetPasswordPage = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <ForgetPasswordComponent />
    </Box>
  );
};

export default ForgetPasswordPage;
