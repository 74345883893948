import { makeStyles } from "@material-ui/core/styles";
import { themeType } from "../../types/theme-types";

const useStyles = makeStyles((theme: themeType) => ({
  root: {
    height: "95% !important",
    padding: "0px 60px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    fontFamily: "poppins",
  },

  leftSideContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "90%  !important",
    padding: "20px 20%",
  },

  inputsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },

  inputLabelAndField: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },

  inputLabel: {
    color: "#606479",
    fontSize: "12px",
  },

  inputField: {
    outline: "none",
    borderRadius: "10px",
    backgroundColor: "#252A38",
    "& .MuiInputBase-input": {
      color: "white",
      padding: "10px",
    },
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  flexGap: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },

  gmailAndAppleButton: {
    backgroundColor: "#252A38 !important",
    display: "flex",
    alignItems: "center",
    gap: "6px !important",
    fontSize: "12px !important",
    padding: "10px !important",
    borderRadius: "10px !important",
  },
  loginOrSignupButton: {
    // backgroundColor: "#6C65D9 !important",
    padding: "8px !important",
    borderRadius: "10px !important",
  },
}));

export default useStyles;
