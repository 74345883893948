import {
  Box,
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { ArrowRight, CaretDown, Trash } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { uploadScenesManuallyApi } from "../../../Services/fileServices/fileServices";
import { ProjectIdType } from "../../../types/public-types";
import DeleteModal from "../../ReusableComponents/DeleteModal/DeleteModal";
import useStyles from "./ScriptFileInput.style";
interface SingleScene {
  sceneNumber: number;
  sceneTitle: string;
  sceneContent: string;
  projectId: ProjectIdType;
}
interface Props {
  projectId: ProjectIdType;
  updateRefreshCounter: () => void;
  setIsUnsavedManualSceneAvailable: (val: boolean) => void;
}

const ManuallyUploadScenes = ({
  projectId,
  updateRefreshCounter,
  setIsUnsavedManualSceneAvailable,
}: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [newSceneTitle, setNewSceneTitle] = useState<string>("");
  const [newSceneTitleDetail, setNewSceneTitleDetail] = useState<string>("");
  const [newSceneLocation, setNewSceneLocation] = useState<string>("INT");
  const [newSceneDescription, setNewSceneDescription] = useState<string>("");
  const [scenes, setScenes] = useState<SingleScene[]>([]);
  const [selectedScene, setSelectedScene] = useState<SingleScene | null>();
  const [currentMode, setCurrentMode] = useState<string>("new");
  const [deleteModalState, setDeleteModalState] = useState<boolean>(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateScenes = () => {
    let currentScenes = [];
    if (newSceneTitle?.length && newSceneDescription?.length) {
      if (currentMode === "new") {
        const newScene = {
          sceneNumber: scenes?.length + 1,
          sceneTitle: `${newSceneLocation.trim()}. ${newSceneTitle.trim()} - ${newSceneTitleDetail.trim()}`,
          sceneContent: newSceneDescription,
          projectId: projectId,
        };
        setScenes((previous) => [...previous, newScene]);
        currentScenes = [...scenes, newScene];
      } else {
        const newScenes = scenes.map((singleScene: SingleScene) =>
          singleScene.sceneNumber === selectedScene?.sceneNumber
            ? {
                ...singleScene,
                sceneTitle: `${newSceneLocation.trim()}. ${newSceneTitle.trim()} - ${newSceneTitleDetail.trim()}`,
                sceneContent: newSceneDescription,
              }
            : singleScene
        );
        setScenes(newScenes);
        currentScenes = newScenes;
      }
      return currentScenes;
    } else {
      toast.error("Title or Scene missing");
      return false;
    }
  };

  const handleSelectScene = (scene: SingleScene) => {
    if (newSceneTitle?.length && newSceneDescription?.length) {
      handleUpdateScenes();
    }

    // Find the index of the first dot and the last hyphen
    let firstDotIndex = scene.sceneTitle.indexOf(".");
    let lastHyphenIndex = scene.sceneTitle.lastIndexOf("-");
    //decode scene title too
    setCurrentMode("edit");
    setNewSceneLocation(scene.sceneTitle.split(".")[0].trim());
    setNewSceneTitle(
      scene.sceneTitle.substring(firstDotIndex + 1, lastHyphenIndex)
    );
    setNewSceneTitleDetail(scene.sceneTitle.substring(lastHyphenIndex + 1));
    setNewSceneDescription(scene.sceneContent);
    setSelectedScene(scene);
  };

  const handleClickAddNewScene = () => {
    if (newSceneTitle?.length && newSceneDescription?.length) {
      handleUpdateScenes();
      handleResetAll();
    } else {
      toast.error("Add title and scene");
    }
  };

  const handleDeleteScene = () => {
    if (selectedScene) {
      setScenes(
        scenes.filter(
          (single: SingleScene) =>
            single.sceneNumber !== selectedScene?.sceneNumber
        )
      );
      handleResetAll();
    } else {
      toast.error("Select a scene to delete");
    }
  };

  const handleResetAll = () => {
    setSelectedScene(null);
    setCurrentMode("new");
    setNewSceneTitle("");
    setNewSceneDescription("");
    setDeleteModalState(false);
    setNewSceneTitleDetail("");
    setNewSceneLocation("INT");
  };

  const handleSubmit = async () => {
    const availableScenes =
      newSceneTitle || newSceneDescription ? handleUpdateScenes() : scenes;

    if (availableScenes) {
      handleResetAll();
      try {
        const promise = toast.promise(
          uploadScenesManuallyApi({
            projectId,
            scenes: availableScenes,
          }),
          {
            loading: "Uploading Scenes",
            success: "Scenes uploaded successfully",
            error: "Error on Upload Scenes",
          }
        );
        const res = await promise;
        if (res.status === 200 || 201) {
          updateRefreshCounter();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (scenes?.length) {
      setIsUnsavedManualSceneAvailable(true);
    } else {
      setIsUnsavedManualSceneAvailable(false);
    }
  }, [scenes]);

  return (
    <Box className={classes.manualSceneAddRoot}>
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item md={4} lg={4} className={classes.heightWidthFull}>
          <Box sx={{ borderRight: "2px solid #606479", height: "100%", pr: 1 }}>
            <Box className={classes.scenesContainer}>
              {scenes?.map((scene: SingleScene) => (
                <Box
                  className={
                    scene.sceneNumber === selectedScene?.sceneNumber
                      ? classes.selectedScene
                      : classes.scene
                  }
                  onClick={() => handleSelectScene(scene)}
                >
                  <Typography
                    className={
                      scene.sceneNumber === selectedScene?.sceneNumber
                        ? ""
                        : classes.textGray
                    }
                    sx={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      display: "inline-block",
                    }}
                  >
                    {scene.sceneTitle}
                  </Typography>
                  <Button
                    sx={{ minWidth: "20px", padding: "0px" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteModalState(true);
                    }}
                    disabled={!selectedScene?.sceneTitle}
                  >
                    <Trash
                      size={18}
                      style={{ cursor: "pointer" }}
                      fill={
                        scene.sceneNumber === selectedScene?.sceneNumber
                          ? "red"
                          : "#606479"
                      }
                    />
                  </Button>
                </Box>
              ))}
              {newSceneTitle?.length && currentMode === "new" ? (
                <Typography
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    display: "inline-block",
                  }}
                >
                  {newSceneTitle}
                </Typography>
              ) : (
                ""
              )}
            </Box>
            <Button
              className={classes.addNewSceneButton}
              onClick={() => handleClickAddNewScene()}
            >
              <Typography>+ Add New Scene</Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item md={8} lg={8}>
          <Box sx={{ mt: 2 }}>
            <Box className={classes.flexBetween}>
              <Typography className={classes.textGray}>
                Number of scenes :{" "}
                {scenes.length ? (
                  <span style={{ color: "white" }}>{scenes?.length}</span>
                ) : (
                  "--"
                )}
              </Typography>
              <Button
                variant="contained"
                onClick={() => handleSubmit()}
                className={classes.submitButton}
                disabled={
                  scenes?.length ||
                  (newSceneTitle?.length && newSceneDescription?.length)
                    ? false
                    : true
                }
                sx={{
                  ":disabled": { backgroundColor: "#252A38", color: "#606479" },
                }}
              >
                <Typography sx={{ mr: 1 }}>Submit & continue</Typography>
                <ArrowRight size={20} />
              </Button>
            </Box>
            <Divider sx={{ backgroundColor: "#606479", mt: 4 }} />
            <Box sx={{ mt: 4 }}>
              <Typography sx={{ mb: 1 }} className={classes.textGray}>
                Enter Title
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Box className={classes.flexCenter}>
                <Button
                  endIcon={<CaretDown size={12} color="#606479" />}
                  style={{
                    padding: "4px 8px 4px 4px",
                    width: "150px",
                    height: "36px",
                    borderRadius: "5px 0px 0px 5px",
                    backgroundColor: "#252A38",
                  }}
                  onClick={handleClick}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      color: newSceneLocation ? "white" : "#606479",
                    }}
                  >
                    {newSceneLocation === "INT"
                      ? "internal (INT)"
                      : "external (EXT)"}
                  </span>
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    className: `${classes.menuList}`,
                  }}
                >
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => {
                      setNewSceneLocation("INT");
                      handleClose();
                    }}
                  >
                    internal (INT)
                  </MenuItem>
                  <Divider style={{ backgroundColor: "#606479" }} />
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => {
                      setNewSceneLocation("EXT");
                      handleClose();
                    }}
                  >
                    external (EXT)
                  </MenuItem>
                </Menu>
                <TextField
                  onChange={(e) => setNewSceneTitle(e.target.value)}
                  value={newSceneTitle}
                  type="text"
                  variant="standard"
                  placeholder="enter title"
                  sx={{
                    backgroundColor: "#252A38",
                    color: "white",
                    borderLeft: "1px solid #606479",
                    borderRight: "1px solid #606479",
                    width: "70%",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      height: "36px",
                    },
                  }}
                  inputProps={{
                    disableFieldSet: true,
                    style: {
                      padding: "4px 8px",
                      color: "white",
                    },
                  }}
                />
                <TextField
                  onChange={(e) => setNewSceneTitleDetail(e.target.value)}
                  value={newSceneTitleDetail}
                  type="text"
                  variant="standard"
                  placeholder="enter details (sunny)"
                  sx={{
                    backgroundColor: "#252A38",
                    color: "white",
                    borderRadius: "0px 5px 5px 0px",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      height: "36px",
                    },
                  }}
                  inputProps={{
                    disableFieldSet: true,
                    style: {
                      padding: "4px 8px",
                      color: "white",
                    },
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ mb: 1 }} className={classes.textGray}>
                Enter Scene
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <textarea
                placeholder="Add scene here"
                className={classes.descriptionInput}
                onChange={(event) => setNewSceneDescription(event.target.value)}
                value={newSceneDescription}
              />
            </Box>
            <Typography sx={{ mt: 2 }} className={classes.textGray}>
              Number of words :{" "}
              {newSceneDescription ? (
                <span style={{ color: "white" }}>
                  {newSceneDescription?.length}
                </span>
              ) : (
                "--"
              )}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Modal
        open={deleteModalState}
        className={classes.flexCenter}
        onClose={() => setDeleteModalState(false)}
      >
        <DeleteModal
          type="Scene"
          handleCloseDeleteModal={() => setDeleteModalState(false)}
          handleDelete={() => handleDeleteScene()}
          name={selectedScene?.sceneTitle}
          isDeleting={false}
        />
      </Modal>
    </Box>
  );
};

export default ManuallyUploadScenes;
