import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { EnvelopeSimple } from "@phosphor-icons/react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routes";
import { CheckIcon } from "../ReusableComponents/SvgAndIcons/SvgIcons";
import useStyles from "./AuthenticationStyles.style";
import RightSideWithLogo from "./RightSideWithLogo";
import { ForgetPasswordResetApi } from "../../Services/authServices/authServices";
import toast from "react-hot-toast";
import { Logo } from "../ReusableComponents/SvgAndIcons/ScriptVizLogo";

const ForgetPasswordComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLinkSent, setIsLinkSent] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const handleResetPassword = async () => {
    try {
      const res = await ForgetPasswordResetApi(email);
      if (res.status === 200) {
        setIsLinkSent(true);
        toast.success("Reset link sent successfully");
      }
    } catch (error) {
      toast.error("Error on sending reset url");
    }
  };

  return (
    <Box className={classes.root}>
      <Grid container spacing={4} sx={{ height: "100%" }}>
        <Grid item md={6} sx={{ height: "100%" }}>
          <Box className={classes.leftSideContainer}>
            <Box>
              <Logo />
            </Box>
            <Box className={classes.flexGap}>
              <Typography variant="h4">
                {isLinkSent ? `Link Sent!` : `Forget Password?`}
              </Typography>
              {isLinkSent && <CheckIcon />}
            </Box>
            <Typography>
              {isLinkSent
                ? `Please follow the instructions sent to your e-mail id to reset your password.`
                : `Don't worry enter your registered email below and we will send you
              a reset link immediately!`}
            </Typography>
            {!isLinkSent && (
              <Box className={classes.inputsContainer}>
                <Box className={classes.inputLabelAndField}>
                  <Typography className={classes.inputLabel}>
                    Email <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    placeholder="Enter your email address"
                    type="email"
                    name="email"
                    fullWidth
                    variant="standard"
                    className={classes.inputField}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <EnvelopeSimple size={22} fill="#606479" />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                    }}
                  />
                </Box>
              </Box>
            )}
            <Box>
              {isLinkSent ? (
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.loginOrSignupButton}
                  onClick={() => navigate(ROUTES.LOG_IN)}
                >
                  <Typography>Back to log in</Typography>
                </Button>
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.loginOrSignupButton}
                  onClick={() => handleResetPassword()}
                  disabled={!email?.length}
                >
                  <Typography>Send Password Reset Link</Typography>
                </Button>
              )}
            </Box>
            <Divider sx={{ backgroundColor: "#606479", mx: 4 }} />
            <Box>
              {isLinkSent ? (
                <Typography sx={{ textAlign: "center", color: "#606479" }}>
                  Don’t receive mail?{" "}
                  <span
                    onClick={() => handleResetPassword()}
                    style={{
                      color: "#6C65D9",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    Click to Resend
                  </span>{" "}
                </Typography>
              ) : (
                <Typography sx={{ textAlign: "center", color: "#606479" }}>
                  Remember the password?{" "}
                  <Link
                    to={ROUTES.LOG_IN}
                    style={{ color: "#6C65D9", textDecoration: "none" }}
                  >
                    Back to Log in
                  </Link>{" "}
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} sx={{ height: "100%" }}>
          <RightSideWithLogo />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForgetPasswordComponent;
