import React, { useEffect, useState } from "react";
import "./termsandcondition.css";

export default function TermsAndCondition() {
  const [fileContent, setFileContent] = useState("");

  useEffect(() => {
    fetch("./termsandcondition.txt")
      .then((response) => response.text())
      .then((text) => {
        setFileContent(text);
      })
      .catch((error) => {
        console.error("Error reading text file:", error);
      });

    return () => {};
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ margin: "20px", fontSize: "24px" }}>
          Terms And Condition
        </div>
        <span
          className="terms-and-conditions"
          style={{ height: "80vh", width: "80%" }}
        >
          {fileContent}
        </span>
      </div>
    </>
  );
}
