import { Box } from "@mui/material";
import SetupNewPasswordComponent from "../../Components/Authentications/SetupNewPasswordComponent";

const SetupNewPasswordPage = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <SetupNewPasswordComponent />
    </Box>
  );
};

export default SetupNewPasswordPage;
