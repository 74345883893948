import { useState } from "react";
import {
  CardContent,
  Card,
  Typography,
  Box,
  TextField,
  Button,
} from "@mui/material";
import { CloudArrowUp } from "@phosphor-icons/react";
import useStyles from "./ReportIssueModal.style";
import {
  FilledButton,
  OutlinedButton,
} from "../../ReusableComponents/Buttons/Buttons";
import { sendReports } from "../../../Services/reportServices/reportServices";
import { getStorage, ref, uploadBytes } from "firebase/storage";

interface Props {
  handleClose: () => void;
  onpenReportSuccessModal: () => void;
}

const ReportIssueModal = ({ handleClose, onpenReportSuccessModal }: Props) => {
  const classes = useStyles();
  const storage = getStorage();
  const [attachment, setAttachment] = useState<any>([]);
  const [reportName, setReportName] = useState<string>("");
  const [reportDetail, setReportDetail] = useState<string>("");

  const handleUploadAttachment = (event: any) => {
    setAttachment((prevAttachment: any) => [
      ...prevAttachment,
      event?.target.files[0],
    ]);
  };

  const handleRemoveAttachment = (attachedFile: any) => {
    const newAttachment = attachment.filter(
      (attachment: any) => attachment !== attachedFile
    );
    setAttachment(newAttachment);
  };

  const handleDragFile = (e: any) => {
    //catching the dragged file for uploading
    if (e.type === "drop") {
      setAttachment((prevAttachment: any) => [
        ...prevAttachment,
        e?.dataTransfer.files[0],
      ]);
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSubmitReport = async () => {
    try {
      const res = await sendReports(reportName, reportDetail);
      const { report_path_folder } = res.data?.data;

      for (let i = 0; i < attachment.length; i++) {
        const fileRef = ref(
          storage,
          `${report_path_folder}attachment_${i}.png`
        );

        await uploadBytes(fileRef, await attachment[i].arrayBuffer(), {
          contentType: "image/png",
        });
      }

      if (res.data.message == "New Report Created Successfully") {
        handleClose();
        onpenReportSuccessModal();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Card
      className={classes.root}
      onDragEnter={(e) => handleDragFile(e)}
      onDrop={(e) => handleDragFile(e)}
      onDragLeave={(e) => handleDragFile(e)}
      onDragOver={(e) => handleDragFile(e)}
    >
      <CardContent className={classes.cardContents}>
        <Typography variant="h4" className={classes.title}>
          Report an issue
        </Typography>
        <Box>
          <Typography sx={{ mb: 1 }}>
            What was the issue about?
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            fullWidth
            type="text"
            variant="standard"
            placeholder="name your issue"
            value={reportName}
            onChange={(e) => setReportName(e.target.value)}
            sx={{
              backgroundColor: "#252A38",
              color: "white",
              borderRadius: "5px",
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                height: "36px",
              },
            }}
            inputProps={{
              disableFieldSet: true,
              style: {
                padding: "4px 8px",
                color: "white",
              },
            }}
          />
        </Box>
        <Box>
          <Typography sx={{ mb: 1 }}>
            Add attachments here
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <Box
            style={{
              height: "80px",
              width: "100%",
              border: "1px dashed #606479",
              borderRadius: "10px",
              backgroundColor: "#252A38",
              position: "relative",
            }}
          >
            {attachment.length > 0 ? (
              <Box
                style={{
                  height: "100%",
                  width: "90%",
                  display: "inline-flex",
                  overflowY: "hidden",
                  overflowX: "auto",
                }}
              >
                {attachment.map((attachedFile: any) => {
                  return (
                    <Box style={{ position: "relative" }}>
                      <img
                        width={130}
                        height={72}
                        style={{ marginLeft: "10px" }}
                        src={URL.createObjectURL(attachedFile)}
                        alt="just an img"
                      />
                      <Button
                        style={{
                          zIndex: "10",
                          position: "absolute",
                          top: "0px",
                          right: "-10px",
                          minWidth: "0px",
                          padding: "4px",
                          color: "white",
                          backgroundColor: "#FF0000",
                          borderRadius: "50%",
                        }}
                        onClick={() => handleRemoveAttachment(attachedFile)}
                      >
                        X
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <label
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <CloudArrowUp size={35} color="white" />
                <Typography sx={{ textAlign: "center" }}>
                  Click to upload
                  <br /> or <br />
                  Drag and Drop on the screen
                </Typography>
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/* video/*"
                  id="thumbnailUpload"
                  onChange={(e) => handleUploadAttachment(e)}
                />
              </label>
            )}
            {attachment.length > 0 && (
              <label
                style={{
                  position: "absolute",
                  top: -37,
                  right: 0,
                  height: "156px",
                  width: "55px",
                  backgroundColor: "#606479",
                  borderRadius: "0px 10px 10px 0px",
                  fontSize: "48px",
                  textAlign: "center",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                +
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/* video/*"
                  id="thumbnailUpload"
                  onChange={(e) => handleUploadAttachment(e)}
                />
              </label>
            )}
          </Box>
        </Box>
        <Box>
          <Typography sx={{ mb: 1 }}>
            Describe your issue
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            type="text"
            variant="standard"
            placeholder="Describe the issue in detail here"
            value={reportDetail}
            onChange={(e) => setReportDetail(e.target.value)}
            sx={{
              backgroundColor: "#252A38",
              color: "white",
              borderRadius: "10px",
            }}
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              disableFieldSet: true,
              style: {
                padding: "4px 8px",
                color: "white",
              },
            }}
          />
        </Box>
        <Box sx={{ mt: 4 }} className={classes.buttons}>
          <FilledButton
            text="Report"
            disabled={false}
            handleClick={handleSubmitReport}
            fullWidth={false}
            minWidth={120}
            isLoading={false}
          />
          <OutlinedButton
            text="Cancel"
            disabled={false}
            handleClick={() => handleClose()}
            fullWidth={false}
            minWidth={120}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default ReportIssueModal;
