import { Box, CircularProgress, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Info, Trash, X } from "@phosphor-icons/react";
import useStyles from "./DeleteModal.style";

type Props = {
  name?: string;
  type: string;
  handleDelete: () => void;
  handleCloseDeleteModal: () => void;
  isDeleting: boolean;
};
const DeleteModal = ({
  name,
  type,
  handleDelete,
  handleCloseDeleteModal,
  isDeleting,
}: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <DeleteIconSvg />
      <Box className={classes.card}>
        <Box className={classes.cardContent}>
          <Box className={classes.flexAndGap}>
            <Typography variant="h5" sx={{ fontSize: "22px", fontWeight: 600 }}>
              Delete Confirmation
            </Typography>
            <Info size={22} fill="red" />
          </Box>
          <Typography sx={{ textAlign: "center", fontWeight: 500 }}>
            Are you sure you want to delete {type} <br /> “{name}”
          </Typography>
          <Box className={classes.buttonDiv}>
            <Button
              className={classes.yesButton}
              type="submit"
              size="small"
              disableElevation
              onClick={(e) => {
                e.stopPropagation();
                handleDelete && handleDelete();
              }}
              startIcon={!isDeleting && <Trash size={16} />}
              disabled={isDeleting}
              variant="contained"
            >
              {isDeleting ? <CircularProgress size={20} /> : "Confirm Delete"}
            </Button>
            <Button
              className={classes.noButton}
              type="submit"
              size="small"
              disableElevation
              onClick={(e) => {
                e.stopPropagation();
                handleCloseDeleteModal && handleCloseDeleteModal();
              }}
              startIcon={<X size={16} />}
              disabled={isDeleting}
            >
              Cancel Delete
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteModal;

const DeleteIconSvg = () => {
  return (
    <svg
      width="600"
      height="220"
      viewBox="0 0 548 203"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M527 181.5H528.5V180V13C528.5 6.64873 523.351 1.5 517 1.5H28C21.6487 1.5 16.5 6.64872 16.5 13V180V181.5H18H527Z"
        fill="#C6C4E4"
        stroke="#6C65D9"
        stroke-width="3"
      />
      <rect
        x="197.142"
        y="78.7227"
        width="28.4091"
        height="163.567"
        rx="5"
        transform="rotate(90 197.142 78.7227)"
        fill="#B5B2E4"
      />
      <rect
        x="196.281"
        y="120.906"
        width="28.4091"
        height="163.567"
        rx="5"
        transform="rotate(90 196.281 120.906)"
        fill="#B5B2E4"
      />
      <path
        d="M0 187C0 183.134 3.13401 180 7 180H541C544.866 180 548 183.134 548 187H0Z"
        fill="#B5B2E4"
      />
      <path
        d="M169.426 71.0254H209.574V101.614C209.574 104.375 207.336 106.614 204.574 106.614H174.426C171.664 106.614 169.426 104.375 169.426 101.614V71.0254Z"
        fill="#6C65D9"
      />
      <rect
        x="164"
        y="60.4707"
        width="51"
        height="7.54902"
        rx="2.5"
        fill="#1B1D28"
      />
      <rect
        x="178.107"
        y="54"
        width="21.7021"
        height="14.0196"
        rx="2.5"
        fill="#1B1D28"
      />
      <path
        d="M183.532 79.6523V95.5592"
        stroke="#1B1D28"
        stroke-width="5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M195.536 79.6523V95.5592"
        stroke="#1B1D28"
        stroke-width="5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.8201 156.257C40.2077 121.933 80.837 110.506 100.046 110.066C169.551 104.787 166.093 148.754 166.239 180.134L100.693 180.134L35.1467 180.134C35.1474 171.336 34.8485 163.858 35.8201 156.257Z"
        fill="#6C65D9"
      />
      <path
        d="M56.702 117.668C61.277 98.3122 84.7094 94.353 95.8536 94.793C116.265 88.4583 130.46 100.072 135.006 106.67C140.871 114.735 142.66 131.569 102.892 134.385C63.1246 137.2 55.5289 124.413 56.702 117.668Z"
        fill="#A09AFB"
      />
      <path
        d="M76.4967 102.71C83.5352 98.4873 93.7997 98.6046 98.0521 99.1911C107.437 100.364 124.271 104.822 116.528 113.268C108.786 121.715 93.6531 118.547 87.0544 115.908C80.6025 113.268 69.4582 106.933 76.4967 102.71Z"
        fill="#252A38"
      />
      <path
        d="M84.8553 115.029L84.4154 91.7139L109.05 99.6322L110.37 117.228C103.331 120.748 90.1342 117.668 84.8553 115.029Z"
        fill="#E5986D"
      />
      <path
        d="M109.93 109.75C101.484 111.157 92.9201 104.178 89.6942 100.512L109.49 103.151L109.93 109.75Z"
        fill="#252A38"
      />
      <path
        d="M117.553 76.1607C119.768 90.3228 117.717 102.907 107.73 104.469C90.5737 107.153 83.6025 95.9796 81.3874 81.8175C79.1722 67.6554 85.4723 54.9085 95.4591 53.3464C105.446 51.7844 115.337 61.9987 117.553 76.1607Z"
        fill="#E6986D"
      />
      <path
        d="M107.001 69.5334C108.006 68.6725 110.531 67.4673 112.597 69.5334"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M98.3926 74.3076L98.823 78.612"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M110.875 73.0166L111.306 77.321"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M107.001 77.751C108.006 78.7553 109.842 82.0554 109.154 87.2207"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M94.9491 72.116C95.523 71.2552 97.3595 69.7056 100.114 70.3943"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M89.995 79.0357C90.79 84.1188 90.0539 88.6355 86.4695 89.1962C80.3117 90.1593 77.8096 86.1491 77.0145 81.066C76.2195 75.9829 78.4807 71.4078 82.0652 70.8471C85.6496 70.2865 89.1999 73.9526 89.995 79.0357Z"
        fill="#E6986D"
      />
      <path
        d="M80.4138 80.3887C80.4138 81.7084 80.8537 84.3478 82.6133 84.3478"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M112 95C112 95.4431 111.763 95.9205 111.228 96.3213C110.694 96.7224 109.909 97 109 97C108.091 97 107.306 96.7224 106.772 96.3213C106.237 95.9205 106 95.4431 106 95C106 94.5569 106.237 94.0795 106.772 93.6787C107.306 93.2776 108.091 93 109 93C109.909 93 110.694 93.2776 111.228 93.6787C111.763 94.0795 112 94.5569 112 95Z"
        stroke="#1B1D28"
        stroke-width="2"
      />
      <path
        d="M124.887 54.3208C119.608 64.1747 108.317 60.7728 103.331 57.84C100.516 67.3419 91.0141 67.9579 86.6152 67.0782C88.6058 71.0593 88.4413 75.2751 87.8631 77.9218C87.5541 79.3362 86.0707 79.9062 84.7151 79.3979L80.0166 77.6359C73.682 80.8033 67.8458 76.3162 66.3793 73.6768C62.1562 62.4152 71.5114 57.5469 75.6172 56.9603C76.673 48.1621 80.163 44.2029 82.2159 42.8832C95.9409 33.7332 101.425 39.9505 102.452 44.2029C104.915 42.0914 110.223 41.2702 112.569 41.1236C118.875 41.4169 130.166 44.4669 124.887 54.3208Z"
        fill="#1B1D28"
      />
      <path
        d="M65.4994 129.985C69.7518 132.038 81.2481 135.88 93.2135 134.824"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M135.445 123.827C133.685 126.467 128.846 129.546 122.247 130.866"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M93.6544 146.702C93.6544 146.702 95.4141 159.899 93.6544 164.738"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M114.33 144.502C114.33 144.502 116.53 153.74 121.808 160.339"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M112.087 179.808C108.128 182.448 102.996 183.621 102.409 185.527L88.3322 177.169C106.808 169.251 109.448 177.609 112.087 179.808Z"
        fill="#E5986D"
      />
      <path
        d="M50.9405 202.682C37.7433 200.042 35.1037 185.965 35.1039 178.047L54.8997 178.927L84.8133 174.528C97.4826 173.824 103.729 181.126 104.169 185.965C104.609 190.805 64.1377 205.322 50.9405 202.682Z"
        fill="#6C65D9"
      />
      <path
        d="M99.3301 178.488C101.236 179.808 105.049 183.415 105.049 187.286"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M83.0535 176.289C72.0559 178.489 63.2577 176.729 63.2577 176.729"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M171.8 114.806L167.824 120.024C167.078 121.003 167.384 122.417 168.468 122.999L176.627 127.378C177.119 127.642 177.699 127.689 178.227 127.506L205.955 117.909C208.087 117.171 207.557 114.019 205.301 114.019H173.391C172.767 114.019 172.178 114.31 171.8 114.806Z"
        fill="#E5986D"
      />
      <path
        d="M184.664 131.728C176.254 141.917 169.66 161.629 165.942 173.916C165.256 176.182 162.775 177.379 160.581 176.487L142.385 169.085C140.59 168.355 139.566 166.442 140.057 164.568C145.197 144.935 158.565 126.703 166.464 117.965C167.676 116.625 169.649 116.356 171.224 117.242L183.698 124.259C186.259 125.699 186.535 129.462 184.664 131.728Z"
        fill="#6C64D9"
      />
      <path
        d="M157.793 127.361C154.78 131.666 149.615 139.414 147.463 148.022"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M180.176 122.627L185.341 125.64"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};
