import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { CaretDown } from "@phosphor-icons/react";
import useStyles from "./ExportPreview.style";

const PDFDownloadOptions = ({ selectedPdfFormat, handlePdfOptions }: any) => {
  const classes = useStyles();

  return (
    <Box className={classes.downloadPdfContainer}>
      <Typography style={{ textAlign: "center" }}>Download Options</Typography>
      <Typography className={classes.downloadOptionPdf}>Pdf</Typography>
      <FormGroup
        style={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "85%",
          alignSelf: "center",
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  name="Cover"
                  value="Cover"
                  checked={selectedPdfFormat.Cover}
                  onChange={handlePdfOptions}
                />
              }
              label={<Typography>Cover</Typography>}
            />
          </Grid>
          <Grid item md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  name="Breakdown"
                  value="Breakdown"
                  checked={selectedPdfFormat.Breakdown}
                  onChange={handlePdfOptions}
                />
              }
              label={<Typography>Breakdown</Typography>}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  value="Index"
                  name="Index"
                  checked={selectedPdfFormat.Index}
                  onChange={handlePdfOptions}
                />
              }
              label={<Typography>Index</Typography>}
            />
          </Grid>
          <Grid item md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  name="Storyboard"
                  value="Storyboard"
                  checked={selectedPdfFormat.Storyboard}
                  onChange={handlePdfOptions}
                  aria-label="StoryBoard"
                />
              }
              label={<Typography>Storyboard</Typography>}
            />
          </Grid>
        </Grid>
      </FormGroup>
      <Accordion style={{ backgroundColor: "transparent" }}>
        <AccordionSummary expandIcon={<CaretDown color="#f9f9f9" />}>
          <Typography style={{ color: "#f9f9f9" }}>Preview</Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {selectedPdfFormat.Breakdown && !selectedPdfFormat.Storyboard && (
            <img height={120} width={130} src="/images/breakdown.png" alt="" />
          )}
          {selectedPdfFormat.Storyboard && !selectedPdfFormat.Breakdown && (
            <img height={120} width={130} src="/images/storyboard.png" alt="" />
          )}
          {selectedPdfFormat.Storyboard && selectedPdfFormat.Breakdown && (
            <img
              height={120}
              width={130}
              src="/images/storyboardBreakdown.png"
              alt=""
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default PDFDownloadOptions;
