import { makeStyles } from "@material-ui/core/styles";
import { themeType } from "../../../types/theme-types";

const useStyles = makeStyles((theme: themeType) => ({
  root: {
    height: "100%",
    backgroundColor: "#1B1D28",
  },
  breakDownText: {
    height: "6%",
    // borderBottom: "2px solid black",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 10px",
  },

  leftMenuAndRightDetails: {
    height: "92%",
    backgroundColor: "#252A38",
    padding: "10px 0px 10px 10px",
  },

  leftSideContents: {
    height: "100%",
    backgroundColor: "#1B1D28",
    borderRadius: "10px",
    padding: "5px",
  },
  breakDownElement: {
    height: "5%",
    textAlign: "center",
    color: "#6C65D9",
  },
  rightSideContainer: {
    height: "100%",
    borderRadius: "10px",
  },
  rightSideTitle: {
    height: "5%",
    backgroundColor: "#1B1D28",
    borderRadius: "10px",
    marginBottom: "10px",
    border: "2px solid #6C65D9",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4px 2px 4px 8px",
  },
  dotAndText: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  generateBreakdownButton: {
    color: "white !important",
    minWidth: "0px !important",
    borderRadius: "5px !important",
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  rightSideTools: {
    height: "4%",
    marginBottom: "5px",
    display: "flex",
    alignItems: "center",
    padding: "0px 5px",
    justifyContent: "space-between",
  },

  shortListContainer: {
    overflowY: "auto",
    paddingRight: "8px",
  },

  shortListCard: {
    backgroundColor: "#1B1D28",
    // marginBottom: "20px",
    padding: "10px",
    borderRadius: "10px",
  },

  shortListMenuItem: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    color: "#606479",
  },
  shortListItemHide: {
    display: "none",
  },

  bottomButtonContainer: {
    height: "6%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
    gap: "20px",
  },
  breakDownButton: {
    borderRadius: "5px",
  },

  storyBoardButton: {
    borderRadius: "5px",
  },

  singleCharacterOrLocationCard: {
    backgroundColor: "#1B1D28",
    marginBottom: "10px",
    padding: "10px",
    borderRadius: "10px",
  },

  searchBoxContainer: {
    height: "35px",
    width: "98%",
    backgroundColor: "#252A38",
    borderRadius: "10px",
    marginBottom: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "4px",
  },
  searchInput: {
    height: "80%",
    backgroundColor: "transparent",
    border: "none",
    width: "80%",
    outline: "none",
    padding: "4px 8px",
    color: "white",
    fontFamily: "Poppins !important",
  },

  modal: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "20px",
    justifyContent: "center",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  flexEnd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "relative",
  },

  selectAllButton: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    color: "#606479 !important",
    padding: "0px !important",
  },
  clearAllButton: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    color: "#6C65D9 !important",
  },

  //shot details styles

  addShotButtonContainer: {
    height: "100%",
    overflow: "hidden",
    backgroundColor: "#606479",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  addNewShotButton: {
    width: "40px !important",
    borderRadius: "10px !important",
    zIndex: 2,
    position: "absolute",
    padding: "5px 0px !important",
    color: "white !important",
    minWidth: "0px !important",
  },
  closeEdit: {
    cursor: "pointer",
    backgroundColor: "#252A38",
    padding: "2px",
    borderRadius: "5px",
  },
  confirmEdit: {
    cursor: "pointer",
    backgroundColor: "#6C65D9",
    padding: "2px",
    borderRadius: "5px",
  },
  shotInfo: {
    width: "550px",
    padding: "10px",
    borderRadius: "5px",
    border: "0.5px solid #6C65D9",
    backgroundColor: "#252A38",
    position: "absolute",
    right: "55%", // Adjust as needed//
    top: "-50%",
    zIndex: 99,
  },
  firstShotInfo: {
    width: "550px",
    padding: "10px",
    borderRadius: "5px",
    border: "0.5px solid #6C65D9",
    backgroundColor: "#252A38",
    position: "absolute",
    right: "55%", // Adjust as needed//
    top: "-5%",
    zIndex: 99,
  },
  dividerContainer: {
    display: "flex",
    gap: "20px",
  },
  categoryContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  initialContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    width: "40%",
  },
  initialContainer2: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    width: "50%",
  },
  textFirstWord: {
    color: "#F9F9F9",
    wordWrap: "break-word",
    width: "90%",
  },
  secondWord: {
    textAlign: "center",
    width: "10%",
  },
  lastWord: {
    wordWrap: "break-word",
    color: "#6C65D9",
    overflowWrap: "break-word",
  },

  // select genres modal style
  selectGenreRoot: {
    width: "600px !important",
    fontFamily: "poppins",
    backgroundColor: "#1B1D28",
    padding: "20px",
    borderRadius: "20px",
  },
  inputField: {
    outline: "none",
    borderRadius: "5px",
    backgroundColor: "#252A38",
    padding: "4px 8px",
    "& .MuiInputBase-input": {
      color: "white",
      padding: "10px",
    },
  },

  inputLabelAndField: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    width: "100%",
  },

  inputLabel: {
    color: "#606479",
    fontSize: "12px",
  },

  inputsContainer: {
    display: "flex",
    gap: "10px",
  },

  imageInput: {
    cursor: "pointer",
    opacity: 0,
    width: "100%",
    height: "100%",
    fontSize: "0",
    "&:hover": {
      cursor: "pointer",
    },
    borderRadius: "0px",
  },
  iconStyle: {
    width: "100%",
    marginTop: "30%",
    color: "red",
  },

  imageInputAndNameAndAbbreviation: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "15px",
    alignItems: "start",
  },

  imageViewerDiv: {
    width: "100%",
    height: "150px",
    position: "relative",
    borderRadius: "0px",
  },

  removeThumbnailIcon: {
    color: "#fff",
    backgroundColor: "red",
    position: "absolute",
    height: "24px",
    width: "24px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    right: "-8px",
    top: "-8px",
    cursor: "pointer",
    borderColor: "transparent",
  },
  imageContentDiv: {
    width: "100%",
    height: "100%",
    display: "grid",
    // borderRadius: "10px",
    overflow: "hidden",
  },
  projectThumbnail: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    // borderRadius: "10px",
    objectFit: "cover",
  },
  cardContentDiv2: {
    width: "100%",
    height: "150px",
    display: "grid",
    // marginTop: "2%",
    placeItems: "center",
    backgroundColor: "#252A38",
    borderRadius: "10px 10px 0px 0px",
    backgroundPosition: "center",
    backgroundSize: "25px",
    backgroundRepeat: "no-repeat",
  },
}));

export default useStyles;
