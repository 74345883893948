import { Box, Button, CardContent, Typography } from "@mui/material";
import { CheckCircle, X } from "@phosphor-icons/react";
import useStyles from "./ReportIssueModal.style";

interface Props {
  handleClose: () => void;
  openReportIssueModal: () => void;
}

const ReportSuccessfulModal = ({
  handleClose,
  openReportIssueModal,
}: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <ReportIssueSvgIcon />
      <CardContent className={classes.cardContentsTwo}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Typography variant="h5" fontWeight={600}>
            Issue Reported!
          </Typography>
          <CheckCircle weight="fill" size={22} color="#28982D" />
        </Box>
        <Typography mt={2} fontSize={12} fontWeight={500}>
          Thank You for reporting an issue!
        </Typography>
        <Typography mt={2} fontSize={8}>
          It’s very helpful for us, to provide you with better experience!
        </Typography>
        <Box
          sx={{ mt: 2 }}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            justifyContent: "center",
          }}
        >
          <Button
            className={classes.crossBtn}
            startIcon={<X color="#F9F9F9" />}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            className={classes.btn}
            onClick={() => {
              handleClose();
              openReportIssueModal();
            }}
          >
            Report another issue
          </Button>
        </Box>
      </CardContent>
    </Box>
  );
};

const ReportIssueSvgIcon = () => {
  return (
    <svg
      width="650"
      height="400"
      viewBox="0 0 600 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M527 181.5H528.5V180V13C528.5 6.64873 523.351 1.5 517 1.5H28C21.6487 1.5 16.5 6.64872 16.5 13V180V181.5H18H527Z"
        fill="#C6C4E4"
        stroke="#6C65D9"
        stroke-width="3"
      />
      <path
        d="M0 187C0 183.134 3.13401 180 7 180H541C544.866 180 548 183.134 548 187H0Z"
        fill="#B5B2E4"
      />
      <rect
        x="197.142"
        y="78.7227"
        width="28.4091"
        height="163.567"
        rx="5"
        transform="rotate(90 197.142 78.7227)"
        fill="#B5B2E4"
      />
      <rect
        x="196.281"
        y="120.906"
        width="28.4091"
        height="163.567"
        rx="5"
        transform="rotate(90 196.281 120.906)"
        fill="#B5B2E4"
      />
      <path
        d="M36.51 156.258C40.8663 122.178 81.2062 110.833 100.279 110.397C169.289 105.155 165.855 148.809 166 179.965L35.8414 179.965C35.842 171.23 35.5453 163.805 36.51 156.258Z"
        fill="#6C65D9"
      />
      <path
        d="M57.243 117.943C61.7855 98.7253 85.0509 94.7943 96.1159 95.2311C116.382 88.9415 130.475 100.472 134.989 107.024C140.813 115.032 142.589 131.745 103.104 134.541C63.6199 137.336 56.0783 124.641 57.243 117.943Z"
        fill="#A09AFB"
      />
      <path
        d="M76.8971 103.093C83.8855 98.8998 94.0769 99.0163 98.299 99.5987C107.617 100.763 124.331 105.189 116.644 113.576C108.956 121.962 93.9313 118.817 87.3797 116.196C80.9737 113.576 69.9087 107.286 76.8971 103.093Z"
        fill="#252A38"
      />
      <path
        d="M85.1961 115.323L84.7593 92.1743L109.219 100.036L110.529 117.507C103.541 121.001 90.4373 117.944 85.1961 115.323Z"
        fill="#E5986D"
      />
      <path
        d="M110.092 110.081C101.706 111.479 93.2035 104.549 90.0005 100.909L109.655 103.53L110.092 110.081Z"
        fill="#252A38"
      />
      <path
        d="M117.661 76.7328C119.86 90.794 117.824 103.289 107.908 104.84C90.8742 107.504 83.9527 96.4105 81.7533 82.3492C79.5539 68.288 85.8092 55.6319 95.7248 54.0809C105.64 52.53 115.462 62.6716 117.661 76.7328Z"
        fill="#E6986D"
      />
      <path
        d="M109.136 93.932L103.822 91.5706C103.117 91.257 102.344 91.8323 102.545 92.5778C103.694 96.8435 106.969 96.8988 108.878 96.2358C109.101 96.1582 109.276 95.988 109.382 95.7764L109.624 95.293C109.879 94.7831 109.657 94.1635 109.136 93.932Z"
        stroke="#1B1D28"
        stroke-width="2"
        strokeLinejoin="round"
      />
      <path
        d="M107.908 68.9852C108.927 68.1117 111.49 66.8887 113.586 68.9852"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M99.1729 74.7021L99.6096 79.0699"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M111.839 73.3921L112.276 77.7598"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M107.908 78.1963C108.927 79.2154 110.791 82.564 110.092 87.8053"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M95.6787 71.6048C96.2611 70.7313 98.1246 69.1589 100.92 69.8577"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M89.4257 79.5852C90.2151 84.6321 89.4843 89.1166 85.9253 89.6733C79.8114 90.6296 77.3271 86.6479 76.5377 81.6011C75.7483 76.5542 77.9935 72.0116 81.5524 71.455C85.1113 70.8983 88.6363 74.5383 89.4257 79.5852Z"
        fill="#E6986D"
      />
      <path
        d="M79.9131 80.9302C79.9131 82.2405 80.3499 84.8611 82.097 84.8611"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M124.942 55.0475C119.701 64.8312 108.491 61.4535 103.541 58.5417C100.745 67.9759 91.311 68.5875 86.9434 67.714C88.9155 71.6582 88.7572 75.8342 88.1862 78.4631C87.8789 79.8779 86.3958 80.4481 85.0402 79.9397L80.3918 78.1966C74.1023 81.3414 68.3077 76.8863 66.8516 74.2656C62.6586 63.0842 71.9472 58.2506 76.0237 57.6682C77.072 48.9327 80.5371 45.0017 82.5754 43.6914C96.2027 34.6065 101.648 40.7795 102.667 45.0017C105.113 42.9052 110.383 42.0899 112.713 41.9443C118.973 42.2355 130.184 45.2638 124.942 55.0475Z"
        fill="#1B1D28"
      />
      <path
        d="M65.978 130.173C70.2002 132.211 81.6145 136.026 93.4948 134.977"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M135.425 124.058C133.678 126.679 128.873 129.736 122.322 131.046"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M93.9326 146.77C93.9326 146.77 94.3267 150.814 94.3267 155.618"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M114.461 144.586C114.461 144.586 114.461 148.63 117.039 152.561"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M132.152 151.747L124.577 154.397C123.823 154.661 123.298 155.349 123.242 156.147L122.436 167.772C122.353 168.972 123.34 169.974 124.541 169.908L149.17 168.552C150.142 168.499 150.934 167.753 151.047 166.787L152.594 153.51C152.732 152.322 151.803 151.279 150.607 151.279H141.675C140.57 151.279 139.675 150.383 139.675 149.279V143.205C139.675 142.101 138.78 141.205 137.675 141.205H135.492C134.387 141.205 133.492 142.101 133.492 143.205V149.859C133.492 150.709 132.954 151.467 132.152 151.747Z"
        fill="#E5986D"
      />
      <path
        d="M35.8521 179.072C35.6208 177.048 37.0854 175.366 39.053 174.841L123.328 152.368C125.733 151.727 128.239 152.956 129.021 155.319C130.811 160.731 133.159 170.111 129.704 174.399C124.694 180.618 76.4184 193.181 55.0162 193.181C39.6269 193.18 36.4324 184.147 35.8521 179.072Z"
        fill="#6C64D9"
      />
      <path
        d="M130.578 162.17C131.015 166.101 131.015 166.101 131.015 170.469"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M66.3726 151.251C66.8093 153.872 70.2162 159.812 83.8435 157.366L120.096 152.561"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default ReportSuccessfulModal;
