import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    fontFamily: "poppins",
  },
  card: {
    position: "absolute",
    top: "0px",
    right: "30px",
    color: "black",
    width: "320px",
    display: "flex",
    justifyContent: "flex-end",
  },

  cardContent: {
    alignSelf: "right",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    height: "120px",
    width: "200px",
    alignItems: "center",
    justifyContent: "center",
    
  },

}));

export default useStyles;
