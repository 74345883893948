import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Info } from "@phosphor-icons/react";
import { FileImportIcon } from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import useStyles from "./InvalidFileFormatModal.style";

type Props = {
  handleCloseDeleteModal: () => void;
};
const InvalidFileFormatModal = ({ handleCloseDeleteModal }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <DeleteIconSvg />
      <Box className={classes.card}>
        <Box className={classes.cardContent}>
          <Box className={classes.flexAndGap}>
            <Typography variant="h5" sx={{ fontSize: "22px", fontWeight: 600 }}>
              Opps!
            </Typography>
            <Info size={22} fill="red" />
          </Box>
          <Typography sx={{ textAlign: "center", fontWeight: 500 }}>
            Seems like there was an issue <br /> with the PDF format.
          </Typography>
          <Box className={classes.buttonDiv}>
            <Button
              className={classes.yesButton}
              type="submit"
              size="small"
              disableElevation
              onClick={(e) => {
                e.stopPropagation();
                handleCloseDeleteModal();
              }}
              startIcon={<FileImportIcon />}
              variant="contained"
            >
              Back to Script Input
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InvalidFileFormatModal;

const DeleteIconSvg = () => {
  return (
    <svg
      width="600"
      height="220"
      viewBox="0 0 491 203"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M471.816 181.333H473.316V179.833V13C473.316 6.64873 468.168 1.5 461.816 1.5H26.4314C20.0801 1.5 14.9314 6.64872 14.9314 13V179.833V181.333H16.4314H471.816Z"
        fill="#C6C4E4"
        stroke="#6C65D9"
        stroke-width="3"
      />
      <path
        d="M0 186.876C0 182.986 3.15283 179.833 7.04204 179.833H483.553C487.442 179.833 490.595 182.986 490.595 186.876H0Z"
        fill="#B5B2E4"
      />
      <rect
        x="197.142"
        y="78.7227"
        width="28.4091"
        height="163.567"
        rx="5"
        transform="rotate(90 197.142 78.7227)"
        fill="#B5B2E4"
      />
      <rect
        x="196.281"
        y="120.906"
        width="28.4091"
        height="163.567"
        rx="5"
        transform="rotate(90 196.281 120.906)"
        fill="#B5B2E4"
      />
      <path
        d="M34.8201 156.257C39.2076 121.933 79.837 110.506 99.0462 110.066C168.551 104.787 165.092 148.754 165.239 180.134L99.6928 180.134L34.1467 180.134C34.1474 171.336 33.8485 163.858 34.8201 156.257Z"
        fill="#6C65D9"
      />
      <path
        d="M55.702 117.668C60.277 98.3122 83.7093 94.353 94.8536 94.793C115.265 88.4583 129.46 100.072 134.006 106.67C139.871 114.735 141.66 131.569 101.892 134.385C62.1246 137.2 54.5289 124.413 55.702 117.668Z"
        fill="#A09AFB"
      />
      <path
        d="M75.4967 102.71C82.5352 98.4868 92.7997 98.6041 97.0521 99.1906C106.437 100.364 123.271 104.821 115.528 113.268C107.786 121.714 92.6531 118.547 86.0544 115.907C79.6025 113.268 68.4582 106.933 75.4967 102.71Z"
        fill="#252A38"
      />
      <path
        d="M83.8553 115.029L83.4154 91.7139L108.05 99.6322L109.37 117.228C102.331 120.748 89.1342 117.668 83.8553 115.029Z"
        fill="#E5986D"
      />
      <path
        d="M108.93 109.75C100.484 111.157 91.9201 104.178 88.6942 100.512L108.49 103.151L108.93 109.75Z"
        fill="#252A38"
      />
      <path
        d="M116.553 76.1612C118.768 90.3233 116.717 102.908 106.73 104.47C89.5737 107.153 82.6025 95.9801 80.3874 81.818C78.1722 67.6559 84.4723 54.909 94.4591 53.3469C104.446 51.7848 114.337 61.9992 116.553 76.1612Z"
        fill="#E6986D"
      />
      <path
        d="M106.001 69.5329C107.006 68.672 109.531 67.4668 111.597 69.5329"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M97.3926 74.3071L97.823 78.6115"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M109.875 73.0161L110.306 77.3205"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M106.001 77.751C107.006 78.7553 108.842 82.0554 108.154 87.2207"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M93.9491 72.116C94.523 71.2552 96.3595 69.7056 99.1143 70.3943"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M105.53 94.2188L111.125 92.4971"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M88.995 79.0357C89.79 84.1188 89.0539 88.6355 85.4695 89.1962C79.3117 90.1593 76.8096 86.1491 76.0145 81.066C75.2195 75.9829 77.4807 71.4078 81.0652 70.8471C84.6496 70.2865 88.1999 73.9526 88.995 79.0357Z"
        fill="#E6986D"
      />
      <path
        d="M79.4138 80.3892C79.4138 81.7089 79.8537 84.3483 81.6133 84.3483"
        stroke="#1B1D28"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M123.887 54.3208C118.608 64.1747 107.317 60.7728 102.331 57.84C99.5157 67.3419 90.0141 67.9579 85.6152 67.0782C87.6058 71.0593 87.4413 75.2751 86.8631 77.9218C86.5541 79.3362 85.0707 79.9062 83.7151 79.3979L79.0166 77.6359C72.682 80.8033 66.8458 76.3162 65.3793 73.6768C61.1562 62.4152 70.5114 57.5469 74.6172 56.9603C75.673 48.1621 79.1629 44.2029 81.2158 42.8832C94.9409 33.7332 100.425 39.9505 101.452 44.2029C103.915 42.0914 109.223 41.2702 111.569 41.1236C117.875 41.4169 129.166 44.4669 123.887 54.3208Z"
        fill="#1B1D28"
      />
      <path
        d="M64.4994 129.985C68.7518 132.038 80.2481 135.88 92.2135 134.824"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M134.445 123.827C132.685 126.467 127.846 129.546 121.247 130.866"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M92.6544 146.703C92.6544 146.703 94.4141 159.9 92.6544 164.739"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M113.33 144.502C113.33 144.502 115.53 153.74 120.808 160.339"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M111.087 179.809C107.128 182.448 101.996 183.621 101.409 185.527L87.3322 177.17C105.808 169.251 108.448 177.609 111.087 179.809Z"
        fill="#E5986D"
      />
      <path
        d="M49.9405 202.682C36.7433 200.042 34.1037 185.966 34.1039 178.048L53.8997 178.927L83.8133 174.528C96.4826 173.824 102.729 181.127 103.169 185.966C103.609 190.805 63.1377 205.323 49.9405 202.682Z"
        fill="#6C65D9"
      />
      <path
        d="M98.3301 178.489C100.236 179.808 104.049 183.416 104.049 187.287"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M82.0535 176.289C71.0558 178.489 62.2577 176.729 62.2577 176.729"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M170.8 114.806L166.824 120.024C166.078 121.003 166.384 122.417 167.468 122.999L175.627 127.378C176.119 127.642 176.699 127.689 177.227 127.506L204.955 117.909C207.087 117.171 206.557 114.019 204.301 114.019H172.391C171.767 114.019 171.178 114.31 170.8 114.806Z"
        fill="#E5986D"
      />
      <path
        d="M183.664 131.728C175.254 141.916 168.66 161.628 164.942 173.915C164.256 176.182 161.775 177.379 159.581 176.486L141.385 169.084C139.59 168.354 138.566 166.441 139.057 164.567C144.197 144.935 157.565 126.703 165.464 117.965C166.676 116.624 168.648 116.355 170.224 117.241L182.698 124.258C185.259 125.699 185.535 129.461 183.664 131.728Z"
        fill="#6C64D9"
      />
      <path
        d="M156.793 127.362C153.78 131.666 148.615 139.414 146.463 148.023"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M179.176 122.627L184.341 125.64"
        stroke="#252A38"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M182.517 67C184.827 63 190.6 63 192.91 67L210.848 98.0702C213.157 102.07 210.271 107.07 205.652 107.07H169.775C165.156 107.07 162.27 102.07 164.579 98.0702L182.517 67Z"
        fill="#6C64D9"
      />
      <path
        d="M190.036 78.644L189.476 92.056H185.416L184.856 78.644H190.036ZM187.516 99.224C186.676 99.224 185.985 98.9813 185.444 98.496C184.921 97.992 184.66 97.376 184.66 96.648C184.66 95.9013 184.921 95.276 185.444 94.772C185.985 94.268 186.676 94.016 187.516 94.016C188.337 94.016 189.009 94.268 189.532 94.772C190.073 95.276 190.344 95.9013 190.344 96.648C190.344 97.376 190.073 97.992 189.532 98.496C189.009 98.9813 188.337 99.224 187.516 99.224Z"
        fill="#F9F9F9"
      />
    </svg>
  );
};
