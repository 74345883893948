import { Box, TextField, Typography } from "@mui/material";

type Props = {
  title: string;
  isRequired: boolean;
  placeholder: string;
  handleChange: (value: string) => void;
  value: string;
  handleEnter?: (value: any) => void;
};

const CustomInputField = ({
  title,
  isRequired,
  placeholder,
  handleChange,
  value,
  handleEnter,
}: Props) => {
  return (
    <Box>
      <Typography sx={{ mb: 2 }}>
        {title} <span style={{ color: "red" }}>{isRequired ? "*" : ""}</span>{" "}
      </Typography>
      <TextField
        onChange={(e) => handleChange(e.target.value)}
        onKeyDown={(e) => handleEnter && handleEnter(e.key)}
        value={value}
        type="text"
        variant="standard"
        placeholder={placeholder}
        fullWidth
        sx={{
          backgroundColor: "#252A38",
          color: "white",
          borderRadius: "10px",
        }}
        InputProps={{
          disableUnderline: true,
          style: {
            height: "40px",
          },
        }}
        inputProps={{
          disableFieldSet: true,
          style: {
            padding: "8px",
            color: "white",
          },
        }}
      />
    </Box>
  );
};

export default CustomInputField;
