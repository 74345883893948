import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const getAllTransactionHistory = async () => {
  const res = await axios.get(`${baseUrl + "payments/list-transactions"}`);
  return res;
};

export const getProductList = async () => {
  const res = await axios.get(`${baseUrl + "payments/list-products"}`);
  return res;
};

export const getAvailableCredits = async () => {
  const res = await axios.get(`${baseUrl + "user/credits/"}`);
  return res;
};

export const getCreditLogs = async () => {
  const res = await axios.get(`${baseUrl + "user/credit-logs/"}`);
  return res;
};

export const purchaseProduct = async (
  paymentPlan: string,
  clientSuccessRedirection: string,
  clientFailureRedirection: string
) => {
  const res = await axios.post(`${baseUrl + `payments/new-purchase`}`, {
    paymentPlan: paymentPlan,
    clientSuccessRedirection: clientSuccessRedirection,
    clientFailureRedirection: clientFailureRedirection,
  });
  return res;
};
