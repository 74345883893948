import axios from "axios";
import {
  ProjectIdType,
  ProjectsType,
  SingleProjectDetails,
} from "../../types/public-types";

const baseUrl = process.env.REACT_APP_BASE_URL;

interface ProjectGetApi {
  size: number;
  previous: string;
  next: string;
  projects: ProjectsType;
}

interface CreateNewProjectOrEditProjectApi {
  id: string;
  message: string;
  name: string;
}

export const getAllProject = async () => {
  const res = await axios.get<ProjectGetApi>(`${baseUrl + "project/"}`);
  return res;
};

export const getProjectDetails = async (projectId: ProjectIdType) => {
  const res = await axios.get<SingleProjectDetails>(
    `${baseUrl + `project/${projectId}`}`
  );
  return res;
};

export const createNewProject = async (data: { name: string }) => {
  const res = await axios.post<CreateNewProjectOrEditProjectApi>(
    `${baseUrl + "project/"}`,
    data
  );
  return res;
};

export const updateProject = async (
  id?: ProjectIdType,
  data?: { name: string }
) => {
  const res = await axios.patch<CreateNewProjectOrEditProjectApi>(
    `${baseUrl + "project/" + id}`,
    data
  );
  return res;
};

export const deleteProject = async (projectId: ProjectIdType | undefined) => {
  const res = await axios.delete(`${baseUrl + "project/" + projectId}`);
  return res;
};

export const getShotQueueDetail = async () => {
  const res = await axios.get(`${baseUrl}generation-queue/`);
  return res;
};
