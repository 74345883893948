import { TextField } from "@mui/material";

const ShotEditInput = ({
  value,
  setValue,
}: {
  value: string;
  setValue: (value: string) => void;
}) => {
  return (
    <TextField
      fullWidth
      multiline
      value={value}
      onChange={(e) => setValue(e.target.value)}
      variant="standard"
      InputProps={{
        disableUnderline: true,
      }}
      inputProps={{
        disableFieldSet: true,
      }}
      sx={{
        "& .MuiInputBase-input": {
          color: "white",
          border: "none",
          padding: 0,
          fontSize: "12px",
        },
        "& .MuiInputBase-root": {
          padding: 0,
          border: "none",
        },
        "& .MuiOutlinedInput-root": {
          border: "none",
          "&:focus": {
            border: "none",
            borderColor: "transparent", // Make the focus border transparent
          },
        },
      }}
    />
  );
};

export default ShotEditInput;
