import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import SequenceStoryBoardOverview from "../../Components/SequenceStoryBoardComponent/SequenceStoryBoardOverview/SequenceStoryBoardOverview";

const SequenceStoryBoardPage = () => {
  const { projectId, sequenceId } = useParams();
  return (
    <Box
      sx={{
        height: {
          sm: `calc(100vh - 45px)`,
          md: `calc(100vh - 45px)`,
          lg: `calc(100vh - 45px)`,
          xl: `calc(100vh - 65px)`,
        },
      }}
    >
      <SequenceStoryBoardOverview
        projectId={projectId || ""}
        sequenceId={sequenceId || ""}
      />
    </Box>
  );
};

export default SequenceStoryBoardPage;
