import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    height: "68%",
    width: "100%",
    backgroundColor: "#1B1D28",
    marginTop: "20px",
    borderRadius: "10px",
    padding: "0px 8px",
  },
  tableHeaderCells: {
    backgroundColor: "#1B1D28 !important",
    color: "rgba(249, 249, 249, 0.5) !important",
  },
  //table body style
  tableCellsGray: {
    color: "rgba(249, 249, 249, 0.5) !important",
    fontFamily: "Poppins",
    textAlign: "center",
  },
  wordWraps: {
    width: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down(1485)]: {
      width: "110px",
    },
    [theme.breakpoints.down("md")]: {
      width: "260px",
    },
  },
  tableCellsWhite: {
    color: "rgba(249, 249, 249, 1) !important",
    fontFamily: "Poppins",
    textAlign: "center",
  },
  text: {
    fontSize: "12px !important",
    color: "#606479",
    fontFamily: "Poppins",
  },
}));

export default useStyles;
