import { Box } from "@mui/material";
import CreditPageOverview from "../../Components/CreditPageComponent/CreditPageOverview/CreditPageOverview";

const CreditPage = () => {
  return (
    <Box sx={{ mx: 4, height: `calc(100vh - 65px)` }}>
      <CreditPageOverview />
    </Box>
  );
};

export default CreditPage;
