import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { CreditLog } from "../../../types/public-types";
import useStyles from "./CreditUsageTable.style";
import CreditUsageTableItems from "./CreditUsageTableItems";
import NoDataFoundCreditPage from "../NoDataFoundCreditPage/NoDataFoundCreditPage";
import clsx from "clsx";

type Props = {
  creditUsageList: CreditLog[];
  isLoading: any;
};

const CreditUsageTable = ({ creditUsageList, isLoading }: Props) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="left" className={classes.tableHeaderCells}>
              Date
            </TableCell>
            <TableCell align="center" className={classes.tableHeaderCells}>
              Spent On
            </TableCell>
            <TableCell align="center" className={classes.tableHeaderCells}>
              Details
            </TableCell>
            <TableCell align="center" className={classes.tableHeaderCells}>
              Project
            </TableCell>
            <TableCell align="right" className={classes.tableHeaderCells}>
              Amount
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ position: "relative" }}>
          {!isLoading.creditList && creditUsageList.length > 0 ? (
            creditUsageList.map((creditUsageDetail: CreditLog) => {
              return (
                // key?
                <CreditUsageTableItems
                  key={creditUsageDetail.breakdownId}
                  creditUsageDetail={creditUsageDetail}
                />
              );
            })
          ) : (
            <Box
              style={{ position: "absolute", left: "34%", marginTop: "15%" }}
            >
              <NoDataFoundCreditPage />
            </Box>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CreditUsageTable;
