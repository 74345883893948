import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },

  //MC Project Details
  mcProjectDetailsRoot: {
    height: "71%",
    border: "2px solid #606479",
    backgroundColor: "#1B1D28",
    borderRadius: "10px",
    padding: "8px 6px 8px 12px",
    color: "#606479",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    marginTop: "10px",
  },
  mcProjectDetails: {
    backgroundColor: "#252A38",
    padding: "6px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  mcProjectSequences: {
    height: "68%",
    overflowY: "auto",
    paddingRight: "5px",
  },
  mcProjectSingleSequence: {
    backgroundColor: "#252A38",
    padding: "6px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "8px",
  },

  bdElements: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    width: "100%",
  },
  buttons: {
    backgroundColor: "#252A38",
    borderRadius: "10px",
    padding: "10px",
  },
  radioGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  radioButtons: {
    border: "1px solid #606479",
    borderRadius: "10px",
    padding: "8px !important",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  outlinedRadio: {
    width: "100%",
    border: "0.5px solid #6C65D9 !important",
    borderRadius: "5px !important",
    padding: "8px !important",
    color: "#606479 !important",
  },
  selectedRadio: {
    width: "100%",
    backgroundColor: "#6C65D9 !important",
    borderRadius: "5px !important",
    padding: "8px !important",
    color: "#F9F9F9 !important",
  },
  downloadPdfContainer: {
    width: "100%",
    backgroundColor: "#252A38",
    padding: "8px !important",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  outlinedCheckbox: {
    border: "0.5px solid #606479",
    borderRadius: "5px",
    padding: "4px 55px 4px 6px",
    color: "#606479",
  },
  selectedCheckbox: {
    border: "1px solid #6C65D9",
    borderRadius: "5px",
    padding: "4px 55px 4px 6px",
    color: "#F9F9F9",
  },
  downloadOptionPdf: {
    width: "95%",
    border: "1px solid #6C65D9",
    borderRadius: "5px",
    padding: "4px 6px",
    color: "#F9F9F9",
  },
  filledButton: {
    borderRadius: "10px !important",
    color: "white !important",
    backgroundColor: "#6C65D9 !important",
    padding: "8px !important",
    marginTop: "10px !important",
    marginBottom: "10px !important",
  },
  disabledButton: {
    borderRadius: "10px !important",
    color: "white !important",
    backgroundColor: "gray !important",
    padding: "8px !important",
    marginTop: "10px !important",
    marginBottom: "10px !important",
    cursor: "not-allowed",
  },

  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
    marginTop: "6px",
  },

  //sequence view styles

  sequenceListRoot: {
    height: "98%",
    border: "2px solid #606479",
    backgroundColor: "#252A38",
    borderRadius: "10px",
    padding: "8px 6px 8px 12px",
  },
  selectAll: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    height: "4%",
    borderBottom: "2px solid #1B1D28",
    paddingBottom: "6px",
    marginBottom: "6px",
    cursor: "pointer",
    color: "#6C65D9",
  },
  sequencesContainer: {
    height: "95%",
    borderRadius: "10px",
    overflow: "auto",
    paddingRight: "8px",
  },
  singleSequence: {
    padding: "6px",
    border: "1px solid transparent",
    borderRadius: "10px",
    cursor: "pointer",
    color: "#606479",
  },
  previewSequence: {
    padding: "6px",
    border: "1px solid #606479",
    borderRadius: "10px",
    backgroundColor: "#1B1D28",
    color: "#606479",
  },
  selectedSequence1: {
    padding: "6px",
    border: "1px solid #6C65D9",
    borderRadius: "10px",
    // backgroundColor: "#1B1D28",
    color: "#6C65D9",
  },
  selectedAndPrevSequence: {
    padding: "6px",
    border: "1px solid #6C65D9",
    borderRadius: "10px",
    backgroundColor: "#1B1D28",
    color: "#6C65D9",
  },

  //shot list preview section
  shotListRoot: {
    height: "100%",
    paddingRight: "8px",
    overflowY: "auto",
  },
  selectedSequence: {
    border: "2px solid #606479",
    backgroundColor: "#252A38",
    marginBottom: "6px",
    padding: "8px 12px !important",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  singleShot: {
    border: "2px solid #606479",
    backgroundColor: "#252A38",
    marginBottom: "6px",
    padding: "8px 12px !important",
    borderRadius: "10px",
  },
  shotNameImg: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  shotImg: {
    width: "50px",
    height: "40px",
    borderRadius: "5px",
  },
  noImgBox: {
    height: "40px",
    width: "50px",
    backgroundColor: "#D9D9D9",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  //export modal styles

  modalRoot: {
    width: "45%",
    backgroundColor: "#1B1D28",
    padding: "20px",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  projectDetails: {
    backgroundColor: "#252A38",
    padding: "6px 10px",
    borderRadius: "10px",
  },
  conflictedSequence: {
    border: "1px solid red",
    borderRadius: "10px",
    padding: "6px 10px",
    marginBottom: "6px",
  },
  conflictWarning: {
    padding: "0px 80px",
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  mergeSequence: {
    padding: "8px 32px",
    backgroundColor: "#252A38",
    borderRadius: "10px",
    width: "100%",
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",
  },
  separateSequence: {
    padding: "8px 32px",
    backgroundColor: "#252A38",
    borderRadius: "10px",
    width: "100%",
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",
    border: "2px solid #6C65D9",
  },
  backButton: {
    borderColor: "#6C65D9",
    color: "white",
    width: "150px",
    borderRadius: "10px",
  },
  exportButton: {
    backgroundColor: "#6C65D9",
    borderRadius: "10px",
  },
  //common
  heightWidthFull: {
    height: "100%",
    width: "100%",
  },
  flexAndGap: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export default useStyles;
