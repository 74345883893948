import { Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { IndexPageStyle } from "./IndexPage.style";
import Header from "../CommonComponents/Header";
import Footer from "../CommonComponents/Footer";

const styles = StyleSheet.create(IndexPageStyle);

const IndexPage = ({ projectName, sequencesToExport }) => {
  return (
    <Page size="A4" style={styles.page}>
      <Header projectName={projectName} />
      <Text
        style={{
          fontWeight: "semibold",
          fontSize: "18px",
          fontFamily: "Poppins",
        }}
        fixed
      >
        Index
      </Text>
      <View style={styles.borderBox} wrap>
        {sequencesToExport.map((eachSequence, index) => (
          <View
            key={eachSequence.sequence.id}
            style={{
              border: "1px solid black",
              borderTop:
                index === 0 || index % 20 === 0 ? "1px solid black" : "none",
              borderTopLeftRadius:
                index === 0 || index % 20 === 0 ? "10px" : "0px",
              borderTopRightRadius:
                index === 0 || index % 20 === 0 ? "10px" : "0px",

              borderBottom:
                sequencesToExport.length - 1 === index || (index + 1) % 20 === 0
                  ? "1px solid black"
                  : "none",
              borderBottomLeftRadius:
                sequencesToExport.length - 1 === index || (index + 1) % 20 === 0
                  ? "10px"
                  : "0px",
              borderBottomRightRadius:
                sequencesToExport.length - 1 === index || (index + 1) % 20 === 0
                  ? "10px"
                  : "0px",
              paddingTop: index === 0 || index % 20 === 0 ? "2px" : "0px",
            }}
          >
            <View style={{ padding: "5px 8px", width: "100%" }}>
              <View style={styles.tableItem}>
                <Text style={styles.textStyleTwo}>
                  {eachSequence.sequence.sceneNumber}.{" "}
                  {eachSequence.sequence.sceneTitle}
                </Text>
                <Text style={styles.tableTextOne}>
                  Shots:{" "}
                  <Text style={styles.textStyleThree}>
                    {eachSequence.sequence.breakdownShotCount}
                  </Text>
                </Text>
              </View>
              {!((index + 1) % 20 !== 0) ||
                (sequencesToExport.length - 1 !== index && (
                  <View
                    style={{
                      border: "0.5px solid black",
                      width: "100%",
                      margin: "5px 0px",
                    }}
                  />
                ))}
            </View>
          </View>
        ))}
      </View>
      <Footer />
    </Page>
  );
};

export default IndexPage;
