import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: "#1B1D28 !important",
    color: "#606479 !important",
    borderBottom: "1px solid #1B1D28 !important",
    fontSize: "14px !important",
  },
  tableCells: {
    backgroundColor: "#252A38 !important",
    borderBottom: "1px solid #606479 !important",
    fontSize: "16px !important",
  },
}));

export default useStyles;
