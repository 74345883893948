import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Radio,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ContextTypes,
  ScriptBreakdownContext,
} from "../../../Context/ContextDataProvider";
import { ROUTES } from "../../../Routes";
import { ProjectIdType } from "../../../types/public-types";
import {
  FilledButton,
  OutlinedButton,
} from "../../ReusableComponents/Buttons/Buttons";
import Loader from "../../ReusableComponents/Loader/Loader";
import ResponsivePhosphorIcon from "../../ReusableComponents/SvgAndIcons/ResponsivePhosphorIcon";
import { StoryBoardIcon } from "../../ReusableComponents/SvgAndIcons/StoryBoardIcon";
import useStyles from "./StoryBoardOverview.styles";

type Props = {
  projectId: ProjectIdType;
};

const StoryBoardOverview = ({ projectId }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedSequence, setSelectedSequence] = useState<string>("");
  const {
    sequenceList,
    isSequenceListLoading,
    handleGetProjectDetails,
  }: ContextTypes = useContext(ScriptBreakdownContext);

  useEffect(() => {
    if (isSequenceListLoading && !sequenceList?.length) {
      handleGetProjectDetails(projectId);
    }
  }, [projectId, isSequenceListLoading, sequenceList]);

  return (
    <Box className={classes.root}>
      <Box sx={{ height: "10%" }}>
        <Box className={classes.header}>
          <StoryBoardIcon fill="#F9F9F9" />
          <Typography className={classes.text}>Story Boarding</Typography>
        </Box>
        <Typography className={classes.subHeader}>
          Select a Sequence and a story board style you want to visualize in!
        </Typography>
      </Box>
      <Box sx={{ height: "78%", width: "100%", mt: 2 }}>
        <Grid container spacing={0} sx={{ px: 1 }}>
          <Grid item md={3}>
            <Typography sx={{ fontSize: "14px", color: "#8F9298" }}>
              Sequence Name
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography
              sx={{ textAlign: "center", fontSize: "14px", color: "#8F9298" }}
            >
              Version
            </Typography>
          </Grid>
          <Grid item md={7}>
            <Typography
              sx={{ textAlign: "center", fontSize: "14px", color: "#8F9298" }}
            >
              Description
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography
              sx={{ textAlign: "end", fontSize: "14px", color: "#8F9298" }}
            >
              Shot Count
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ backgroundColor: "#8F9298", my: 2 }} />
        <Box sx={{ height: "95%", overflowY: "auto", mt: 1, px: 1 }}>
          {!isSequenceListLoading ? (
            sequenceList.map((eachSequence) => {
              return (
                <Box
                  key={eachSequence?.id}
                  sx={{ bgcolor: "#252A38", mb: 1, p: 1, borderRadius: "10px" }}
                >
                  <Grid container spacing={0} alignItems="center">
                    <Grid item md={3}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Radio
                          style={{
                            padding: "0px",
                            margin: "0px",
                            marginRight: "10px",
                          }}
                          value={eachSequence.id}
                          checked={selectedSequence === eachSequence.id}
                          disabled={!eachSequence.selectedBreakdown}
                          onClick={() => setSelectedSequence(eachSequence.id)}
                        />
                        <Typography sx={{ fontSize: "14px" }}>
                          {eachSequence.sceneNumber + "   "}
                          {eachSequence.sceneTitle}
                        </Typography>
                        <CircularProgress variant="determinate" value={0} />
                      </div>
                    </Grid>
                    <Grid item md={1}>
                      {eachSequence.breakdownVersion ? (
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: "12px",
                            color: "#8F9298",
                          }}
                        >
                          Version : {eachSequence.breakdownVersion}
                        </Typography>
                      ) : (
                        <Typography sx={{ textAlign: "center" }}>
                          <ResponsivePhosphorIcon
                            sizes={{ md: 12, lg: 18, xl: 22 }}
                            icon="Warning"
                            fill="red"
                          />
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={7}>
                      <Typography sx={{ fontSize: "12px", color: "#8F9298" }}>
                        {eachSequence.sceneContent.slice(0, 130)}
                      </Typography>
                    </Grid>
                    <Grid item md={1}>
                      <Typography
                        sx={{
                          textAlign: "end",
                          color: "#8F9298",
                          fontSize: "12px",
                        }}
                      >
                        Shots:{" "}
                        <span style={{ fontSize: "12px", color: "white" }}>
                          {eachSequence.breakdownShotCount || "--"}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              );
            })
          ) : (
            <div>
              <Loader />
            </div>
          )}
        </Box>
      </Box>
      <Box style={{ display: "flex", gap: "36px", marginTop: "32px" }}>
        <OutlinedButton
          text="Back"
          disabled={false}
          handleClick={() => navigate(ROUTES.PROJECTS.VIEW(projectId))}
          fullWidth={false}
          minWidth={120}
        />
        <FilledButton
          text="Continue"
          disabled={!selectedSequence}
          handleClick={() =>
            navigate(ROUTES.STORYBOARD.SEQUENCE(projectId, selectedSequence))
          }
          fullWidth={false}
          minWidth={120}
          startIcon={
            <StoryBoardIcon fill={!selectedSequence ? "#1B1D28" : "#F9F9F9"} />
          }
          isLoading={false}
        />
      </Box>
    </Box>
  );
};

export default StoryBoardOverview;
