import {
  Box,
  Button,
  Chip,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { getStorage } from "firebase/storage";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import {
  FilledButton,
  OutlinedButton,
} from "../../../ReusableComponents/Buttons/Buttons";
import { CoinIconXsmall } from "../../../ReusableComponents/SvgAndIcons/SvgIcons";
import useStyles from "./CharactersStyles";

interface Props {
  handleClose: () => void;
  handleUpdate: () => void;
}

const GenerateCharacterImage = ({ handleClose }: Props) => {
  const storage = getStorage();
  const classes = useStyles();
  const [currentAlias, setCurrentAlias] = useState("");
  const SignUpValidationSchema = yup.object({
    characterName: yup
      .string()
      .label("characterName")
      .required("Name is required"),
    characterGender: yup
      .string()
      .label("characterGender")
      .required("characterGender is required"),
    characterAge: yup
      .string()
      .label("characterAge")
      .required("characterAge is required"),
    characterEthnicity: yup
      .string()
      .label("characterEthnicity")
      .required("characterEthnicity is required"),
  });
  const formik = useFormik({
    initialValues: {
      characterName: "",
      characterDescription: "null",
      characterGender: "Male",
      characterAge: "",
      characterEthnicity: "",
    },
    validationSchema: SignUpValidationSchema,
    onSubmit: async (values) => {
      console.log(values);
    },
  });

  return (
    <div className={classes.characterSettingsModalRoot}>
      <Typography variant="h5" textAlign="center">
        Generate Character Image
      </Typography>
      <Box>
        <Grid container spacing={2} mt={2}>
          <Grid item md={4} display="flex" flexDirection="column" gap={1}>
            <Typography className={classes.inputLabel}>
              Character Image
            </Typography>
            <Box>
              <Box
                width="100%"
                height="200px"
                bgcolor="#252A38"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                borderRadius={4}
                gap={2}
              ></Box>
            </Box>
          </Grid>
          <Grid item md={8} display="flex" flexDirection="column" gap={1}>
            <form
              onSubmit={formik.handleSubmit}
              style={{
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <Box className={classes.inputsContainer}>
                <Box className={classes.inputLabelAndField}>
                  <Typography className={classes.inputLabel}>
                    Prompt <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    placeholder="Enter Name"
                    type="text"
                    name="characterName"
                    fullWidth
                    variant="standard"
                    className={classes.inputField}
                    value={formik.values.characterName}
                    onChange={formik.handleChange}
                    sx={{ height: "80px" }}
                  />
                  {formik.errors.characterName && (
                    <Typography sx={{ color: "red", fontSize: "10px" }}>
                      {formik.errors.characterName}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="flex-end"
                justifyContent="space-between"
                gap={4}
              >
                <Box className={classes.inputsContainer} sx={{ width: "100%" }}>
                  <Box className={classes.inputLabelAndField}>
                    <Typography className={classes.inputLabel}>Age</Typography>
                    <TextField
                      placeholder="Enter Age"
                      type="number"
                      name="age"
                      fullWidth
                      variant="standard"
                      className={classes.inputField}
                      value={currentAlias}
                      onChange={(e) => setCurrentAlias(e.target.value)}
                    />
                    {formik.errors.characterName && (
                      <Typography sx={{ color: "red", fontSize: "10px" }}>
                        {formik.errors.characterName}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box className={classes.inputsContainer} sx={{ width: "100%" }}>
                  <Box className={classes.inputLabelAndField}>
                    <Typography className={classes.inputLabel}>
                      Gender
                    </Typography>
                    <Select
                      className={classes.inputField}
                      value={formik.values.characterGender}
                      onChange={formik.handleChange}
                      style={{ height: "40px" }}
                      name="characterGender"
                    >
                      <MenuItem
                        value="Male"
                        style={{ backgroundColor: "#252A38" }}
                      >
                        Male
                      </MenuItem>
                      <MenuItem
                        value="Female"
                        style={{ backgroundColor: "#252A38" }}
                      >
                        Female
                      </MenuItem>
                      <MenuItem
                        value="Other"
                        style={{ backgroundColor: "#252A38" }}
                      >
                        Other
                      </MenuItem>
                    </Select>
                    {formik.errors.characterName && (
                      <Typography sx={{ color: "red", fontSize: "10px" }}>
                        {formik.errors.characterName}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box className={classes.inputsContainer} sx={{ width: "100%" }}>
                  <Box className={classes.inputLabelAndField}>
                    <Typography className={classes.inputLabel}>
                      Ethnicity
                    </Typography>
                    <Select
                      className={classes.inputField}
                      value={formik.values.characterGender}
                      onChange={formik.handleChange}
                      style={{ height: "40px" }}
                      name="characterGender"
                    >
                      <MenuItem
                        value="Male"
                        style={{ backgroundColor: "#252A38" }}
                      >
                        Male
                      </MenuItem>
                      <MenuItem
                        value="Female"
                        style={{ backgroundColor: "#252A38" }}
                      >
                        Female
                      </MenuItem>
                      <MenuItem
                        value="Other"
                        style={{ backgroundColor: "#252A38" }}
                      >
                        Other
                      </MenuItem>
                    </Select>
                    {formik.errors.characterName && (
                      <Typography sx={{ color: "red", fontSize: "10px" }}>
                        {formik.errors.characterName}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box>
                <Button
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    color: "white",
                    px: 4,
                    py: 1,
                    borderRadius: "10px",
                  }}
                  variant="outlined"
                >
                  <span>Generate</span>
                  <Chip
                    style={{ color: "white", padding: "4px" }}
                    avatar={<CoinIconXsmall />}
                    label={2}
                    variant="outlined"
                  />
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>

        <div
          style={{
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <FilledButton
            text="save"
            handleClick={() => formik.handleSubmit()}
            disabled={false}
            isLoading={false}
            fullWidth={false}
            minWidth={200}
          />
          <OutlinedButton
            text="Cancel"
            handleClick={handleClose}
            disabled={false}
            // isLoading={false}
            fullWidth={false}
            minWidth={200}
          />
        </div>
      </Box>
    </div>
  );
};

export default GenerateCharacterImage;
