import { Box } from "@mui/material";

export const GenerateNewBreakdownSvg = () => {
  return (
    <Box
      sx={{
        height: { xs: "16px", sm: "16px", md: "18px", lg: "18px", xl: "24px" },
        width: { xs: "16px", sm: "16px", md: "18px", lg: "18px", xl: "24px" },
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.53625 2.74316C6.89184 2.74316 7.1801 3.03143 7.1801 3.38702C7.1801 3.74261 6.89184 4.03087 6.53625 4.03087H1.61798C1.26238 4.03087 0.974121 3.74261 0.974121 3.38702C0.974121 3.03143 1.26238 2.74316 1.61798 2.74316H6.53625ZM8.64548 5.81858C9.00107 5.81858 9.28934 6.10684 9.28934 6.46243C9.28934 6.81802 9.00107 7.10629 8.64548 7.10629H1.61798C1.26238 7.10629 0.974121 6.81802 0.974121 6.46243C0.974121 6.10684 1.26238 5.81858 1.61798 5.81858H8.64548ZM4.69339 8.89399C5.04898 8.89399 5.33724 9.18226 5.33724 9.53785C5.33724 9.89344 5.04898 10.1817 4.69339 10.1817H1.61797C1.26238 10.1817 0.974121 9.89344 0.974121 9.53785C0.974121 9.18226 1.26238 8.89399 1.61798 8.89399H4.69339ZM12.3819 8.89399C12.7375 8.89399 13.0258 9.18226 13.0258 9.53785C13.0258 9.89344 12.7375 10.1817 12.3819 10.1817H7.76881C7.41321 10.1817 7.12495 9.89344 7.12495 9.53785C7.12495 9.18226 7.41321 8.89399 7.76881 8.89399H12.3819ZM9.30651 11.9694C9.6621 11.9694 9.95037 12.2577 9.95037 12.6133C9.95037 12.9689 9.6621 13.2571 9.30651 13.2571H1.61797C1.26238 13.2571 0.974121 12.9689 0.974121 12.6133C0.974121 12.2577 1.26238 11.9694 1.61798 11.9694H9.30651Z"
          fill="#F9F9F9"
          stroke="#6C65D9"
          stroke-width="0.25"
        />
        <path
          d="M8.9982 3.64453C8.69591 3.59113 8.69591 3.15793 8.9982 3.1054C9.53344 3.01224 10.0288 2.7618 10.4212 2.38601C10.8135 2.01022 11.0851 1.52611 11.2012 0.995379L11.2193 0.910978C11.2848 0.612131 11.7102 0.609547 11.7783 0.908395L11.8007 1.00571C11.9212 1.53409 12.1951 2.01506 12.5882 2.38818C12.9812 2.7613 13.4758 3.00993 14.0097 3.10281C14.3137 3.15535 14.3137 3.59199 14.0097 3.64539C13.4759 3.73843 12.9816 3.98712 12.5887 4.36023C12.1958 4.73334 11.922 5.21423 11.8015 5.74249L11.7791 5.83895C11.7111 6.13693 11.2856 6.13521 11.2202 5.83636L11.203 5.75368C11.0867 5.22272 10.8149 4.73843 10.4222 4.36262C10.0295 3.98681 9.53376 3.73737 8.9982 3.64453Z"
          fill="#F9F9F9"
        />
      </svg>
    </Box>
  );
};
