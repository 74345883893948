import { Box } from "@mui/material";
import SignUpPageOverview from "../../Components/Authentications/SignUpPageOverview";

const SignUpPage = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <SignUpPageOverview />
    </Box>
  );
};

export default SignUpPage;
