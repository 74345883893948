import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import {
  PencilLine,
  PersonSimple,
  Slideshow,
  SortAscending,
  Trash,
} from "@phosphor-icons/react";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  deleteCharacterApi,
  getAllCharactersApi,
} from "../../../../Services/characterAndLocationServices/characterAndLocationServices";
import { Character, ProjectIdType } from "../../../../types/public-types";
import { IdentifyCharactersSvg } from "../../../ReusableComponents/SvgAndIcons/IdentifyCharactersSvg";
import { WhiteDot } from "../../../ReusableComponents/SvgAndIcons/SvgIcons";
import CharacterSettingsModal from "./CharacterSettingsModal";
import useStyles from "./CharactersStyles";
import ConfirmCharactersModal from "./ConfirmCharactersModal";
import Loader from "../../../ReusableComponents/Loader/Loader";
import NoCharacterImage from "../../../Utilities/NoCharImage.jpg";
import {
  ContextTypes,
  ScriptBreakdownContext,
} from "../../../../Context/ContextDataProvider";

interface Props {
  projectId: ProjectIdType;
  fileId: string;
}
const CharactersViewer = ({ projectId, fileId }: Props) => {
  const classes = useStyles();
  const {
    characterListData,
    isCharacterListLoading,
    handleGetCharacterList,
  }: ContextTypes = useContext(ScriptBreakdownContext);
  const [isCharactersLoaded, setIsCharactersLoaded] = useState(false);
  const [isCreateNewCharacterModalOpen, setIsCreateNewCharacterModalOpen] =
    useState<boolean>(false);
  const [isConfirmCharacterModalOpen, setIsConfirmCharacterModalOpen] =
    useState<boolean>(false);
  const [editCharacterModalData, setEditCharacterModalData] = useState<{
    isOpen: boolean;
    character: Character | null;
  }>({
    isOpen: false,
    character: null,
  });
  const [characters, setCharacters] = useState<Character[]>([]);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    getAllCharacters();
  }, [projectId]);

  const getAllCharacters = async () => {
    try {
      await handleGetCharacterList(projectId, fileId);
      const res = await getAllCharactersApi(projectId, fileId);
      setCharacters(res.data.characters);
      setTotal(res.data.size);
      setIsCharactersLoaded(true);
    } catch (error) {
      toast.error("Error on getting characters");
    }
  };

  const handleDeleteCharacter = async (characterId: string) => {
    try {
      const res = await deleteCharacterApi({
        projectId,
        characterId,
      });
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Box className={classes.rightSideTitle}>
        <Typography className={classes.dotAndText} sx={{ py: 1 }}>
          <WhiteDot />
          <span>Characters</span>
        </Typography>
        <Typography sx={{ fontSize: "12px" }}>Total-{total}</Typography>
      </Box>
      <Box className={classes.rightSideTools}>
        <Button
          sx={{
            display: "flex",
            color: "#606479",
            gap: "5px",
            alignItems: "center",
            minWidth: "0px",
          }}
        >
          <Slideshow size={22} />
          <span>Card View</span>
        </Button>
        <Button
          sx={{
            display: "flex",
            color: "#606479",
            gap: "5px",
            alignItems: "center",
            minWidth: "0px",
          }}
        >
          <SortAscending size={22} />
          <span>Sort</span>
        </Button>
      </Box>
      <Box className={classes.shortListContainer}>
        <Box sx={{ height: "67vh", overflow: "auto" }}>
          {isCharactersLoaded ? (
            <>
              {characters?.length ? (
                <Grid container spacing={2} alignItems="stretch">
                  {characters?.length
                    ? characters?.map((singleCharacter: Character) => (
                        <Grid
                          item
                          md={3}
                          key={singleCharacter?.charId}
                          sx={{ display: "flex" }}
                        >
                          <Card
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              flexGrow: 1,
                            }}
                          >
                            <CardContent
                              sx={{
                                backgroundColor: "#1B1D28",
                                p: 1,
                                borderRadius: "10px",
                                paddingBottom: "0px",
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                                flexGrow: 1,
                              }}
                            >
                              <img
                                src={
                                  singleCharacter?.characterReferenceImages[0]
                                    ?.signedUrl ?? NoCharacterImage
                                }
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "250px",
                                  objectFit: "cover",
                                }}
                              />
                              <Box
                                display="flex"
                                alignItems="flex-start"
                                justifyContent="space-between"
                                gap={1}
                                mt={1}
                              >
                                <Typography
                                  sx={{
                                    textAlign: "start",
                                    fontSize: "20px !important",
                                    fontWeight: 600,
                                  }}
                                >
                                  {singleCharacter?.characterName}
                                </Typography>
                                <Box
                                  display="flex"
                                  alignItems="flex-start"
                                  justifyContent="space-between"
                                >
                                  <Button
                                    onClick={() =>
                                      setEditCharacterModalData({
                                        isOpen: true,
                                        character: singleCharacter,
                                      })
                                    }
                                  >
                                    <PencilLine size={20} />
                                  </Button>
                                  <Button
                                    onClick={() =>
                                      handleDeleteCharacter(
                                        singleCharacter?.charId
                                      )
                                    }
                                  >
                                    <Trash size={20} />
                                  </Button>
                                </Box>
                              </Box>
                              <Typography
                                sx={{ textAlign: "start", color: "gray" }}
                              >
                                Age:{" "}
                                <span style={{ color: "white" }}>
                                  {singleCharacter?.characterAge}
                                </span>
                              </Typography>
                              <Typography
                                sx={{ textAlign: "start", color: "gray" }}
                              >
                                Gender:{" "}
                                <span style={{ color: "white" }}>
                                  {singleCharacter?.characterGender}
                                </span>
                              </Typography>
                              <Typography
                                sx={{ textAlign: "start", color: "gray" }}
                              >
                                Ethnicity:{" "}
                                <span style={{ color: "white" }}>
                                  {singleCharacter?.characterEthnicity}
                                </span>
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))
                    : ""}
                  <Grid item md={3} sx={{ display: "flex" }}>
                    <Card
                      onClick={() => setIsCreateNewCharacterModalOpen(true)}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                      }}
                    >
                      <CardContent
                        sx={{
                          backgroundColor: "#1B1D28",
                          p: 1,
                          borderRadius: "10px",
                          paddingBottom: "0px",
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                          flexGrow: 1,
                          justifyContent: "center", // Center the button text
                          alignItems: "center",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        Create New <br />
                        Character
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              ) : (
                <Box
                  sx={{
                    height: "67vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <IdentifyCharactersSvg />
                  <Button
                    variant="contained"
                    sx={{ py: 1, px: 4, mt: 4 }}
                    startIcon={<PersonSimple />}
                    onClick={() => setIsConfirmCharacterModalOpen(true)}
                  >
                    Identify Characters
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <Loader />
          )}
        </Box>
      </Box>
      <Modal
        open={isCreateNewCharacterModalOpen}
        onClose={() => setIsCreateNewCharacterModalOpen(false)}
        className={classes.flexCenter}
      >
        <CharacterSettingsModal
          projectId={projectId}
          fileId={fileId}
          handleClose={() => setIsCreateNewCharacterModalOpen(false)}
          handleUpdate={getAllCharacters}
          type="new"
        />
      </Modal>
      <Modal
        open={editCharacterModalData?.isOpen}
        onClose={() =>
          setEditCharacterModalData({
            isOpen: false,
            character: null,
          })
        }
        className={classes.flexCenter}
      >
        <CharacterSettingsModal
          projectId={projectId}
          fileId={fileId}
          handleClose={() => () =>
            setEditCharacterModalData({
              isOpen: false,
              character: null,
            })}
          handleUpdate={getAllCharacters}
          type="edit"
          characterDetails={editCharacterModalData?.character}
        />
      </Modal>
      <Modal
        open={isConfirmCharacterModalOpen}
        onClose={() => setIsConfirmCharacterModalOpen(false)}
        className={classes.flexCenter}
      >
        <ConfirmCharactersModal
          handleClose={() => setIsConfirmCharacterModalOpen(false)}
          handleUpdate={getAllCharacters}
          projectId={projectId}
          fileId={fileId}
        />
      </Modal>
    </div>
  );
};

export default CharactersViewer;
