import { makeStyles } from "@material-ui/core/styles";
import { themeType } from "../../../types/theme-types";

const useStyles = makeStyles((theme: themeType) => ({
  // create new project modal styles
  root: {
    width: "45%",
    backgroundColor: "#1B1D28",
    padding: "20px",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  createProjectRoot: {
    width: "30%",
    backgroundColor: "#1B1D28",
    padding: "20px",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  createProjectButton: {
    width: "100%",
    backgroundColor: "#252A38 !important",
    borderRadius: "10px",
    padding: "10px !important",
    color: "white !important",
    border: "1px solid #606479 !important",
  },
  projectDetails: {
    backgroundColor: "#252A38",
    padding: "6px 10px",
    borderRadius: "10px",
    marginBottom: "10px",
  },
  backButton: {
    borderColor: "#6C65D9",
    color: "white",
    width: "150px",
    borderRadius: "10px",
  },
  exportButton: {
    backgroundColor: "#6C65D9",
    borderRadius: "10px",
  },

  //movie colab log in modal
  movieColabLoginRoot: {
    width: "30%",
    backgroundColor: "#1B1D28",
    padding: "20px",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    color: "#606479",
  },

  inputField: {
    marginTop: "10px !important",
    outline: "none",
    borderRadius: "10px",
    backgroundColor: "#252A38",
    "& .MuiInputBase-input": {
      color: "#606479",
      padding: "10px",
    },
  },
  //common styles
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexAndGap: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
}));

export default useStyles;
