import { Toaster } from "react-hot-toast";

const ToastMessage = () => {
  return (
    <Toaster
      position="bottom-right"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: "",
        duration: 3000,
        style: {
          background: "#363636",
          color: "#fff",
        },

        // Default options for specific types
        success: {
          duration: 3000,
          style: {
            background: "green",
            color: "#fff",
          },
        },
        loading: {
          duration: 1000,
        },
      }}
    />
  );
};

export default ToastMessage;
